import produce from 'immer';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type EnvironmentsResponseEnvironment } from '../tools/openapi';

interface Environment {
	id: string;
	name: string;
}

const initialState = {
	environments: [] as EnvironmentsResponseEnvironment[],
	environment: { id: '', name: 'Select Environment' },
};

const environmentSlice = createSlice({
	name: 'environment',
	initialState,
	reducers: {
		setEnvironment: (state, action: PayloadAction<Environment>) => {
			return produce(state, (draftState) => {
				draftState.environment = action.payload;
			});
		},
		setEnvironmentList: (
			state,
			action: PayloadAction<EnvironmentsResponseEnvironment[]>
		) => {
			return produce(state, (draftState) => {
				draftState.environments = action.payload;
			});
		},
	},
});

export const { setEnvironment, setEnvironmentList } = environmentSlice.actions;
export default environmentSlice.reducer;
