import { type MenuProps } from 'antd';
import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { type AccountMemberResponse } from '../../../tools/openapi';
import DropdownButton from '../../../shared/DropdownButton';
import AccountMemberInRowDelete from './AccountMemberInRowDelete';

interface Props {
	record: AccountMemberResponse;
}

const AccountMemberDropDownButton: React.FC<Props> = (props: Props) => {
	const items: MenuProps['items'] = [
		{
			label: <AccountMemberInRowDelete record={props.record} />,
			key: '1',
			danger: true,
			icon: <DeleteOutlined />,
		},
	];

	return <DropdownButton menu={items} />;
};

export default React.memo(AccountMemberDropDownButton);
