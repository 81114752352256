import { type MenuProps } from 'antd';
import React from 'react';
import { EyeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import DropdownButton from '../../../../shared/DropdownButton';

interface Props {
	id: string;
}

const AccessSessionsDropDownButton: React.FC<Props> = (props: Props) => {
	const navigate = useNavigate();

	const openAccessSessionDetail = (id: string): void => {
		navigate(`/access_session/detail/${id}`);
	};

	const items: MenuProps['items'] = [
		{
			label: (
				<a
					onClick={() => {
						openAccessSessionDetail(props.id);
					}}
				>
					View Details
				</a>
			),
			key: '0',
			icon: <EyeOutlined />,
		},
	];

	return <DropdownButton menu={items} />;
};

export default React.memo(AccessSessionsDropDownButton);
