/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BatchCreateUsersRequestUser } from './BatchCreateUsersRequestUser';
import {
    BatchCreateUsersRequestUserFromJSON,
    BatchCreateUsersRequestUserFromJSONTyped,
    BatchCreateUsersRequestUserToJSON,
} from './BatchCreateUsersRequestUser';

/**
 * 
 * @export
 * @interface BatchCreateUsersRequest
 */
export interface BatchCreateUsersRequest {
    /**
     * 
     * @type {string}
     * @memberof BatchCreateUsersRequest
     */
    customerId: string;
    /**
     * 
     * @type {Array<BatchCreateUsersRequestUser>}
     * @memberof BatchCreateUsersRequest
     */
    users: Array<BatchCreateUsersRequestUser>;
}

/**
 * Check if a given object implements the BatchCreateUsersRequest interface.
 */
export function instanceOfBatchCreateUsersRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "customerId" in value;
    isInstance = isInstance && "users" in value;

    return isInstance;
}

export function BatchCreateUsersRequestFromJSON(json: any): BatchCreateUsersRequest {
    return BatchCreateUsersRequestFromJSONTyped(json, false);
}

export function BatchCreateUsersRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchCreateUsersRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': json['customerId'],
        'users': ((json['users'] as Array<any>).map(BatchCreateUsersRequestUserFromJSON)),
    };
}

export function BatchCreateUsersRequestToJSON(value?: BatchCreateUsersRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': value.customerId,
        'users': ((value.users as Array<any>).map(BatchCreateUsersRequestUserToJSON)),
    };
}

