/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccessFactor } from './AccessFactor';
import {
    AccessFactorFromJSON,
    AccessFactorFromJSONTyped,
    AccessFactorToJSON,
} from './AccessFactor';
import type { SecurityScoreRating } from './SecurityScoreRating';
import {
    SecurityScoreRatingFromJSON,
    SecurityScoreRatingFromJSONTyped,
    SecurityScoreRatingToJSON,
} from './SecurityScoreRating';

/**
 * 
 * @export
 * @interface AccessSessionHistoriesResponseAccessSession
 */
export interface AccessSessionHistoriesResponseAccessSession {
    /**
     * 
     * @type {string}
     * @memberof AccessSessionHistoriesResponseAccessSession
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof AccessSessionHistoriesResponseAccessSession
     */
    duration?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof AccessSessionHistoriesResponseAccessSession
     */
    startedAt?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccessSessionHistoriesResponseAccessSession
     */
    succeeded?: boolean | null;
    /**
     * 
     * @type {AccessFactor}
     * @memberof AccessSessionHistoriesResponseAccessSession
     */
    concludedFactor?: AccessFactor | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessSessionHistoriesResponseAccessSession
     */
    tags?: Array<string> | null;
    /**
     * 
     * @type {SecurityScoreRating}
     * @memberof AccessSessionHistoriesResponseAccessSession
     */
    securityScoreRating?: SecurityScoreRating | null;
    /**
     * 
     * @type {string}
     * @memberof AccessSessionHistoriesResponseAccessSession
     */
    customerName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessSessionHistoriesResponseAccessSession
     */
    customerId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessSessionHistoriesResponseAccessSession
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessSessionHistoriesResponseAccessSession
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessSessionHistoriesResponseAccessSession
     */
    photoUrl?: string | null;
}

/**
 * Check if a given object implements the AccessSessionHistoriesResponseAccessSession interface.
 */
export function instanceOfAccessSessionHistoriesResponseAccessSession(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function AccessSessionHistoriesResponseAccessSessionFromJSON(json: any): AccessSessionHistoriesResponseAccessSession {
    return AccessSessionHistoriesResponseAccessSessionFromJSONTyped(json, false);
}

export function AccessSessionHistoriesResponseAccessSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessSessionHistoriesResponseAccessSession {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'startedAt': !exists(json, 'startedAt') ? undefined : (json['startedAt'] === null ? null : new Date(json['startedAt'])),
        'succeeded': !exists(json, 'succeeded') ? undefined : json['succeeded'],
        'concludedFactor': !exists(json, 'concludedFactor') ? undefined : AccessFactorFromJSON(json['concludedFactor']),
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'securityScoreRating': !exists(json, 'securityScoreRating') ? undefined : SecurityScoreRatingFromJSON(json['securityScoreRating']),
        'customerName': !exists(json, 'customerName') ? undefined : json['customerName'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'photoUrl': !exists(json, 'photoUrl') ? undefined : json['photoUrl'],
    };
}

export function AccessSessionHistoriesResponseAccessSessionToJSON(value?: AccessSessionHistoriesResponseAccessSession | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'duration': value.duration,
        'startedAt': value.startedAt === undefined ? undefined : (value.startedAt === null ? null : value.startedAt.toISOString()),
        'succeeded': value.succeeded,
        'concludedFactor': AccessFactorToJSON(value.concludedFactor),
        'tags': value.tags,
        'securityScoreRating': SecurityScoreRatingToJSON(value.securityScoreRating),
        'customerName': value.customerName,
        'customerId': value.customerId,
        'userName': value.userName,
        'userId': value.userId,
        'photoUrl': value.photoUrl,
    };
}

