/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccountMemberResponse } from './AccountMemberResponse';
import {
    AccountMemberResponseFromJSON,
    AccountMemberResponseFromJSONTyped,
    AccountMemberResponseToJSON,
} from './AccountMemberResponse';

/**
 * 
 * @export
 * @interface AccountMembersResponse
 */
export interface AccountMembersResponse {
    /**
     * 
     * @type {Array<AccountMemberResponse>}
     * @memberof AccountMembersResponse
     */
    accountMembers: Array<AccountMemberResponse>;
}

/**
 * Check if a given object implements the AccountMembersResponse interface.
 */
export function instanceOfAccountMembersResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accountMembers" in value;

    return isInstance;
}

export function AccountMembersResponseFromJSON(json: any): AccountMembersResponse {
    return AccountMembersResponseFromJSONTyped(json, false);
}

export function AccountMembersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountMembersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accountMembers': ((json['accountMembers'] as Array<any>).map(AccountMemberResponseFromJSON)),
    };
}

export function AccountMembersResponseToJSON(value?: AccountMembersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accountMembers': ((value.accountMembers as Array<any>).map(AccountMemberResponseToJSON)),
    };
}

