import produce from 'immer';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type CustomersResponseCustomer } from '../tools/openapi';

const initialState = {
	customers: [] as CustomersResponseCustomer[],
	customer: {},
	count: 0,
	pageNumber: 1,
	PageSize: 10,
	useCustomPagination: false,
	activeOnly: true,
	loading: true,
	isCreateModalVisible: false,
	modalOnUpdate: false,
};

const customerSlice = createSlice({
	name: 'customer',
	initialState,
	reducers: {
		setCurrentCustomer: (
			state,
			action: PayloadAction<CustomersResponseCustomer>
		) => {
			return produce(state, (draftState) => {
				draftState.customer = action.payload;
			});
		},
		setCustomerList: (
			state,
			action: PayloadAction<CustomersResponseCustomer[]>
		) => {
			return produce(state, (draftState) => {
				draftState.customers = action.payload;
			});
		},
		setCustomerCount: (state, action: PayloadAction<number>) => {
			return produce(state, (draftState) => {
				draftState.count = action.payload;
			});
		},
		setPageSize: (state, action: PayloadAction<number>) => {
			return produce(state, (draftState) => {
				draftState.PageSize = action.payload;
			});
		},
		setPageNumber: (state, action: PayloadAction<number>) => {
			return produce(state, (draftState) => {
				draftState.pageNumber = action.payload;
			});
		},
		setUseCustomPagination: (state, action: PayloadAction<boolean>) => {
			return produce(state, (draftState) => {
				draftState.useCustomPagination = action.payload;
			});
		},
		setActiveOnly: (state, action: PayloadAction<boolean>) => {
			return produce(state, (draftState) => {
				draftState.activeOnly = action.payload;
			});
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			return produce(state, (draftState) => {
				draftState.loading = action.payload;
			});
		},
		setIsCreateModalVisible: (state, action: PayloadAction<boolean>) => {
			return produce(state, (draftState) => {
				draftState.isCreateModalVisible = action.payload;
			});
		},
		setModalOnUpdate: (state, action: PayloadAction<boolean>) => {
			return produce(state, (draftState) => {
				draftState.modalOnUpdate = action.payload;
			});
		},
	},
});

export const {
	setCurrentCustomer,
	setCustomerList,
	setCustomerCount,
	setPageNumber,
	setPageSize,
	setUseCustomPagination,
	setActiveOnly,
	setLoading,
	setIsCreateModalVisible,
	setModalOnUpdate,
} = customerSlice.actions;
export default customerSlice.reducer;
