import produce from 'immer';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type UsersResponseUser, type User } from '../tools/openapi';

const initialState = {
	users: [] as UsersResponseUser[],
	user: {},
	count: 0,
	pageNumber: 1,
	PageSize: 10,
	useCustomPagination: false,
	activeOnly: true,
	loading: true,
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setCurrentUser: (state, action: PayloadAction<User>) => {
			return produce(state, (draftState) => {
				draftState.user = action.payload;
			});
		},
		setUserList: (state, action: PayloadAction<UsersResponseUser[]>) => {
			return produce(state, (draftState) => {
				draftState.users = action.payload;
			});
		},
		setUserCount: (state, action: PayloadAction<number>) => {
			return produce(state, (draftState) => {
				draftState.count = action.payload;
			});
		},
		setPageSize: (state, action: PayloadAction<number>) => {
			return produce(state, (draftState) => {
				draftState.PageSize = action.payload;
			});
		},
		setPageNumber: (state, action: PayloadAction<number>) => {
			return produce(state, (draftState) => {
				draftState.pageNumber = action.payload;
			});
		},
		setUseCustomPagination: (state, action: PayloadAction<boolean>) => {
			return produce(state, (draftState) => {
				draftState.useCustomPagination = action.payload;
			});
		},
		setActiveOnly: (state, action: PayloadAction<boolean>) => {
			return produce(state, (draftState) => {
				draftState.activeOnly = action.payload;
			});
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			return produce(state, (draftState) => {
				draftState.loading = action.payload;
			});
		},
	},
});

export const {
	setCurrentUser,
	setUserList,
	setUserCount,
	setPageNumber,
	setPageSize,
	setUseCustomPagination,
	setActiveOnly,
	setLoading,
} = userSlice.actions;
export default userSlice.reducer;
