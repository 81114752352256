import { Typography } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import React from 'react';
import DataTable from '../../../shared/DataTable';
import { type ApiKey } from '../../../tools/openapi';
import AccessKeyDropdownButton from './AccessKeyDropdownButton';
import '../settings.scss';

interface Props {
	accessKeys: ApiKey[];
	fetchData: () => void;
}

const { Paragraph } = Typography;

const AccessKeysTable: React.FC<Props> = (props: Props) => {
	const columns: ColumnsType<ApiKey> = [
		{
			title: 'Created',
			dataIndex: 'createdAt',
			render: (_, record) => <span>{record.createdAt.toLocaleString()}</span>,
		},
		{
			title: 'Key',
			dataIndex: 'name',
			render: (text) => (
				<Paragraph className="access-paragraph" copyable>
					{text}
				</Paragraph>
			),
		},
		{
			title: 'Secret',
			dataIndex: 'secret',
			render: (_, record) => {
				return (
					<Paragraph className="access-paragraph access-secret" copyable>
						{record.secret}
					</Paragraph>
				);
			},
		},
		{
			title: 'Action',
			render: (_, record) => (
				<AccessKeyDropdownButton
					fetchData={props.fetchData}
					toDelete={record.id}
				/>
			),
		},
	];

	return (
		<DataTable
			data={props.accessKeys}
			columns={columns}
			pagination={false}
			rowKey={'id'}
			tableStyle={undefined}
		/>
	);
};

export default React.memo(AccessKeysTable);
