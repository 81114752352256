/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const StepTag = {
    Incorrect: 'INCORRECT'
} as const;
export type StepTag = typeof StepTag[keyof typeof StepTag];


export function StepTagFromJSON(json: any): StepTag {
    return StepTagFromJSONTyped(json, false);
}

export function StepTagFromJSONTyped(json: any, ignoreDiscriminator: boolean): StepTag {
    return json as StepTag;
}

export function StepTagToJSON(value?: StepTag | null): any {
    return value as any;
}

