/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FaceAccessResponseIdentified } from './FaceAccessResponseIdentified';
import {
    FaceAccessResponseIdentifiedFromJSON,
    FaceAccessResponseIdentifiedFromJSONTyped,
    FaceAccessResponseIdentifiedToJSON,
} from './FaceAccessResponseIdentified';
import type { FaceAccessResponseNoMatch } from './FaceAccessResponseNoMatch';
import {
    FaceAccessResponseNoMatchFromJSON,
    FaceAccessResponseNoMatchFromJSONTyped,
    FaceAccessResponseNoMatchToJSON,
} from './FaceAccessResponseNoMatch';

/**
 * 
 * @export
 * @interface FaceAccessResponse
 */
export interface FaceAccessResponse {
    /**
     * 
     * @type {string}
     * @memberof FaceAccessResponse
     */
    sessionId: string;
    /**
     * 
     * @type {string}
     * @memberof FaceAccessResponse
     */
    photoUrl: string;
    /**
     * 
     * @type {FaceAccessResponseIdentified}
     * @memberof FaceAccessResponse
     */
    identified?: FaceAccessResponseIdentified | null;
    /**
     * 
     * @type {FaceAccessResponseNoMatch}
     * @memberof FaceAccessResponse
     */
    noMatch?: FaceAccessResponseNoMatch | null;
}

/**
 * Check if a given object implements the FaceAccessResponse interface.
 */
export function instanceOfFaceAccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sessionId" in value;
    isInstance = isInstance && "photoUrl" in value;

    return isInstance;
}

export function FaceAccessResponseFromJSON(json: any): FaceAccessResponse {
    return FaceAccessResponseFromJSONTyped(json, false);
}

export function FaceAccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FaceAccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessionId': json['sessionId'],
        'photoUrl': json['photoUrl'],
        'identified': !exists(json, 'identified') ? undefined : FaceAccessResponseIdentifiedFromJSON(json['identified']),
        'noMatch': !exists(json, 'noMatch') ? undefined : FaceAccessResponseNoMatchFromJSON(json['noMatch']),
    };
}

export function FaceAccessResponseToJSON(value?: FaceAccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sessionId': value.sessionId,
        'photoUrl': value.photoUrl,
        'identified': FaceAccessResponseIdentifiedToJSON(value.identified),
        'noMatch': FaceAccessResponseNoMatchToJSON(value.noMatch),
    };
}

