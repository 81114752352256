import {
	Configuration,
	MgmtApi,
	type ConfigurationParameters,
} from '../../tools/openapi';
import { useAppSelector } from '../../store/hooks';
import { type RootState } from '../../store/store';
// import { message } from 'antd';

const useMgmtApi = (): MgmtApi => {
	const accessToken = useAppSelector(
		(state: RootState) => state.startState.accessToken
	);

	const configParams: ConfigurationParameters = {
		headers: { Authorization: `Bearer ${accessToken}` },
		basePath: process.env.REACT_APP_API_BASE_URL,
	};
	const openApiConfiguration = new Configuration(configParams);
	const mgmtAPi = new MgmtApi(openApiConfiguration);

	// function withGlobalErrorHandling<T extends (...args: any[]) => any>(
	// 	apiFunction: T
	//   ): T {
	// 	return (async function (...args: Parameters<T>) {
	// 	  try {
	// 		console.log(apiFunction)
	// 		const response = apiFunction(...args);
	// 		return response;
	// 	  } catch (error: unknown) {
	// 		// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
	// 		if ((error as { response?: any }).response) {
	// 		  const errorData = (error as { response: { data: { detail: string; logref: string } } }).response.data;
	// 		  void message.error(`${errorData.detail} ${errorData.logref}`);
	// 		} else {
	// 		  console.error("Unexpected Error:", error);
	// 		}
	// 	  }
	// 	}) as T;
	//   }

	// const handler: ProxyHandler<MgmtApi> = {
	//   get: function (target, prop) {
	// 	if (typeof target[prop as keyof MgmtApi] === "function") {
	// 	  return withGlobalErrorHandling(target[prop as keyof MgmtApi]);
	// 	}
	// 	return target[prop as keyof MgmtApi];
	//   },
	// };

	// return new Proxy(mgmtAPi, handler);

	return mgmtAPi;
};
export default useMgmtApi;
