/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetAccessStepTagsRequest
 */
export interface SetAccessStepTagsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof SetAccessStepTagsRequest
     */
    tags: Array<string>;
}

/**
 * Check if a given object implements the SetAccessStepTagsRequest interface.
 */
export function instanceOfSetAccessStepTagsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tags" in value;

    return isInstance;
}

export function SetAccessStepTagsRequestFromJSON(json: any): SetAccessStepTagsRequest {
    return SetAccessStepTagsRequestFromJSONTyped(json, false);
}

export function SetAccessStepTagsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetAccessStepTagsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tags': json['tags'],
    };
}

export function SetAccessStepTagsRequestToJSON(value?: SetAccessStepTagsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tags': value.tags,
    };
}

