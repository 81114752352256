import React from 'react';
import { type User } from '../../../../../../tools/openapi';
import { useAppSelector } from '../../../../../../store/hooks';
import { type RootState } from '../../../../../../store/store';
import useMgmtApiWrapper from '../../../../../../hooks/openapi/useMgmtApiWrapper';

interface Props {
	fetchUser: () => void;
}

const UserInformationActivateUser: React.FC<Props> = (props: Props) => {
	const mgmtApiWrapper = useMgmtApiWrapper();

	const userDetail = useAppSelector(
		(state: RootState) => state.userDetail.user as User
	);

	const activateUser = (): void => {
		void mgmtApiWrapper
			.activateUser({ userId: userDetail.id })
			.then(() => {
				props.fetchUser();
			})
	};

	return (
		<a type="primary" onClick={activateUser}>
			Activate User
		</a>
	);
};
export default React.memo(UserInformationActivateUser);
