/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PinConfig } from './PinConfig';
import {
    PinConfigFromJSON,
    PinConfigFromJSONTyped,
    PinConfigToJSON,
} from './PinConfig';

/**
 * 
 * @export
 * @interface CustomerSettingsResponse
 */
export interface CustomerSettingsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CustomerSettingsResponse
     */
    allowManualEntry: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerSettingsResponse
     */
    allowFaceRegistrationOnClient: boolean;
    /**
     * 
     * @type {PinConfig}
     * @memberof CustomerSettingsResponse
     */
    pinCodeDigit: PinConfig;
    /**
     * 
     * @type {number}
     * @memberof CustomerSettingsResponse
     */
    pinAttemptLimit: number;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerSettingsResponse
     */
    requireApprovalOnFaceRegistration: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerSettingsResponse
     */
    autoTakingPhotoOnIdentification: boolean;
}

/**
 * Check if a given object implements the CustomerSettingsResponse interface.
 */
export function instanceOfCustomerSettingsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "allowManualEntry" in value;
    isInstance = isInstance && "allowFaceRegistrationOnClient" in value;
    isInstance = isInstance && "pinCodeDigit" in value;
    isInstance = isInstance && "pinAttemptLimit" in value;
    isInstance = isInstance && "requireApprovalOnFaceRegistration" in value;
    isInstance = isInstance && "autoTakingPhotoOnIdentification" in value;

    return isInstance;
}

export function CustomerSettingsResponseFromJSON(json: any): CustomerSettingsResponse {
    return CustomerSettingsResponseFromJSONTyped(json, false);
}

export function CustomerSettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerSettingsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowManualEntry': json['allowManualEntry'],
        'allowFaceRegistrationOnClient': json['allowFaceRegistrationOnClient'],
        'pinCodeDigit': PinConfigFromJSON(json['pinCodeDigit']),
        'pinAttemptLimit': json['pinAttemptLimit'],
        'requireApprovalOnFaceRegistration': json['requireApprovalOnFaceRegistration'],
        'autoTakingPhotoOnIdentification': json['autoTakingPhotoOnIdentification'],
    };
}

export function CustomerSettingsResponseToJSON(value?: CustomerSettingsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowManualEntry': value.allowManualEntry,
        'allowFaceRegistrationOnClient': value.allowFaceRegistrationOnClient,
        'pinCodeDigit': PinConfigToJSON(value.pinCodeDigit),
        'pinAttemptLimit': value.pinAttemptLimit,
        'requireApprovalOnFaceRegistration': value.requireApprovalOnFaceRegistration,
        'autoTakingPhotoOnIdentification': value.autoTakingPhotoOnIdentification,
    };
}

