import Title from 'antd/es/typography/Title';
import React from 'react';
import CustomerDeletion from './CustomerDeletion';

const CustomerDangerZone: React.FC = () => {

	return (
		<section className="section">
			<div>
				<Title className='section-header' level={5}>Danger Zone</Title>
				<CustomerDeletion />
			</div>
		</section>
	);
};

export default React.memo(CustomerDangerZone);
