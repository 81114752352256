import React, { useState } from 'react';
import DeleteConfirmation from '../../../../../../shared/DeleteConfirmation';
import {
	type ActiveRegistrationResponse,
	type DeleteFaceRegistrationRequest,
} from '../../../../../../tools/openapi';
import useMgmtApiWrapper from '../../../../../../hooks/openapi/useMgmtApiWrapper';

interface Props {
	dataActive: ActiveRegistrationResponse | undefined;
	fetchFaceRegistrations: () => void;
}

const UserFaceActiveDropdown: React.FC<Props> = ({
	dataActive,
	fetchFaceRegistrations,
}) => {
	const mgmtApiWrapper = useMgmtApiWrapper();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const handleDelete = (): void => {
		const requestBody: DeleteFaceRegistrationRequest = {
			faceRegistrationId: dataActive?.registration?.id as string,
		};
		void mgmtApiWrapper.deleteFaceRegistration(requestBody).then(() => {
			fetchFaceRegistrations();
			setIsModalVisible(false);
		});
	};
	return (
		<>
			<a
				onClick={() => {
					setIsModalVisible(true);
				}}
			>
				Delete Active Registration
			</a>
			<DeleteConfirmation
				title="Are you sure? "
				message={`This action will permanently delete the face registration record. It cannot be undone. `}
				visible={isModalVisible}
				centered={true}
				inputDisplay={'none'}
				onCancel={() => {
					setIsModalVisible(false);
				}}
				onConfirm={handleDelete}
			/>
		</>
	);
};
export default React.memo(UserFaceActiveDropdown);
