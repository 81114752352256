import React, { useEffect, useState } from 'react';
import { Card, Skeleton, Statistic } from 'antd';
import { useNavigate } from 'react-router-dom';
import useMgmtApiWrapper from '../../hooks/openapi/useMgmtApiWrapper';

interface Props {
	id: string | undefined;
}

const ActivityPageTotalCustomers: React.FC<Props> = (props: Props) => {
	const mgmtApiWrapper = useMgmtApiWrapper();
	const navigate = useNavigate();

	const [totalCustomers, setTotalCustomers] = useState(0);
	const [isTotalCustomerLoading, setIsTotalCustomerLoading] = useState(true);

	const handleCustomersClick = (): void => {
		navigate('/customer');
	};

	useEffect(() => {
		setIsTotalCustomerLoading(true);
		const environmentId = props.id;
		if (environmentId != null) {
			void mgmtApiWrapper
				.getCustomerCountByEnvironmentId({
					environmentId,
				})
				.then((r) => {
					if (r !== undefined) {
						setTotalCustomers(r.size);
					}
					setIsTotalCustomerLoading(false);
				})
		}
	}, []);

	if (isTotalCustomerLoading) {
		return (
			<Card onClick={handleCustomersClick}>
				<Statistic
					title="Number of Customers"
					valueRender={() => (
						<Skeleton
							loading={isTotalCustomerLoading}
							paragraph={false}
							title={{ style: { marginTop: 12, marginBottom: 12 } }}
						/>
					)}
				/>
			</Card>
		);
	} else {
		return (
			<Card onClick={handleCustomersClick}>
				<Statistic title="Number of Customers" value={totalCustomers} />
			</Card>
		);
	}
};

export default React.memo(ActivityPageTotalCustomers);
