/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useRef } from 'react';
import { Spin } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import DataTable from '../../../../shared/DataTable';
import { type CustomersResponseCustomer } from '../../../../tools/openapi';
import { setPageNumber, setPageSize } from '../../../../store/customerReducer';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { type RootState } from '../../../../store/store';
import '../Customers.scss';
import CustomerDropdownButton from './CustomerDropdownButton';
import CustomerNameIDInRow from './CustomerNameIDInRow';

const CustomersTable: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const tableRef = useRef<HTMLDivElement | null>(null);
	const isFirstRender = useRef(true);

	const customers = useAppSelector(
		(state: RootState) => state.customer.customers
	);

	const customerCount = useAppSelector(
		(state: RootState) => state.customer.count
	);

	const page = useAppSelector((state: RootState) => state.customer.pageNumber);

	const size = useAppSelector((state: RootState) => state.customer.PageSize);

	const useCustomPagination = useAppSelector(
		(state: RootState) => state.customer.useCustomPagination
	);

	const loading = useAppSelector((state: RootState) => state.customer.loading);

	const openCustomerDetail = (id: string): void => {
		navigate(`/customer/detail/${id}`);
	};

	const scrollToFirstRow = (): void => {
		if (!isFirstRender.current && tableRef.current != null) {
			tableRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	const pageChange = (page: number): void => {
		dispatch(setPageNumber(page));
	};

	const pageSizeChange = (cur: number, size: number): void => {
		dispatch(setPageSize(size));
	};

	useEffect(() => {
		dispatch(setPageNumber(1));
	}, [size]);

	useEffect(() => {
		scrollToFirstRow();
	}, [page, size]);

	useEffect(() => {
		isFirstRender.current = false;
	}, []);

	const columns: ColumnsType<CustomersResponseCustomer> = [
		{
			title: 'Customer',
			dataIndex: 'name',
			render: (_, record) => <CustomerNameIDInRow record={record} />,
		},
		{
			title: 'Size',
			dataIndex: 'size',
			render: (_, record) => <span>{record.size}</span>,
		},
		{
			title: 'Created',
			dataIndex: 'createdAt',
			render: (_, record) => <span>{record.createdAt?.toLocaleString()}</span>,
		},
		{
			title: 'Last Active',
			dataIndex: 'lastActive',
			render: (_, record) => <span>{record.lastActive?.toLocaleString()}</span>,
		},
		{
			title: 'Actions',
			render: (_, record) => <CustomerDropdownButton record={record} />,
		},
	];

	if (loading) {
		return <Spin className="loading flex-all-center" size="large" />;
	} else {
		return (
			<div ref={tableRef}>
				<div>
					<DataTable
						onRow={(record: CustomersResponseCustomer) => {
							return {
								onClick: (): void => {
									openCustomerDetail(record.id);
								},
								style: { cursor: 'pointer' },
							};
						}}
						data={customers}
						columns={columns}
						rowKey={'id'}
						tableStyle={undefined}
						pagination={true}
						paginationOption={{
							page,
							pageChange,
							customPagination: useCustomPagination,
							pageSize: size,
							sizeChange: pageSizeChange,
							totalCount: customerCount,
							showSizeChanger: true,
							pageSizeOptions: [10, 20, 50],
						}}
					/>
				</div>
			</div>
		);
	}
};

export default React.memo(CustomersTable);
