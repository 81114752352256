/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FaceRegistration } from './FaceRegistration';
import {
    FaceRegistrationFromJSON,
    FaceRegistrationFromJSONTyped,
    FaceRegistrationToJSON,
} from './FaceRegistration';

/**
 * 
 * @export
 * @interface ActiveRegistrationResponse
 */
export interface ActiveRegistrationResponse {
    /**
     * 
     * @type {FaceRegistration}
     * @memberof ActiveRegistrationResponse
     */
    registration?: FaceRegistration | null;
}

/**
 * Check if a given object implements the ActiveRegistrationResponse interface.
 */
export function instanceOfActiveRegistrationResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ActiveRegistrationResponseFromJSON(json: any): ActiveRegistrationResponse {
    return ActiveRegistrationResponseFromJSONTyped(json, false);
}

export function ActiveRegistrationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActiveRegistrationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'registration': !exists(json, 'registration') ? undefined : FaceRegistrationFromJSON(json['registration']),
    };
}

export function ActiveRegistrationResponseToJSON(value?: ActiveRegistrationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'registration': FaceRegistrationToJSON(value.registration),
    };
}

