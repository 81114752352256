/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccessFactor } from './AccessFactor';
import {
    AccessFactorFromJSON,
    AccessFactorFromJSONTyped,
    AccessFactorToJSON,
} from './AccessFactor';

/**
 * 
 * @export
 * @interface NoMatchInstruction
 */
export interface NoMatchInstruction {
    /**
     * 
     * @type {boolean}
     * @memberof NoMatchInstruction
     */
    concluded: boolean;
    /**
     * 
     * @type {number}
     * @memberof NoMatchInstruction
     */
    dismissTimeoutMs: number;
    /**
     * 
     * @type {boolean}
     * @memberof NoMatchInstruction
     */
    offerRegistration: boolean;
    /**
     * 
     * @type {Array<AccessFactor>}
     * @memberof NoMatchInstruction
     */
    fallbackSteps: Array<AccessFactor>;
}

/**
 * Check if a given object implements the NoMatchInstruction interface.
 */
export function instanceOfNoMatchInstruction(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "concluded" in value;
    isInstance = isInstance && "dismissTimeoutMs" in value;
    isInstance = isInstance && "offerRegistration" in value;
    isInstance = isInstance && "fallbackSteps" in value;

    return isInstance;
}

export function NoMatchInstructionFromJSON(json: any): NoMatchInstruction {
    return NoMatchInstructionFromJSONTyped(json, false);
}

export function NoMatchInstructionFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoMatchInstruction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'concluded': json['concluded'],
        'dismissTimeoutMs': json['dismissTimeoutMs'],
        'offerRegistration': json['offerRegistration'],
        'fallbackSteps': ((json['fallbackSteps'] as Array<any>).map(AccessFactorFromJSON)),
    };
}

export function NoMatchInstructionToJSON(value?: NoMatchInstruction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'concluded': value.concluded,
        'dismissTimeoutMs': value.dismissTimeoutMs,
        'offerRegistration': value.offerRegistration,
        'fallbackSteps': ((value.fallbackSteps as Array<any>).map(AccessFactorToJSON)),
    };
}

