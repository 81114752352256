import React from 'react';
import { Descriptions } from 'antd';
import '../../CustomerDetail.scss';
import { useAppSelector } from '../../../../../store/hooks';
import { type RootState } from '../../../../../store/store';
import { type CustomerResponseCustomer } from '../../../../../tools/openapi';
import CustomerDescriptionDropdown from './CustomerDescriptionDropdown';

const CustomerDescription: React.FC = () => {
	const customerDetail = useAppSelector(
		(state: RootState) =>
			state.customerDetail.customer as CustomerResponseCustomer
	);

	const title = (
		<div className="customer-session-title">
			<span>Customer Information</span>
			<CustomerDescriptionDropdown />
		</div>
	);

	const descriptionItems = [
		{
			label: 'ID',
			content: customerDetail?.id,
		},
		{
			label: 'Name',
			content: customerDetail?.name,
		},
		{
			label: 'Created Date',
			content: customerDetail?.createdAt.toLocaleString(),
		},
		{
			label: 'Last Active',
			content: customerDetail?.lastActive?.toLocaleString(),
		},
		{
			label: 'Size',
			content: customerDetail?.size,
		},
	];

	return (
		<section className="section">
			<Descriptions title={title} layout="horizontal" column={1} bordered>
				{descriptionItems.map((item) => (
					<Descriptions.Item
						key={item.label}
						label={item.label}
						labelStyle={{ width: '12em', margin: '0.5em 0' }}
						contentStyle={{ margin: '0.5em 0 0 15em' }}
					>
						{item.content}
					</Descriptions.Item>
				))}
			</Descriptions>
		</section>
	);
};
export default React.memo(CustomerDescription);
