import { message } from 'antd';
import {
	type ApproveFaceRegistrationRequest,
	type RejectFaceRegistrationRequest,
	type DeleteFaceRegistrationRequest,
	type GetUsersRequest,
	type UsersResponse,
	type StartOperationRequest,
	type StartResponse,
	type GetSessionDetailsWithStepsRequest,
	type SessionDetailsWithStepsResponse,
	type GetAccessSessionHistoriesRequest,
	type AccessSessionHistoriesResponse,
	type CreateAccountMemberOperationRequest,
	type CreateAccountMemberResponse,
	type AccountMembersResponse,
	type DeleteAccountMemberRequest,
	type GetUserCountByEnvironmentIdRequest,
	type EnvironmentUserCountResponse,
	type FaceRegistrationsCountResponse,
	type GetTopCustomerFaceRegistrationsRequest,
	type GetFaceRegistrationCountRequest,
	type GetAccessStepAttemptCountRequest,
	type AccessStepAttemptCountsResponse,
	type EnvironmentCustomerCountResponse,
	type GetCustomerCountByEnvironmentIdRequest,
	type DeleteCustomerRequest,
	type CustomerResponse,
	type GetCustomerRequest,
	type UpdateCustomerSettingsOperationRequest,
	type CustomerSettingsResponse,
	type GetCustomerSettingsRequest,
	type CustomersResponse,
	type GetCustomersRequest,
	type CreateCustomerOperationRequest,
	type CreateCustomerResponse,
	type UpdateCustomerByIdRequest,
	type DeleteEnvironmentRequest,
	type GetEnvironmentRequest,
	type EnvironmentResponse,
	type CreateEnvironmentResponse,
	type CreateEnvironmentOperationRequest,
	type UpdateEnvironmentByIdRequest,
	type EnvironmentsResponse,
	type SwitchEnvironmentRequest,
	type ApiKeysResponse,
	type GetApiKeysRequest,
	type CreateApiKeyRequest,
	type CreateApiKeyResponse,
	type DeleteApiKeyRequest,
	type UpdateEnvironmentSettingsOperationRequest,
	type GetWebhookByEnvironmentIdRequest,
	type EnvironmentWebhookResponse,
	type DeactivateUserRequest,
	type DeleteUserRequest,
	type NewFaceRegistrationOperationRequest,
	type NewFaceRegistrationResponse,
	type SubmitFaceRegistrationRequest,
	type ActiveRegistrationResponse,
	type GetActiveRegistrationRequest,
	type GetSubmittedRegistrationRequest,
	type SubmittedRegistrationResponse,
	type UpdateUserByIdRequest,
	type ActivateUserRequest,
	type GetQrcodeRequest,
	type QrCodeResponseBody,
	type User,
	type GetUserByIdRequest,
	type CreateUserOperationRequest,
	type CreateUserResponse,
	type CreateOnboardingAccountOperationRequest,
	type ConfirmFaceAccessOperationRequest,
	type FaceAccessRequest,
	type FaceAccessResponse,
	type ConfirmedFaceAccessResponse,
	type UpdateCustomDataByIdRequest,
	type ConfirmPinAccessOperationRequest,
	type PinAccessResponse,
	type ConfirmedPinAccessResponse,
	type PinAccessOperationRequest,
	type ManualAccessOperationRequest,
	type ManualAccessResponse,
} from '../../tools/openapi';
import useMgmtApi from './useMgmtApi';
import { v4 as uuidv4 } from 'uuid';

class ApiWrapper {
	mgmtApi = useMgmtApi();
	async handleError(error: any, key?: string): Promise<void> {
		message.destroy(key);
		if (error.response !== null && error.response !== undefined) {
			const errorData = await error.response.json();
			void message.error(
				`${errorData.message as string} (Support Code: ${
					errorData.logref as string
				})`
			);
		} else {
			console.error('Error:', error);
		}
	}

	async start(
		requestBody: StartOperationRequest
	): Promise<StartResponse | undefined> {
		try {
			return await this.mgmtApi.start(requestBody);
		} catch (error) {
			await this.handleError(error);
		}
	}

	/* Access Session */
	async getSessionDetailsWithSteps(
		requestBody: GetSessionDetailsWithStepsRequest
	): Promise<SessionDetailsWithStepsResponse | undefined> {
		try {
			return await this.mgmtApi.getSessionDetailsWithSteps(requestBody);
		} catch (error) {
			await this.handleError(error);
		}
	}

	async getAccessSessionHistories(
		requestBody: GetAccessSessionHistoriesRequest
	): Promise<AccessSessionHistoriesResponse | undefined> {
		try {
			return await this.mgmtApi.getAccessSessionHistories(requestBody);
		} catch (error) {
			await this.handleError(error);
		}
	}

	async updateCustomDataById(
		requestBody: UpdateCustomDataByIdRequest
	): Promise<void> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			await this.mgmtApi.updateCustomDataById(requestBody).then(() => {
				message.destroy(key);
				void message.success('Custom data has been saved successfully');
			});
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	/* Account Members */
	async createAccountMember(
		requestBody: CreateAccountMemberOperationRequest
	): Promise<CreateAccountMemberResponse | undefined> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			const response = await this.mgmtApi.createAccountMember(requestBody);
			message.destroy(key);
			void message.success('Team Member Invitation Sent Successfully');
			return response;
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	async getAccountMembers(): Promise<AccountMembersResponse | undefined> {
		try {
			return await this.mgmtApi.getAccountMembers();
		} catch (error) {
			await this.handleError(error);
		}
	}

	async deleteAccountMember(
		requestBody: DeleteAccountMemberRequest
	): Promise<void> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			await this.mgmtApi.deleteAccountMember(requestBody).then(() => {
				message.destroy(key);
				void message.success('Team Member has been deleted successfully');
			});
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	/* Activity */
	async getUserCountByEnvironmentId(
		requestBody: GetUserCountByEnvironmentIdRequest
	): Promise<EnvironmentUserCountResponse | undefined> {
		try {
			return await this.mgmtApi.getUserCountByEnvironmentId(requestBody);
		} catch (error) {
			await this.handleError(error);
		}
	}

	async getTopCustomerFaceRegistrations(
		requestBody: GetTopCustomerFaceRegistrationsRequest
	): Promise<FaceRegistrationsCountResponse | undefined> {
		try {
			return await this.mgmtApi.getTopCustomerFaceRegistrations(requestBody);
		} catch (error) {
			await this.handleError(error);
		}
	}

	async getFaceRegistrationCount(
		requestBody: GetFaceRegistrationCountRequest
	): Promise<FaceRegistrationsCountResponse | undefined> {
		try {
			return await this.mgmtApi.getFaceRegistrationCount(requestBody);
		} catch (error) {
			await this.handleError(error);
		}
	}

	async getAccessStepAttemptCount(
		requestBody: GetAccessStepAttemptCountRequest
	): Promise<AccessStepAttemptCountsResponse | undefined> {
		try {
			return await this.mgmtApi.getAccessStepAttemptCount(requestBody);
		} catch (error) {
			await this.handleError(error);
		}
	}

	async getCustomerCountByEnvironmentId(
		requestBody: GetCustomerCountByEnvironmentIdRequest
	): Promise<EnvironmentCustomerCountResponse | undefined> {
		try {
			return await this.mgmtApi.getCustomerCountByEnvironmentId(requestBody);
		} catch (error) {
			await this.handleError(error);
		}
	}

	/* Customer */
	async deleteCustomer(requestBody: DeleteCustomerRequest): Promise<void> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			await this.mgmtApi.deleteCustomer(requestBody).then(() => {
				message.destroy(key);
				void message.success('Customer has been deleted successfully');
			});
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	async getCustomer(
		requestBody: GetCustomerRequest
	): Promise<CustomerResponse | undefined> {
		try {
			return await this.mgmtApi.getCustomer(requestBody);
		} catch (error) {
			await this.handleError(error);
		}
	}

	async updateCustomerSettings(
		requestBody: UpdateCustomerSettingsOperationRequest
	): Promise<void> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			await this.mgmtApi.updateCustomerSettings(requestBody).then(() => {
				message.destroy(key);
				void message.success('Settings has been updated successfully.');
			});
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	async getCustomerSettings(
		requestBody: GetCustomerSettingsRequest
	): Promise<CustomerSettingsResponse | undefined> {
		try {
			return await this.mgmtApi.getCustomerSettings(requestBody);
		} catch (error) {
			await this.handleError(error);
		}
	}

	async getCustomers(
		requestBody: GetCustomersRequest
	): Promise<CustomersResponse | undefined> {
		try {
			return await this.mgmtApi.getCustomers(requestBody);
		} catch (error) {
			await this.handleError(error);
		}
	}

	async createCustomer(
		requestBody: CreateCustomerOperationRequest
	): Promise<CreateCustomerResponse | undefined> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			const response = await this.mgmtApi.createCustomer(requestBody);
			message.destroy(key);
			void message.success('Cutstomer created successfully');
			return response;
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	async updateCustomerById(
		requestBody: UpdateCustomerByIdRequest
	): Promise<void> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			await this.mgmtApi.updateCustomerById(requestBody).then(() => {
				message.destroy(key);
				void message.success('Customer updated successfully');
			});
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	/* Environment */
	async deleteEnvironment(
		requestBody: DeleteEnvironmentRequest
	): Promise<void> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			await this.mgmtApi.deleteEnvironment(requestBody).then(() => {
				message.destroy(key);
				void message.success('Environment has been deleted successfully');
			});
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	async getEnvironment(
		requestBody: GetEnvironmentRequest
	): Promise<EnvironmentResponse | undefined> {
		try {
			return await this.mgmtApi.getEnvironment(requestBody);
		} catch (error) {
			await this.handleError(error);
		}
	}

	async createEnvironment(
		requestBody: CreateEnvironmentOperationRequest
	): Promise<CreateEnvironmentResponse | undefined> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			const response = await this.mgmtApi.createEnvironment(requestBody);
			message.destroy(key);
			void message.success('Environment created successfully');
			return response;
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	async updateEnvironmentById(
		requestBody: UpdateEnvironmentByIdRequest
	): Promise<void> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			await this.mgmtApi.updateEnvironmentById(requestBody).then(() => {
				message.destroy(key);
				void message.success('Environment updated successfully');
			});
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	async getEnvironments(): Promise<EnvironmentsResponse | undefined> {
		try {
			return await this.mgmtApi.getEnvironments();
		} catch (error) {
			await this.handleError(error);
		}
	}

	async switchEnvironment(
		requestBody: SwitchEnvironmentRequest
	): Promise<void> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			await this.mgmtApi.switchEnvironment(requestBody).then(() => {
				message.destroy(key);
			});
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	/* Settings */
	async getApiKeys(
		requestBody: GetApiKeysRequest
	): Promise<ApiKeysResponse | undefined> {
		try {
			return await this.mgmtApi.getApiKeys(requestBody);
		} catch (error) {
			await this.handleError(error);
		}
	}

	async createApiKey(
		requestBody: CreateApiKeyRequest
	): Promise<CreateApiKeyResponse | undefined> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			const response = await this.mgmtApi.createApiKey(requestBody);
			message.destroy(key);
			void message.success('Access key created successfully');
			return response;
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	async deleteApiKey(requestBody: DeleteApiKeyRequest): Promise<void> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			await this.mgmtApi.deleteApiKey(requestBody).then(() => {
				message.destroy(key);
				void message.success('Access key has been deleted successfully');
			});
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	async updateEnvironmentSettings(
		requestBody: UpdateEnvironmentSettingsOperationRequest
	): Promise<void> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			await this.mgmtApi.updateEnvironmentSettings(requestBody).then(() => {
				message.destroy(key);
				void message.success(
					'Environment Settings has been updated successfully'
				);
			});
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	async getWebhookByEnvironmentId(
		requestBody: GetWebhookByEnvironmentIdRequest
	): Promise<EnvironmentWebhookResponse | undefined> {
		try {
			return await this.mgmtApi.getWebhookByEnvironmentId(requestBody);
		} catch (error) {
			await this.handleError(error);
		}
	}

	/* User */
	async deactivateUser(requestBody: DeactivateUserRequest): Promise<void> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			await this.mgmtApi.deactivateUser(requestBody).then(() => {
				message.destroy(key);
				void message.success('User has been deactivated successfully');
			});
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	async deleteUser(requestBody: DeleteUserRequest): Promise<void> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			await this.mgmtApi.deleteUser(requestBody).then(() => {
				message.destroy(key);
				void message.success('User has been deleted successfully');
			});
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	async getUsers(
		requestBody: GetUsersRequest
	): Promise<UsersResponse | undefined> {
		try {
			return await this.mgmtApi.getUsers(requestBody);
		} catch (error) {
			await this.handleError(error);
		}
	}

	async deleteFaceRegistration(
		requestBody: DeleteFaceRegistrationRequest
	): Promise<void> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			await this.mgmtApi.deleteFaceRegistration(requestBody).then(() => {
				message.destroy(key);
				void message.success('Face Rigistration has been deleted successfully');
			});
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	async approveFaceRegistration(
		requestBody: ApproveFaceRegistrationRequest
	): Promise<void> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			await this.mgmtApi.approveFaceRegistration(requestBody).then(() => {
				message.destroy(key);
				void message.success('Face Registration has been approved');
			});
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	async rejectFaceRegistration(
		requestBody: RejectFaceRegistrationRequest
	): Promise<void> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			await this.mgmtApi.rejectFaceRegistration(requestBody).then(() => {
				message.destroy(key);
				void message.success('Face Registration has been rejected');
			});
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	async newFaceRegistration(
		requestBody: NewFaceRegistrationOperationRequest
	): Promise<NewFaceRegistrationResponse | undefined> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			const response = await this.mgmtApi.newFaceRegistration(requestBody);
			message.destroy(key);
			return response;
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	async submitFaceRegistration(
		requestBody: SubmitFaceRegistrationRequest
	): Promise<void> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			await this.mgmtApi.submitFaceRegistration(requestBody).then(() => {
				message.destroy(key);
				void message.success('New face has been registered successfully');
			});
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	async getActiveRegistration(
		requestBody: GetActiveRegistrationRequest
	): Promise<ActiveRegistrationResponse | undefined> {
		try {
			return await this.mgmtApi.getActiveRegistration(requestBody);
		} catch (error) {
			await this.handleError(error);
		}
	}

	async getSubmittedRegistration(
		requestBody: GetSubmittedRegistrationRequest
	): Promise<SubmittedRegistrationResponse | undefined> {
		try {
			return await this.mgmtApi.getSubmittedRegistration(requestBody);
		} catch (error) {
			await this.handleError(error);
		}
	}

	async updateUserById(requestBody: UpdateUserByIdRequest): Promise<void> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			await this.mgmtApi.updateUserById(requestBody).then(() => {
				message.destroy(key);
				void message.success('User updated successfully');
			});
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	async activateUser(requestBody: ActivateUserRequest): Promise<void> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			await this.mgmtApi.activateUser(requestBody).then(() => {
				message.destroy(key);
				void message.success('Successfully Activated User');
			});
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	async getQrcode(
		requestBody: GetQrcodeRequest
	): Promise<QrCodeResponseBody | undefined> {
		try {
			return await this.mgmtApi.getQrcode(requestBody);
		} catch (error) {
			await this.handleError(error);
		}
	}

	async getUserById(
		requestBody: GetUserByIdRequest
	): Promise<User | undefined> {
		try {
			return await this.mgmtApi.getUserById(requestBody);
		} catch (error) {
			await this.handleError(error);
		}
	}

	async createUser(
		requestBody: CreateUserOperationRequest
	): Promise<CreateUserResponse | undefined> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			const response = await this.mgmtApi.createUser(requestBody);
			message.destroy(key);
			void message.success('User created successfully');
			return response;
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	/* Email verify page */
	async resendAuth0VerificationEmail(): Promise<void> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			await this.mgmtApi.resendAuth0VerificationEmail().then(() => {
				message.destroy(key);
				void message.success('Verification Email Sent Successfully.');
			});
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	/* Onboarding page */
	async createOnboardingAccount(
		requestBody: CreateOnboardingAccountOperationRequest
	): Promise<void> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			await this.mgmtApi.createOnboardingAccount(requestBody).then(() => {
				message.destroy(key);
				void message.success('Account has been created successfully.');
			});
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	/* web sdk page */
	async faceAccess(
		requestBody: FaceAccessRequest
	): Promise<FaceAccessResponse | undefined> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			const response = await this.mgmtApi.faceAccess(requestBody);
			message.destroy(key);
			return response;
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	/* web sdk page */
	async confirmFaceAccess(
		requestBody: ConfirmFaceAccessOperationRequest
	): Promise<ConfirmedFaceAccessResponse | undefined> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			const response = await this.mgmtApi.confirmFaceAccess(requestBody);
			message.destroy(key);
			void message.success('Access Session has been confirmed successfully');
			return response;
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	/* web sdk page */
	async pinAccess(
		requestBody: PinAccessOperationRequest
	): Promise<PinAccessResponse | undefined> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			const response = await this.mgmtApi.pinAccess(requestBody);
			message.destroy(key);
			return response;
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	/* web sdk page */
	async confirmPinAccess(
		requestBody: ConfirmPinAccessOperationRequest
	): Promise<ConfirmedPinAccessResponse | undefined> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			const response = await this.mgmtApi.confirmPinAccess(requestBody);
			message.destroy(key);
			void message.success('Access Session has been confirmed successfully');
			return response;
		} catch (error) {
			await this.handleError(error, key);
		}
	}

	/* web sdk page */
	async manualAccess(
		requestBody: ManualAccessOperationRequest
	): Promise<ManualAccessResponse | undefined> {
		const key = uuidv4();
		void message.open({
			type: 'loading',
			content: 'Action in progress..',
			duration: 0,
			key,
		});
		try {
			const response = await this.mgmtApi.manualAccess(requestBody);
			message.destroy(key);
			return response;
		} catch (error) {
			await this.handleError(error, key);
		}
	}
}

const useMgmtApiWrapper = (): ApiWrapper => {
	const apiWrapper = new ApiWrapper();
	return apiWrapper;
};

export default useMgmtApiWrapper;
