import { Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Loading: React.FC = () => {
	const [showLogout, setShowLogout] = useState<boolean>(false);

	const { logout } = useAuth0();

	useEffect(() => {
		setTimeout(() => {
			setShowLogout(true);
		}, 3000);
	}, []);

	return (
		<Space size="large" className="loading flex-all-center">
			<Spin
				size="large"
				tip={
					showLogout ? (
						<a
							onClick={() => {
								logout({ logoutParams: { returnTo: window.location.origin } });
							}}
						>
							Cancel Loading
						</a>
					) : (
						'Loading...'
					)
				}
			></Spin>
		</Space>
	);
};

export default React.memo(Loading);
