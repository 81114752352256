import { type MenuProps } from 'antd';
import React from 'react';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import UserInRowDelete from './UserInRowDelete';
import { useNavigate } from 'react-router-dom';
import DropdownButton from '../../../../shared/DropdownButton';

interface Props {
	id: string;
}

const UserDropDownButton: React.FC<Props> = (props: Props) => {
	const navigate = useNavigate();

	const openUserDetail = (id: string): void => {
		navigate(`/user/detail/${id}`);
	};

	const items: MenuProps['items'] = [
		{
			label: (
				<a
					onClick={() => {
						openUserDetail(props.id);
					}}
				>
					View Detail
				</a>
			),
			key: '0',
			icon: <EyeOutlined />,
		},
		{
			type: 'divider',
		},
		{
			label: <UserInRowDelete id={props.id} />,
			key: '1',
			icon: <DeleteOutlined />,
			danger: true,
		},
	];

	return <DropdownButton menu={items} />;
};

export default React.memo(UserDropDownButton);
