import React from 'react';
import { Space } from 'antd';
import Title from 'antd/es/typography/Title';
import './Users.scss';
import UserCreateButton from './usersHeader/UserCreateButton';
import UserSearchBar from './usersHeader/UserSearchBar';
import UsersTable from './usersTable/UsersTable';

const Users: React.FC = () => {
	return (
		<>
			<Title level={3}>Users</Title>
			<div className="users-content">
				<Space className="custom-table-space" direction="vertical" size="large">
					<div className="users-header">
						<UserSearchBar />
						<UserCreateButton />
					</div>
					<UsersTable />
				</Space>
			</div>
		</>
	);
};

export default React.memo(Users);
