import React, { useEffect, useState } from 'react';
import { Card, Typography } from 'antd';
import { Column, type ColumnConfig } from '@ant-design/plots';
import { type FaceRegistrationCountResponse } from '../../tools/openapi';
import useMgmtApiWrapper from '../../hooks/openapi/useMgmtApiWrapper';

interface Props {
	id: string | undefined;
}

interface BarChartData {
	customerName: string | null | undefined;
	type: string;
	value?: number | undefined | null;
}

const { Title } = Typography;

const ActivityPageFaceRegistrationByCustomer: React.FC<Props> = (
	props: Props
) => {
	const mgmtApiWrapper = useMgmtApiWrapper();

	const [barChartData, setBarChartData] = useState<BarChartData[]>([]);

	const transformCountsToBarChartData = (
		counts: FaceRegistrationCountResponse[]
	): BarChartData[] => {
		const barChartData: BarChartData[] = [];
		counts.forEach((count: FaceRegistrationCountResponse) => {
			barChartData.push({
				customerName: count.name,
				type: 'Active',
				value: count.activeCount as number,
			});
			barChartData.push({
				customerName: count.name,
				type: 'Submitted',
				value: count.submittedCount as number,
			});
			barChartData.push({
				customerName: count.name,
				type: 'Not Registered',
				value: 0,
			});
		});

		return barChartData;
	};

	useEffect(() => {
		const environmentId = props.id;
		if (environmentId != null) {
			void mgmtApiWrapper
				.getTopCustomerFaceRegistrations({ environmentId, top: 10 })
				.then((r) => {
					if (r !== undefined) {
						setBarChartData(
							transformCountsToBarChartData(r.registrationsCount)
						);
					}
				})
		}
	}, [props.id]);

	const columnChartConfig: ColumnConfig = {
		data: barChartData,
		xField: 'customerName',
		yField: 'value',
		seriesField: 'type',
		color: ({ type }) => {
			switch (type) {
				case 'Active':
					return '#5B8FF9';
				case 'Submitted':
					return '#61DDAA';
				case 'Not Registered':
					return '#65789B';
				default:
					return '#ccc';
			}
		},
		legend: { position: 'top-left' },
		tooltip: { shared: true },
		xAxis: {
			label: {
				autoHide: true,
				autoRotate: false,
			},
		},
	};

	return (
		<Card>
			<Title level={4}>Face Registration Status by Customer</Title>
			<Column {...columnChartConfig} />
		</Card>
	);
};

export default React.memo(ActivityPageFaceRegistrationByCustomer);
