import { Modal } from 'antd';
import React from 'react';
import { setModalVisible } from '../../../store/accessKeyReducer';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { type RootState } from '../../../store/store';
import useMgmtApiWrapper from '../../../hooks/openapi/useMgmtApiWrapper';

interface Props {
	fetchData: () => void;
}

const AccessKeyCreateAndDeleteModal: React.FC<Props> = (props: Props) => {
	const mgmtApiWrapper = useMgmtApiWrapper();
	const dispatch = useAppDispatch();

	const modalVisible = useAppSelector(
		(state: RootState) => state.accessKey.modalVisible
	);

	const modalOnCreate = useAppSelector(
		(state: RootState) => state.accessKey.modalOnCreate
	);

	const curToDeleteId = useAppSelector(
		(state: RootState) => state.accessKey.curToDeleteId
	);

	const environment = useAppSelector(
		(state: RootState) => state.environment.environment
	);

	const handleSubmit = (): void => {
		if (modalOnCreate) {
			void mgmtApiWrapper
				.createApiKey({ environmentId: environment.id })
				.then(() => {
					dispatch(setModalVisible(false));
					props.fetchData();
				})
		} else {
			void mgmtApiWrapper
				.deleteApiKey({ keyId: curToDeleteId })
				.then(() => {
					dispatch(setModalVisible(false));
					props.fetchData();
				})
		}
	};

	return (
		<Modal
			open={modalVisible}
			title={modalOnCreate ? 'Create Key' : 'Delete Key'}
			okText="Yes"
			onOk={handleSubmit}
			okType={modalOnCreate ? 'primary' : 'danger'}
			cancelText="No"
			onCancel={() => {
				dispatch(setModalVisible(false));
			}}
			centered={true}
		>
			{modalOnCreate ? (
				<p>Are you sure to create a new access key?</p>
			) : (
				<p>Are you sure to delete this access key?</p>
			)}
		</Modal>
	);
};

export default React.memo(AccessKeyCreateAndDeleteModal);
