import { Button } from 'antd';
import AccountMemberCreateModal from '../modal/AccountMemberCreateModal';
import React, { useState } from 'react';

interface Props {
	fetchData: () => void;
}

const AccountMemberCreateButton: React.FC<Props> = (props: Props) => {
	const [modalOpen, setModalOpen] = useState(false);
	const showInviteModal = (): void => {
		setModalOpen(true);
	};

	return (
		<>
			<Button type="primary" onClick={showInviteModal}>
				Invite
			</Button>
			<AccountMemberCreateModal  modalOpen={modalOpen} setModalOpen={setModalOpen} fetchData={props.fetchData}/>
		</>
	);
};

export default React.memo(AccountMemberCreateButton);
