import { type TabsProps } from 'antd';
import Title from 'antd/es/typography/Title';
import React from 'react';
import InformationTab from '../../shared/InformationTab';
import AccountMembers from '../accountMembers/AccountMembers';
import EnvironmentSettings from '../environment/Environments/Environments';
import AccessKey from './AccessKeys/AccessKey';
import Webhook from './Webhook';
import { type StartResponse } from '../../tools/openapi';
import { useAppSelector } from '../../store/hooks';
import { type RootState } from '../../store/store';

const SettingsPage: React.FC = () => {
	const startState: StartResponse = useAppSelector(
		(state: RootState) => state.startState.startState
	);

	const items: TabsProps['items'] = [
		{
			key: 'teamMembers',
			label: 'Team Members',
			children: <AccountMembers />,
		},
		{
			key: 'accessKeys',
			label: 'Access Keys',
			children: <AccessKey />,
		},
		{
			key: 'webhook',
			label: 'Webhook',
			children: <Webhook />,
		},
		{
			key: 'environments',
			label: 'Environments',
			children: <EnvironmentSettings />,
		},
	];

	if (startState.account?.account.singleCustomer === true) {
		return (
			<>
				<Title level={3}>Settings</Title>
				<div style={{ padding: '1em', width: '95%' }}>
					<InformationTab
						items={items.filter((item) => item.key !== 'environments')}
					/>
				</div>
			</>
		);
	}

	return (
		<>
			<Title level={3}>Settings</Title>
			<div style={{ padding: '1em', width: '95%' }}>
				<InformationTab items={items} />
			</div>
		</>
	);
};

export default React.memo(SettingsPage);
