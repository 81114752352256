import produce from 'immer';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type SessionDetailsWithStepsResponse } from '../tools/openapi';

const initialState = {
	sessionDetailWithSteps: {},
};

const sessionDetailSlice = createSlice({
	name: 'sessionDetail',
	initialState,
	reducers: {
		setCurrentSessionDetailWithSteps: (state, action: PayloadAction<SessionDetailsWithStepsResponse>) => {
			return produce(state, (draftState) => {
				draftState.sessionDetailWithSteps = action.payload;
			});
		},
		resetCurrentSessionDetailWithSteps: (state) => {
			return produce(state, (draftState) => {
				draftState.sessionDetailWithSteps = {};
			});
		},
	},
});

export const { setCurrentSessionDetailWithSteps, resetCurrentSessionDetailWithSteps } =
sessionDetailSlice.actions;
export default sessionDetailSlice.reducer;
