/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AccountMemberRole = {
    Owner: 'OWNER',
    Admin: 'ADMIN',
    Viewer: 'VIEWER'
} as const;
export type AccountMemberRole = typeof AccountMemberRole[keyof typeof AccountMemberRole];


export function AccountMemberRoleFromJSON(json: any): AccountMemberRole {
    return AccountMemberRoleFromJSONTyped(json, false);
}

export function AccountMemberRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountMemberRole {
    return json as AccountMemberRole;
}

export function AccountMemberRoleToJSON(value?: AccountMemberRole | null): any {
    return value as any;
}

