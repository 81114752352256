import React from 'react';
import { useAppSelector } from '../../../../../../../store/hooks';
import { type RootState } from '../../../../../../../store/store';
import { type UpdateCustomerSettingsRequest } from '../../../../../../../tools/openapi';
import DeleteConfirmation from '../../../../../../../shared/DeleteConfirmation';
import {
	setIsPinModalOpen,
	setPinCodeDigit,
} from '../../../../../../../store/customerDetailReducer';
import { useDispatch } from 'react-redux';

interface Props {
	updateOperation: <T extends keyof UpdateCustomerSettingsRequest>(
		target: T,
		value: UpdateCustomerSettingsRequest[T]
	) => void;
}

const CustomerPinChangeConformation: React.FC<Props> = (props) => {
	const dispatch = useDispatch();
	const pinCodeDigit = useAppSelector(
		(state: RootState) => state.customerDetail.pinCodeDigit
	);
	const previousPinCodeDigit = useAppSelector(
		(state: RootState) => state.customerDetail.previousPinCodeDigit
	);
	const isPinModalOpen = useAppSelector(
		(state: RootState) => state.customerDetail.isPinModalOpen
	);
	return (
		<DeleteConfirmation
			title="Are you sure? "
			message={`This change will require the customer to update all the pin codes for their team members. `}
			visible={isPinModalOpen}
			centered={true}
			inputDisplay={'none'}
			customKey={'Confirm'}
			onCancel={() => {
				dispatch(setIsPinModalOpen(false));
				dispatch(setPinCodeDigit(previousPinCodeDigit));
			}}
			onConfirm={() => {
				dispatch(setIsPinModalOpen(false));
				props.updateOperation('pinCodeDigit', pinCodeDigit);
			}}
		/>
	);
};
export default React.memo(CustomerPinChangeConformation);
