/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UploadFaceRegistrationImageResponse
 */
export interface UploadFaceRegistrationImageResponse {
    /**
     * 
     * @type {string}
     * @memberof UploadFaceRegistrationImageResponse
     */
    imageId: string;
    /**
     * 
     * @type {string}
     * @memberof UploadFaceRegistrationImageResponse
     */
    url: string;
}

/**
 * Check if a given object implements the UploadFaceRegistrationImageResponse interface.
 */
export function instanceOfUploadFaceRegistrationImageResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "imageId" in value;
    isInstance = isInstance && "url" in value;

    return isInstance;
}

export function UploadFaceRegistrationImageResponseFromJSON(json: any): UploadFaceRegistrationImageResponse {
    return UploadFaceRegistrationImageResponseFromJSONTyped(json, false);
}

export function UploadFaceRegistrationImageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadFaceRegistrationImageResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'imageId': json['imageId'],
        'url': json['url'],
    };
}

export function UploadFaceRegistrationImageResponseToJSON(value?: UploadFaceRegistrationImageResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'imageId': value.imageId,
        'url': value.url,
    };
}

