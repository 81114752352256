/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiKey
 */
export interface ApiKey {
    /**
     * 
     * @type {string}
     * @memberof ApiKey
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof ApiKey
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof ApiKey
     */
    accountId: string;
    /**
     * 
     * @type {string}
     * @memberof ApiKey
     */
    environmentId: string;
    /**
     * 
     * @type {string}
     * @memberof ApiKey
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ApiKey
     */
    secret: string;
}

/**
 * Check if a given object implements the ApiKey interface.
 */
export function instanceOfApiKey(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "accountId" in value;
    isInstance = isInstance && "environmentId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "secret" in value;

    return isInstance;
}

export function ApiKeyFromJSON(json: any): ApiKey {
    return ApiKeyFromJSONTyped(json, false);
}

export function ApiKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'accountId': json['accountId'],
        'environmentId': json['environmentId'],
        'name': json['name'],
        'secret': json['secret'],
    };
}

export function ApiKeyToJSON(value?: ApiKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'accountId': value.accountId,
        'environmentId': value.environmentId,
        'name': value.name,
        'secret': value.secret,
    };
}

