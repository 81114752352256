import { Input, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import {
	setUserList,
	setUserCount,
	setUseCustomPagination,
	setLoading,
	setPageNumber,
} from '../../../../store/usersReducer';
import '../Users.scss';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { type RootState } from '../../../../store/store';
import { type StartResponse } from '../../../../tools/openapi';
import UserActiveOnlyRadioButton from './UserActiveOnlyRadioButton';
import useMgmtApiWrapper from '../../../../hooks/openapi/useMgmtApiWrapper';
const { Search } = Input;

interface Props {
	searchMode?: string;
	customerId?: string;
}

const SearchBar: React.FC<Props> = (props: Props) => {
	const dispatch = useAppDispatch();
	const mgmtApiWrapper = useMgmtApiWrapper();

	const startState: StartResponse = useAppSelector(
		(state: RootState) => state.startState.startState
	);

	const environment = useAppSelector(
		(state: RootState) => state.environment.environment
	);

	const page = useAppSelector((state: RootState) => state.user.pageNumber);

	const size = useAppSelector((state: RootState) => state.user.PageSize);

	const activeOnly = useAppSelector(
		(state: RootState) => state.user.activeOnly
	);

	const [searchType, setSearchType] = useState<'name' | 'customerId'>('name');
	const [inputSearchValue, setInputSearchValue] = useState('');
	const [debouncedSearchValue, setDebouncedSearchValue] = useState('');

	const fetchData = (searchType: string, searchWords: string): void => {
		dispatch(setLoading(true));
		const environmentId =
			environment?.id.length > 0
				? environment?.id
				: startState.account?.activeEnvironment?.id;

		const requestBody = {
			environmentId,
			pageNumber: page - 1,
			pageSize: size,
			active: activeOnly,
			...(props.searchMode === 'ManualAccess'
				? { customerId: props.customerId, query: searchWords }
				: {
						...(searchType === 'customerId'
							? { customerId: searchWords }
							: {
									...(searchType === 'name'
										? { query: searchWords, searchType }
										: {}),
							  }),
				  }),
		};

		void mgmtApiWrapper.getUsers(requestBody).then((r) => {
			if (r !== undefined) {
				if (searchType === 'name' && searchWords.length > 0) {
					console.log('');
				} else {
					dispatch(setUserCount(r.totalCount));
				}
				dispatch(setUserList(r.users));
				dispatch(
					setUseCustomPagination(
						searchType === 'name' && searchWords.length > 0
					)
				);
				dispatch(setLoading(false));
			}
		});
	};

	useEffect(() => {
		const debouncedValueUpdate = setTimeout(() => {
			page !== 1
				? dispatch(setPageNumber(1))
				: setDebouncedSearchValue(inputSearchValue);
		}, 500);

		return () => {
			clearTimeout(debouncedValueUpdate);
		};
	}, [inputSearchValue]);

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const searchQuery = searchParams.get('search');
		if (searchQuery != null) {
			setInputSearchValue(searchQuery);
			setSearchType('customerId');
			fetchData('customerId', searchQuery);
		}
	}, [location.search]);

	useEffect(() => {
		fetchData(searchType, inputSearchValue);
	}, [activeOnly, page, size, debouncedSearchValue]);

	return (
		<Space.Compact style={{ alignItems: 'center' }}>
			{props.searchMode === 'ManualAccess' ||
			startState.account?.account.singleCustomer === true ? null : (
				<Select
					value={searchType}
					onChange={(value) => {
						setSearchType(value);
					}}
					style={{ width: '40%' }}
				>
					<Select.Option value="name">Name</Select.Option>
					<Select.Option value="customerId">Customer ID</Select.Option>
				</Select>
			)}
			<Search
				value={inputSearchValue}
				placeholder={`Search by ${searchType}`}
				allowClear
				enterButton
				style={{ width: '60%' }}
				onChange={(e) => {
					setInputSearchValue(e.target.value);
				}}
			/>
			{props.searchMode === 'ManualAccess' ? null : (
				<UserActiveOnlyRadioButton />
			)}
		</Space.Compact>
	);
};

export default React.memo(SearchBar);
