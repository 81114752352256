import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type AccountMemberResponse } from '../tools/openapi';
import produce from 'immer';

const initialState = {
    accountMembers: [] as AccountMemberResponse[],
    modalOpen: false,
    searchTerm: ''
};

const accountMemberSlice = createSlice({
    name: 'accountMember',   
    initialState,
    reducers: {
        setAccountMemberList: (state, action: PayloadAction<AccountMemberResponse[]>) => {
            return produce(state, (draftState) => {
				draftState.accountMembers = action.payload;
			});
        },
        setSearchTerm: (state, action: PayloadAction<string>) => {
			return produce(state, (draftState) => {
				draftState.searchTerm = action.payload;
			});
		},
    }
});

export const {
    setAccountMemberList,
    setSearchTerm,
} = accountMemberSlice.actions;
export default accountMemberSlice.reducer;
