import { Layout } from 'antd';
import { Outlet, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import '../AppLayout.scss';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { type StartResponse } from '../../tools/openapi';
import { type RootState } from '../../store/store';
import BreadcrumbNav from '../BreadcrumbNav';
import { setEnvironment } from '../../store/environmentReducer';

const { Content } = Layout;

const SingleCustomerMainContent: React.FC = () => {
	const startState: StartResponse = useAppSelector(
		(state: RootState) => state.startState.startState
	);
	const location = useLocation();

	const dispatch = useAppDispatch();
	const environment = useAppSelector(
		(state: RootState) => state.environment.environment
	);

	useEffect(() => {
		if (environment.id.length === 0 && startState.account != null) {
			dispatch(setEnvironment(startState.account.activeEnvironment));
		}
	}, []);

	return (
		<Layout className="mainLayout">
			<BreadcrumbNav pathname={location.pathname} />
			<Content className="mainContent">
				<Outlet />
			</Content>
		</Layout>
	);
};

export default React.memo(SingleCustomerMainContent);
