import React, { useEffect, useRef, useState } from 'react';
import { type MenuProps } from 'antd';
import QRCode from 'qrcode.react';
import { DownloadOutlined } from '@ant-design/icons';
import DropdownButton from '../../../../../shared/DropdownButton';
import Title from 'antd/es/typography/Title';
import { type User } from '@auth0/auth0-react';
import { useAppSelector } from '../../../../../store/hooks';
import { type RootState } from '../../../../../store/store';
import {
	type QrCodeRequestBody,
	type QrCodeResponseBody,
} from '../../../../../tools/openapi';
import useMgmtApiWrapper from '../../../../../hooks/openapi/useMgmtApiWrapper';

const UserQrCodeSection: React.FC = () => {
	const mgmtApiWrapper = useMgmtApiWrapper();
	const qrCodeRef = useRef(null);

	const userDetail = useAppSelector(
		(state: RootState) => state.userDetail.user as User
	);

	const [qrCode, setQrCode] = useState<QrCodeResponseBody>();

	const fetchQrCodeInfo = (): void => {
		const qrCodeRequestBody: QrCodeRequestBody = { userId: userDetail.id };
		void mgmtApiWrapper.getQrcode({ qrCodeRequestBody }).then((r) => {
			setQrCode(r);
		});
	};

	const downloadQRCode = (): void => {
		if (qrCodeRef.current != null) {
			const divElement = qrCodeRef.current as HTMLDivElement;
			const canvas = divElement.querySelector('canvas') as HTMLCanvasElement;
			const url = canvas.toDataURL();
			const a = document.createElement('a');
			a.download = 'QRCode.png';
			a.href = url;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}
	};

	const dropDown: MenuProps['items'] = [
		{
			label: (
				<a type="primary" onClick={downloadQRCode}>
					Download
				</a>
			),
			key: '0',
			icon: <DownloadOutlined />,
		},
	];

	useEffect(() => {
		fetchQrCodeInfo();
	}, []);
	return (
		<section className="section">
			<div className="user-section-header ">
				<Title className="user-section-title" level={5}>
					QR Code
				</Title>
				<DropdownButton menu={dropDown} />
			</div>
			<div
				style={{ display: 'flex', justifyContent: 'center' }}
				ref={qrCodeRef}
			>
				<QRCode
					value={qrCode?.payload ?? ''}
					style={{
						marginBottom: 16,
						backgroundColor: 'white',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
					level="M"
				/>
			</div>
		</section>
	);
};

export default React.memo(UserQrCodeSection);
