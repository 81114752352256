import React from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import './SharedComponentsStyle.scss';

const { Option } = Select;

interface CustomPaginationProps {
	dataSize: number;
	currentPage: number;
	onPageChange: (page: number) => void;
	onPageSizeChange: (cur: number, size: number) => void;
	pageSize: number;
	pageSizeOptions: number[];
	showSizeChanger?: boolean;
}

const CustomPagination: React.FC<CustomPaginationProps> = ({
	dataSize,
	currentPage,
	onPageChange,
	onPageSizeChange,
	pageSize,
	pageSizeOptions,
	showSizeChanger,
}) => {
	const handlePrevPage = (): void => {
		if (currentPage > 1) {
			onPageChange(currentPage - 1);
		}
	};

	const handleNextPage = (): void => {
		onPageChange(currentPage + 1);
	};

	const handlePageSizeChange = (value: number): void => {
		onPageSizeChange(1, value);
	};

	return (
		<div style={{ display: 'flex', alignItems: 'center', marginTop: '16px', marginBottom: '16px' }}>
			<LeftOutlined onClick={handlePrevPage} />
			<span className="custom-pagination-current">{currentPage}</span>
			{dataSize < pageSize ? null : (
				<>
					<span className="custom-pagination-dots">...</span>
					<RightOutlined onClick={handleNextPage} />
				</>
			)}
			{showSizeChanger === true ? (
				<Select
					value={pageSize}
					style={{ width: 120, marginLeft: 10 }}
					onChange={handlePageSizeChange}
				>
					{pageSizeOptions.map((option) => (
						<Option key={option} value={option}>
							{option} / page
						</Option>
					))}
				</Select>
			) : null}
		</div>
	);
};

export default CustomPagination;
