import React from 'react';
import { Descriptions } from 'antd';
import UserFaceSubmittedDropdown from './UserFaceSubmittedDropdown';
import {
	type SubmittedRegistrationResponse,
	type FaceRegistration,
} from '../../../../../../tools/openapi';

interface DescriptionItem {
	label: string;
	content: React.ReactNode;
}

interface Props {
	dataSubmitted: SubmittedRegistrationResponse | undefined;
	fetchFaceRegistrations: () => void;
	createDescriptionItems: (registration: FaceRegistration) => DescriptionItem[];
}

const UserFaceSubmitted: React.FC<Props> = ({
	dataSubmitted,
	fetchFaceRegistrations,
	createDescriptionItems,
}) => {
	const submittedTitle = (
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				width: '100%',
			}}
		>
			<span>Submitted Face Registration</span>
			<UserFaceSubmittedDropdown
				dataSubmitted={dataSubmitted}
				fetchFaceRegistrations={fetchFaceRegistrations}
			/>
		</div>
	);

	return (
		<>
			{dataSubmitted?.registration != null ? (
				<section className="section">
					<Descriptions
						title={submittedTitle}
						layout="horizontal"
						column={1}
						bordered
					>
						{createDescriptionItems(dataSubmitted?.registration).map((item) => (
							<Descriptions.Item
								key={item.label}
								label={item.label}
								labelStyle={{ width: '12em', margin: '0.5em 0' }}
								contentStyle={{ margin: '0.5em 0 0 15em' }}
							>
								{item.content}
							</Descriptions.Item>
						))}
					</Descriptions>
				</section>
			) : null}
		</>
	);
};
export default React.memo(UserFaceSubmitted);
