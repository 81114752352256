/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeactivateUsersRequest
 */
export interface DeactivateUsersRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeactivateUsersRequest
     */
    userIds: Array<string>;
}

/**
 * Check if a given object implements the DeactivateUsersRequest interface.
 */
export function instanceOfDeactivateUsersRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userIds" in value;

    return isInstance;
}

export function DeactivateUsersRequestFromJSON(json: any): DeactivateUsersRequest {
    return DeactivateUsersRequestFromJSONTyped(json, false);
}

export function DeactivateUsersRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeactivateUsersRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userIds': json['userIds'],
    };
}

export function DeactivateUsersRequestToJSON(value?: DeactivateUsersRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userIds': value.userIds,
    };
}

