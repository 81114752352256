/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NoMatchInstruction } from './NoMatchInstruction';
import {
    NoMatchInstructionFromJSON,
    NoMatchInstructionFromJSONTyped,
    NoMatchInstructionToJSON,
} from './NoMatchInstruction';

/**
 * 
 * @export
 * @interface FaceAccessResponseNoMatch
 */
export interface FaceAccessResponseNoMatch {
    /**
     * 
     * @type {NoMatchInstruction}
     * @memberof FaceAccessResponseNoMatch
     */
    instruction: NoMatchInstruction;
}

/**
 * Check if a given object implements the FaceAccessResponseNoMatch interface.
 */
export function instanceOfFaceAccessResponseNoMatch(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "instruction" in value;

    return isInstance;
}

export function FaceAccessResponseNoMatchFromJSON(json: any): FaceAccessResponseNoMatch {
    return FaceAccessResponseNoMatchFromJSONTyped(json, false);
}

export function FaceAccessResponseNoMatchFromJSONTyped(json: any, ignoreDiscriminator: boolean): FaceAccessResponseNoMatch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'instruction': NoMatchInstructionFromJSON(json['instruction']),
    };
}

export function FaceAccessResponseNoMatchToJSON(value?: FaceAccessResponseNoMatch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'instruction': NoMatchInstructionToJSON(value.instruction),
    };
}

