import React, { useState } from 'react';
import DropdownButton from '../../../../shared/DropdownButton';
import { EyeOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { type ItemType } from 'antd/es/menu/hooks/useItems';
import { type EnvironmentsResponseEnvironment } from '../../../../tools/openapi';
import { Form } from 'antd';
import EnvironmentsCreateAndUpdateModal from '../EnvironmentsHeader/EnvironmentsCreateAndUpdateModal';

interface Props {
	row: EnvironmentsResponseEnvironment;
	fetchEnvironments: () => void;
}

const EnvironmentsDropdownButton: React.FC<Props> = (props: Props) => {
	const navigate = useNavigate();

	const [form] = Form.useForm();
	const [modalVisible, setModalVisible] = useState(false);

	const showUpdateModal = (
		environment: EnvironmentsResponseEnvironment
	): void => {
		form.setFieldsValue({
			id: environment?.id,
			name: environment?.name,
		});
		setModalVisible(true);
	};

	const getdropdownItems = (): ItemType[] => [
		{
			label: (
				<a
					onClick={() => {
						navigate(`/environment/detail/${props.row.id}`);
					}}
				>
					View Details
				</a>
			),
			key: '0',
			icon: <EyeOutlined />,
		},

		{
			type: 'divider',
		},

		{
			label: (
				<a
					onClick={() => {
						showUpdateModal(props.row);
					}}
				>
					Edit
				</a>
			),
			key: '1',
			icon: <EditOutlined />,
		},
	];

	return (
		<>
			<DropdownButton menu={getdropdownItems()} />
			<EnvironmentsCreateAndUpdateModal
				form={form}
				modalVisible={modalVisible}
				isCreate={false}
				setModalVisible={setModalVisible}
				fetchEnvironments={props.fetchEnvironments}
			/>
		</>
	);
};

export default React.memo(EnvironmentsDropdownButton);
