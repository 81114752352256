import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { setSearchTerm } from '../../../store/accountMemberReducer';
import { useAppDispatch } from '../../../store/hooks';
import React from 'react';
import '../AccountMembers.scss';
import { debounce } from 'lodash';

const AccountMemberSearchBar: React.FC = () => {
	const dispatch = useAppDispatch();

	const setSearch = debounce((value: string) => {
		dispatch(setSearchTerm(value));
	}, 500);

	return (
		<Input
			className="account-header-input"
			placeholder="Search members"
			prefix={<SearchOutlined />}
			onChange={(e) => {
				setSearch(e.target.value);
			}}
		/>
	);
};

export default React.memo(AccountMemberSearchBar);
