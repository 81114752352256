import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
	id: string;
}

const OpenCustomerDetail: React.FC<Props> = (props: Props) => {
	const navigate = useNavigate();

	const openCustomerDetail = (id: string): void => {
		navigate(`/customer/detail/${id}`);
	};

	return (
		<a
			onClick={() => {
				openCustomerDetail(props.id);
			}}
		>
			View Details
		</a>
	);
};

export default React.memo(OpenCustomerDetail);
