import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Space, Avatar } from 'antd';
import { UserOutlined, MailOutlined, ShopOutlined } from '@ant-design/icons';
import './OnboardingPage.scss';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { type RootState } from '../../store/store';
import { type StartResponse } from '../../tools/openapi';
import { useNavigate } from 'react-router-dom';
import { setStartState } from '../../store/startReducer';
import LogoutButton from '../LogoutButton';
import { type CreateOnboardingAccountRequest } from '../../tools/openapi/models/CreateOnboardingAccountRequest';
import useMgmtApiWrapper from '../../hooks/openapi/useMgmtApiWrapper';

const OnboardingPage: React.FC = () => {
	const mgmtApiWrapper = useMgmtApiWrapper();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const startState: StartResponse = useAppSelector(
		(state: RootState) => state.startState.startState
	);
	const idToken = useAppSelector(
		(state: RootState) => state.startState.idToken
	);

	const { onboarding } = startState;
	const imgUrl = onboarding?.picture;

	const [form] = Form.useForm();
	const [avatarInitial, setAvatarInitial] = useState('');
	const [isAccountCreated, setIsAccountCreated] = useState<boolean>(false);

	const handleUsernameChange = (
		e: React.ChangeEvent<HTMLInputElement>
	): void => {
		const username = e.target.value;
		if (username.length > 0 && onboarding?.picture == null) {
			setAvatarInitial(username.charAt(0));
		} else {
			setAvatarInitial('');
		}
	};

	const onFinish = (values: CreateOnboardingAccountRequest): void => {
		const requestBody: CreateOnboardingAccountRequest = {
			accountName: values.accountName,
			ownerName: values.ownerName,
			idToken,
		};
		void mgmtApiWrapper
			.createOnboardingAccount({
				createOnboardingAccountRequest: requestBody,
			})
			.then(() => {
				setIsAccountCreated(true);
			});
	};

	useEffect(() => {
		void mgmtApiWrapper
			.start({ startRequest: { idToken } })
			.then((r) => {
				if (r !== undefined) {
					void dispatch(setStartState(r));
				}
				navigate('/');
			});
	}, [isAccountCreated]);

	return (
		<div className="onboarding-container">
			<div className="onboarding-title">
				<h1>Create Account</h1>
			</div>
			<div className="onboarding-box">
				<Avatar className="onboarding-avatar" size={64} src={imgUrl}>
					{avatarInitial}
				</Avatar>
				<Form
					className="onboarding-form"
					form={form}
					name="onboarding"
					onFinish={onFinish}
				>
					<Form.Item name="email" initialValue={onboarding?.email}>
						<Input
							prefix={<MailOutlined />}
							placeholder={onboarding?.email}
							disabled={true}
						/>
					</Form.Item>
					<Form.Item
						name="accountName"
						rules={[
							{ required: true, message: 'Please input your business name!' },
						]}
					>
						<Input prefix={<ShopOutlined />} placeholder="Business Name" />
					</Form.Item>
					<Form.Item
						name="ownerName"
						rules={[{ required: true, message: 'Please input your name!' }]}
					>
						<Input
							prefix={<UserOutlined />}
							placeholder="Your name"
							onChange={handleUsernameChange}
						/>
					</Form.Item>
					<Form.Item className="onboarding-form-button">
						<Space>
							<Button type="primary" htmlType="submit">
								Sign Up
							</Button>
							<LogoutButton />
						</Space>
					</Form.Item>
				</Form>
			</div>
		</div>
	);
};

export default React.memo(OnboardingPage);
