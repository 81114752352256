/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateCustomerResponseCustomer } from './CreateCustomerResponseCustomer';
import {
    CreateCustomerResponseCustomerFromJSON,
    CreateCustomerResponseCustomerFromJSONTyped,
    CreateCustomerResponseCustomerToJSON,
} from './CreateCustomerResponseCustomer';

/**
 * 
 * @export
 * @interface CreateCustomerResponse
 */
export interface CreateCustomerResponse {
    /**
     * 
     * @type {CreateCustomerResponseCustomer}
     * @memberof CreateCustomerResponse
     */
    customer: CreateCustomerResponseCustomer;
}

/**
 * Check if a given object implements the CreateCustomerResponse interface.
 */
export function instanceOfCreateCustomerResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "customer" in value;

    return isInstance;
}

export function CreateCustomerResponseFromJSON(json: any): CreateCustomerResponse {
    return CreateCustomerResponseFromJSONTyped(json, false);
}

export function CreateCustomerResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCustomerResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customer': CreateCustomerResponseCustomerFromJSON(json['customer']),
    };
}

export function CreateCustomerResponseToJSON(value?: CreateCustomerResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customer': CreateCustomerResponseCustomerToJSON(value.customer),
    };
}

