import { Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { type GetCustomerRequest } from '../../../../tools/openapi';
import { type Params, useParams } from 'react-router-dom';
import CustomerDangerZone from './CustomerDangerZone/CustomerDangerZone';
import { useDispatch } from 'react-redux';
import {
	setCurrentCustomerDetail,
	resetCurrentCustomerDetail,
} from '../../../../store/customerDetailReducer';
import CustomerDescription from './CustomerDescription/CustomerDescription';
import useMgmtApiWrapper from '../../../../hooks/openapi/useMgmtApiWrapper';

interface RouteParams extends Params {
	id: string;
}

const CustomerInformationTab: React.FC = () => {
	const mgmtApiWrapper = useMgmtApiWrapper();
	const dispatch = useDispatch();
	const { id } = useParams<RouteParams>();

	const [customerInfoOnLoading, setUserInfoOnLoading] = useState(true);
	const [customerInfoNotFound, setCustomerInfoNotFound] = useState(false);

	useEffect(() => {
		dispatch(resetCurrentCustomerDetail());
		if (id != null) {
			const requestBody: GetCustomerRequest = { customerId: id };
			setTimeout(() => {
				void mgmtApiWrapper
					.getCustomer(requestBody)
					.then((r) => {
						setUserInfoOnLoading(false);
						if (r !== undefined) {
							dispatch(setCurrentCustomerDetail(r.customer));
						}
					})
					.catch(async () => {
						setCustomerInfoNotFound(true);
					});
			}, 0);
		}
	}, []);

	if (customerInfoOnLoading) {
		return <Spin className="loading flex-all-center" size="large" />;
	} else if (customerInfoNotFound) {
		return <span>Customer Not Found</span>;
	} else {
		return (
			<Space className="custom-detail-space" size="large" direction="vertical">
				<CustomerDescription />
				<CustomerDangerZone />
			</Space>
		);
	}
};

export default React.memo(CustomerInformationTab);
