/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FaceRegistrationStatus } from './FaceRegistrationStatus';
import {
    FaceRegistrationStatusFromJSON,
    FaceRegistrationStatusFromJSONTyped,
    FaceRegistrationStatusToJSON,
} from './FaceRegistrationStatus';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    phone?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    environmentId: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    initial?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    avatarUrl?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    lastAccessed?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    totalAccessed?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    customerName: string;
    /**
     * 
     * @type {FaceRegistrationStatus}
     * @memberof User
     */
    faceRegistrationStatus?: FaceRegistrationStatus | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    pin?: string | null;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "environmentId" in value;
    isInstance = isInstance && "customerId" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "customerName" in value;

    return isInstance;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'environmentId': json['environmentId'],
        'customerId': json['customerId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'initial': !exists(json, 'initial') ? undefined : json['initial'],
        'avatarUrl': !exists(json, 'avatarUrl') ? undefined : json['avatarUrl'],
        'lastAccessed': !exists(json, 'lastAccessed') ? undefined : (json['lastAccessed'] === null ? null : new Date(json['lastAccessed'])),
        'totalAccessed': !exists(json, 'totalAccessed') ? undefined : json['totalAccessed'],
        'active': json['active'],
        'customerName': json['customerName'],
        'faceRegistrationStatus': !exists(json, 'faceRegistrationStatus') ? undefined : FaceRegistrationStatusFromJSON(json['faceRegistrationStatus']),
        'pin': !exists(json, 'pin') ? undefined : json['pin'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'phone': value.phone,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'environmentId': value.environmentId,
        'customerId': value.customerId,
        'name': value.name,
        'initial': value.initial,
        'avatarUrl': value.avatarUrl,
        'lastAccessed': value.lastAccessed === undefined ? undefined : (value.lastAccessed === null ? null : value.lastAccessed.toISOString()),
        'totalAccessed': value.totalAccessed,
        'active': value.active,
        'customerName': value.customerName,
        'faceRegistrationStatus': FaceRegistrationStatusToJSON(value.faceRegistrationStatus),
        'pin': value.pin,
    };
}

