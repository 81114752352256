import React from 'react';
import Icon, {
	SmileOutlined,
	QrcodeOutlined,
	WarningOutlined,
} from '@ant-design/icons';
import {
	type AccessSessionHistoriesResponseAccessSession,
} from '../../../../tools/openapi';
import { ReactComponent as DialpadSvg } from '../../../../shared/svg/dialpad.svg';
import { ReactComponent as NFC } from '../../../../shared/svg/NFC.svg';
import '../AccessSession.scss';

interface Props {
	record: AccessSessionHistoriesResponseAccessSession;
}

const AccessSessionsFactorWithIcon: React.FC<Props> = (props: Props) => {
	const ratingClassName = (
		record: AccessSessionHistoriesResponseAccessSession
	): string => {
		if (record.succeeded === true) {
			if (record.securityScoreRating === 'MOST_SECURE') {
				return 'most-secure-rating';
			}
			if (record.securityScoreRating === 'MORE_SECURE') {
				return 'more-secure-rating';
			}
			if (record.securityScoreRating === 'LESS_SECURE') {
				return 'less-secure-rating';
			}
			return 'least-secure-rating';
		}
		return ''
	};

	switch (props.record.concludedFactor) {
		case 'FACE': {
			return (
				<span className={ratingClassName(props.record)}>
					<SmileOutlined /> Face
				</span>
			);
		}
		case 'PIN': {
			return (
				<span className={ratingClassName(props.record)}>
					<Icon component={DialpadSvg} /> Pin
				</span>
			);
		}
		case 'NFC': {
			return (
				<span className={ratingClassName(props.record)}>
					<Icon component={NFC} /> NFC
				</span>
			);
		}
		case 'QR': {
			return (
				<span className={ratingClassName(props.record)}>
					<QrcodeOutlined /> QR
				</span>
			);
		}
		case 'MANUAL': {
			return (
				<span className={ratingClassName(props.record)}>
					<WarningOutlined /> Manual
				</span>
			);
		}
		default: {
			return <></>;
		}
	}
};

export default React.memo(AccessSessionsFactorWithIcon);
