import { Radio } from 'antd';
import React from 'react';
import { useAppSelector } from '../../../../../../../store/hooks';
import { type RootState } from '../../../../../../../store/store';
import { type UpdateCustomerSettingsRequest } from '../../../../../../../tools/openapi';
import CustomerPinChangeConformation from './CustomerPinChangeConformation';

interface Props {
	updateSettings: <T extends keyof UpdateCustomerSettingsRequest>(
		target: T,
		value: UpdateCustomerSettingsRequest[T]
	) => void;
	updateOperation: <T extends keyof UpdateCustomerSettingsRequest>(
		target: T,
		value: UpdateCustomerSettingsRequest[T]
	) => void;
}

const CustomerPinRadioGroup: React.FC<Props> = (props) => {
	const pinCodeDigit = useAppSelector(
		(state: RootState) => state.customerDetail.pinCodeDigit
	);

	return (
		<>
			<Radio.Group
				value={pinCodeDigit}
				onChange={(e) => {
					props.updateSettings('pinCodeDigit', e.target.value);
				}}
				style={{ display: 'flex', alignItems: 'center' }}
			>
				<Radio value="FOUR_DIGIT" style={{ marginRight: '1rem' }}>
					<span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
						4-digit
					</span>
				</Radio>
				<Radio value="SIX_DIGIT">
					<span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
						6-digit
					</span>
				</Radio>
			</Radio.Group>
			<CustomerPinChangeConformation
				updateOperation={props.updateOperation}
			/>
		</>
	);
};
export default React.memo(CustomerPinRadioGroup);
