/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnvironmentWebhookResponse
 */
export interface EnvironmentWebhookResponse {
    /**
     * 
     * @type {string}
     * @memberof EnvironmentWebhookResponse
     */
    webhook?: string | null;
}

/**
 * Check if a given object implements the EnvironmentWebhookResponse interface.
 */
export function instanceOfEnvironmentWebhookResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EnvironmentWebhookResponseFromJSON(json: any): EnvironmentWebhookResponse {
    return EnvironmentWebhookResponseFromJSONTyped(json, false);
}

export function EnvironmentWebhookResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnvironmentWebhookResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'webhook': !exists(json, 'webhook') ? undefined : json['webhook'],
    };
}

export function EnvironmentWebhookResponseToJSON(value?: EnvironmentWebhookResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'webhook': value.webhook,
    };
}

