import { Row, Col, Switch } from 'antd';
import React from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import { type RootState } from '../../../../../../store/store';
import { type UpdateCustomerSettingsRequest } from '../../../../../../tools/openapi';
import { QuestionCircleTwoTone } from '@ant-design/icons';

interface Props {
	updateSettings: <T extends keyof UpdateCustomerSettingsRequest>(
		target: T,
		value: UpdateCustomerSettingsRequest[T]
	) => void;
}

const CustomerFaceRigisOnClient: React.FC<Props> = (props) => {
	const allowFaceRegistrationOnClient = useAppSelector(
		(state: RootState) => state.customerDetail.allowFaceRegistrationOnClient
	);

	return (
		<Row>
			<Col span={16}>
				Allow Face Registration On Client:{' '}
				<QuestionCircleTwoTone title="Enable this feature to allow users the convenience of registering their facial recognition data from the client side." />
			</Col>
			<Col span={4} push={2}>
				<Switch
					checked={allowFaceRegistrationOnClient}
					onChange={(checked) => {
						props.updateSettings('allowFaceRegistrationOnClient', checked);
					}}
				/>
			</Col>
		</Row>
	);
};
export default React.memo(CustomerFaceRigisOnClient);
