import { type TabsProps } from 'antd';
import React from 'react';
import InformationTab from '../../../shared/InformationTab';
import AccessSessionInformationTab from './AccessSessionInformationTab/AccessSessionInformationTab';

const AccessSessionDetail: React.FC = () => {
	
		const items: TabsProps['items'] = [
			{
				key: '1',
				label: 'Information',
				children: <AccessSessionInformationTab />,
			},
		];

	return (
		<div style={{ padding: '1em', width: '100%' }}>
			<InformationTab items={items} />
		</div>
	);
};

export default React.memo(AccessSessionDetail);
