import { Button } from 'antd';
import React, { useState } from 'react';
import UserCreateModal from './UserCreateModal';

const UserCreateButton: React.FC = () => {
	const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);

	return (
		<>
			<Button
				type="primary"
				onClick={() => {
					setCreateModalVisible(true);
				}}
			>
				Create User
			</Button>
			<UserCreateModal
				visible={createModalVisible}
				setCreateModalVisible={setCreateModalVisible}
			/>
		</>
	);
};

export default React.memo(UserCreateButton);
