import React, { useEffect, useState } from 'react';
import { Card, Typography } from 'antd';
import { Pie, type PieConfig } from '@ant-design/plots';
import useMgmtApiWrapper from '../../hooks/openapi/useMgmtApiWrapper';

interface Props {
	id: string | undefined;
	totalUsers: number;
}

const { Title } = Typography;

const ActivityPageFaceRegistrationPercentage: React.FC<Props> = (
	props: Props
) => {
	const mgmtApiWrapper = useMgmtApiWrapper();

	const [
		totalActiveRegistrationByEnvironment,
		setTotalActiveRegistrationByEnvironment,
	] = useState(0);
	const [
		totalSubmittedRegistrationByEnvironment,
		setTotalSubmittedRegistrationByEnvironment,
	] = useState(0);

	useEffect(() => {
		const environmentId = props.id;
		if (environmentId != null) {
			void mgmtApiWrapper
				.getFaceRegistrationCount({
					environmentIds: [environmentId],
				})
				.then((r) => {
					if (r !== undefined) {
						setTotalActiveRegistrationByEnvironment(
							r.registrationsCount[0].activeCount as number
						);
						setTotalSubmittedRegistrationByEnvironment(
							r.registrationsCount[0].submittedCount as number
						);
					}
				})
		}
	}, []);

	const pieChartData = [
		{ type: 'Active', value: totalActiveRegistrationByEnvironment },
		{ type: 'Submitted', value: totalSubmittedRegistrationByEnvironment },
		{
			type: 'Not Registered',
			value:
				props.totalUsers -
				totalActiveRegistrationByEnvironment -
				totalSubmittedRegistrationByEnvironment,
		},
	];

	const pieChartConfig: PieConfig = {
		appendPadding: 10,
		data: pieChartData,
		angleField: 'value',
		colorField: 'type',
		radius: 0.8,
		label: {
			type: 'outer',
			content: '{name} {percentage}',
		},
	};

	return (
		<Card>
			<Title level={4}>Face Registration Percentage</Title>
			<Pie {...pieChartConfig} />
		</Card>
	);
};

export default React.memo(ActivityPageFaceRegistrationPercentage);
