/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccountMemberRole } from './AccountMemberRole';
import {
    AccountMemberRoleFromJSON,
    AccountMemberRoleFromJSONTyped,
    AccountMemberRoleToJSON,
} from './AccountMemberRole';

/**
 * 
 * @export
 * @interface CreateAccountMemberResponse
 */
export interface CreateAccountMemberResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateAccountMemberResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountMemberResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountMemberResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountMemberResponse
     */
    password: string;
    /**
     * 
     * @type {AccountMemberRole}
     * @memberof CreateAccountMemberResponse
     */
    role: AccountMemberRole;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountMemberResponse
     */
    avatar?: string | null;
}

/**
 * Check if a given object implements the CreateAccountMemberResponse interface.
 */
export function instanceOfCreateAccountMemberResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "role" in value;

    return isInstance;
}

export function CreateAccountMemberResponseFromJSON(json: any): CreateAccountMemberResponse {
    return CreateAccountMemberResponseFromJSONTyped(json, false);
}

export function CreateAccountMemberResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAccountMemberResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
        'name': json['name'],
        'password': json['password'],
        'role': AccountMemberRoleFromJSON(json['role']),
        'avatar': !exists(json, 'avatar') ? undefined : json['avatar'],
    };
}

export function CreateAccountMemberResponseToJSON(value?: CreateAccountMemberResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'name': value.name,
        'password': value.password,
        'role': AccountMemberRoleToJSON(value.role),
        'avatar': value.avatar,
    };
}

