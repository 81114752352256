import InformationTab from '../../../shared/InformationTab';
import UserInformation from './UserInformationTab/UserInformationTab';
import { type TabsProps } from 'antd';
import React from 'react';

const UserDetail: React.FC = () => {
	const items: TabsProps['items'] = [
		{
			key: '1',
			label: 'Information',
			children: <UserInformation />,
		},
	];

	return (
		<div className="user-information-tab">
			<InformationTab items={items} />
		</div>
	);
};

export default React.memo(UserDetail);
