import InformationTab from '../../../shared/InformationTab';
import EnvironmentInformation from './EnvironmentInformationTab/EnvironmentInformationTab';
import { type TabsProps } from 'antd';
import React from 'react';

const EnvironmentDetail: React.FC = () => {

	const items: TabsProps['items'] = [
		{
			key: '1',
			label: 'Information',
			children: <EnvironmentInformation />,
		},
	];

	return (
		<div style={{ padding: '1em', width: '100%' }}>
			<InformationTab items={items} />
		</div>
	);
};

export default React.memo(EnvironmentDetail);
