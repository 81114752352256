import React from 'react';
import DropdownButton from '../../../../../../shared/DropdownButton';
import { type User } from '../../../../../../tools/openapi';
import { type MenuProps } from 'antd';
import UserEditButton from './UserEditButton';
import { EditOutlined, EyeOutlined, UserAddOutlined } from '@ant-design/icons';
import { useAppSelector } from '../../../../../../store/hooks';
import { type RootState } from '../../../../../../store/store';
import UserInformationActivateUser from './UserInformationActivateUser';
import UserInformationOpenAccessSessions from './UserInformationOpenAccessSessions';

interface Props {
	fetchUser: () => void;
}

const UserInformationDropdownButton: React.FC<Props> = (props: Props) => {
	const userDetail = useAppSelector(
		(state: RootState) => state.userDetail.user as User
	);

	const userDropdownitems: MenuProps['items'] = [
		{
			label: <UserEditButton fetchUser={props.fetchUser} />,
			key: '0',
			icon: <EditOutlined />,
		},
		{
			label: <UserInformationOpenAccessSessions />,
			key: '2',
			icon: <EyeOutlined />,
		},
		userDetail.active
			? null
			: {
					label: <UserInformationActivateUser fetchUser={props.fetchUser} />,
					key: '1',
					icon: <UserAddOutlined />,
			  },
	];
	return <DropdownButton menu={userDropdownitems} />;
};
export default React.memo(UserInformationDropdownButton);
