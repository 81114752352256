import { Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { type GetEnvironmentRequest } from '../../../../tools/openapi';
import EnvironmentDescription from './EnvironmentDescription';
import { useDispatch } from 'react-redux';
import { resetCurrentCustomerDetail } from '../../../../store/customerDetailReducer';
import { setCurrentEnvironmentDetail } from '../../../../store/environmentDetailReducer';
import { type Params, useParams } from 'react-router-dom';
import EnvironmentDangerZone from './EnvironmentDangerZone/EnvironmentDangerZone';
import useMgmtApiWrapper from '../../../../hooks/openapi/useMgmtApiWrapper';

interface RouteParams extends Params {
	id: string;
}

const EnvironmentInformationTab: React.FC = () => {
	const mgmtApiWrapper = useMgmtApiWrapper();
	const dispatch = useDispatch();
	const { id } = useParams<RouteParams>();

	const [isEnvirInfoLoading, setIsEnvirInfoLoading] = useState<boolean>(true);
	const [envirInfoNotFound, setEnvirInfoNotFound] = useState(false);

	useEffect(() => {
		dispatch(resetCurrentCustomerDetail());
		if (id != null) {
			setIsEnvirInfoLoading(true);
			const requestBody: GetEnvironmentRequest = { environmentId: id };
			void mgmtApiWrapper
				.getEnvironment(requestBody)
				.then((r) => {
					if (r !== undefined) {
						dispatch(setCurrentEnvironmentDetail(r.environment));
					}
					setIsEnvirInfoLoading(false);
				})
				.catch(async () => {
					setEnvirInfoNotFound(true);
				});
		}
	}, []);

	if (isEnvirInfoLoading) {
		return <Spin className="loading flex-all-center" size="large" />;
	} else if (envirInfoNotFound) {
		return <h1>Environment Not Found</h1>;
	} else {
		return (
			<Space className="custom-detail-space" size="large" direction="vertical">
				<EnvironmentDescription />
				<EnvironmentDangerZone />
			</Space>
		);
	}
};
export default React.memo(EnvironmentInformationTab);
