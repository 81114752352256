import { Row, Col, Switch } from 'antd';
import React from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import { type RootState } from '../../../../../../store/store';
import { type UpdateCustomerSettingsRequest } from '../../../../../../tools/openapi';
import { QuestionCircleTwoTone } from '@ant-design/icons';

interface Props {
	updateSettings: <T extends keyof UpdateCustomerSettingsRequest>(
		target: T,
		value: UpdateCustomerSettingsRequest[T]
	) => void;
}

const CustomerManualEntry: React.FC<Props> = (props) => {
	const allowManualEntry = useAppSelector(
		(state: RootState) => state.customerDetail.allowManualEntry
	);

	return (
		<Row>
			<Col span={22}>
				Allow Manual Entry:{' '}
				<QuestionCircleTwoTone title="Activate this option to permit users to self-check in, providing an alternative when all automated identification methods are unsuccessful." />
			</Col>
			<Col span={2} push={12}>
				<Switch
					checked={allowManualEntry}
					onChange={(checked) => {
						props.updateSettings('allowManualEntry', checked);
					}}
				/>
			</Col>
		</Row>
	);
};
export default React.memo(CustomerManualEntry);
