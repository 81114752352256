import React, { useEffect, useState } from 'react';
import AppLayout from '../../layout/AppLayout';
import { useAppSelector } from '../../store/hooks';
import { type RootState } from '../../store/store';
import { type StartResponse } from '../../tools/openapi';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../Loading';
import EmailVerifyPage from '../EmailVerifyPage/EmailVerifyPage';
import OnboardingPage from '../onBoarding/OnboardingPage';

const HomePage: React.FC = () => {
	const startState: StartResponse = useAppSelector(
		(state: RootState) => state.startState.startState
	);
	const [isAccountExist, setIsAccountExist] = useState<boolean>(false);
	const [isSignUpRequired, setIsSignUpRequired] = useState<boolean>(false);
	const [isEmailVerify, setIsEmailVerify] = useState<boolean>(false);

	const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

	const handleLogin = async (): Promise<void> => {
		try {
			await loginWithRedirect();
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (!isLoading) {
			if (!isAuthenticated) {
				void handleLogin();
			}
		}
	}, [isLoading, isAuthenticated]);

	useEffect(() => {
		if (startState?.account != null) {
			setIsAccountExist(true);
			setIsSignUpRequired(false);
			setIsEmailVerify(false);
		} else if (startState?.onboarding != null) {
			setIsSignUpRequired(true);
			setIsAccountExist(false);
			setIsEmailVerify(false);
		} else if (startState?.verifyEmail != null) {
			setIsSignUpRequired(false);
			setIsAccountExist(false);
			setIsEmailVerify(true);
		}
	}, [startState]);

	if (isAccountExist) {
		return <AppLayout />;
	} else if (isSignUpRequired) {
		return <OnboardingPage />;
	} else if (isEmailVerify) {
		return <EmailVerifyPage />;
	} else {
		return <Loading />;
	}
};

export default React.memo(HomePage);
