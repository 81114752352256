import React from 'react';
import { type User } from '../../../../../tools/openapi';
import { Descriptions } from 'antd';
import { useNavigate } from 'react-router-dom';
import '../../UserDetail.scss';
import { useAppSelector } from '../../../../../store/hooks';
import { type RootState } from '../../../../../store/store';

const UserDescription: React.FC = () => {
	const navigate = useNavigate();

	const userDetail = useAppSelector(
		(state: RootState) => state.userDetail.user as User
	);

	const openCustomerDetail = (id: string): void => {
		navigate(`/customer/detail/${id}`);
	};

	const title = (
		<div className="user-section-title">
			<span className={userDetail.active ? '' : 'inactive-user'}>
				User Information
			</span>
		</div>
	);

	const descriptionItems = [
		{
			label: 'User ID',
			content: userDetail.id,
		},
		{
			label: 'Created At',
			content: userDetail.createdAt?.toLocaleString(),
		},
		{
			label: 'Last Access',
			content: userDetail.lastAccessed?.toLocaleString(),
		},
		{
			label: 'Customer Name',
			content: (
				<a
					onClick={() => {
						openCustomerDetail(userDetail.customerId);
					}}
				>
					{userDetail.customerName}
				</a>
			),
		},
		{
			label: 'Email',
			content: userDetail.email,
		},
		{
			label: 'Phone',
			content: userDetail.phone,
		},
		{
			label: 'PIN',
			content: userDetail.pin,
		},
	];

	return (
		<section className="section">
			<Descriptions title={title} layout="horizontal" column={1} bordered>
				{descriptionItems.map((item) => (
					<Descriptions.Item
						key={item.label}
						label={item.label}
						labelStyle={{ width: '12em', margin: '0.5em 0' }}
						contentStyle={{ margin: '0.5em 0 0 15em' }}
					>
						{item.content}
					</Descriptions.Item>
				))}
			</Descriptions>
		</section>
	);
};
export default React.memo(UserDescription);
