/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FaceRegistrationStatus } from './FaceRegistrationStatus';
import {
    FaceRegistrationStatusFromJSON,
    FaceRegistrationStatusFromJSONTyped,
    FaceRegistrationStatusToJSON,
} from './FaceRegistrationStatus';

/**
 * 
 * @export
 * @interface UsersResponseUser
 */
export interface UsersResponseUser {
    /**
     * 
     * @type {string}
     * @memberof UsersResponseUser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UsersResponseUser
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UsersResponseUser
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UsersResponseUser
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UsersResponseUser
     */
    initials: string;
    /**
     * 
     * @type {string}
     * @memberof UsersResponseUser
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof UsersResponseUser
     */
    avatarUrl?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UsersResponseUser
     */
    lastAccessed?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof UsersResponseUser
     */
    totalAccessed: number;
    /**
     * 
     * @type {FaceRegistrationStatus}
     * @memberof UsersResponseUser
     */
    registrationStatus?: FaceRegistrationStatus | null;
    /**
     * 
     * @type {string}
     * @memberof UsersResponseUser
     */
    customerName: string;
    /**
     * 
     * @type {boolean}
     * @memberof UsersResponseUser
     */
    active: boolean;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof UsersResponseUser
     */
    accessFactorStatus: { [key: string]: boolean; };
}

/**
 * Check if a given object implements the UsersResponseUser interface.
 */
export function instanceOfUsersResponseUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "initials" in value;
    isInstance = isInstance && "customerId" in value;
    isInstance = isInstance && "totalAccessed" in value;
    isInstance = isInstance && "customerName" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "accessFactorStatus" in value;

    return isInstance;
}

export function UsersResponseUserFromJSON(json: any): UsersResponseUser {
    return UsersResponseUserFromJSONTyped(json, false);
}

export function UsersResponseUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersResponseUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'initials': json['initials'],
        'customerId': json['customerId'],
        'avatarUrl': !exists(json, 'avatarUrl') ? undefined : json['avatarUrl'],
        'lastAccessed': !exists(json, 'lastAccessed') ? undefined : (json['lastAccessed'] === null ? null : new Date(json['lastAccessed'])),
        'totalAccessed': json['totalAccessed'],
        'registrationStatus': !exists(json, 'registrationStatus') ? undefined : FaceRegistrationStatusFromJSON(json['registrationStatus']),
        'customerName': json['customerName'],
        'active': json['active'],
        'accessFactorStatus': json['accessFactorStatus'],
    };
}

export function UsersResponseUserToJSON(value?: UsersResponseUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'name': value.name,
        'initials': value.initials,
        'customerId': value.customerId,
        'avatarUrl': value.avatarUrl,
        'lastAccessed': value.lastAccessed === undefined ? undefined : (value.lastAccessed === null ? null : value.lastAccessed.toISOString()),
        'totalAccessed': value.totalAccessed,
        'registrationStatus': FaceRegistrationStatusToJSON(value.registrationStatus),
        'customerName': value.customerName,
        'active': value.active,
        'accessFactorStatus': value.accessFactorStatus,
    };
}

