/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccessStepHistoryResponseAttempt } from './AccessStepHistoryResponseAttempt';
import {
    AccessStepHistoryResponseAttemptFromJSON,
    AccessStepHistoryResponseAttemptFromJSONTyped,
    AccessStepHistoryResponseAttemptToJSON,
} from './AccessStepHistoryResponseAttempt';

/**
 * 
 * @export
 * @interface AccessStepHistoryResponse
 */
export interface AccessStepHistoryResponse {
    /**
     * 
     * @type {Array<AccessStepHistoryResponseAttempt>}
     * @memberof AccessStepHistoryResponse
     */
    attempts: Array<AccessStepHistoryResponseAttempt>;
}

/**
 * Check if a given object implements the AccessStepHistoryResponse interface.
 */
export function instanceOfAccessStepHistoryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "attempts" in value;

    return isInstance;
}

export function AccessStepHistoryResponseFromJSON(json: any): AccessStepHistoryResponse {
    return AccessStepHistoryResponseFromJSONTyped(json, false);
}

export function AccessStepHistoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessStepHistoryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attempts': ((json['attempts'] as Array<any>).map(AccessStepHistoryResponseAttemptFromJSON)),
    };
}

export function AccessStepHistoryResponseToJSON(value?: AccessStepHistoryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attempts': ((value.attempts as Array<any>).map(AccessStepHistoryResponseAttemptToJSON)),
    };
}

