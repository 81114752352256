import React, { useEffect, useState } from 'react';
import '../AccountMembers.scss';
import { type ColumnsType } from 'antd/es/table';
import DataTable from '../../../shared/DataTable';
import { type AccountMemberResponse } from '../../../tools/openapi/models/AccountMemberResponse';
import { useAppSelector } from '../../../store/hooks';
import { type RootState } from '../../../store/store';
import AccountMemberDropDownButton from './AccountMemberDropDownButton';

const AccountMemberTable: React.FC = () => {
	const [filteredMembers, setFilteredMembers] = useState<
		AccountMemberResponse[]
	>([]);

	const searchTerm = useAppSelector(
		(state: RootState) => state.accountMember.searchTerm
	);

	const accountMemberList = useAppSelector(
		(state: RootState) => state.accountMember.accountMembers
	);

	useEffect(() => {
		const filteredMembers = accountMemberList.filter((member) =>
			member.name.toLowerCase().includes(searchTerm.toLowerCase())
		);

		setFilteredMembers(filteredMembers);
	}, [searchTerm]);

	const columns: ColumnsType<AccountMemberResponse> = [
		{
			title: 'Name',
			dataIndex: 'name',
		},
		{
			title: 'Email',
			dataIndex: 'email',
		},
		{
			title: 'Role',
			dataIndex: 'role',
		},
		{
			title: 'Actions',
			render: (_, record) => <AccountMemberDropDownButton record={record} />,
		},
	];

	return (
		<>
			<DataTable
				data={searchTerm.length > 0 ? filteredMembers : accountMemberList}
				columns={columns}
				rowKey={'id'}
				tableStyle={undefined}
				pagination={true}
			/>
		</>
	);
};

export default React.memo(AccountMemberTable);
