/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    avatarUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    pin?: string | null;
}

/**
 * Check if a given object implements the UpdateUserRequest interface.
 */
export function instanceOfUpdateUserRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateUserRequestFromJSON(json: any): UpdateUserRequest {
    return UpdateUserRequestFromJSONTyped(json, false);
}

export function UpdateUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'avatarUrl': !exists(json, 'avatarUrl') ? undefined : json['avatarUrl'],
        'pin': !exists(json, 'pin') ? undefined : json['pin'],
    };
}

export function UpdateUserRequestToJSON(value?: UpdateUserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'name': value.name,
        'email': value.email,
        'phone': value.phone,
        'avatarUrl': value.avatarUrl,
        'pin': value.pin,
    };
}

