import React from 'react';
import { Space } from 'antd';
import Title from 'antd/es/typography/Title';
import './Customers.scss';
import CreateButton from './header/CustomerCreateButton';
import SearchBar from './header/CustomerSearchBar';
import CustomersTable from './table/CustomersTable';

const Customers: React.FC = () => {
	return (
		<>
			<Title level={3}>Customers</Title>
			<div className="customer-content">
				<Space direction="vertical" className="custom-table-space" size="large">
					<div className="customers-header">
						<SearchBar />
						<CreateButton />
					</div>
					<CustomersTable />
				</Space>
			</div>
		</>
	);
};

export default React.memo(Customers);
