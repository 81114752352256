import { Input, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { type RootState } from '../../../../store/store';
import { type StartResponse } from '../../../../tools/openapi';
import {
	setAccessSessionList,
	setLoading,
	setPageNumber,
} from '../../../../store/accessSessionReducer';
import AccessSessionsIncludeUnsuccessfulCheckbox from './AccessSessionsIncludeUnsuccessfulCheckbox';
import AccessSessionsSelectSearchType from './AccessSessionsSelectSearchType';
import useMgmtApiWrapper from '../../../../hooks/openapi/useMgmtApiWrapper';

const { Search } = Input;

type SearchType = 'customer' | 'user' | 'customerId' | 'userId';

const AccessSessionsSearchBar: React.FC = () => {
	const mgmtApiWrapper = useMgmtApiWrapper();
	const dispatch = useAppDispatch();

	const startState: StartResponse = useAppSelector(
		(state: RootState) => state.startState.startState
	);
	const environment = useAppSelector(
		(state: RootState) => state.environment.environment
	);
	const page = useAppSelector(
		(state: RootState) => state.accessSession.pageNumber
	);
	const size = useAppSelector(
		(state: RootState) => state.accessSession.PageSize
	);

	const [searchType, setSearchType] = useState<SearchType>('user');
	const [inputSearchValue, setInputSearchValue] = useState('');
	const [debouncedSearchValue, setDebouncedSearchValue] = useState('');
	const [includeUnsuccessful, setIncludeUnsuccessful] = useState(false);

	const fetchData = (): void => {
		const searchParams = new URLSearchParams(location.search);
		const searchQuery = searchParams.get('search');
		const environmentId =
			environment?.id.length > 0
				? environment?.id
				: startState.account?.activeEnvironment?.id;

		dispatch(setLoading(true));

		const requestBody = {
			...(searchType !== 'customerId' && searchType !== 'userId'
				? { environmentId: environmentId as string }
				: {}),
			includeUnSuccessful: includeUnsuccessful,
			pageNumber: page - 1,
			pageSize: size,
			...(debouncedSearchValue != null && debouncedSearchValue.length > 0
				? searchType === 'customer'
					? { customerName: debouncedSearchValue }
					: searchType === 'user'
					? { userName: debouncedSearchValue }
					: searchType === 'customerId'
					? { customerId: searchQuery }
					: { userId: searchQuery }
				: {}),
		};

		void mgmtApiWrapper
			.getAccessSessionHistories(requestBody)
			.then((r) => {
				if (r !== undefined) {
					dispatch(setAccessSessionList(r.accessSessions));
				}
				dispatch(setLoading(false));
			})
	};

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const searchQuery = searchParams.get('search');
		const typeQuery: SearchType | null = searchParams.get('type') as SearchType;
		if (searchQuery != null) {
			setInputSearchValue(searchQuery);
			setSearchType(typeQuery);
		}
	}, [location.search]);

	useEffect(() => {
		const debouncedValueUpdate = setTimeout(() => {
			page !== 1
				? dispatch(setPageNumber(1))
				: setDebouncedSearchValue(inputSearchValue);
		}, 500);

		return () => {
			clearTimeout(debouncedValueUpdate);
		};
	}, [inputSearchValue]);

	useEffect(() => {
		fetchData();
	}, [environment, debouncedSearchValue, size, page, includeUnsuccessful]);

	return (
		<Space.Compact style={{ alignItems: 'center' }}>
			<AccessSessionsSelectSearchType
				searchType={searchType}
				setSearchType={setSearchType}
			/>
			<Search
				value={inputSearchValue}
				placeholder={`Search by ${searchType}`}
				allowClear
				enterButton
				style={{ width: '60%' }}
				onChange={(e) => {
					setInputSearchValue(e.target.value);
				}}
			/>
			<AccessSessionsIncludeUnsuccessfulCheckbox
				setIncludeUnsuccessful={setIncludeUnsuccessful}
				includeUnsuccessful={includeUnsuccessful}
			/>
		</Space.Compact>
	);
};

export default React.memo(AccessSessionsSearchBar);
