/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SecurityScoreRating = {
    MostSecure: 'MOST_SECURE',
    MoreSecure: 'MORE_SECURE',
    LessSecure: 'LESS_SECURE',
    LeastSecure: 'LEAST_SECURE'
} as const;
export type SecurityScoreRating = typeof SecurityScoreRating[keyof typeof SecurityScoreRating];


export function SecurityScoreRatingFromJSON(json: any): SecurityScoreRating {
    return SecurityScoreRatingFromJSONTyped(json, false);
}

export function SecurityScoreRatingFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecurityScoreRating {
    return json as SecurityScoreRating;
}

export function SecurityScoreRatingToJSON(value?: SecurityScoreRating | null): any {
    return value as any;
}

