import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'antd';
import { useAppSelector } from '../../store/hooks';
import { type RootState } from '../../store/store';
import AccessSessionsTable from './ActivityPageAccessSessionsTable';
import ActivityPageLineChart from './ActivityPageLineChart';
import { type StartResponse } from '../../tools/openapi';
import ActivityPageFaceRegistrationPercentage from './ActivityPageFaceRegistrationPercentage';
import ActivityPageFaceRegistrationByCustomer from './ActivityPageFaceRegistrationByCustomer';
import ActivityPageTotalCustomers from './ActivityPageTotalCustomers';
import ActivityPageTotalUsers from './ActivityPageTotalUsers';
import useMgmtApiWrapper from '../../hooks/openapi/useMgmtApiWrapper';

const ActivityPage: React.FC = () => {
	const mgmtApiWrapper = useMgmtApiWrapper();

	const [totalUsers, setTotalUsers] = useState(0);
	const [isTotalUserLoading, setIsTotalUserLoading] = useState(true);

	const startState: StartResponse = useAppSelector(
		(state: RootState) => state.startState.startState
	);
	const environment = useAppSelector(
		(state: RootState) => state.environment.environment
	);

	const envId =
		environment?.id.length > 0
			? environment?.id
			: startState.account?.activeEnvironment?.id;

	useEffect(() => {
		setIsTotalUserLoading(true);
		const environmentId =
			environment?.id.length > 0
				? environment?.id
				: startState.account?.activeEnvironment?.id;
		if (environmentId != null) {
			void mgmtApiWrapper
				.getUserCountByEnvironmentId({
					environmentId,
				})
				.then((r) => {
					if (r !== undefined) {
						setTotalUsers(r.size);
					}
					setIsTotalUserLoading(false);
				});
		}
	}, []);

	if (startState.account?.account.singleCustomer === true) {
		return (
			<div>
				<Row gutter={16}>
					<Col span={24}>
						<ActivityPageTotalUsers
							totalUsers={totalUsers}
							id={envId}
							isTotalUserLoading={isTotalUserLoading}
						/>
					</Col>
				</Row>
				<Row gutter={16} style={{ marginTop: 16 }}>
					<Col span={24}>
						<Card>
							<ActivityPageLineChart id={envId} />
						</Card>
					</Col>
				</Row>
				<Row gutter={16} style={{ marginTop: 16 }}>
					<Col span={24}>
						<AccessSessionsTable id={envId} />
					</Col>
				</Row>
				<Row gutter={16} style={{ marginTop: 16 }}>
					<Col span={24}>
						<ActivityPageFaceRegistrationPercentage
							id={envId}
							totalUsers={totalUsers}
						/>
					</Col>
				</Row>
			</div>
		);
	}

	return (
		<div>
			<Row gutter={16}>
				<Col span={12}>
					<ActivityPageTotalCustomers id={envId} />
				</Col>
				<Col span={12}>
					<ActivityPageTotalUsers
						totalUsers={totalUsers}
						id={envId}
						isTotalUserLoading={isTotalUserLoading}
					/>
				</Col>
			</Row>

			<Row gutter={16} style={{ marginTop: 16 }}>
				<Col span={24}>
					<Card>
						<ActivityPageLineChart id={envId} />
					</Card>
				</Col>
			</Row>
			<Row gutter={16} style={{ marginTop: 16 }}>
				<Col span={24}>
					<AccessSessionsTable id={envId} />
				</Col>
			</Row>
			<Row gutter={16} style={{ marginTop: 16 }}>
				<Col span={12}>
					<ActivityPageFaceRegistrationPercentage
						id={envId}
						totalUsers={totalUsers}
					/>
				</Col>
				<Col span={12}>
					<ActivityPageFaceRegistrationByCustomer id={envId} />
				</Col>
			</Row>
		</div>
	);
};

export default React.memo(ActivityPage);
