/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UpdatedCustomDataRequest
 */
export interface UpdatedCustomDataRequest {
	/**
	 *
	 * @type {string}
	 * @memberof UpdatedCustomDataRequest
	 */
	customData: string;
}

/**
 * Check if a given object implements the UpdatedCustomDataRequest interface.
 */
export function instanceOfUpdatedCustomDataRequest(value: object): boolean {
	let isInstance = true;
	isInstance = isInstance && 'customData' in value;

	return isInstance;
}

export function UpdatedCustomDataRequestFromJSON(
	json: any
): UpdatedCustomDataRequest {
	return UpdatedCustomDataRequestFromJSONTyped(json, false);
}

export function UpdatedCustomDataRequestFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): UpdatedCustomDataRequest {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		customData: json['customData'],
	};
}

export function UpdatedCustomDataRequestToJSON(
	value?: UpdatedCustomDataRequest | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		customData: value.customData,
	};
}
