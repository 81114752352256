/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateEnvironmentSettingsRequest
 */
export interface UpdateEnvironmentSettingsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateEnvironmentSettingsRequest
     */
    webhook?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEnvironmentSettingsRequest
     */
    allowManualEntry?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEnvironmentSettingsRequest
     */
    allowNewFaceRegistrationOnIncorrectResult?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEnvironmentSettingsRequest
     */
    allowNewFaceRegistrationOnUnrecognizedFace?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEnvironmentSettingsRequest
     */
    allowTurningOffCamera?: boolean | null;
}

/**
 * Check if a given object implements the UpdateEnvironmentSettingsRequest interface.
 */
export function instanceOfUpdateEnvironmentSettingsRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateEnvironmentSettingsRequestFromJSON(json: any): UpdateEnvironmentSettingsRequest {
    return UpdateEnvironmentSettingsRequestFromJSONTyped(json, false);
}

export function UpdateEnvironmentSettingsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateEnvironmentSettingsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'webhook': !exists(json, 'webhook') ? undefined : json['webhook'],
        'allowManualEntry': !exists(json, 'allowManualEntry') ? undefined : json['allowManualEntry'],
        'allowNewFaceRegistrationOnIncorrectResult': !exists(json, 'allowNewFaceRegistrationOnIncorrectResult') ? undefined : json['allowNewFaceRegistrationOnIncorrectResult'],
        'allowNewFaceRegistrationOnUnrecognizedFace': !exists(json, 'allowNewFaceRegistrationOnUnrecognizedFace') ? undefined : json['allowNewFaceRegistrationOnUnrecognizedFace'],
        'allowTurningOffCamera': !exists(json, 'allowTurningOffCamera') ? undefined : json['allowTurningOffCamera'],
    };
}

export function UpdateEnvironmentSettingsRequestToJSON(value?: UpdateEnvironmentSettingsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'webhook': value.webhook,
        'allowManualEntry': value.allowManualEntry,
        'allowNewFaceRegistrationOnIncorrectResult': value.allowNewFaceRegistrationOnIncorrectResult,
        'allowNewFaceRegistrationOnUnrecognizedFace': value.allowNewFaceRegistrationOnUnrecognizedFace,
        'allowTurningOffCamera': value.allowTurningOffCamera,
    };
}

