import React, { useState } from 'react';
import { type CustomerResponseCustomer } from '../../../../tools/openapi';
import CustomerCreateAndUpdateModal from '../header/CustomerCreateAndUpdateModal';
interface Props {
	record: CustomerResponseCustomer;
}

const CustomerInRowEdit: React.FC<Props> = (props: Props) => {
	const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

	return (
		<>
			<a
				onClick={() => {
					setIsCreateModalVisible(true);
				}}
			>
				Edit
			</a>
			<CustomerCreateAndUpdateModal
				record={props.record}
				isCreateModalVisible={isCreateModalVisible}
				setIsCreateModalVisible={setIsCreateModalVisible}
			/>
		</>
	);
};

export default React.memo(CustomerInRowEdit);
