import { Descriptions } from 'antd';
import React from 'react';

import { type EnvironmentResponseEnvironment } from '../../../../tools/openapi';
import { type RootState } from '../../../../store/store';
import { useAppSelector } from '../../../../store/hooks';

const EnvironmentDescription: React.FC = () => {
	const environmentDetail = useAppSelector(
		(state: RootState) =>
			state.environmentDetail.environment as EnvironmentResponseEnvironment
	);

	const descriptionItems = [
		{
			label: 'Name',
			content: environmentDetail?.name,
		},
		{
			label: 'Customers',
			content: environmentDetail?.size,
		},
	];

	return (
		<section className="section">
			<Descriptions
				title={'Environment Infomation'}
				layout="horizontal"
				column={1}
				bordered
			>
				{descriptionItems.map((item) => (
					<Descriptions.Item
						key={item.label}
						label={item.label}
						labelStyle={{ width: '12em', margin: '0.5em 0' }}
						contentStyle={{ margin: '0.5em 0 0 15em' }}
					>
						{item.content}
					</Descriptions.Item>
				))}
			</Descriptions>
		</section>
	);
};
export default React.memo(EnvironmentDescription);
