import React, { useState } from 'react';
import { Popover, Avatar } from 'antd';

import './AvatarButton.css';
import { type StartResponse } from '../../tools/openapi';
import { useAppSelector } from '../../store/hooks';
import { type RootState } from '../../store/store';
import LogoutButton from '../../login/LogoutButton';

const AvatarButton: React.FC = () => {
	const startState: StartResponse = useAppSelector(
		(state: RootState) => state.startState.startState
	);
	const { account } = startState;
	const accountMember = account?.accountMember;
	const imgUrl = accountMember?.avatar;
	const username = accountMember?.name;
	const email = accountMember?.email;
	const [visible, setVisible] = useState(false);

	const toggleVisible = (): void => {
		setVisible(!visible);
	};

	const content = (
		<div className="avatar-button-popover">
			<Avatar size={64} src={imgUrl}>
				{username?.charAt(0)}
			</Avatar>
			<div className="avatar-button-profile">
				<div className="avatar-button-username">{username}</div>
				<div className="avatar-button-email">{email}</div>
			</div>
			<LogoutButton />
		</div>
	);

	return (
		<Popover
			content={content}
			trigger="click"
			open={visible}
			onOpenChange={toggleVisible}
			placement="bottomRight"
		>
			<Avatar size={40} src={imgUrl} style={{ backgroundColor: 'gray' }}>
				{username?.charAt(0)}
			</Avatar>
		</Popover>
	);
};

export default AvatarButton;
