/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FaceRegistrationImage } from './FaceRegistrationImage';
import {
    FaceRegistrationImageFromJSON,
    FaceRegistrationImageFromJSONTyped,
    FaceRegistrationImageToJSON,
} from './FaceRegistrationImage';
import type { FaceRegistrationSource } from './FaceRegistrationSource';
import {
    FaceRegistrationSourceFromJSON,
    FaceRegistrationSourceFromJSONTyped,
    FaceRegistrationSourceToJSON,
} from './FaceRegistrationSource';
import type { FaceRegistrationStatus } from './FaceRegistrationStatus';
import {
    FaceRegistrationStatusFromJSON,
    FaceRegistrationStatusFromJSONTyped,
    FaceRegistrationStatusToJSON,
} from './FaceRegistrationStatus';

/**
 * 
 * @export
 * @interface FaceRegistration
 */
export interface FaceRegistration {
    /**
     * 
     * @type {string}
     * @memberof FaceRegistration
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof FaceRegistration
     */
    createdAt: Date;
    /**
     * 
     * @type {FaceRegistrationSource}
     * @memberof FaceRegistration
     */
    source: FaceRegistrationSource;
    /**
     * 
     * @type {FaceRegistrationStatus}
     * @memberof FaceRegistration
     */
    status?: FaceRegistrationStatus | null;
    /**
     * 
     * @type {Array<FaceRegistrationImage>}
     * @memberof FaceRegistration
     */
    images: Array<FaceRegistrationImage>;
}

/**
 * Check if a given object implements the FaceRegistration interface.
 */
export function instanceOfFaceRegistration(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "images" in value;

    return isInstance;
}

export function FaceRegistrationFromJSON(json: any): FaceRegistration {
    return FaceRegistrationFromJSONTyped(json, false);
}

export function FaceRegistrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): FaceRegistration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'source': FaceRegistrationSourceFromJSON(json['source']),
        'status': !exists(json, 'status') ? undefined : FaceRegistrationStatusFromJSON(json['status']),
        'images': ((json['images'] as Array<any>).map(FaceRegistrationImageFromJSON)),
    };
}

export function FaceRegistrationToJSON(value?: FaceRegistration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'source': FaceRegistrationSourceToJSON(value.source),
        'status': FaceRegistrationStatusToJSON(value.status),
        'images': ((value.images as Array<any>).map(FaceRegistrationImageToJSON)),
    };
}

