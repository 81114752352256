/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConfirmPinAccessRequest
 */
export interface ConfirmPinAccessRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfirmPinAccessRequest
     */
    stepId: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmPinAccessRequest
     */
    environmentId: string;
}

/**
 * Check if a given object implements the ConfirmPinAccessRequest interface.
 */
export function instanceOfConfirmPinAccessRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "stepId" in value;
    isInstance = isInstance && "environmentId" in value;

    return isInstance;
}

export function ConfirmPinAccessRequestFromJSON(json: any): ConfirmPinAccessRequest {
    return ConfirmPinAccessRequestFromJSONTyped(json, false);
}

export function ConfirmPinAccessRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfirmPinAccessRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stepId': json['stepId'],
        'environmentId': json['environmentId'],
    };
}

export function ConfirmPinAccessRequestToJSON(value?: ConfirmPinAccessRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stepId': value.stepId,
        'environmentId': value.environmentId,
    };
}

