/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EnvironmentResponseEnvironment } from './EnvironmentResponseEnvironment';
import {
    EnvironmentResponseEnvironmentFromJSON,
    EnvironmentResponseEnvironmentFromJSONTyped,
    EnvironmentResponseEnvironmentToJSON,
} from './EnvironmentResponseEnvironment';

/**
 * 
 * @export
 * @interface EnvironmentResponse
 */
export interface EnvironmentResponse {
    /**
     * 
     * @type {EnvironmentResponseEnvironment}
     * @memberof EnvironmentResponse
     */
    environment: EnvironmentResponseEnvironment;
}

/**
 * Check if a given object implements the EnvironmentResponse interface.
 */
export function instanceOfEnvironmentResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "environment" in value;

    return isInstance;
}

export function EnvironmentResponseFromJSON(json: any): EnvironmentResponse {
    return EnvironmentResponseFromJSONTyped(json, false);
}

export function EnvironmentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnvironmentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'environment': EnvironmentResponseEnvironmentFromJSON(json['environment']),
    };
}

export function EnvironmentResponseToJSON(value?: EnvironmentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'environment': EnvironmentResponseEnvironmentToJSON(value.environment),
    };
}

