import React from 'react';
import { Card, Skeleton, Statistic } from 'antd';
import { useNavigate } from 'react-router-dom';

interface Props {
	id: string | undefined;
	totalUsers: number;
	isTotalUserLoading: boolean;
}

const ActivityPageTotalUsers: React.FC<Props> = (props: Props) => {
	const navigate = useNavigate();

	const handleUsersClick = (): void => {
		navigate('/user');
	};

	if (props.isTotalUserLoading) {
		return (
			<Card onClick={handleUsersClick}>
				<Statistic
					title="Number of Users"
					valueRender={() => (
						<Skeleton
							loading={props.isTotalUserLoading}
							paragraph={false}
							title={{ style: { marginTop: 12, marginBottom: 12 } }}
						/>
					)}
				/>
			</Card>
		);
	} else {
		return (
			<Card onClick={handleUsersClick}>
				<Statistic title="Number of Users" value={props.totalUsers} />
			</Card>
		);
	}
};

export default React.memo(ActivityPageTotalUsers);
