import React, { useEffect } from 'react';
import { Dropdown } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import {
	setEnvironment,
	setEnvironmentList,
} from '../../store/environmentReducer';
import { type RootState } from '../../store/store';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { type StartResponse } from '../../tools/openapi';
import { type EnvironmentsResponseEnvironment } from '../../tools/openapi/models/EnvironmentsResponseEnvironment';
import useMgmtApiWrapper from '../../hooks/openapi/useMgmtApiWrapper';

const EnvironmentSwitch: React.FC = () => {
	const dispatch = useAppDispatch();
	const environment = useAppSelector(
		(state: RootState) => state.environment.environment
	);
	const environmentList = useAppSelector(
		(state: RootState) => state.environment.environments
	);
	const startState: StartResponse = useAppSelector(
		(state: RootState) => state.startState.startState
	);
	const mgmtApiWrapper = useMgmtApiWrapper();

	const fetchEnvironments = (): void => {
		void mgmtApiWrapper
			.getEnvironments()
			.then((r) => {
				if (r !== undefined) {
					dispatch(setEnvironmentList(r.environments));
				}
			})
	};

	useEffect(() => {
		if (environment.id.length === 0 && startState.account != null) {
			dispatch(setEnvironment(startState.account.activeEnvironment));
		}
	}, []);

	const items = environmentList.map((e: EnvironmentsResponseEnvironment) => {
		return { label: e.name, key: e.id, value: e };
	});

	const onClick = (value: EnvironmentsResponseEnvironment): void => {
		void mgmtApiWrapper
			.switchEnvironment({ environmentId: value.id })
			.then(() => {
				dispatch(setEnvironment(value));
				window.location.reload();
			})
	};

	const menu = {
		items: items.map((item) => ({
			key: item.key,
			onClick: () => {
				onClick(item.value);
			},
			label: item.label,
		})),
	};

	// const menu = (
	// 	<Menu>
	// 		{items.map((item) => (
	// 			<Menu.Item
	// 				key={item.key}
	// 				onClick={() => {
	// 					onClick(item.value);
	// 				}}
	// 			>
	// 				{item.label}
	// 			</Menu.Item>
	// 		))}
	// 	</Menu>
	// );

	return (
		<Dropdown menu={menu} placement="bottomRight" trigger={['click']}>
			<a className="ant-dropdown-link" onClick={fetchEnvironments}>
				ENVIRONMENT: <br />
				{environment.name} <RightOutlined />
			</a>
		</Dropdown>
	);
};

export default React.memo(EnvironmentSwitch);
