import { Modal, Form, Input } from 'antd';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { type RootState } from '../../../../store/store';
import {
	type StartResponse,
	type CreateCustomerRequest,
	type CustomerResponseCustomer,
} from '../../../../tools/openapi';
import {
	setCustomerCount,
	setCustomerList,
	setLoading,
} from '../../../../store/customerReducer';
import useMgmtApiWrapper from '../../../../hooks/openapi/useMgmtApiWrapper';

interface Props {
	record?: CustomerResponseCustomer;
	setIsCreateModalVisible: (e: boolean) => void;
	isCreateModalVisible: boolean;
}

const CustomerCreateAndUpdateModal: React.FC<Props> = (props: Props) => {
	const mgmtApiWrapper = useMgmtApiWrapper();
	const dispatch = useAppDispatch();
	const [form] = Form.useForm();

	const startState: StartResponse = useAppSelector(
		(state: RootState) => state.startState.startState
	);

	const environment = useAppSelector(
		(state: RootState) => state.environment.environment
	);

	const size = useAppSelector((state: RootState) => state.customer.PageSize);

	const page = useAppSelector((state: RootState) => state.customer.pageNumber);

	const fetchData = (): void => {
		const environmentId =
			environment?.id.length > 0
				? environment?.id
				: startState.account?.activeEnvironment?.id;
		dispatch(setLoading(true));
		const requestBody = {
			environmentId: environmentId as string,
			pageNumber: page - 1,
			pageSize: size,
		};
		void mgmtApiWrapper.getCustomers(requestBody).then((r) => {
			if (r !== undefined) {
				dispatch(setCustomerCount(r.totalCount));
				dispatch(setCustomerList(r.customers));
			}
			dispatch(setLoading(false));
		});
	};

	const handleCancel = (): void => {
		form.resetFields();
		props.setIsCreateModalVisible(false);
	};

	const handleSubmit = (values: CreateCustomerRequest): void => {
		const environmentId =
			environment?.id.length > 0
				? environment?.id
				: startState.account?.activeEnvironment?.id;
		if (props.record == null) {
			const requestBody = {
				environmentId: environmentId as string,
				name: values.name,
			};
			void mgmtApiWrapper
				.createCustomer({ createCustomerRequest: requestBody })
				.then(() => {
					props.setIsCreateModalVisible(false);
					fetchData();
				});
		} else {
			void mgmtApiWrapper
				.updateCustomerById({
					customerId: props.record?.id,
					updateCustomerRequest: { name: values.name },
				})
				.then(() => {
					props.setIsCreateModalVisible(false);
					fetchData();
				});
		}
	};

	useEffect(() => {
		if (props.record != null) {
			form.setFieldsValue({
				name: props.record?.name,
			});
		} else {
			form.setFieldsValue({
				name: '',
			});
		}
	}, []);

	return (
		<Modal
			className="create-modal"
			width={600}
			title={props.record != null ? 'Update Customer' : 'New Customer'}
			open={props.isCreateModalVisible}
			okText={props.record != null ? 'Update' : 'Create'}
			cancelText="Cancel"
			onCancel={handleCancel}
			centered={true}
			onOk={() => {
				form
					.validateFields()
					.then((values) => {
						form.resetFields();
						handleSubmit(values);
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
		>
			<Form
				form={form}
				name="basic"
				labelCol={{ span: 6 }}
				wrapperCol={{ span: 16 }}
			>
				<Form.Item
					label="Name"
					name="name"
					tooltip="The name of your customer, e.g. Corner Coffee Shop, Main St Restaurant, or McAlister manufacturing."
					rules={[
						{
							required: true,
							message: 'Customer name is required.',
						},
					]}
				>
					<Input />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default React.memo(CustomerCreateAndUpdateModal);
