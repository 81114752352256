/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FaceRegistrationCountResponse
 */
export interface FaceRegistrationCountResponse {
    /**
     * 
     * @type {string}
     * @memberof FaceRegistrationCountResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FaceRegistrationCountResponse
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FaceRegistrationCountResponse
     */
    activeCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FaceRegistrationCountResponse
     */
    submittedCount?: number | null;
}

/**
 * Check if a given object implements the FaceRegistrationCountResponse interface.
 */
export function instanceOfFaceRegistrationCountResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function FaceRegistrationCountResponseFromJSON(json: any): FaceRegistrationCountResponse {
    return FaceRegistrationCountResponseFromJSONTyped(json, false);
}

export function FaceRegistrationCountResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FaceRegistrationCountResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'activeCount': !exists(json, 'activeCount') ? undefined : json['activeCount'],
        'submittedCount': !exists(json, 'submittedCount') ? undefined : json['submittedCount'],
    };
}

export function FaceRegistrationCountResponseToJSON(value?: FaceRegistrationCountResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'activeCount': value.activeCount,
        'submittedCount': value.submittedCount,
    };
}

