import { Layout, Menu } from 'antd';
import {
	UserOutlined,
	SettingOutlined,
	MailOutlined,
	LineChartOutlined,
	SmileOutlined,
} from '@ant-design/icons';
import { Link, matchRoutes, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { router } from '../../router';
import '../AppLayout.scss';
import { type ItemType } from 'antd/es/menu/hooks/useItems';

const { Sider } = Layout;

const SingleCustomerSider: React.FC = () => {
	const navigate = useNavigate();

	const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<string[]>([]);
	const [defaultOpenKeys, setDefaultOpenKeys] = useState<string[]>([]);

	useEffect(() => {
		const routes = matchRoutes(router, location.pathname);
		const pathArr: string[] = [];
		if (routes !== null) {
			if (routes.length === 1) {
				navigate('/activity');
			}
			routes.forEach((item) => {
				let path = item.route.path;
				if (path != null) {
					if (path.indexOf('customer') > 0) {
						path = '/customer';
					} else if (path.indexOf('access_session') > 0) {
						path = '/access_session';
					} else if (path.indexOf('user') > 0) {
						path = '/user';
					}

					pathArr.push(path);
				}
			});
		}
		setDefaultSelectedKeys(pathArr);
		setDefaultOpenKeys(pathArr);
	}, [location.pathname]);

	const menuItems: ItemType[] = [
		{
			key: '/s/activity',
			icon: <LineChartOutlined />,
			label: <Link to="/s/activity">Activities</Link>,
		},
		{
			key: '/s/user',
			icon: <UserOutlined />,
			label: <Link to="/s/user">Users</Link>,
		},
		{
			key: '/s/access_session',
			icon: <SmileOutlined />,
			label: <Link to="/s/access_session">Access Sessions</Link>,
		},
		{
			key: '/s/feedback',
			icon: <MailOutlined />,
			label: (
				<Link to="https://facade.canny.io/" target={'_blank'}>
					Feedback
				</Link>
			),
			className: 'feedback',
		},
		{
			key: '/s/settings',
			icon: <SettingOutlined />,
			label: <Link to="/s/settings">Settings</Link>,
			className: 'settings',
		},
	];

	return (
		<Sider className="siderLayout">
			<Menu
				mode="inline"
				theme="dark"
				selectedKeys={defaultSelectedKeys}
				defaultOpenKeys={defaultOpenKeys}
				className="sideMenu"
				items={menuItems}
			>
				{}
			</Menu>
		</Sider>
	);
};

export default React.memo(SingleCustomerSider);
