/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BatchCreateCustomersResponseCustomer
 */
export interface BatchCreateCustomersResponseCustomer {
    /**
     * 
     * @type {string}
     * @memberof BatchCreateCustomersResponseCustomer
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof BatchCreateCustomersResponseCustomer
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof BatchCreateCustomersResponseCustomer
     */
    accountId: string;
    /**
     * 
     * @type {string}
     * @memberof BatchCreateCustomersResponseCustomer
     */
    environmentId: string;
    /**
     * 
     * @type {string}
     * @memberof BatchCreateCustomersResponseCustomer
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BatchCreateCustomersResponseCustomer
     */
    faceCollectionId: string;
    /**
     * 
     * @type {number}
     * @memberof BatchCreateCustomersResponseCustomer
     */
    size: number;
    /**
     * 
     * @type {Date}
     * @memberof BatchCreateCustomersResponseCustomer
     */
    lastActive?: Date | null;
}

/**
 * Check if a given object implements the BatchCreateCustomersResponseCustomer interface.
 */
export function instanceOfBatchCreateCustomersResponseCustomer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "accountId" in value;
    isInstance = isInstance && "environmentId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "faceCollectionId" in value;
    isInstance = isInstance && "size" in value;

    return isInstance;
}

export function BatchCreateCustomersResponseCustomerFromJSON(json: any): BatchCreateCustomersResponseCustomer {
    return BatchCreateCustomersResponseCustomerFromJSONTyped(json, false);
}

export function BatchCreateCustomersResponseCustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchCreateCustomersResponseCustomer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'accountId': json['accountId'],
        'environmentId': json['environmentId'],
        'name': json['name'],
        'faceCollectionId': json['faceCollectionId'],
        'size': json['size'],
        'lastActive': !exists(json, 'lastActive') ? undefined : (json['lastActive'] === null ? null : new Date(json['lastActive'])),
    };
}

export function BatchCreateCustomersResponseCustomerToJSON(value?: BatchCreateCustomersResponseCustomer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'accountId': value.accountId,
        'environmentId': value.environmentId,
        'name': value.name,
        'faceCollectionId': value.faceCollectionId,
        'size': value.size,
        'lastActive': value.lastActive === undefined ? undefined : (value.lastActive === null ? null : value.lastActive.toISOString()),
    };
}

