/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { message, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { type GetUserByIdRequest } from '../../../../tools/openapi';
import UserFaceRegisteration from './UserFaceRegistration/UserFaceRegistration';
import '../../UserList/Users.scss';
import UserQrCodeGeneration from './UserQRCode/UserQrCodeSection';
import UserInformationTitle from './UserInformationHeader/UserInformationTitle';
import UserDescription from './UserDescription/UserDescription';
import UserDangerZone from './UserDangerZone/UserDangerZone';
import UserDropdownButton from './UserInformationHeader/UserInformationDropdownButton.tsx/UserInformationDropdownButton';
import { type Params, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../store/hooks';
import {
	resetCurrentUserDetail,
	setCurrentUserDetail,
} from '../../../../store/userDetailReducer';
import useMgmtApiWrapper from '../../../../hooks/openapi/useMgmtApiWrapper';

interface RouteParams extends Params {
	id: string;
}

const UserInformationTab: React.FC = () => {
	const mgmtApiWrapper = useMgmtApiWrapper();
	const dispatch = useAppDispatch();
	const { id } = useParams<RouteParams>();

	const [userDetailOnLoading, setUserDetailOnLoading] = useState(true);
	const [userDetailNotFound, setUserDetailNotFound] = useState(false);

	const fetchUser = (): void => {
		dispatch(resetCurrentUserDetail());
		if (id != null) {
			setUserDetailOnLoading(true);
			const requestBody: GetUserByIdRequest = {
				user: id,
			};
			void mgmtApiWrapper
				.getUserById(requestBody)
				.then((r) => {
					if (r !== undefined) {
						dispatch(setCurrentUserDetail(r));
					}
					setUserDetailOnLoading(false);
				})
				.catch(async (error) => {
					setUserDetailNotFound(true);
					if (error.response !== null && error.response !== undefined) {
						const errorData = await error.response.json();
						void message.error(
							`${errorData.message as string} (Support Code: ${
								errorData.logref as string
							})`
						);
					} else {
						console.log('Error:', error);
					}
				});
		} else {
			setUserDetailNotFound(true);
		}
	};

	useEffect(() => {
		fetchUser();
	}, []);

	return (
		<>
			{userDetailOnLoading ? (
				<Spin className="loading flex-all-center" size="large" />
			) : userDetailNotFound ? (
				<span>User Not Found</span>
			) : (
				<Space
					className="custom-detail-space"
					size="large"
					direction="vertical"
				>
					<div className="user-information-header">
						<UserInformationTitle />
						<UserDropdownButton fetchUser={fetchUser} />
					</div>
					<UserDescription />
					<UserFaceRegisteration />
					<UserQrCodeGeneration />
					<UserDangerZone fetchUser={fetchUser} />
				</Space>
			)}
		</>
	);
};
export default React.memo(UserInformationTab);
