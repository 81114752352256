import React, { type ReactElement, useEffect, useState } from 'react';
import { Modal, Form, Spin, Button } from 'antd';
import { type CreateAccountMemberRequest } from '../../../tools/openapi/models/CreateAccountMemberRequest';
import '../AccountMembers.scss';
import AccountMemberCreateModalForm from './AccountMemberCreateModalForm';
import AccountMemberCreateModalSuccess from './AccountMemberCreateModalSuccess';
import useMgmtApiWrapper from '../../../hooks/openapi/useMgmtApiWrapper';

interface Props {
	modalOpen: boolean;
	setModalOpen: (e: boolean) => void;
	fetchData: () => void;
}

const AccountMemberCreateModal: React.FC<Props> = (props: Props) => {
	const [modalContent, setModalContent] = useState<ReactElement>();
	const [modalFooter, setModalFooter] = useState<ReactElement>();
	const mgmtApiWrapper = useMgmtApiWrapper();

	const [form] = Form.useForm();

	const clearModal = (): void => {
		props.setModalOpen(false);
		form.resetFields();
		setModalContent(<AccountMemberCreateModalForm form={form} />);
		setModalFooter(creatingFooter);
	};

	const creatingFooter = (
		<>
			<Button key="cancel" onClick={clearModal}>
				Cancel
			</Button>
			,
			<Button
				key="submit"
				type="primary"
				onClick={() => {
					form
						.validateFields()
						.then((values) => {
							form.resetFields();
							handleInvite(values);
						})
						.catch((info) => {
							console.log('Validate Failed:', info);
						});
				}}
			>
				Send Invitation
			</Button>
		</>
	);

	const createdFooter = (
		<Button key="dismiss" onClick={clearModal}>
			Dismiss
		</Button>
	);

	const handleInvite = (e: CreateAccountMemberRequest): void => {
		setModalContent(<Spin className="flex-all-center"></Spin>);
		setModalFooter(<></>);
		void mgmtApiWrapper
			.createAccountMember({
				createAccountMemberRequest: { email: e.email, name: e.name },
			})
			.then((r) => {
				if (r !== undefined) {
					setModalContent(
						<AccountMemberCreateModalSuccess accountMember={r} />
					);
					setModalFooter(createdFooter);
					props.fetchData();
				}
			})
	};

	useEffect(() => {
		setModalContent(<AccountMemberCreateModalForm form={form} />);
		setModalFooter(creatingFooter);
	}, []);

	return (
		<>
			<Modal
				centered
				className="create-modal"
				title="Invite Member"
				open={props.modalOpen}
				onCancel={clearModal}
				onOk={() => {
					form
						.validateFields()
						.then((values) => {
							form.resetFields();
							handleInvite(values);
						})
						.catch((info) => {
							console.log('Validate Failed:', info);
						});
				}}
				footer={modalFooter}
			>
				{modalContent}
			</Modal>
		</>
	);
};

export default React.memo(AccountMemberCreateModal);
