/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BatchCreateUsersResponseUser
 */
export interface BatchCreateUsersResponseUser {
    /**
     * 
     * @type {string}
     * @memberof BatchCreateUsersResponseUser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BatchCreateUsersResponseUser
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BatchCreateUsersResponseUser
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BatchCreateUsersResponseUser
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BatchCreateUsersResponseUser
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BatchCreateUsersResponseUser
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BatchCreateUsersResponseUser
     */
    avatarUrl?: string | null;
}

/**
 * Check if a given object implements the BatchCreateUsersResponseUser interface.
 */
export function instanceOfBatchCreateUsersResponseUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function BatchCreateUsersResponseUserFromJSON(json: any): BatchCreateUsersResponseUser {
    return BatchCreateUsersResponseUserFromJSONTyped(json, false);
}

export function BatchCreateUsersResponseUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchCreateUsersResponseUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'avatarUrl': !exists(json, 'avatarUrl') ? undefined : json['avatarUrl'],
    };
}

export function BatchCreateUsersResponseUserToJSON(value?: BatchCreateUsersResponseUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'name': value.name,
        'email': value.email,
        'phone': value.phone,
        'avatarUrl': value.avatarUrl,
    };
}

