import produce from 'immer';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

const initialState = {
	modalVisible: false,
	modalOnCreate: true,
	curToDeleteId: '',
};

const accessKeySlice = createSlice({
	name: 'accessKey',
	initialState,
	reducers: {
		setModalVisible: (state, action: PayloadAction<boolean>) => {
			return produce(state, (draftState) => {
				draftState.modalVisible = action.payload;
			});
		},
		setModalOnCreate: (state, action: PayloadAction<boolean>) => {
			return produce(state, (draftState) => {
				draftState.modalOnCreate = action.payload;
			});
		},
		setCurToDelete: (state, action: PayloadAction<string>) => {
			return produce(state, (draftState) => {
				draftState.curToDeleteId = action.payload;
			});
		},
	},
});

export const { setModalVisible, setModalOnCreate, setCurToDelete } =
	accessKeySlice.actions;
export default accessKeySlice.reducer;
