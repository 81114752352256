import { Button, Dropdown, type MenuProps } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

import React, { useState, type MouseEventHandler } from 'react';

interface DropdownButtonProps {
	menu: MenuProps['items'];
}
const handleBubbling: MouseEventHandler<HTMLAnchorElement> &
	MouseEventHandler<HTMLButtonElement> = (event) => {
	event.stopPropagation();
};
const DropdownButton: React.FC<DropdownButtonProps> = (
	props: DropdownButtonProps
) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const handleMenuClick: MenuProps['onClick'] = (menuInfo) => {
		setDropdownOpen(false);
		menuInfo.domEvent.stopPropagation();
	};

	return (
		<Dropdown
			menu={{ items: props.menu, onClick: handleMenuClick }}
			trigger={['click']}
			open={dropdownOpen}
			onOpenChange={(open) => {
				setDropdownOpen(open);
			}}
		>
			<Button onClick={handleBubbling}>
				<EllipsisOutlined />
			</Button>
		</Dropdown>
	);
};

export default React.memo(DropdownButton);
