import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import CustomerSettingSection from './CustomerSettingsSection/CustomerSettingSection';
import {
	setAllowManualEntry,
	setAllowFaceRegistrationOnClient,
	setPinCodeDigit,
	setPinAttemptLimit,
	setRequireApprovalOnFaceRegistration,
	setAutoTakingPhotoOnIdentification,
} from '../../../../store/customerDetailReducer';
import { useDispatch } from 'react-redux';
import { type Params, useParams } from 'react-router-dom';
import useMgmtApiWrapper from '../../../../hooks/openapi/useMgmtApiWrapper';

interface RouteParams extends Params {
	id: string;
}

const CustomerSettings: React.FC = () => {
	const dispatch = useDispatch();
	const mgmtApiWrapper = useMgmtApiWrapper();
	const { id } = useParams<RouteParams>();

	const [customerSettingsOnLoading, setCustomerSettingsOnLoading] =
		useState(true);

	const fetchSettings = (): void => {
		setCustomerSettingsOnLoading(true);
		void mgmtApiWrapper
			.getCustomerSettings({ customerId: id as string })
			.then((r) => {
				if (r !== undefined) {
					dispatch(
						setAllowManualEntry(
							r.allowManualEntry != null ? r.allowManualEntry : false
						)
					);
					dispatch(
						setAutoTakingPhotoOnIdentification(
							r.autoTakingPhotoOnIdentification != null
								? r.autoTakingPhotoOnIdentification
								: true
						)
					);
					dispatch(
						setAllowFaceRegistrationOnClient(
							r.allowFaceRegistrationOnClient != null
								? r.allowFaceRegistrationOnClient
								: false
						)
					);
					dispatch(
						setPinCodeDigit(
							r.pinCodeDigit != null ? r.pinCodeDigit : 'SIX_DIGIT'
						)
					);
					dispatch(
						setPinAttemptLimit(
							r.pinAttemptLimit != null ? r.pinAttemptLimit : 3
						)
					);
					dispatch(
						setRequireApprovalOnFaceRegistration(
							r.requireApprovalOnFaceRegistration != null
								? r.requireApprovalOnFaceRegistration
								: false
						)
					);
					setCustomerSettingsOnLoading(false);
				}
			})
	};

	useEffect(() => {
		fetchSettings();
	}, []);

	if (customerSettingsOnLoading) {
		return <Spin className="loading flex-all-center" size="large" />;
	} else if (id !== undefined) {
		return <CustomerSettingSection fetchSettings={fetchSettings} id={id} />;
	} else {
		return <span>Customer Setting Not Found</span>;
	}
};

export default CustomerSettings;
