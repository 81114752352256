import { Button, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { type Params, useParams } from 'react-router-dom';
import useMgmtApiWrapper from '../hooks/openapi/useMgmtApiWrapper';
import {
	type ConfirmFaceAccessRequest,
	type PinAccessResponse,
} from '../tools/openapi';
import PinInput from 'react-pin-input';
import Title from 'antd/es/typography/Title';

interface RouteParams extends Params {
	customerId: string;
	environmentId: string;
}

interface Props {
	setCurrentStep: (step: string) => void;
}

const PinAccess: React.FC<Props> = (props: Props) => {
	const mgmtApi = useMgmtApiWrapper();
	const { customerId, environmentId } = useParams<RouteParams>();

	const [identified, setIdentified] = useState<boolean>(false);
	const [matchFound, setMatchFound] = useState<boolean>(false);
	const [pinAccessResponse, setPinAccessResponse] =
		useState<PinAccessResponse>();
	const [identifying, setIdentifying] = useState<boolean>(false);
	const [sessionId, setSessionId] = useState<string>();

	const pinAccess = async (value: string): Promise<void> => {
		setIdentifying(true);

		if (environmentId != null && customerId != null) {
			void mgmtApi
				.pinAccess({
					pinAccessRequest: {
						customerId,
						environmentId,
						pin: value,
						...(sessionId !== null ? { sessionId } : {}),
					},
				})
				.then((r) => {
					if (r !== undefined) {
						setIdentifying(false);
						setPinAccessResponse(r);
						if (r.sessionId !== sessionId) {
							setSessionId(r.sessionId);
						}
						if (r.identified != null) {
							setIdentified(true);
							setMatchFound(true);
						} else if (r.noMatch != null) {
							setIdentified(true);
							setMatchFound(false);
						}
					}
				});
		}
	};

	const confirmAccess = (): void => {
		if (pinAccessResponse !== undefined && environmentId != null) {
			const requestBody: ConfirmFaceAccessRequest = {
				environmentId,
				stepId: pinAccessResponse.stepId,
			};
			void mgmtApi
				.confirmPinAccess({ confirmPinAccessRequest: requestBody })
				.then((r) => {
					console.log(r);
				});
		}
	};

	const restartStep = (): void => {
		setIdentified(false);
		setIdentifying(false);
		setPinAccessResponse(undefined);
		setMatchFound(false);
	};

	useEffect(() => {
		if (
			pinAccessResponse?.noMatch != null &&
			pinAccessResponse.noMatch.instruction.attemptsLeft === 0
		) {
			props.setCurrentStep('Manual');
		}
	}, [pinAccessResponse]);

	return (
		<div>
			{identifying ? (
				<Spin className="loading flex-all-center" size="large" />
			) : identified ? (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<Title level={3} style={{ marginRight: '15px' }}>
						{matchFound ? pinAccessResponse?.identified?.name : 'No Match'}
					</Title>
					{matchFound ? (
						<Button onClick={confirmAccess}>Confirm</Button>
					) : (
						<>
							<Button onClick={restartStep}>Try Again</Button>
						</>
					)}
				</div>
			) : (
				<>
					<Title level={5}>
						Attempts Left:{' '}
						{pinAccessResponse?.noMatch !== null
							? pinAccessResponse?.noMatch?.instruction.attemptsLeft
							: 3}
					</Title>
					<PinInput
						length={6}
						initialValue=""
						secret
						secretDelay={100}
						type="numeric"
						inputMode="number"
						style={{ padding: '10px' }}
						inputStyle={{ borderColor: 'red' }}
						inputFocusStyle={{ borderColor: 'blue' }}
						onComplete={(value) => {
							void pinAccess(value);
						}}
						autoSelect={true}
						regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
					/>
				</>
			)}
		</div>
	);
};

export default React.memo(PinAccess);
