import { Button, Space, Typography } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
	setPreviousPinCodeDigit,
	setPinCodeDigit,
	setIsPinModalOpen,
} from '../../../../../store/customerDetailReducer';
import { useAppSelector } from '../../../../../store/hooks';
import { type RootState } from '../../../../../store/store';
import {
	type UpdateCustomerSettingsRequest,
	type PinConfig,
} from '../../../../../tools/openapi';
import CustomerApprovalOnFace from './CustomSettingsSectionRow/CustomerApprovalOnFace';
import CustomerFaceRigisOnClient from './CustomSettingsSectionRow/CustomerFaceRigisOnClient';
import CustomerManualEntry from './CustomSettingsSectionRow/CustomerManualEntry';
import CustomerPinLimit from './CustomSettingsSectionRow/CustomerPinLimit';
import CustomerPinDigitRow from './CustomSettingsSectionRow/CustomerPinDigit/CustomerPinDigitRow';
import CustomerAutoTakingPhoto from './CustomSettingsSectionRow/CustomerAutoTakingPhoto';
import useMgmtApiWrapper from '../../../../../hooks/openapi/useMgmtApiWrapper';

interface Props {
	fetchSettings: () => void;
	id: string;
}

const { Title } = Typography;

const CustomerSettingsSection: React.FC<Props> = (props) => {
	const mgmtApiWrapper = useMgmtApiWrapper();
	const dispatch = useDispatch();
	const pinCodeDigit = useAppSelector(
		(state: RootState) => state.customerDetail.pinCodeDigit
	);

	const environment = useAppSelector(
		(state: RootState) => state.environment.environment
	);

	const openWebSDK = (): void => {
		window.open(`/websdk/${props.id}/${environment.id}`, '_blank');
	};

	const updateSettings = <T extends keyof UpdateCustomerSettingsRequest>(
		target: T,
		value: UpdateCustomerSettingsRequest[T]
	): void => {
		if (target === 'pinCodeDigit') {
			dispatch(setPreviousPinCodeDigit(pinCodeDigit));
			dispatch(setPinCodeDigit(value as PinConfig));
			dispatch(setIsPinModalOpen(true));
		} else {
			updateOperation(target, value);
		}
	};

	const updateOperation = <T extends keyof UpdateCustomerSettingsRequest>(
		target: T,
		value: UpdateCustomerSettingsRequest[T]
	): void => {
		const updateRequest: UpdateCustomerSettingsRequest = {
			[target]: value,
		};

		void mgmtApiWrapper
			.updateCustomerSettings({
				customerId: props.id,
				updateCustomerSettingsRequest: updateRequest,
			})
			.then(() => {
				props.fetchSettings();
			});
	};

	return (
		<Space direction="vertical" size="large">
			<section className="section customer-setting-section">
				<Space direction="vertical" size="large">
					<Title className="customer-setting-title" level={4}>
						General
					</Title>
					<CustomerManualEntry updateSettings={updateSettings} />
					<CustomerAutoTakingPhoto updateSettings={updateOperation} />
				</Space>
			</section>
			<section className="section customer-setting-section">
				<Space direction="vertical" size="large">
					<Title className="customer-setting-title" level={4}>
						Pin
					</Title>
					<CustomerPinDigitRow
						updateSettings={updateSettings}
						updateOperation={updateOperation}
					/>
					<CustomerPinLimit updateSettings={updateSettings} />
				</Space>
			</section>
			<section className="section customer-setting-section">
				<Space direction="vertical" size="large">
					<Title className="customer-setting-title" level={4}>
						Face
					</Title>
					<CustomerFaceRigisOnClient updateSettings={updateSettings} />
					<CustomerApprovalOnFace updateSettings={updateSettings} />
				</Space>
			</section>
			<section className="section customer-setting-section">
				<div>
					<Title className="section-header" level={5}>
						Web Experience
					</Title>
					<Button
						type="primary"
						style={{ marginLeft: '28em' }}
						onClick={openWebSDK}
					>
						Launch Web Experience
					</Button>
				</div>
			</section>
		</Space>
	);
};

export default React.memo(CustomerSettingsSection);
