/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomerResponseCustomer } from './CustomerResponseCustomer';
import {
    CustomerResponseCustomerFromJSON,
    CustomerResponseCustomerFromJSONTyped,
    CustomerResponseCustomerToJSON,
} from './CustomerResponseCustomer';

/**
 * 
 * @export
 * @interface CustomerResponse
 */
export interface CustomerResponse {
    /**
     * 
     * @type {CustomerResponseCustomer}
     * @memberof CustomerResponse
     */
    customer: CustomerResponseCustomer;
}

/**
 * Check if a given object implements the CustomerResponse interface.
 */
export function instanceOfCustomerResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "customer" in value;

    return isInstance;
}

export function CustomerResponseFromJSON(json: any): CustomerResponse {
    return CustomerResponseFromJSONTyped(json, false);
}

export function CustomerResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customer': CustomerResponseCustomerFromJSON(json['customer']),
    };
}

export function CustomerResponseToJSON(value?: CustomerResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customer': CustomerResponseCustomerToJSON(value.customer),
    };
}

