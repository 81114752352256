/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SessionDetailsWithStepsResponseSession } from './SessionDetailsWithStepsResponseSession';
import {
    SessionDetailsWithStepsResponseSessionFromJSON,
    SessionDetailsWithStepsResponseSessionFromJSONTyped,
    SessionDetailsWithStepsResponseSessionToJSON,
} from './SessionDetailsWithStepsResponseSession';
import type { SessionDetailsWithStepsResponseStep } from './SessionDetailsWithStepsResponseStep';
import {
    SessionDetailsWithStepsResponseStepFromJSON,
    SessionDetailsWithStepsResponseStepFromJSONTyped,
    SessionDetailsWithStepsResponseStepToJSON,
} from './SessionDetailsWithStepsResponseStep';

/**
 * 
 * @export
 * @interface SessionDetailsWithStepsResponse
 */
export interface SessionDetailsWithStepsResponse {
    /**
     * 
     * @type {SessionDetailsWithStepsResponseSession}
     * @memberof SessionDetailsWithStepsResponse
     */
    session: SessionDetailsWithStepsResponseSession;
    /**
     * 
     * @type {Array<SessionDetailsWithStepsResponseStep>}
     * @memberof SessionDetailsWithStepsResponse
     */
    steps: Array<SessionDetailsWithStepsResponseStep>;
}

/**
 * Check if a given object implements the SessionDetailsWithStepsResponse interface.
 */
export function instanceOfSessionDetailsWithStepsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "session" in value;
    isInstance = isInstance && "steps" in value;

    return isInstance;
}

export function SessionDetailsWithStepsResponseFromJSON(json: any): SessionDetailsWithStepsResponse {
    return SessionDetailsWithStepsResponseFromJSONTyped(json, false);
}

export function SessionDetailsWithStepsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionDetailsWithStepsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'session': SessionDetailsWithStepsResponseSessionFromJSON(json['session']),
        'steps': ((json['steps'] as Array<any>).map(SessionDetailsWithStepsResponseStepFromJSON)),
    };
}

export function SessionDetailsWithStepsResponseToJSON(value?: SessionDetailsWithStepsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'session': SessionDetailsWithStepsResponseSessionToJSON(value.session),
        'steps': ((value.steps as Array<any>).map(SessionDetailsWithStepsResponseStepToJSON)),
    };
}

