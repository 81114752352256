import React, { useEffect, useState } from 'react';
import DangerZone from '../../../../../shared/DangerZone';
import DeleteConfirmation from '../../../../../shared/DeleteConfirmation';
import { type CustomerResponseCustomer } from '../../../../../tools/openapi';
import { useAppSelector } from '../../../../../store/hooks';
import { type RootState } from '../../../../../store/store';
import useMgmtApiWrapper from '../../../../../hooks/openapi/useMgmtApiWrapper';

const EnvironmentDeletion: React.FC = () => {
	const mgmtApiWrapper = useMgmtApiWrapper();
	const customerDetail = useAppSelector(
		(state: RootState) =>
			state.customerDetail.customer as CustomerResponseCustomer
	);

	const [inputValue, setInputValue] = useState('');
	const [isModalVisible, setIsModalVisible] = useState(false);

	const handleDelete = (): void => {
		if (customerDetail != null) {
			void mgmtApiWrapper
				.deleteEnvironment({ environmentId: customerDetail.id })
				.then(() => {
					setIsModalVisible(false);
					window.location.reload();
				})
		}
	};

	useEffect(() => {
		if (isModalVisible) {
			setInputValue('');
		}
	}, [isModalVisible]);

	return (
		<>
			<DangerZone
				title="Delete Environment"
				description="This environment will be removed and it will no longer have the application acess."
				onConfirm={() => {
					setIsModalVisible(true);
				}}
			/>
			<DeleteConfirmation
				title="Are you sure? "
				message={`This action will permanently delete the environment. It cannot be undone. `}
				messageAdd={`Please enter the environment name "${
					customerDetail != null ? customerDetail?.name : ''
				}" to confirm.`}
				placeholder={'Type the environment name to confirm.'}
				visible={isModalVisible}
				deleDisabled={inputValue !== customerDetail?.name}
				centered={true}
				inputDisplay={'inline'}
				onCancel={() => {
					setIsModalVisible(false);
				}}
				onConfirm={handleDelete}
				onInputChange={(value) => {
					setInputValue(value);
				}}
			/>
		</>
	);
};

export default React.memo(EnvironmentDeletion);
