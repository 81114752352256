import { Select } from 'antd';
import React from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { type RootState } from '../../../../store/store';
import { type StartResponse } from '../../../../tools/openapi';

type SearchType = 'customer' | 'user' | 'customerId' | 'userId';

interface Props {
	searchType: SearchType;
	setSearchType: (e: SearchType) => void;
}

const AccessSessionsSelectSearchType: React.FC<Props> = (props: Props) => {
	const startState: StartResponse = useAppSelector(
		(state: RootState) => state.startState.startState
	);

	if (startState.account?.account.singleCustomer === true) {
		return (
			<Select
				value={props.searchType}
				onChange={(value) => {
					props.setSearchType(value);
				}}
				style={{ width: '40%' }}
			>
				<Select.Option value="user">User</Select.Option>
				<Select.Option value="userId">User ID</Select.Option>
			</Select>
		);
	}

	return (
		<Select
			value={props.searchType}
			onChange={(value) => {
				props.setSearchType(value);
			}}
			style={{ width: '40%' }}
		>
			<Select.Option value="customer">Customer</Select.Option>
			<Select.Option value="user">User</Select.Option>
			<Select.Option value="customerId">Customer ID</Select.Option>
			<Select.Option value="userId">User ID</Select.Option>
		</Select>
	);
};

export default React.memo(AccessSessionsSelectSearchType);
