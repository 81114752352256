/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FaceAccessResponseIdentifiedInstruction } from './FaceAccessResponseIdentifiedInstruction';
import {
    FaceAccessResponseIdentifiedInstructionFromJSON,
    FaceAccessResponseIdentifiedInstructionFromJSONTyped,
    FaceAccessResponseIdentifiedInstructionToJSON,
} from './FaceAccessResponseIdentifiedInstruction';

/**
 * 
 * @export
 * @interface FaceAccessResponseIdentified
 */
export interface FaceAccessResponseIdentified {
    /**
     * 
     * @type {string}
     * @memberof FaceAccessResponseIdentified
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof FaceAccessResponseIdentified
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FaceAccessResponseIdentified
     */
    avatar?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FaceAccessResponseIdentified
     */
    stepId: string;
    /**
     * 
     * @type {FaceAccessResponseIdentifiedInstruction}
     * @memberof FaceAccessResponseIdentified
     */
    instruction: FaceAccessResponseIdentifiedInstruction;
}

/**
 * Check if a given object implements the FaceAccessResponseIdentified interface.
 */
export function instanceOfFaceAccessResponseIdentified(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "stepId" in value;
    isInstance = isInstance && "instruction" in value;

    return isInstance;
}

export function FaceAccessResponseIdentifiedFromJSON(json: any): FaceAccessResponseIdentified {
    return FaceAccessResponseIdentifiedFromJSONTyped(json, false);
}

export function FaceAccessResponseIdentifiedFromJSONTyped(json: any, ignoreDiscriminator: boolean): FaceAccessResponseIdentified {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'name': json['name'],
        'avatar': !exists(json, 'avatar') ? undefined : json['avatar'],
        'stepId': json['stepId'],
        'instruction': FaceAccessResponseIdentifiedInstructionFromJSON(json['instruction']),
    };
}

export function FaceAccessResponseIdentifiedToJSON(value?: FaceAccessResponseIdentified | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'name': value.name,
        'avatar': value.avatar,
        'stepId': value.stepId,
        'instruction': FaceAccessResponseIdentifiedInstructionToJSON(value.instruction),
    };
}

