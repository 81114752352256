/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
	AccessFactor,
	AccessSessionHistoriesResponse,
	AccessStepAttemptCountsResponse,
	AccessStepDetailResponse,
	AccessStepHistoryResponse,
	AccountMemberResponse,
	AccountMembersResponse,
	ActiveRegistrationResponse,
	ApiKeysResponse,
	BatchCreateCustomersRequest,
	BatchCreateCustomersResponse,
	BatchCreateUsersRequest,
	BatchCreateUsersResponse,
	ConfirmFaceAccessRequest,
	ConfirmPinAccessRequest,
	ConfirmedFaceAccessResponse,
	ConfirmedPinAccessResponse,
	CreateAccountMemberRequest,
	CreateAccountMemberResponse,
	CreateAccountRequest,
	CreateAccountResponse,
	CreateApiKeyResponse,
	CreateCustomerRequest,
	CreateCustomerResponse,
	CreateEnvironmentRequest,
	CreateEnvironmentResponse,
	CreateOnboardingAccountRequest,
	CreateUserRequest,
	CreateUserResponse,
	CustomerResponse,
	CustomerSettingsResponse,
	CustomersResponse,
	DeactivateUsersRequest,
	DeleteAccessStepsRequest,
	DeleteCustomersRequest,
	DeleteUsersRequest,
	EnvironmentCustomerCountResponse,
	EnvironmentResponse,
	EnvironmentUserCountResponse,
	EnvironmentWebhookResponse,
	EnvironmentsResponse,
	FaceAccessResponse,
	FaceRegistrationsCountResponse,
	ManualAccessRequest,
	ManualAccessResponse,
	NewFaceRegistrationRequest,
	NewFaceRegistrationResponse,
	PinAccessRequest,
	PinAccessResponse,
	QrCodeRequestBody,
	QrCodeResponseBody,
	SecurityScoreRating,
	SessionDetailsWithStepsResponse,
	SetAccessSessionTagsRequest,
	SetAccessStepTagsRequest,
	Sort,
	StartRequest,
	StartResponse,
	SubmittedRegistrationResponse,
	UpdateAccountMemberSettingsRequest,
	UpdateAccountSettingsRequest,
	UpdateCustomerRequest,
	UpdateCustomerSettingsRequest,
	UpdateEnvironmentRequest,
	UpdateEnvironmentSettingsRequest,
	UpdateUserRequest,
	UpdatedCustomDataRequest,
	UploadFaceRegistrationImageResponse,
	User,
	UserCountResponse,
	UserRegistrationResponse,
	UsersResponse,
} from '../models';
import {
	AccessFactorFromJSON,
	AccessFactorToJSON,
	AccessSessionHistoriesResponseFromJSON,
	AccessSessionHistoriesResponseToJSON,
	AccessStepAttemptCountsResponseFromJSON,
	AccessStepAttemptCountsResponseToJSON,
	AccessStepDetailResponseFromJSON,
	AccessStepDetailResponseToJSON,
	AccessStepHistoryResponseFromJSON,
	AccessStepHistoryResponseToJSON,
	AccountMemberResponseFromJSON,
	AccountMemberResponseToJSON,
	AccountMembersResponseFromJSON,
	AccountMembersResponseToJSON,
	ActiveRegistrationResponseFromJSON,
	ActiveRegistrationResponseToJSON,
	ApiKeysResponseFromJSON,
	ApiKeysResponseToJSON,
	BatchCreateCustomersRequestFromJSON,
	BatchCreateCustomersRequestToJSON,
	BatchCreateCustomersResponseFromJSON,
	BatchCreateCustomersResponseToJSON,
	BatchCreateUsersRequestFromJSON,
	BatchCreateUsersRequestToJSON,
	BatchCreateUsersResponseFromJSON,
	BatchCreateUsersResponseToJSON,
	ConfirmFaceAccessRequestFromJSON,
	ConfirmFaceAccessRequestToJSON,
	ConfirmPinAccessRequestFromJSON,
	ConfirmPinAccessRequestToJSON,
	ConfirmedFaceAccessResponseFromJSON,
	ConfirmedFaceAccessResponseToJSON,
	ConfirmedPinAccessResponseFromJSON,
	ConfirmedPinAccessResponseToJSON,
	CreateAccountMemberRequestFromJSON,
	CreateAccountMemberRequestToJSON,
	CreateAccountMemberResponseFromJSON,
	CreateAccountMemberResponseToJSON,
	CreateAccountRequestFromJSON,
	CreateAccountRequestToJSON,
	CreateAccountResponseFromJSON,
	CreateAccountResponseToJSON,
	CreateApiKeyResponseFromJSON,
	CreateApiKeyResponseToJSON,
	CreateCustomerRequestFromJSON,
	CreateCustomerRequestToJSON,
	CreateCustomerResponseFromJSON,
	CreateCustomerResponseToJSON,
	CreateEnvironmentRequestFromJSON,
	CreateEnvironmentRequestToJSON,
	CreateEnvironmentResponseFromJSON,
	CreateEnvironmentResponseToJSON,
	CreateOnboardingAccountRequestFromJSON,
	CreateOnboardingAccountRequestToJSON,
	CreateUserRequestFromJSON,
	CreateUserRequestToJSON,
	CreateUserResponseFromJSON,
	CreateUserResponseToJSON,
	CustomerResponseFromJSON,
	CustomerResponseToJSON,
	CustomerSettingsResponseFromJSON,
	CustomerSettingsResponseToJSON,
	CustomersResponseFromJSON,
	CustomersResponseToJSON,
	DeactivateUsersRequestFromJSON,
	DeactivateUsersRequestToJSON,
	DeleteAccessStepsRequestFromJSON,
	DeleteAccessStepsRequestToJSON,
	DeleteCustomersRequestFromJSON,
	DeleteCustomersRequestToJSON,
	DeleteUsersRequestFromJSON,
	DeleteUsersRequestToJSON,
	EnvironmentCustomerCountResponseFromJSON,
	EnvironmentCustomerCountResponseToJSON,
	EnvironmentResponseFromJSON,
	EnvironmentResponseToJSON,
	EnvironmentUserCountResponseFromJSON,
	EnvironmentUserCountResponseToJSON,
	EnvironmentWebhookResponseFromJSON,
	EnvironmentWebhookResponseToJSON,
	EnvironmentsResponseFromJSON,
	EnvironmentsResponseToJSON,
	FaceAccessResponseFromJSON,
	FaceAccessResponseToJSON,
	FaceRegistrationsCountResponseFromJSON,
	FaceRegistrationsCountResponseToJSON,
	ManualAccessRequestFromJSON,
	ManualAccessRequestToJSON,
	ManualAccessResponseFromJSON,
	ManualAccessResponseToJSON,
	NewFaceRegistrationRequestFromJSON,
	NewFaceRegistrationRequestToJSON,
	NewFaceRegistrationResponseFromJSON,
	NewFaceRegistrationResponseToJSON,
	PinAccessRequestFromJSON,
	PinAccessRequestToJSON,
	PinAccessResponseFromJSON,
	PinAccessResponseToJSON,
	QrCodeRequestBodyFromJSON,
	QrCodeRequestBodyToJSON,
	QrCodeResponseBodyFromJSON,
	QrCodeResponseBodyToJSON,
	SecurityScoreRatingFromJSON,
	SecurityScoreRatingToJSON,
	SessionDetailsWithStepsResponseFromJSON,
	SessionDetailsWithStepsResponseToJSON,
	SetAccessSessionTagsRequestFromJSON,
	SetAccessSessionTagsRequestToJSON,
	SetAccessStepTagsRequestFromJSON,
	SetAccessStepTagsRequestToJSON,
	SortFromJSON,
	SortToJSON,
	StartRequestFromJSON,
	StartRequestToJSON,
	StartResponseFromJSON,
	StartResponseToJSON,
	SubmittedRegistrationResponseFromJSON,
	SubmittedRegistrationResponseToJSON,
	UpdateAccountMemberSettingsRequestFromJSON,
	UpdateAccountMemberSettingsRequestToJSON,
	UpdateAccountSettingsRequestFromJSON,
	UpdateAccountSettingsRequestToJSON,
	UpdateCustomerRequestFromJSON,
	UpdateCustomerRequestToJSON,
	UpdateCustomerSettingsRequestFromJSON,
	UpdateCustomerSettingsRequestToJSON,
	UpdateEnvironmentRequestFromJSON,
	UpdateEnvironmentRequestToJSON,
	UpdateEnvironmentSettingsRequestFromJSON,
	UpdateEnvironmentSettingsRequestToJSON,
	UpdateUserRequestFromJSON,
	UpdateUserRequestToJSON,
	UpdatedCustomDataRequestFromJSON,
	UpdatedCustomDataRequestToJSON,
	UploadFaceRegistrationImageResponseFromJSON,
	UploadFaceRegistrationImageResponseToJSON,
	UserFromJSON,
	UserToJSON,
	UserCountResponseFromJSON,
	UserCountResponseToJSON,
	UserRegistrationResponseFromJSON,
	UserRegistrationResponseToJSON,
	UsersResponseFromJSON,
	UsersResponseToJSON,
} from '../models';

export interface ActivateUserRequest {
	userId: string;
}

export interface ApproveFaceRegistrationRequest {
	registrationId: string;
}

export interface BatchCreateCustomersOperationRequest {
	batchCreateCustomersRequest: BatchCreateCustomersRequest;
}

export interface BatchCreateUsersOperationRequest {
	batchCreateUsersRequest: BatchCreateUsersRequest;
}

export interface ConfirmFaceAccessOperationRequest {
	confirmFaceAccessRequest: ConfirmFaceAccessRequest;
}

export interface ConfirmPinAccessOperationRequest {
	confirmPinAccessRequest: ConfirmPinAccessRequest;
}

export interface CreateAccountOperationRequest {
	createAccountRequest: CreateAccountRequest;
}

export interface CreateAccountMemberOperationRequest {
	createAccountMemberRequest: CreateAccountMemberRequest;
}

export interface CreateApiKeyRequest {
	environmentId: string;
}

export interface CreateCustomerOperationRequest {
	createCustomerRequest: CreateCustomerRequest;
}

export interface CreateEnvironmentOperationRequest {
	createEnvironmentRequest: CreateEnvironmentRequest;
}

export interface CreateOnboardingAccountOperationRequest {
	createOnboardingAccountRequest: CreateOnboardingAccountRequest;
}

export interface CreateUserOperationRequest {
	createUserRequest: CreateUserRequest;
}

export interface DeactivateUserRequest {
	userId: string;
}

export interface DeactivateUsersOperationRequest {
	deactivateUsersRequest: DeactivateUsersRequest;
}

export interface DeleteAccessStepRequest {
	accessStepId: string;
}

export interface DeleteAccessStepsOperationRequest {
	deleteAccessStepsRequest: DeleteAccessStepsRequest;
}

export interface DeleteAccountMemberRequest {
	accountMemberId: string;
}

export interface DeleteApiKeyRequest {
	keyId: string;
}

export interface DeleteCustomerRequest {
	customerId: string;
}

export interface DeleteCustomersOperationRequest {
	deleteCustomersRequest: DeleteCustomersRequest;
}

export interface DeleteEnvironmentRequest {
	environmentId: string;
}

export interface DeleteFaceRegistrationRequest {
	faceRegistrationId: string;
}

export interface DeleteUserRequest {
	userId: string;
}

export interface DeleteUsersOperationRequest {
	deleteUsersRequest: DeleteUsersRequest;
}

export interface FaceAccessRequest {
	customerId: string;
	environmentId: string;
	file: Blob;
	sessionId?: string | null;
	customJsonData?: string | null;
}

export interface GetAccessSessionHistoriesRequest {
	environmentId?: string | null;
	customerId?: string | null;
	userId?: string | null;
	userName?: string | null;
	customerName?: string | null;
	accessFactor?: AccessFactor | null;
	tag?: string | null;
	includeUnSuccessful?: boolean | null;
	securityScoreRating?: SecurityScoreRating | null;
	pageNumber?: number | null;
	pageSize?: number | null;
	startDate?: Date | null;
	endDate?: Date | null;
}

export interface GetAccessStepAttemptCountRequest {
	environmentId?: string | null;
	customerId?: string | null;
	userId?: string | null;
	startDate?: Date | null;
	endDate?: Date | null;
	succeeded?: boolean | null;
}

export interface GetAccessStepDetailRequest {
	accessStepId: string;
}

export interface GetAccessStepHistoriesRequest {
	environmentId?: string | null;
	customerId?: string | null;
	mappedCustomerId?: string | null;
	userId?: string | null;
	mappedUserId?: string | null;
	sessionId?: string | null;
	accessFactor?: AccessFactor | null;
	tag?: string | null;
	includeUnSuccessful?: boolean | null;
	pageNumber?: number | null;
	pageSize?: number | null;
	startDate?: Date | null;
	endDate?: Date | null;
}

export interface GetActiveRegistrationRequest {
	userId: string;
}

export interface GetApiKeysRequest {
	environmentId: string;
}

export interface GetCustomerRequest {
	customerId: string;
}

export interface GetCustomerCountByEnvironmentIdRequest {
	environmentId: string;
}

export interface GetCustomerSettingsRequest {
	customerId: string;
}

export interface GetCustomersRequest {
	environmentId: string;
	query?: string | null;
	pageNumber?: number | null;
	pageSize?: number | null;
	sort?: Sort | null;
}

export interface GetEnvironmentRequest {
	environmentId: string;
}

export interface GetFaceRegistrationCountRequest {
	environmentIds?: Array<string> | null;
	customerIds?: Array<string> | null;
	userIds?: Array<string> | null;
}

export interface GetQrcodeRequest {
	qrCodeRequestBody: QrCodeRequestBody;
}

export interface GetRegistrationsRequest {
	userId: string;
}

export interface GetSessionDetailsWithStepsRequest {
	sessionId: string;
}

export interface GetSubmittedRegistrationRequest {
	userId: string;
}

export interface GetTopCustomerFaceRegistrationsRequest {
	environmentId: string;
	top: number;
}

export interface GetUserByIdRequest {
	user: string;
}

export interface GetUserCountByCustomerIdRequest {
	customerId: string;
}

export interface GetUserCountByEnvironmentIdRequest {
	environmentId: string;
}

export interface GetUsersRequest {
	pageNumber: number;
	pageSize: number;
	environmentId?: string | null;
	customerId?: string | null;
	query?: string | null;
	queryType?: string | null;
	active?: boolean | null;
	sort?: Sort | null;
}

export interface GetWebhookByEnvironmentIdRequest {
	environmentId: string;
}

export interface ManualAccessOperationRequest {
	manualAccessRequest: ManualAccessRequest;
}

export interface NewFaceRegistrationOperationRequest {
	newFaceRegistrationRequest: NewFaceRegistrationRequest;
}

export interface PinAccessOperationRequest {
	pinAccessRequest: PinAccessRequest;
}

export interface RejectFaceRegistrationRequest {
	registrationId: string;
}

export interface SetTagsRequest {
	sessionId: string;
	setAccessSessionTagsRequest: SetAccessSessionTagsRequest;
}

export interface SetTags1Request {
	accessStepId: string;
	setAccessStepTagsRequest: SetAccessStepTagsRequest;
}

export interface StartOperationRequest {
	startRequest: StartRequest;
}

export interface SubmitFaceRegistrationRequest {
	registrationId: string;
}

export interface SwitchEnvironmentRequest {
	environmentId: string;
}

export interface UpdateAccountMemberSettingsOperationRequest {
	userName: string;
	updateAccountMemberSettingsRequest: UpdateAccountMemberSettingsRequest;
}

export interface UpdateAccountSettingsOperationRequest {
	updateAccountSettingsRequest: UpdateAccountSettingsRequest;
}

export interface UpdateCustomDataByIdRequest {
	sessionId: string;
	updatedCustomDataRequest: UpdatedCustomDataRequest;
}

export interface UpdateCustomerByIdRequest {
	customerId: string;
	updateCustomerRequest: UpdateCustomerRequest;
}

export interface UpdateCustomerSettingsOperationRequest {
	customerId: string;
	updateCustomerSettingsRequest: UpdateCustomerSettingsRequest;
}

export interface UpdateEnvironmentByIdRequest {
	environmentId: string;
	updateEnvironmentRequest: UpdateEnvironmentRequest;
}

export interface UpdateEnvironmentSettingsOperationRequest {
	environmentId: string;
	updateEnvironmentSettingsRequest: UpdateEnvironmentSettingsRequest;
}

export interface UpdateUserByIdRequest {
	userId: string;
	updateUserRequest: UpdateUserRequest;
}

export interface UploadFaceRegistrationImageRequest {
	registrationId: string;
	file: Blob;
}

/**
 *
 */
export class MgmtApi extends runtime.BaseAPI {
	/**
	 */
	async activateUserRaw(
		requestParameters: ActivateUserRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.userId === null ||
			requestParameters.userId === undefined
		) {
			throw new runtime.RequiredError(
				'userId',
				'Required parameter requestParameters.userId was null or undefined when calling activateUser.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/user/{userId}/activate`.replace(
					`{${'userId'}}`,
					encodeURIComponent(String(requestParameters.userId))
				),
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async activateUser(
		requestParameters: ActivateUserRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.activateUserRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async approveFaceRegistrationRaw(
		requestParameters: ApproveFaceRegistrationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.registrationId === null ||
			requestParameters.registrationId === undefined
		) {
			throw new runtime.RequiredError(
				'registrationId',
				'Required parameter requestParameters.registrationId was null or undefined when calling approveFaceRegistration.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/face/registration/{registrationId}/approve`.replace(
					`{${'registrationId'}}`,
					encodeURIComponent(String(requestParameters.registrationId))
				),
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async approveFaceRegistration(
		requestParameters: ApproveFaceRegistrationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.approveFaceRegistrationRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async batchCreateCustomersRaw(
		requestParameters: BatchCreateCustomersOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<BatchCreateCustomersResponse>> {
		if (
			requestParameters.batchCreateCustomersRequest === null ||
			requestParameters.batchCreateCustomersRequest === undefined
		) {
			throw new runtime.RequiredError(
				'batchCreateCustomersRequest',
				'Required parameter requestParameters.batchCreateCustomersRequest was null or undefined when calling batchCreateCustomers.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/customer/batch`,
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
				body: BatchCreateCustomersRequestToJSON(
					requestParameters.batchCreateCustomersRequest
				),
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			BatchCreateCustomersResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async batchCreateCustomers(
		requestParameters: BatchCreateCustomersOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<BatchCreateCustomersResponse> {
		const response = await this.batchCreateCustomersRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async batchCreateUsersRaw(
		requestParameters: BatchCreateUsersOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<BatchCreateUsersResponse>> {
		if (
			requestParameters.batchCreateUsersRequest === null ||
			requestParameters.batchCreateUsersRequest === undefined
		) {
			throw new runtime.RequiredError(
				'batchCreateUsersRequest',
				'Required parameter requestParameters.batchCreateUsersRequest was null or undefined when calling batchCreateUsers.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/user/batch`,
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
				body: BatchCreateUsersRequestToJSON(
					requestParameters.batchCreateUsersRequest
				),
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			BatchCreateUsersResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async batchCreateUsers(
		requestParameters: BatchCreateUsersOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<BatchCreateUsersResponse> {
		const response = await this.batchCreateUsersRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async confirmFaceAccessRaw(
		requestParameters: ConfirmFaceAccessOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<ConfirmedFaceAccessResponse>> {
		if (
			requestParameters.confirmFaceAccessRequest === null ||
			requestParameters.confirmFaceAccessRequest === undefined
		) {
			throw new runtime.RequiredError(
				'confirmFaceAccessRequest',
				'Required parameter requestParameters.confirmFaceAccessRequest was null or undefined when calling confirmFaceAccess.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/access/face/confirm`,
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
				body: ConfirmFaceAccessRequestToJSON(
					requestParameters.confirmFaceAccessRequest
				),
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			ConfirmedFaceAccessResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async confirmFaceAccess(
		requestParameters: ConfirmFaceAccessOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<ConfirmedFaceAccessResponse> {
		const response = await this.confirmFaceAccessRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async confirmPinAccessRaw(
		requestParameters: ConfirmPinAccessOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<ConfirmedPinAccessResponse>> {
		if (
			requestParameters.confirmPinAccessRequest === null ||
			requestParameters.confirmPinAccessRequest === undefined
		) {
			throw new runtime.RequiredError(
				'confirmPinAccessRequest',
				'Required parameter requestParameters.confirmPinAccessRequest was null or undefined when calling confirmPinAccess.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/access/pin/confirm`,
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
				body: ConfirmPinAccessRequestToJSON(
					requestParameters.confirmPinAccessRequest
				),
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			ConfirmedPinAccessResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async confirmPinAccess(
		requestParameters: ConfirmPinAccessOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<ConfirmedPinAccessResponse> {
		const response = await this.confirmPinAccessRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async createAccountRaw(
		requestParameters: CreateAccountOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<CreateAccountResponse>> {
		if (
			requestParameters.createAccountRequest === null ||
			requestParameters.createAccountRequest === undefined
		) {
			throw new runtime.RequiredError(
				'createAccountRequest',
				'Required parameter requestParameters.createAccountRequest was null or undefined when calling createAccount.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/account`,
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
				body: CreateAccountRequestToJSON(
					requestParameters.createAccountRequest
				),
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			CreateAccountResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async createAccount(
		requestParameters: CreateAccountOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<CreateAccountResponse> {
		const response = await this.createAccountRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async createAccountMemberRaw(
		requestParameters: CreateAccountMemberOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<CreateAccountMemberResponse>> {
		if (
			requestParameters.createAccountMemberRequest === null ||
			requestParameters.createAccountMemberRequest === undefined
		) {
			throw new runtime.RequiredError(
				'createAccountMemberRequest',
				'Required parameter requestParameters.createAccountMemberRequest was null or undefined when calling createAccountMember.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/account_member`,
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
				body: CreateAccountMemberRequestToJSON(
					requestParameters.createAccountMemberRequest
				),
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			CreateAccountMemberResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async createAccountMember(
		requestParameters: CreateAccountMemberOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<CreateAccountMemberResponse> {
		const response = await this.createAccountMemberRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async createApiKeyRaw(
		requestParameters: CreateApiKeyRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<CreateApiKeyResponse>> {
		if (
			requestParameters.environmentId === null ||
			requestParameters.environmentId === undefined
		) {
			throw new runtime.RequiredError(
				'environmentId',
				'Required parameter requestParameters.environmentId was null or undefined when calling createApiKey.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/api_key/{environmentId}`.replace(
					`{${'environmentId'}}`,
					encodeURIComponent(String(requestParameters.environmentId))
				),
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			CreateApiKeyResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async createApiKey(
		requestParameters: CreateApiKeyRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<CreateApiKeyResponse> {
		const response = await this.createApiKeyRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async createCustomerRaw(
		requestParameters: CreateCustomerOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<CreateCustomerResponse>> {
		if (
			requestParameters.createCustomerRequest === null ||
			requestParameters.createCustomerRequest === undefined
		) {
			throw new runtime.RequiredError(
				'createCustomerRequest',
				'Required parameter requestParameters.createCustomerRequest was null or undefined when calling createCustomer.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/customer`,
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
				body: CreateCustomerRequestToJSON(
					requestParameters.createCustomerRequest
				),
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			CreateCustomerResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async createCustomer(
		requestParameters: CreateCustomerOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<CreateCustomerResponse> {
		const response = await this.createCustomerRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async createEnvironmentRaw(
		requestParameters: CreateEnvironmentOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<CreateEnvironmentResponse>> {
		if (
			requestParameters.createEnvironmentRequest === null ||
			requestParameters.createEnvironmentRequest === undefined
		) {
			throw new runtime.RequiredError(
				'createEnvironmentRequest',
				'Required parameter requestParameters.createEnvironmentRequest was null or undefined when calling createEnvironment.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/environment`,
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
				body: CreateEnvironmentRequestToJSON(
					requestParameters.createEnvironmentRequest
				),
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			CreateEnvironmentResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async createEnvironment(
		requestParameters: CreateEnvironmentOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<CreateEnvironmentResponse> {
		const response = await this.createEnvironmentRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async createOnboardingAccountRaw(
		requestParameters: CreateOnboardingAccountOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.createOnboardingAccountRequest === null ||
			requestParameters.createOnboardingAccountRequest === undefined
		) {
			throw new runtime.RequiredError(
				'createOnboardingAccountRequest',
				'Required parameter requestParameters.createOnboardingAccountRequest was null or undefined when calling createOnboardingAccount.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/onboarding/create`,
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
				body: CreateOnboardingAccountRequestToJSON(
					requestParameters.createOnboardingAccountRequest
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async createOnboardingAccount(
		requestParameters: CreateOnboardingAccountOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.createOnboardingAccountRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async createUserRaw(
		requestParameters: CreateUserOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<CreateUserResponse>> {
		if (
			requestParameters.createUserRequest === null ||
			requestParameters.createUserRequest === undefined
		) {
			throw new runtime.RequiredError(
				'createUserRequest',
				'Required parameter requestParameters.createUserRequest was null or undefined when calling createUser.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/user`,
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
				body: CreateUserRequestToJSON(requestParameters.createUserRequest),
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			CreateUserResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async createUser(
		requestParameters: CreateUserOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<CreateUserResponse> {
		const response = await this.createUserRaw(requestParameters, initOverrides);
		return await response.value();
	}

	/**
	 */
	async deactivateUserRaw(
		requestParameters: DeactivateUserRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.userId === null ||
			requestParameters.userId === undefined
		) {
			throw new runtime.RequiredError(
				'userId',
				'Required parameter requestParameters.userId was null or undefined when calling deactivateUser.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/user/{userId}/deactivate`.replace(
					`{${'userId'}}`,
					encodeURIComponent(String(requestParameters.userId))
				),
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async deactivateUser(
		requestParameters: DeactivateUserRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.deactivateUserRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async deactivateUsersRaw(
		requestParameters: DeactivateUsersOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.deactivateUsersRequest === null ||
			requestParameters.deactivateUsersRequest === undefined
		) {
			throw new runtime.RequiredError(
				'deactivateUsersRequest',
				'Required parameter requestParameters.deactivateUsersRequest was null or undefined when calling deactivateUsers.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/user/deactivate`,
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
				body: DeactivateUsersRequestToJSON(
					requestParameters.deactivateUsersRequest
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async deactivateUsers(
		requestParameters: DeactivateUsersOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.deactivateUsersRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async deleteAccessStepRaw(
		requestParameters: DeleteAccessStepRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.accessStepId === null ||
			requestParameters.accessStepId === undefined
		) {
			throw new runtime.RequiredError(
				'accessStepId',
				'Required parameter requestParameters.accessStepId was null or undefined when calling deleteAccessStep.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/access_step/{accessStepId}`.replace(
					`{${'accessStepId'}}`,
					encodeURIComponent(String(requestParameters.accessStepId))
				),
				method: 'DELETE',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async deleteAccessStep(
		requestParameters: DeleteAccessStepRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.deleteAccessStepRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async deleteAccessStepsRaw(
		requestParameters: DeleteAccessStepsOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.deleteAccessStepsRequest === null ||
			requestParameters.deleteAccessStepsRequest === undefined
		) {
			throw new runtime.RequiredError(
				'deleteAccessStepsRequest',
				'Required parameter requestParameters.deleteAccessStepsRequest was null or undefined when calling deleteAccessSteps.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/access_step`,
				method: 'DELETE',
				headers: headerParameters,
				query: queryParameters,
				body: DeleteAccessStepsRequestToJSON(
					requestParameters.deleteAccessStepsRequest
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async deleteAccessSteps(
		requestParameters: DeleteAccessStepsOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.deleteAccessStepsRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async deleteAccountMemberRaw(
		requestParameters: DeleteAccountMemberRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.accountMemberId === null ||
			requestParameters.accountMemberId === undefined
		) {
			throw new runtime.RequiredError(
				'accountMemberId',
				'Required parameter requestParameters.accountMemberId was null or undefined when calling deleteAccountMember.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/account_member/{accountMemberId}`.replace(
					`{${'accountMemberId'}}`,
					encodeURIComponent(String(requestParameters.accountMemberId))
				),
				method: 'DELETE',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async deleteAccountMember(
		requestParameters: DeleteAccountMemberRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.deleteAccountMemberRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async deleteApiKeyRaw(
		requestParameters: DeleteApiKeyRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.keyId === null ||
			requestParameters.keyId === undefined
		) {
			throw new runtime.RequiredError(
				'keyId',
				'Required parameter requestParameters.keyId was null or undefined when calling deleteApiKey.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/api_key/{keyId}`.replace(
					`{${'keyId'}}`,
					encodeURIComponent(String(requestParameters.keyId))
				),
				method: 'DELETE',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async deleteApiKey(
		requestParameters: DeleteApiKeyRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.deleteApiKeyRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async deleteCustomerRaw(
		requestParameters: DeleteCustomerRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.customerId === null ||
			requestParameters.customerId === undefined
		) {
			throw new runtime.RequiredError(
				'customerId',
				'Required parameter requestParameters.customerId was null or undefined when calling deleteCustomer.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/customer/{customerId}`.replace(
					`{${'customerId'}}`,
					encodeURIComponent(String(requestParameters.customerId))
				),
				method: 'DELETE',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async deleteCustomer(
		requestParameters: DeleteCustomerRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.deleteCustomerRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async deleteCustomersRaw(
		requestParameters: DeleteCustomersOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.deleteCustomersRequest === null ||
			requestParameters.deleteCustomersRequest === undefined
		) {
			throw new runtime.RequiredError(
				'deleteCustomersRequest',
				'Required parameter requestParameters.deleteCustomersRequest was null or undefined when calling deleteCustomers.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/customer`,
				method: 'DELETE',
				headers: headerParameters,
				query: queryParameters,
				body: DeleteCustomersRequestToJSON(
					requestParameters.deleteCustomersRequest
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async deleteCustomers(
		requestParameters: DeleteCustomersOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.deleteCustomersRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async deleteEnvironmentRaw(
		requestParameters: DeleteEnvironmentRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.environmentId === null ||
			requestParameters.environmentId === undefined
		) {
			throw new runtime.RequiredError(
				'environmentId',
				'Required parameter requestParameters.environmentId was null or undefined when calling deleteEnvironment.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/environment/{environmentId}`.replace(
					`{${'environmentId'}}`,
					encodeURIComponent(String(requestParameters.environmentId))
				),
				method: 'DELETE',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async deleteEnvironment(
		requestParameters: DeleteEnvironmentRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.deleteEnvironmentRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async deleteFaceRegistrationRaw(
		requestParameters: DeleteFaceRegistrationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.faceRegistrationId === null ||
			requestParameters.faceRegistrationId === undefined
		) {
			throw new runtime.RequiredError(
				'faceRegistrationId',
				'Required parameter requestParameters.faceRegistrationId was null or undefined when calling deleteFaceRegistration.'
			);
		}

		const queryParameters: any = {};

		if (requestParameters.faceRegistrationId !== undefined) {
			queryParameters['faceRegistrationId'] =
				requestParameters.faceRegistrationId;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/face/registration`,
				method: 'DELETE',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async deleteFaceRegistration(
		requestParameters: DeleteFaceRegistrationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.deleteFaceRegistrationRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async deleteUserRaw(
		requestParameters: DeleteUserRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.userId === null ||
			requestParameters.userId === undefined
		) {
			throw new runtime.RequiredError(
				'userId',
				'Required parameter requestParameters.userId was null or undefined when calling deleteUser.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/user/{userId}`.replace(
					`{${'userId'}}`,
					encodeURIComponent(String(requestParameters.userId))
				),
				method: 'DELETE',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async deleteUser(
		requestParameters: DeleteUserRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.deleteUserRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async deleteUsersRaw(
		requestParameters: DeleteUsersOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.deleteUsersRequest === null ||
			requestParameters.deleteUsersRequest === undefined
		) {
			throw new runtime.RequiredError(
				'deleteUsersRequest',
				'Required parameter requestParameters.deleteUsersRequest was null or undefined when calling deleteUsers.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/user`,
				method: 'DELETE',
				headers: headerParameters,
				query: queryParameters,
				body: DeleteUsersRequestToJSON(requestParameters.deleteUsersRequest),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async deleteUsers(
		requestParameters: DeleteUsersOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.deleteUsersRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async faceAccessRaw(
		requestParameters: FaceAccessRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<FaceAccessResponse>> {
		if (
			requestParameters.customerId === null ||
			requestParameters.customerId === undefined
		) {
			throw new runtime.RequiredError(
				'customerId',
				'Required parameter requestParameters.customerId was null or undefined when calling faceAccess.'
			);
		}

		if (
			requestParameters.environmentId === null ||
			requestParameters.environmentId === undefined
		) {
			throw new runtime.RequiredError(
				'environmentId',
				'Required parameter requestParameters.environmentId was null or undefined when calling faceAccess.'
			);
		}

		if (
			requestParameters.file === null ||
			requestParameters.file === undefined
		) {
			throw new runtime.RequiredError(
				'file',
				'Required parameter requestParameters.file was null or undefined when calling faceAccess.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const consumes: runtime.Consume[] = [
			{ contentType: 'multipart/form-data' },
		];
		// @ts-ignore: canConsumeForm may be unused
		const canConsumeForm = runtime.canConsumeForm(consumes);

		let formParams: { append(param: string, value: any): any };
		let useForm = false;
		// use FormData to transmit files using content-type "multipart/form-data"
		useForm = canConsumeForm;
		if (useForm) {
			formParams = new FormData();
		} else {
			formParams = new URLSearchParams();
		}

		if (requestParameters.customerId !== undefined) {
			formParams.append('customerId', requestParameters.customerId as any);
		}

		if (requestParameters.environmentId !== undefined) {
			formParams.append(
				'environmentId',
				requestParameters.environmentId as any
			);
		}

		if (requestParameters.sessionId !== undefined) {
			formParams.append('sessionId', requestParameters.sessionId as any);
		}

		if (requestParameters.file !== undefined) {
			formParams.append('file', requestParameters.file as any);
		}

		if (requestParameters.customJsonData !== undefined) {
			formParams.append(
				'customJsonData',
				requestParameters.customJsonData as any
			);
		}

		const response = await this.request(
			{
				path: `/access/face`,
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
				body: formParams,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			FaceAccessResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async faceAccess(
		requestParameters: FaceAccessRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<FaceAccessResponse> {
		const response = await this.faceAccessRaw(requestParameters, initOverrides);
		return await response.value();
	}

	/**
	 */
	async getAccessSessionHistoriesRaw(
		requestParameters: GetAccessSessionHistoriesRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<AccessSessionHistoriesResponse>> {
		const queryParameters: any = {};

		if (requestParameters.environmentId !== undefined) {
			queryParameters['environmentId'] = requestParameters.environmentId;
		}

		if (requestParameters.customerId !== undefined) {
			queryParameters['customerId'] = requestParameters.customerId;
		}

		if (requestParameters.userId !== undefined) {
			queryParameters['userId'] = requestParameters.userId;
		}

		if (requestParameters.userName !== undefined) {
			queryParameters['userName'] = requestParameters.userName;
		}

		if (requestParameters.customerName !== undefined) {
			queryParameters['customerName'] = requestParameters.customerName;
		}

		if (requestParameters.accessFactor !== undefined) {
			queryParameters['accessFactor'] = requestParameters.accessFactor;
		}

		if (requestParameters.tag !== undefined) {
			queryParameters['tag'] = requestParameters.tag;
		}

		if (requestParameters.includeUnSuccessful !== undefined) {
			queryParameters['includeUnSuccessful'] =
				requestParameters.includeUnSuccessful;
		}

		if (requestParameters.securityScoreRating !== undefined) {
			queryParameters['securityScoreRating'] =
				requestParameters.securityScoreRating;
		}

		if (requestParameters.pageNumber !== undefined) {
			queryParameters['pageNumber'] = requestParameters.pageNumber;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters['pageSize'] = requestParameters.pageSize;
		}

		if (requestParameters.startDate !== undefined) {
			queryParameters['startDate'] = (
				requestParameters.startDate as any
			).toISOString();
		}

		if (requestParameters.endDate !== undefined) {
			queryParameters['endDate'] = (
				requestParameters.endDate as any
			).toISOString();
		}

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/access_session/history`,
				method: 'GET',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			AccessSessionHistoriesResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async getAccessSessionHistories(
		requestParameters: GetAccessSessionHistoriesRequest = {},
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<AccessSessionHistoriesResponse> {
		const response = await this.getAccessSessionHistoriesRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async getAccessStepAttemptCountRaw(
		requestParameters: GetAccessStepAttemptCountRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<AccessStepAttemptCountsResponse>> {
		const queryParameters: any = {};

		if (requestParameters.environmentId !== undefined) {
			queryParameters['environmentId'] = requestParameters.environmentId;
		}

		if (requestParameters.customerId !== undefined) {
			queryParameters['customerId'] = requestParameters.customerId;
		}

		if (requestParameters.userId !== undefined) {
			queryParameters['userId'] = requestParameters.userId;
		}

		if (requestParameters.startDate !== undefined) {
			queryParameters['startDate'] = (
				requestParameters.startDate as any
			).toISOString();
		}

		if (requestParameters.endDate !== undefined) {
			queryParameters['endDate'] = (
				requestParameters.endDate as any
			).toISOString();
		}

		if (requestParameters.succeeded !== undefined) {
			queryParameters['succeeded'] = requestParameters.succeeded;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/access_step/attempt_count`,
				method: 'GET',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			AccessStepAttemptCountsResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async getAccessStepAttemptCount(
		requestParameters: GetAccessStepAttemptCountRequest = {},
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<AccessStepAttemptCountsResponse> {
		const response = await this.getAccessStepAttemptCountRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async getAccessStepDetailRaw(
		requestParameters: GetAccessStepDetailRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<AccessStepDetailResponse>> {
		if (
			requestParameters.accessStepId === null ||
			requestParameters.accessStepId === undefined
		) {
			throw new runtime.RequiredError(
				'accessStepId',
				'Required parameter requestParameters.accessStepId was null or undefined when calling getAccessStepDetail.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/access_step/{accessStepId}`.replace(
					`{${'accessStepId'}}`,
					encodeURIComponent(String(requestParameters.accessStepId))
				),
				method: 'GET',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			AccessStepDetailResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async getAccessStepDetail(
		requestParameters: GetAccessStepDetailRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<AccessStepDetailResponse> {
		const response = await this.getAccessStepDetailRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async getAccessStepHistoriesRaw(
		requestParameters: GetAccessStepHistoriesRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<AccessStepHistoryResponse>> {
		const queryParameters: any = {};

		if (requestParameters.environmentId !== undefined) {
			queryParameters['environmentId'] = requestParameters.environmentId;
		}

		if (requestParameters.customerId !== undefined) {
			queryParameters['customerId'] = requestParameters.customerId;
		}

		if (requestParameters.mappedCustomerId !== undefined) {
			queryParameters['mappedCustomerId'] = requestParameters.mappedCustomerId;
		}

		if (requestParameters.userId !== undefined) {
			queryParameters['userId'] = requestParameters.userId;
		}

		if (requestParameters.mappedUserId !== undefined) {
			queryParameters['mappedUserId'] = requestParameters.mappedUserId;
		}

		if (requestParameters.sessionId !== undefined) {
			queryParameters['sessionId'] = requestParameters.sessionId;
		}

		if (requestParameters.accessFactor !== undefined) {
			queryParameters['accessFactor'] = requestParameters.accessFactor;
		}

		if (requestParameters.tag !== undefined) {
			queryParameters['tag'] = requestParameters.tag;
		}

		if (requestParameters.includeUnSuccessful !== undefined) {
			queryParameters['includeUnSuccessful'] =
				requestParameters.includeUnSuccessful;
		}

		if (requestParameters.pageNumber !== undefined) {
			queryParameters['pageNumber'] = requestParameters.pageNumber;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters['pageSize'] = requestParameters.pageSize;
		}

		if (requestParameters.startDate !== undefined) {
			queryParameters['startDate'] = (
				requestParameters.startDate as any
			).toISOString();
		}

		if (requestParameters.endDate !== undefined) {
			queryParameters['endDate'] = (
				requestParameters.endDate as any
			).toISOString();
		}

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/access_step`,
				method: 'GET',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			AccessStepHistoryResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async getAccessStepHistories(
		requestParameters: GetAccessStepHistoriesRequest = {},
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<AccessStepHistoryResponse> {
		const response = await this.getAccessStepHistoriesRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async getAccountMembersRaw(
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<AccountMembersResponse>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/account_member`,
				method: 'GET',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			AccountMembersResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async getAccountMembers(
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<AccountMembersResponse> {
		const response = await this.getAccountMembersRaw(initOverrides);
		return await response.value();
	}

	/**
	 */
	async getActiveRegistrationRaw(
		requestParameters: GetActiveRegistrationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<ActiveRegistrationResponse>> {
		if (
			requestParameters.userId === null ||
			requestParameters.userId === undefined
		) {
			throw new runtime.RequiredError(
				'userId',
				'Required parameter requestParameters.userId was null or undefined when calling getActiveRegistration.'
			);
		}

		const queryParameters: any = {};

		if (requestParameters.userId !== undefined) {
			queryParameters['userId'] = requestParameters.userId;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/face/registration/active`,
				method: 'GET',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			ActiveRegistrationResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async getActiveRegistration(
		requestParameters: GetActiveRegistrationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<ActiveRegistrationResponse> {
		const response = await this.getActiveRegistrationRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async getApiKeysRaw(
		requestParameters: GetApiKeysRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<ApiKeysResponse>> {
		if (
			requestParameters.environmentId === null ||
			requestParameters.environmentId === undefined
		) {
			throw new runtime.RequiredError(
				'environmentId',
				'Required parameter requestParameters.environmentId was null or undefined when calling getApiKeys.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/api_key/{environmentId}`.replace(
					`{${'environmentId'}}`,
					encodeURIComponent(String(requestParameters.environmentId))
				),
				method: 'GET',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			ApiKeysResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async getApiKeys(
		requestParameters: GetApiKeysRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<ApiKeysResponse> {
		const response = await this.getApiKeysRaw(requestParameters, initOverrides);
		return await response.value();
	}

	/**
	 */
	async getCurrentAccountMemberRaw(
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<AccountMemberResponse>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/account_member/me`,
				method: 'GET',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			AccountMemberResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async getCurrentAccountMember(
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<AccountMemberResponse> {
		const response = await this.getCurrentAccountMemberRaw(initOverrides);
		return await response.value();
	}

	/**
	 */
	async getCustomerRaw(
		requestParameters: GetCustomerRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<CustomerResponse>> {
		if (
			requestParameters.customerId === null ||
			requestParameters.customerId === undefined
		) {
			throw new runtime.RequiredError(
				'customerId',
				'Required parameter requestParameters.customerId was null or undefined when calling getCustomer.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/customer/{customerId}`.replace(
					`{${'customerId'}}`,
					encodeURIComponent(String(requestParameters.customerId))
				),
				method: 'GET',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			CustomerResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async getCustomer(
		requestParameters: GetCustomerRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<CustomerResponse> {
		const response = await this.getCustomerRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async getCustomerCountByEnvironmentIdRaw(
		requestParameters: GetCustomerCountByEnvironmentIdRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<EnvironmentCustomerCountResponse>> {
		if (
			requestParameters.environmentId === null ||
			requestParameters.environmentId === undefined
		) {
			throw new runtime.RequiredError(
				'environmentId',
				'Required parameter requestParameters.environmentId was null or undefined when calling getCustomerCountByEnvironmentId.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/environment/{environmentId}/customer_count`.replace(
					`{${'environmentId'}}`,
					encodeURIComponent(String(requestParameters.environmentId))
				),
				method: 'GET',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			EnvironmentCustomerCountResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async getCustomerCountByEnvironmentId(
		requestParameters: GetCustomerCountByEnvironmentIdRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<EnvironmentCustomerCountResponse> {
		const response = await this.getCustomerCountByEnvironmentIdRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async getCustomerSettingsRaw(
		requestParameters: GetCustomerSettingsRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<CustomerSettingsResponse>> {
		if (
			requestParameters.customerId === null ||
			requestParameters.customerId === undefined
		) {
			throw new runtime.RequiredError(
				'customerId',
				'Required parameter requestParameters.customerId was null or undefined when calling getCustomerSettings.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/customer/{customerId}/settings`.replace(
					`{${'customerId'}}`,
					encodeURIComponent(String(requestParameters.customerId))
				),
				method: 'GET',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			CustomerSettingsResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async getCustomerSettings(
		requestParameters: GetCustomerSettingsRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<CustomerSettingsResponse> {
		const response = await this.getCustomerSettingsRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async getCustomersRaw(
		requestParameters: GetCustomersRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<CustomersResponse>> {
		if (
			requestParameters.environmentId === null ||
			requestParameters.environmentId === undefined
		) {
			throw new runtime.RequiredError(
				'environmentId',
				'Required parameter requestParameters.environmentId was null or undefined when calling getCustomers.'
			);
		}

		const queryParameters: any = {};

		if (requestParameters.environmentId !== undefined) {
			queryParameters['environmentId'] = requestParameters.environmentId;
		}

		if (requestParameters.query !== undefined) {
			queryParameters['query'] = requestParameters.query;
		}

		if (requestParameters.pageNumber !== undefined) {
			queryParameters['pageNumber'] = requestParameters.pageNumber;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters['pageSize'] = requestParameters.pageSize;
		}

		if (requestParameters.sort !== undefined) {
			queryParameters['sort'] = requestParameters.sort;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/customer`,
				method: 'GET',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			CustomersResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async getCustomers(
		requestParameters: GetCustomersRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<CustomersResponse> {
		const response = await this.getCustomersRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async getEnvironmentRaw(
		requestParameters: GetEnvironmentRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<EnvironmentResponse>> {
		if (
			requestParameters.environmentId === null ||
			requestParameters.environmentId === undefined
		) {
			throw new runtime.RequiredError(
				'environmentId',
				'Required parameter requestParameters.environmentId was null or undefined when calling getEnvironment.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/environment/{environmentId}`.replace(
					`{${'environmentId'}}`,
					encodeURIComponent(String(requestParameters.environmentId))
				),
				method: 'GET',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			EnvironmentResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async getEnvironment(
		requestParameters: GetEnvironmentRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<EnvironmentResponse> {
		const response = await this.getEnvironmentRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async getEnvironmentsRaw(
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<EnvironmentsResponse>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/environment`,
				method: 'GET',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			EnvironmentsResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async getEnvironments(
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<EnvironmentsResponse> {
		const response = await this.getEnvironmentsRaw(initOverrides);
		return await response.value();
	}

	/**
	 */
	async getFaceRegistrationCountRaw(
		requestParameters: GetFaceRegistrationCountRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<FaceRegistrationsCountResponse>> {
		const queryParameters: any = {};

		if (requestParameters.environmentIds) {
			queryParameters['environmentIds'] = requestParameters.environmentIds;
		}

		if (requestParameters.customerIds) {
			queryParameters['customerIds'] = requestParameters.customerIds;
		}

		if (requestParameters.userIds) {
			queryParameters['userIds'] = requestParameters.userIds;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/face/registration/count`,
				method: 'GET',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			FaceRegistrationsCountResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async getFaceRegistrationCount(
		requestParameters: GetFaceRegistrationCountRequest = {},
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<FaceRegistrationsCountResponse> {
		const response = await this.getFaceRegistrationCountRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async getQrcodeRaw(
		requestParameters: GetQrcodeRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<QrCodeResponseBody>> {
		if (
			requestParameters.qrCodeRequestBody === null ||
			requestParameters.qrCodeRequestBody === undefined
		) {
			throw new runtime.RequiredError(
				'qrCodeRequestBody',
				'Required parameter requestParameters.qrCodeRequestBody was null or undefined when calling getQrcode.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/user/qrcode`,
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
				body: QrCodeRequestBodyToJSON(requestParameters.qrCodeRequestBody),
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			QrCodeResponseBodyFromJSON(jsonValue)
		);
	}

	/**
	 */
	async getQrcode(
		requestParameters: GetQrcodeRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<QrCodeResponseBody> {
		const response = await this.getQrcodeRaw(requestParameters, initOverrides);
		return await response.value();
	}

	/**
	 */
	async getRegistrationsRaw(
		requestParameters: GetRegistrationsRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<UserRegistrationResponse>> {
		if (
			requestParameters.userId === null ||
			requestParameters.userId === undefined
		) {
			throw new runtime.RequiredError(
				'userId',
				'Required parameter requestParameters.userId was null or undefined when calling getRegistrations.'
			);
		}

		const queryParameters: any = {};

		if (requestParameters.userId !== undefined) {
			queryParameters['userId'] = requestParameters.userId;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/face/registration`,
				method: 'GET',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			UserRegistrationResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async getRegistrations(
		requestParameters: GetRegistrationsRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<UserRegistrationResponse> {
		const response = await this.getRegistrationsRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async getSessionDetailsWithStepsRaw(
		requestParameters: GetSessionDetailsWithStepsRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<SessionDetailsWithStepsResponse>> {
		if (
			requestParameters.sessionId === null ||
			requestParameters.sessionId === undefined
		) {
			throw new runtime.RequiredError(
				'sessionId',
				'Required parameter requestParameters.sessionId was null or undefined when calling getSessionDetailsWithSteps.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/access_session/{sessionId}/details`.replace(
					`{${'sessionId'}}`,
					encodeURIComponent(String(requestParameters.sessionId))
				),
				method: 'GET',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			SessionDetailsWithStepsResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async getSessionDetailsWithSteps(
		requestParameters: GetSessionDetailsWithStepsRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<SessionDetailsWithStepsResponse> {
		const response = await this.getSessionDetailsWithStepsRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async getSubmittedRegistrationRaw(
		requestParameters: GetSubmittedRegistrationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<SubmittedRegistrationResponse>> {
		if (
			requestParameters.userId === null ||
			requestParameters.userId === undefined
		) {
			throw new runtime.RequiredError(
				'userId',
				'Required parameter requestParameters.userId was null or undefined when calling getSubmittedRegistration.'
			);
		}

		const queryParameters: any = {};

		if (requestParameters.userId !== undefined) {
			queryParameters['userId'] = requestParameters.userId;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/face/registration/submitted`,
				method: 'GET',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			SubmittedRegistrationResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async getSubmittedRegistration(
		requestParameters: GetSubmittedRegistrationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<SubmittedRegistrationResponse> {
		const response = await this.getSubmittedRegistrationRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async getTopCustomerFaceRegistrationsRaw(
		requestParameters: GetTopCustomerFaceRegistrationsRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<FaceRegistrationsCountResponse>> {
		if (
			requestParameters.environmentId === null ||
			requestParameters.environmentId === undefined
		) {
			throw new runtime.RequiredError(
				'environmentId',
				'Required parameter requestParameters.environmentId was null or undefined when calling getTopCustomerFaceRegistrations.'
			);
		}

		if (requestParameters.top === null || requestParameters.top === undefined) {
			throw new runtime.RequiredError(
				'top',
				'Required parameter requestParameters.top was null or undefined when calling getTopCustomerFaceRegistrations.'
			);
		}

		const queryParameters: any = {};

		if (requestParameters.environmentId !== undefined) {
			queryParameters['environmentId'] = requestParameters.environmentId;
		}

		if (requestParameters.top !== undefined) {
			queryParameters['top'] = requestParameters.top;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/face/registration/top_customers_face_registrations`,
				method: 'GET',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			FaceRegistrationsCountResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async getTopCustomerFaceRegistrations(
		requestParameters: GetTopCustomerFaceRegistrationsRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<FaceRegistrationsCountResponse> {
		const response = await this.getTopCustomerFaceRegistrationsRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async getUserByIdRaw(
		requestParameters: GetUserByIdRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<User>> {
		if (
			requestParameters.user === null ||
			requestParameters.user === undefined
		) {
			throw new runtime.RequiredError(
				'user',
				'Required parameter requestParameters.user was null or undefined when calling getUserById.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/user/{user}`.replace(
					`{${'user'}}`,
					encodeURIComponent(String(requestParameters.user))
				),
				method: 'GET',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			UserFromJSON(jsonValue)
		);
	}

	/**
	 */
	async getUserById(
		requestParameters: GetUserByIdRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<User> {
		const response = await this.getUserByIdRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async getUserCountByCustomerIdRaw(
		requestParameters: GetUserCountByCustomerIdRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<UserCountResponse>> {
		if (
			requestParameters.customerId === null ||
			requestParameters.customerId === undefined
		) {
			throw new runtime.RequiredError(
				'customerId',
				'Required parameter requestParameters.customerId was null or undefined when calling getUserCountByCustomerId.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/customer/{customerId}/user_count`.replace(
					`{${'customerId'}}`,
					encodeURIComponent(String(requestParameters.customerId))
				),
				method: 'GET',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			UserCountResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async getUserCountByCustomerId(
		requestParameters: GetUserCountByCustomerIdRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<UserCountResponse> {
		const response = await this.getUserCountByCustomerIdRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async getUserCountByEnvironmentIdRaw(
		requestParameters: GetUserCountByEnvironmentIdRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<EnvironmentUserCountResponse>> {
		if (
			requestParameters.environmentId === null ||
			requestParameters.environmentId === undefined
		) {
			throw new runtime.RequiredError(
				'environmentId',
				'Required parameter requestParameters.environmentId was null or undefined when calling getUserCountByEnvironmentId.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/environment/{environmentId}/user_count`.replace(
					`{${'environmentId'}}`,
					encodeURIComponent(String(requestParameters.environmentId))
				),
				method: 'GET',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			EnvironmentUserCountResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async getUserCountByEnvironmentId(
		requestParameters: GetUserCountByEnvironmentIdRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<EnvironmentUserCountResponse> {
		const response = await this.getUserCountByEnvironmentIdRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async getUsersRaw(
		requestParameters: GetUsersRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<UsersResponse>> {
		if (
			requestParameters.pageNumber === null ||
			requestParameters.pageNumber === undefined
		) {
			throw new runtime.RequiredError(
				'pageNumber',
				'Required parameter requestParameters.pageNumber was null or undefined when calling getUsers.'
			);
		}

		if (
			requestParameters.pageSize === null ||
			requestParameters.pageSize === undefined
		) {
			throw new runtime.RequiredError(
				'pageSize',
				'Required parameter requestParameters.pageSize was null or undefined when calling getUsers.'
			);
		}

		const queryParameters: any = {};

		if (requestParameters.environmentId !== undefined) {
			queryParameters['environmentId'] = requestParameters.environmentId;
		}

		if (requestParameters.customerId !== undefined) {
			queryParameters['customerId'] = requestParameters.customerId;
		}

		if (requestParameters.query !== undefined) {
			queryParameters['query'] = requestParameters.query;
		}

		if (requestParameters.queryType !== undefined) {
			queryParameters['queryType'] = requestParameters.queryType;
		}

		if (requestParameters.active !== undefined) {
			queryParameters['active'] = requestParameters.active;
		}

		if (requestParameters.pageNumber !== undefined) {
			queryParameters['pageNumber'] = requestParameters.pageNumber;
		}

		if (requestParameters.pageSize !== undefined) {
			queryParameters['pageSize'] = requestParameters.pageSize;
		}

		if (requestParameters.sort !== undefined) {
			queryParameters['sort'] = requestParameters.sort;
		}

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/user`,
				method: 'GET',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			UsersResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async getUsers(
		requestParameters: GetUsersRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<UsersResponse> {
		const response = await this.getUsersRaw(requestParameters, initOverrides);
		return await response.value();
	}

	/**
	 */
	async getWebhookByEnvironmentIdRaw(
		requestParameters: GetWebhookByEnvironmentIdRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<EnvironmentWebhookResponse>> {
		if (
			requestParameters.environmentId === null ||
			requestParameters.environmentId === undefined
		) {
			throw new runtime.RequiredError(
				'environmentId',
				'Required parameter requestParameters.environmentId was null or undefined when calling getWebhookByEnvironmentId.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/environment/{environmentId}/webhook`.replace(
					`{${'environmentId'}}`,
					encodeURIComponent(String(requestParameters.environmentId))
				),
				method: 'GET',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			EnvironmentWebhookResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async getWebhookByEnvironmentId(
		requestParameters: GetWebhookByEnvironmentIdRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<EnvironmentWebhookResponse> {
		const response = await this.getWebhookByEnvironmentIdRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async manualAccessRaw(
		requestParameters: ManualAccessOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<ManualAccessResponse>> {
		if (
			requestParameters.manualAccessRequest === null ||
			requestParameters.manualAccessRequest === undefined
		) {
			throw new runtime.RequiredError(
				'manualAccessRequest',
				'Required parameter requestParameters.manualAccessRequest was null or undefined when calling manualAccess.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/access/manual`,
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
				body: ManualAccessRequestToJSON(requestParameters.manualAccessRequest),
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			ManualAccessResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async manualAccess(
		requestParameters: ManualAccessOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<ManualAccessResponse> {
		const response = await this.manualAccessRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async newFaceRegistrationRaw(
		requestParameters: NewFaceRegistrationOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<NewFaceRegistrationResponse>> {
		if (
			requestParameters.newFaceRegistrationRequest === null ||
			requestParameters.newFaceRegistrationRequest === undefined
		) {
			throw new runtime.RequiredError(
				'newFaceRegistrationRequest',
				'Required parameter requestParameters.newFaceRegistrationRequest was null or undefined when calling newFaceRegistration.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/face/registration`,
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
				body: NewFaceRegistrationRequestToJSON(
					requestParameters.newFaceRegistrationRequest
				),
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			NewFaceRegistrationResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async newFaceRegistration(
		requestParameters: NewFaceRegistrationOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<NewFaceRegistrationResponse> {
		const response = await this.newFaceRegistrationRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}

	/**
	 */
	async pinAccessRaw(
		requestParameters: PinAccessOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<PinAccessResponse>> {
		if (
			requestParameters.pinAccessRequest === null ||
			requestParameters.pinAccessRequest === undefined
		) {
			throw new runtime.RequiredError(
				'pinAccessRequest',
				'Required parameter requestParameters.pinAccessRequest was null or undefined when calling pinAccess.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/access/pin`,
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
				body: PinAccessRequestToJSON(requestParameters.pinAccessRequest),
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			PinAccessResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async pinAccess(
		requestParameters: PinAccessOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<PinAccessResponse> {
		const response = await this.pinAccessRaw(requestParameters, initOverrides);
		return await response.value();
	}

	/**
	 */
	async rejectFaceRegistrationRaw(
		requestParameters: RejectFaceRegistrationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.registrationId === null ||
			requestParameters.registrationId === undefined
		) {
			throw new runtime.RequiredError(
				'registrationId',
				'Required parameter requestParameters.registrationId was null or undefined when calling rejectFaceRegistration.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/face/registration/{registrationId}/reject`.replace(
					`{${'registrationId'}}`,
					encodeURIComponent(String(requestParameters.registrationId))
				),
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async rejectFaceRegistration(
		requestParameters: RejectFaceRegistrationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.rejectFaceRegistrationRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async resendAuth0VerificationEmailRaw(
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/start/resend_verification`,
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async resendAuth0VerificationEmail(
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.resendAuth0VerificationEmailRaw(initOverrides);
	}

	/**
	 */
	async setTagsRaw(
		requestParameters: SetTagsRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.sessionId === null ||
			requestParameters.sessionId === undefined
		) {
			throw new runtime.RequiredError(
				'sessionId',
				'Required parameter requestParameters.sessionId was null or undefined when calling setTags.'
			);
		}

		if (
			requestParameters.setAccessSessionTagsRequest === null ||
			requestParameters.setAccessSessionTagsRequest === undefined
		) {
			throw new runtime.RequiredError(
				'setAccessSessionTagsRequest',
				'Required parameter requestParameters.setAccessSessionTagsRequest was null or undefined when calling setTags.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/access_session/{sessionId}/tags`.replace(
					`{${'sessionId'}}`,
					encodeURIComponent(String(requestParameters.sessionId))
				),
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
				body: SetAccessSessionTagsRequestToJSON(
					requestParameters.setAccessSessionTagsRequest
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async setTags(
		requestParameters: SetTagsRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.setTagsRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async setTags1Raw(
		requestParameters: SetTags1Request,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.accessStepId === null ||
			requestParameters.accessStepId === undefined
		) {
			throw new runtime.RequiredError(
				'accessStepId',
				'Required parameter requestParameters.accessStepId was null or undefined when calling setTags1.'
			);
		}

		if (
			requestParameters.setAccessStepTagsRequest === null ||
			requestParameters.setAccessStepTagsRequest === undefined
		) {
			throw new runtime.RequiredError(
				'setAccessStepTagsRequest',
				'Required parameter requestParameters.setAccessStepTagsRequest was null or undefined when calling setTags1.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/access_step/{accessStepId}/tag`.replace(
					`{${'accessStepId'}}`,
					encodeURIComponent(String(requestParameters.accessStepId))
				),
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
				body: SetAccessStepTagsRequestToJSON(
					requestParameters.setAccessStepTagsRequest
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async setTags1(
		requestParameters: SetTags1Request,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.setTags1Raw(requestParameters, initOverrides);
	}

	/**
	 */
	async startRaw(
		requestParameters: StartOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<StartResponse>> {
		if (
			requestParameters.startRequest === null ||
			requestParameters.startRequest === undefined
		) {
			throw new runtime.RequiredError(
				'startRequest',
				'Required parameter requestParameters.startRequest was null or undefined when calling start.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/start`,
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
				body: StartRequestToJSON(requestParameters.startRequest),
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			StartResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async start(
		requestParameters: StartOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<StartResponse> {
		const response = await this.startRaw(requestParameters, initOverrides);
		return await response.value();
	}

	/**
	 */
	async submitFaceRegistrationRaw(
		requestParameters: SubmitFaceRegistrationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.registrationId === null ||
			requestParameters.registrationId === undefined
		) {
			throw new runtime.RequiredError(
				'registrationId',
				'Required parameter requestParameters.registrationId was null or undefined when calling submitFaceRegistration.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/face/registration/{registrationId}/submit`.replace(
					`{${'registrationId'}}`,
					encodeURIComponent(String(requestParameters.registrationId))
				),
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async submitFaceRegistration(
		requestParameters: SubmitFaceRegistrationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.submitFaceRegistrationRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async switchEnvironmentRaw(
		requestParameters: SwitchEnvironmentRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.environmentId === null ||
			requestParameters.environmentId === undefined
		) {
			throw new runtime.RequiredError(
				'environmentId',
				'Required parameter requestParameters.environmentId was null or undefined when calling switchEnvironment.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const response = await this.request(
			{
				path: `/account_member/{environmentId}`.replace(
					`{${'environmentId'}}`,
					encodeURIComponent(String(requestParameters.environmentId))
				),
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async switchEnvironment(
		requestParameters: SwitchEnvironmentRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.switchEnvironmentRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async updateAccountMemberSettingsRaw(
		requestParameters: UpdateAccountMemberSettingsOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.userName === null ||
			requestParameters.userName === undefined
		) {
			throw new runtime.RequiredError(
				'userName',
				'Required parameter requestParameters.userName was null or undefined when calling updateAccountMemberSettings.'
			);
		}

		if (
			requestParameters.updateAccountMemberSettingsRequest === null ||
			requestParameters.updateAccountMemberSettingsRequest === undefined
		) {
			throw new runtime.RequiredError(
				'updateAccountMemberSettingsRequest',
				'Required parameter requestParameters.updateAccountMemberSettingsRequest was null or undefined when calling updateAccountMemberSettings.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/account_member/{userName}/settings/`.replace(
					`{${'userName'}}`,
					encodeURIComponent(String(requestParameters.userName))
				),
				method: 'PATCH',
				headers: headerParameters,
				query: queryParameters,
				body: UpdateAccountMemberSettingsRequestToJSON(
					requestParameters.updateAccountMemberSettingsRequest
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async updateAccountMemberSettings(
		requestParameters: UpdateAccountMemberSettingsOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.updateAccountMemberSettingsRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async updateAccountSettingsRaw(
		requestParameters: UpdateAccountSettingsOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.updateAccountSettingsRequest === null ||
			requestParameters.updateAccountSettingsRequest === undefined
		) {
			throw new runtime.RequiredError(
				'updateAccountSettingsRequest',
				'Required parameter requestParameters.updateAccountSettingsRequest was null or undefined when calling updateAccountSettings.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/account/settings`,
				method: 'PATCH',
				headers: headerParameters,
				query: queryParameters,
				body: UpdateAccountSettingsRequestToJSON(
					requestParameters.updateAccountSettingsRequest
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async updateAccountSettings(
		requestParameters: UpdateAccountSettingsOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.updateAccountSettingsRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async updateCustomDataByIdRaw(
		requestParameters: UpdateCustomDataByIdRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.sessionId === null ||
			requestParameters.sessionId === undefined
		) {
			throw new runtime.RequiredError(
				'sessionId',
				'Required parameter requestParameters.sessionId was null or undefined when calling updateCustomDataById.'
			);
		}

		if (
			requestParameters.updatedCustomDataRequest === null ||
			requestParameters.updatedCustomDataRequest === undefined
		) {
			throw new runtime.RequiredError(
				'updatedCustomDataRequest',
				'Required parameter requestParameters.updatedCustomDataRequest was null or undefined when calling updateCustomDataById.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/access_session/{sessionId}/json`.replace(
					`{${'sessionId'}}`,
					encodeURIComponent(String(requestParameters.sessionId))
				),
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
				body: UpdatedCustomDataRequestToJSON(
					requestParameters.updatedCustomDataRequest
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async updateCustomDataById(
		requestParameters: UpdateCustomDataByIdRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.updateCustomDataByIdRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async updateCustomerByIdRaw(
		requestParameters: UpdateCustomerByIdRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.customerId === null ||
			requestParameters.customerId === undefined
		) {
			throw new runtime.RequiredError(
				'customerId',
				'Required parameter requestParameters.customerId was null or undefined when calling updateCustomerById.'
			);
		}

		if (
			requestParameters.updateCustomerRequest === null ||
			requestParameters.updateCustomerRequest === undefined
		) {
			throw new runtime.RequiredError(
				'updateCustomerRequest',
				'Required parameter requestParameters.updateCustomerRequest was null or undefined when calling updateCustomerById.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/customer/{customerId}`.replace(
					`{${'customerId'}}`,
					encodeURIComponent(String(requestParameters.customerId))
				),
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
				body: UpdateCustomerRequestToJSON(
					requestParameters.updateCustomerRequest
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async updateCustomerById(
		requestParameters: UpdateCustomerByIdRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.updateCustomerByIdRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async updateCustomerSettingsRaw(
		requestParameters: UpdateCustomerSettingsOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.customerId === null ||
			requestParameters.customerId === undefined
		) {
			throw new runtime.RequiredError(
				'customerId',
				'Required parameter requestParameters.customerId was null or undefined when calling updateCustomerSettings.'
			);
		}

		if (
			requestParameters.updateCustomerSettingsRequest === null ||
			requestParameters.updateCustomerSettingsRequest === undefined
		) {
			throw new runtime.RequiredError(
				'updateCustomerSettingsRequest',
				'Required parameter requestParameters.updateCustomerSettingsRequest was null or undefined when calling updateCustomerSettings.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/customer/{customerId}/settings`.replace(
					`{${'customerId'}}`,
					encodeURIComponent(String(requestParameters.customerId))
				),
				method: 'PATCH',
				headers: headerParameters,
				query: queryParameters,
				body: UpdateCustomerSettingsRequestToJSON(
					requestParameters.updateCustomerSettingsRequest
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async updateCustomerSettings(
		requestParameters: UpdateCustomerSettingsOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.updateCustomerSettingsRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async updateEnvironmentByIdRaw(
		requestParameters: UpdateEnvironmentByIdRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.environmentId === null ||
			requestParameters.environmentId === undefined
		) {
			throw new runtime.RequiredError(
				'environmentId',
				'Required parameter requestParameters.environmentId was null or undefined when calling updateEnvironmentById.'
			);
		}

		if (
			requestParameters.updateEnvironmentRequest === null ||
			requestParameters.updateEnvironmentRequest === undefined
		) {
			throw new runtime.RequiredError(
				'updateEnvironmentRequest',
				'Required parameter requestParameters.updateEnvironmentRequest was null or undefined when calling updateEnvironmentById.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/environment/{environmentId}`.replace(
					`{${'environmentId'}}`,
					encodeURIComponent(String(requestParameters.environmentId))
				),
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
				body: UpdateEnvironmentRequestToJSON(
					requestParameters.updateEnvironmentRequest
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async updateEnvironmentById(
		requestParameters: UpdateEnvironmentByIdRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.updateEnvironmentByIdRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async updateEnvironmentSettingsRaw(
		requestParameters: UpdateEnvironmentSettingsOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.environmentId === null ||
			requestParameters.environmentId === undefined
		) {
			throw new runtime.RequiredError(
				'environmentId',
				'Required parameter requestParameters.environmentId was null or undefined when calling updateEnvironmentSettings.'
			);
		}

		if (
			requestParameters.updateEnvironmentSettingsRequest === null ||
			requestParameters.updateEnvironmentSettingsRequest === undefined
		) {
			throw new runtime.RequiredError(
				'updateEnvironmentSettingsRequest',
				'Required parameter requestParameters.updateEnvironmentSettingsRequest was null or undefined when calling updateEnvironmentSettings.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/environment/{environmentId}/settings/`.replace(
					`{${'environmentId'}}`,
					encodeURIComponent(String(requestParameters.environmentId))
				),
				method: 'PATCH',
				headers: headerParameters,
				query: queryParameters,
				body: UpdateEnvironmentSettingsRequestToJSON(
					requestParameters.updateEnvironmentSettingsRequest
				),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async updateEnvironmentSettings(
		requestParameters: UpdateEnvironmentSettingsOperationRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.updateEnvironmentSettingsRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async updateUserByIdRaw(
		requestParameters: UpdateUserByIdRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<void>> {
		if (
			requestParameters.userId === null ||
			requestParameters.userId === undefined
		) {
			throw new runtime.RequiredError(
				'userId',
				'Required parameter requestParameters.userId was null or undefined when calling updateUserById.'
			);
		}

		if (
			requestParameters.updateUserRequest === null ||
			requestParameters.updateUserRequest === undefined
		) {
			throw new runtime.RequiredError(
				'updateUserRequest',
				'Required parameter requestParameters.updateUserRequest was null or undefined when calling updateUserById.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		headerParameters['Content-Type'] = 'application/json';

		const response = await this.request(
			{
				path: `/user/{userId}`.replace(
					`{${'userId'}}`,
					encodeURIComponent(String(requestParameters.userId))
				),
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
				body: UpdateUserRequestToJSON(requestParameters.updateUserRequest),
			},
			initOverrides
		);

		return new runtime.VoidApiResponse(response);
	}

	/**
	 */
	async updateUserById(
		requestParameters: UpdateUserByIdRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<void> {
		await this.updateUserByIdRaw(requestParameters, initOverrides);
	}

	/**
	 */
	async uploadFaceRegistrationImageRaw(
		requestParameters: UploadFaceRegistrationImageRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<runtime.ApiResponse<UploadFaceRegistrationImageResponse>> {
		if (
			requestParameters.registrationId === null ||
			requestParameters.registrationId === undefined
		) {
			throw new runtime.RequiredError(
				'registrationId',
				'Required parameter requestParameters.registrationId was null or undefined when calling uploadFaceRegistrationImage.'
			);
		}

		if (
			requestParameters.file === null ||
			requestParameters.file === undefined
		) {
			throw new runtime.RequiredError(
				'file',
				'Required parameter requestParameters.file was null or undefined when calling uploadFaceRegistrationImage.'
			);
		}

		const queryParameters: any = {};

		const headerParameters: runtime.HTTPHeaders = {};

		const consumes: runtime.Consume[] = [
			{ contentType: 'multipart/form-data' },
		];
		// @ts-ignore: canConsumeForm may be unused
		const canConsumeForm = runtime.canConsumeForm(consumes);

		let formParams: { append(param: string, value: any): any };
		let useForm = false;
		// use FormData to transmit files using content-type "multipart/form-data"
		useForm = canConsumeForm;
		if (useForm) {
			formParams = new FormData();
		} else {
			formParams = new URLSearchParams();
		}

		if (requestParameters.file !== undefined) {
			formParams.append('file', requestParameters.file as any);
		}

		const response = await this.request(
			{
				path: `/face/registration/{registrationId}/image`.replace(
					`{${'registrationId'}}`,
					encodeURIComponent(String(requestParameters.registrationId))
				),
				method: 'POST',
				headers: headerParameters,
				query: queryParameters,
				body: formParams,
			},
			initOverrides
		);

		return new runtime.JSONApiResponse(response, (jsonValue) =>
			UploadFaceRegistrationImageResponseFromJSON(jsonValue)
		);
	}

	/**
	 */
	async uploadFaceRegistrationImage(
		requestParameters: UploadFaceRegistrationImageRequest,
		initOverrides?: RequestInit | runtime.InitOverrideFunction
	): Promise<UploadFaceRegistrationImageResponse> {
		const response = await this.uploadFaceRegistrationImageRaw(
			requestParameters,
			initOverrides
		);
		return await response.value();
	}
}
