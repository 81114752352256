import { type FormInstance, Modal } from 'antd';
import React from 'react';

import {
	type CreateEnvironmentRequest,
	type EnvironmentsResponseEnvironment,
} from '../../../../tools/openapi';
import EnvironmentsCreateAndUpdateForm from './EnvironmentsCreateAndUpdateForm';
import useMgmtApiWrapper from '../../../../hooks/openapi/useMgmtApiWrapper';

interface Props {
	form: FormInstance;
	modalVisible: boolean;
	isCreate: boolean;
	setModalVisible: (e: boolean) => void;
	fetchEnvironments: () => void;
}

const EnvironmentsCreateAndUpdateModal: React.FC<Props> = (props: Props) => {
	const mgmtApiWrapper = useMgmtApiWrapper();

	const createEnvironment = (environment: CreateEnvironmentRequest): void => {
		void mgmtApiWrapper
			.createEnvironment({ createEnvironmentRequest: environment })
			.then(() => {
				props.fetchEnvironments();
			})
	};

	const updateEnvironment = (id: string, name: string): void => {
		void mgmtApiWrapper
			.updateEnvironmentById({
				environmentId: id,
				updateEnvironmentRequest: { name },
			})
			.then(() => {
				props.fetchEnvironments();
			})
	};

	const handleOk = (): void => {
		props.form.submit();
	};

	const handleCancel = (): void => {
		props.setModalVisible(false);
	};

	const onFinish = (values: EnvironmentsResponseEnvironment): void => {
		const { id, name } = values;
		if (props.isCreate) {
			createEnvironment({ name });
		} else {
			updateEnvironment(id, name);
		}
		props.setModalVisible(false);
	};

	return (
		<Modal
			className="create-modal"
			title={props.isCreate ? 'Create Environment' : 'Edit Environment'}
			open={props.modalVisible}
			onOk={handleOk}
			onCancel={handleCancel}
			centered
		>
			<EnvironmentsCreateAndUpdateForm form={props.form} onFinish={onFinish} />
		</Modal>
	);
};

export default React.memo(EnvironmentsCreateAndUpdateModal);
