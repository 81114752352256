/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiKey } from './ApiKey';
import {
    ApiKeyFromJSON,
    ApiKeyFromJSONTyped,
    ApiKeyToJSON,
} from './ApiKey';

/**
 * 
 * @export
 * @interface CreateApiKeyResponse
 */
export interface CreateApiKeyResponse {
    /**
     * 
     * @type {ApiKey}
     * @memberof CreateApiKeyResponse
     */
    apiKey: ApiKey;
}

/**
 * Check if a given object implements the CreateApiKeyResponse interface.
 */
export function instanceOfCreateApiKeyResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "apiKey" in value;

    return isInstance;
}

export function CreateApiKeyResponseFromJSON(json: any): CreateApiKeyResponse {
    return CreateApiKeyResponseFromJSONTyped(json, false);
}

export function CreateApiKeyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateApiKeyResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apiKey': ApiKeyFromJSON(json['apiKey']),
    };
}

export function CreateApiKeyResponseToJSON(value?: CreateApiKeyResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apiKey': ApiKeyToJSON(value.apiKey),
    };
}

