import React from 'react';
import DropdownButton from '../../../shared/DropdownButton';
import { DeleteOutlined } from '@ant-design/icons';
import { type MenuProps } from 'antd';
import {
	setCurToDelete,
	setModalOnCreate,
	setModalVisible,
} from '../../../store/accessKeyReducer';
import { useAppDispatch } from '../../../store/hooks';

interface Props {
	toDelete: string;
	fetchData: () => void;
}

const EnvironmentsDropdownButton: React.FC<Props> = (props: Props) => {
	const dispatch = useAppDispatch();

	const items: MenuProps['items'] = [
		{
			label: (
				<a
					onClick={() => {
						dispatch(setModalOnCreate(false));
						dispatch(setModalVisible(true));
						dispatch(setCurToDelete(props.toDelete));
					}}
				>
					Delete
				</a>
			),
			key: '1',
			danger: true,
			icon: <DeleteOutlined />,
		},
	];

	return <DropdownButton menu={items} />;
};

export default React.memo(EnvironmentsDropdownButton);
