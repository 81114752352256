/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PinAccessRequest
 */
export interface PinAccessRequest {
    /**
     * 
     * @type {string}
     * @memberof PinAccessRequest
     */
    sessionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PinAccessRequest
     */
    customerId: string;
    /**
     * 
     * @type {string}
     * @memberof PinAccessRequest
     */
    environmentId: string;
    /**
     * 
     * @type {string}
     * @memberof PinAccessRequest
     */
    pin: string;
    /**
     * 
     * @type {string}
     * @memberof PinAccessRequest
     */
    customJsonData?: string | null;
}

/**
 * Check if a given object implements the PinAccessRequest interface.
 */
export function instanceOfPinAccessRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "customerId" in value;
    isInstance = isInstance && "environmentId" in value;
    isInstance = isInstance && "pin" in value;

    return isInstance;
}

export function PinAccessRequestFromJSON(json: any): PinAccessRequest {
    return PinAccessRequestFromJSONTyped(json, false);
}

export function PinAccessRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PinAccessRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessionId': !exists(json, 'sessionId') ? undefined : json['sessionId'],
        'customerId': json['customerId'],
        'environmentId': json['environmentId'],
        'pin': json['pin'],
        'customJsonData': !exists(json, 'customJsonData') ? undefined : json['customJsonData'],
    };
}

export function PinAccessRequestToJSON(value?: PinAccessRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sessionId': value.sessionId,
        'customerId': value.customerId,
        'environmentId': value.environmentId,
        'pin': value.pin,
        'customJsonData': value.customJsonData,
    };
}

