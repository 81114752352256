import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import store from './store/store';
import * as Sentry from '@sentry/react';

Sentry.init({
	dsn: 'https://3caad03b005146b3bf2c30d581d8c211@o4504812846710784.ingest.sentry.io/4504958819631104',
	integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	enabled: process.env.NODE_ENV === 'production',
});

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<Auth0Provider
		domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
		clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
		authorizationParams={{
			redirect_uri: window.location.origin,
			audience: process.env.REACT_APP_AUTH0_AUDIENCE,
			scope: process.env.REACT_APP_AUTH0_SCOPE,
		}}
	>
		<Provider store={store}>
			<Router>
				<App />
			</Router>
		</Provider>
	</Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/**
 * 1. use single api client to build a custom hook, override headers with auth0 token
 * 2. useMemo, useCallback
 * 3. duplicated lines refactor
 * 4. remove div wrapper if there's only one component
 * 5. component has its own style file
 *
 * useTransition
 */
