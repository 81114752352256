import { Row, Col, Switch } from 'antd';
import React from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import { type RootState } from '../../../../../../store/store';
import { type UpdateCustomerSettingsRequest } from '../../../../../../tools/openapi';
import { QuestionCircleTwoTone } from '@ant-design/icons';

interface Props {
	updateSettings: <T extends keyof UpdateCustomerSettingsRequest>(
		target: T,
		value: UpdateCustomerSettingsRequest[T]
	) => void;
}

const CustomerAutoTakingPhoto: React.FC<Props> = (props) => {
	const autoTakingPhotoOnIdentification = useAppSelector(
		(state: RootState) => state.customerDetail.autoTakingPhotoOnIdentification
	);

	return (
		<Row>
			<Col span={22}>
				Auto Taking Photo on Identification:{' '}
				<QuestionCircleTwoTone title="This feature enables automatic photographing during each identification step." />
			</Col>
			<Col span={2} push={12}>
				<Switch
					checked={autoTakingPhotoOnIdentification}
					onChange={(checked) => {
						props.updateSettings('autoTakingPhotoOnIdentification', checked);
					}}
				/>
			</Col>
		</Row>
	);
};
export default React.memo(CustomerAutoTakingPhoto);
