import React from 'react';
import { Tabs } from 'antd';
import { type Tab } from 'rc-tabs/lib/interface';

interface Props {
	items: Tab[];
}
const InformationTab: React.FC<Props> = (props: Props) => {
	return <Tabs defaultActiveKey="1" items={props.items} />;
};

export default React.memo(InformationTab);
