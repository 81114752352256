import React, { useState } from 'react';
import DeleteConfirmation from '../../../../shared/DeleteConfirmation';

interface Props {
	id: string;
	name: string;
	visible: boolean;
	setIsDeletionVisible: (e: boolean) => void;
	handleDelete: (e: string) => void;
}

const CustomerDeleteConfirmationModal: React.FC<Props> = (props: Props) => {
	const [inputValue, setInputValue] = useState('');

	return (
		<DeleteConfirmation
			title="Are you sure? "
			message={`This action will permanently delete the customer. It cannot be undone. `}
			messageAdd={`Please enter the customer name "${props.name}" to confirm.`}
			placeholder={'Type the customer name to confirm.'}
			visible={props.visible}
			deleDisabled={inputValue !== props.name}
			centered={true}
			onCancel={() => {
				props.setIsDeletionVisible(false);
			}}
			onConfirm={() => {
				props.handleDelete(props.id);
			}}
			onInputChange={(value) => {
				setInputValue(value);
			}}
		/>
	);
};

export default React.memo(CustomerDeleteConfirmationModal);
