import { Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams, type Params } from 'react-router-dom';
import '../AccessSessionDetail.scss';
import { type GetSessionDetailsWithStepsRequest } from '../../../../tools/openapi';
import AccessSessionDescription from './AccessSessionDescription';
import { useDispatch } from 'react-redux';
import {
	resetCurrentSessionDetailWithSteps,
	setCurrentSessionDetailWithSteps,
} from '../../../../store/accessSessionDetailReducer';
import AccessSessionSteps from './AccessSessionSteps';
import AccessSessionCustomData from './AccessSessionCustomData';
import useMgmtApiWrapper from '../../../../hooks/openapi/useMgmtApiWrapper';

interface RouteParams extends Params {
	sessionId: string;
}

const AccessSessionInformationTab: React.FC = () => {
	const { sessionId } = useParams<RouteParams>();
	const mgmtApiWrapper = useMgmtApiWrapper();
	const dispatch = useDispatch();

	const [sessionInfoOnLoading, setSessionInfoOnLoading] = useState(true);

	const fetchAccessSessionWithSteps = (): void => {
		const requestBody: GetSessionDetailsWithStepsRequest = {
			sessionId: sessionId ?? '',
		};
		void mgmtApiWrapper.getSessionDetailsWithSteps(requestBody).then((r) => {
			setSessionInfoOnLoading(false);
			if (r !== undefined) {
				dispatch(setCurrentSessionDetailWithSteps(r));
			}
		});
	};

	useEffect(() => {
		dispatch(resetCurrentSessionDetailWithSteps());
		fetchAccessSessionWithSteps();
	}, [sessionId]);

	return (
		<>
			{sessionInfoOnLoading ? (
				<Spin className="loading flex-all-center" size="large" />
			) : (
				<Space
					className="custom-detail-space"
					size="large"
					direction="vertical"
				>
					<AccessSessionDescription />
					<AccessSessionSteps />
					<AccessSessionCustomData />
				</Space>
			)}
		</>
	);
};

export default React.memo(AccessSessionInformationTab);
