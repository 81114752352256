import React from 'react';
import DeleteConfirmation from '../../../../shared/DeleteConfirmation';

interface Props {
	id: string;
	visible: boolean;
	setIsDeletionVisible: (e: boolean) => void;
	handleDelete: (e: string) => void;
}

const UserDeleteConfirmationModal: React.FC<Props> = (props: Props) => {
	return (
		<DeleteConfirmation
			title="Are you sure? "
			message={`This action will permanently delete the user. It cannot be undone. `}
			messageAdd={`Are you absolutely sure?`}
			visible={props.visible}
			centered={true}
			inputDisplay={'none'}
			onCancel={() => {
				props.setIsDeletionVisible(false);
			}}
			onConfirm={() => {
				props.handleDelete(props.id);
			}}
		/>
	);
};

export default React.memo(UserDeleteConfirmationModal);
