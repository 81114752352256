/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PinConfig } from './PinConfig';
import {
    PinConfigFromJSON,
    PinConfigFromJSONTyped,
    PinConfigToJSON,
} from './PinConfig';

/**
 * 
 * @export
 * @interface UpdateCustomerSettingsRequest
 */
export interface UpdateCustomerSettingsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCustomerSettingsRequest
     */
    allowManualEntry?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCustomerSettingsRequest
     */
    allowFaceRegistrationOnClient?: boolean | null;
    /**
     * 
     * @type {PinConfig}
     * @memberof UpdateCustomerSettingsRequest
     */
    pinCodeDigit?: PinConfig | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCustomerSettingsRequest
     */
    pinAttemptLimit?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCustomerSettingsRequest
     */
    requireApprovalOnFaceRegistration?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCustomerSettingsRequest
     */
    autoTakingPhotoOnIdentification?: boolean | null;
}

/**
 * Check if a given object implements the UpdateCustomerSettingsRequest interface.
 */
export function instanceOfUpdateCustomerSettingsRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateCustomerSettingsRequestFromJSON(json: any): UpdateCustomerSettingsRequest {
    return UpdateCustomerSettingsRequestFromJSONTyped(json, false);
}

export function UpdateCustomerSettingsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCustomerSettingsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowManualEntry': !exists(json, 'allowManualEntry') ? undefined : json['allowManualEntry'],
        'allowFaceRegistrationOnClient': !exists(json, 'allowFaceRegistrationOnClient') ? undefined : json['allowFaceRegistrationOnClient'],
        'pinCodeDigit': !exists(json, 'pinCodeDigit') ? undefined : PinConfigFromJSON(json['pinCodeDigit']),
        'pinAttemptLimit': !exists(json, 'pinAttemptLimit') ? undefined : json['pinAttemptLimit'],
        'requireApprovalOnFaceRegistration': !exists(json, 'requireApprovalOnFaceRegistration') ? undefined : json['requireApprovalOnFaceRegistration'],
        'autoTakingPhotoOnIdentification': !exists(json, 'autoTakingPhotoOnIdentification') ? undefined : json['autoTakingPhotoOnIdentification'],
    };
}

export function UpdateCustomerSettingsRequestToJSON(value?: UpdateCustomerSettingsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowManualEntry': value.allowManualEntry,
        'allowFaceRegistrationOnClient': value.allowFaceRegistrationOnClient,
        'pinCodeDigit': PinConfigToJSON(value.pinCodeDigit),
        'pinAttemptLimit': value.pinAttemptLimit,
        'requireApprovalOnFaceRegistration': value.requireApprovalOnFaceRegistration,
        'autoTakingPhotoOnIdentification': value.autoTakingPhotoOnIdentification,
    };
}

