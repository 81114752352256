/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccessStepAttemptCountResponse } from './AccessStepAttemptCountResponse';
import {
    AccessStepAttemptCountResponseFromJSON,
    AccessStepAttemptCountResponseFromJSONTyped,
    AccessStepAttemptCountResponseToJSON,
} from './AccessStepAttemptCountResponse';

/**
 * 
 * @export
 * @interface AccessStepAttemptCountsResponse
 */
export interface AccessStepAttemptCountsResponse {
    /**
     * 
     * @type {Array<AccessStepAttemptCountResponse>}
     * @memberof AccessStepAttemptCountsResponse
     */
    accessStepAttemptCounts: Array<AccessStepAttemptCountResponse>;
}

/**
 * Check if a given object implements the AccessStepAttemptCountsResponse interface.
 */
export function instanceOfAccessStepAttemptCountsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accessStepAttemptCounts" in value;

    return isInstance;
}

export function AccessStepAttemptCountsResponseFromJSON(json: any): AccessStepAttemptCountsResponse {
    return AccessStepAttemptCountsResponseFromJSONTyped(json, false);
}

export function AccessStepAttemptCountsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessStepAttemptCountsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessStepAttemptCounts': ((json['accessStepAttemptCounts'] as Array<any>).map(AccessStepAttemptCountResponseFromJSON)),
    };
}

export function AccessStepAttemptCountsResponseToJSON(value?: AccessStepAttemptCountsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessStepAttemptCounts': ((value.accessStepAttemptCounts as Array<any>).map(AccessStepAttemptCountResponseToJSON)),
    };
}

