import { Radio } from 'antd';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { type RootState } from '../../../../store/store';
import { setActiveOnly, setPageNumber } from '../../../../store/usersReducer';

const UserActiveOnlyRadioButton: React.FC = () => {
	const dispatch = useAppDispatch();

	const activeOnly = useAppSelector(
		(state: RootState) => state.user.activeOnly
	);

	return (
		<div style={{ marginLeft: '16px' }}>
			<Radio.Group
				value={activeOnly}
				onChange={(e) => {
					dispatch(setActiveOnly(e.target.value));
					dispatch(setPageNumber(1));
				}}
				style={{ display: 'flex', alignItems: 'center' }}
			>
				<Radio value={true} style={{ marginRight: '1rem' }}>
					<span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
						Active Only
					</span>
				</Radio>
				<Radio value={false}>
					<span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
						Inactive Only
					</span>
				</Radio>
			</Radio.Group>
		</div>
	);
};

export default React.memo(UserActiveOnlyRadioButton);
