import React from 'react';
import { Space } from 'antd';
import Title from 'antd/es/typography/Title';
import './AccessSession.scss';
import AccessSessionsTable from './accessSessionsTable/AccessSessionsTable';
import AccessSessionsSearchBar from './accessSessionsHeader/AccessSessionsSearchBar';

const AccessSessions: React.FC = () => {
	return (
		<div>
			<div
				style={{
					display: 'flex',
					alignItems: 'baseline',
					justifyContent: 'space-between',
				}}
			>
				<Title level={3}>Access Sessions</Title>
			</div>
			<Space direction="vertical" className="custom-table-space" size="large">
				<AccessSessionsSearchBar />
				<AccessSessionsTable />
			</Space>
		</div>
	);
};

export default React.memo(AccessSessions);
