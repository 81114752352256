import DropdownButton from '../../../../../../shared/DropdownButton';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
	type ApproveFaceRegistrationRequest,
	type RejectFaceRegistrationRequest,
	type SubmittedRegistrationResponse,
} from '../../../../../../tools/openapi';
import { type MenuProps } from 'antd';
import React from 'react';
import useMgmtApiWrapper from '../../../../../../hooks/openapi/useMgmtApiWrapper';

interface Props {
	dataSubmitted: SubmittedRegistrationResponse | undefined;
	fetchFaceRegistrations: () => void;
}

const UserFaceSubmittedDropdown: React.FC<Props> = ({
	dataSubmitted,
	fetchFaceRegistrations,
}) => {
	const mgmtApiWrapper = useMgmtApiWrapper();
	const handleApprove = (): void => {
		const requestBody: ApproveFaceRegistrationRequest = {
			registrationId: dataSubmitted?.registration?.id as string,
		};
		void mgmtApiWrapper.approveFaceRegistration(requestBody).then((r) => {
			fetchFaceRegistrations();
		});
	};

	const handleReject = (): void => {
		const requestBody: RejectFaceRegistrationRequest = {
			registrationId: dataSubmitted?.registration?.id as string,
		};
		void mgmtApiWrapper.rejectFaceRegistration(requestBody).then((r) => {
			fetchFaceRegistrations();
		});
	};

	const submittedDropdown: MenuProps['items'] = [
		{
			label: <a onClick={handleApprove}>Approve</a>,
			key: '0',
			icon: <CheckOutlined />,
		},
		{
			label: <a onClick={handleReject}>Reject</a>,
			key: '1',
			icon: <CloseOutlined />,
			danger: true,
		},
	];
	return <DropdownButton menu={submittedDropdown} />;
};
export default React.memo(UserFaceSubmittedDropdown);
