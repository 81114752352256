import { Form, Input, type FormInstance } from 'antd';
import React from 'react';
import UserActiveOnlyAutoComplete from './UserCreateModalAutoComplete';

interface Props {
	form: FormInstance;
}

const UserCreateFrom: React.FC<Props> = (props: Props) => {
	return (
		<Form
			form={props.form}
			name="basic"
			labelCol={{ span: 6 }}
			wrapperCol={{ span: 16 }}
			onValuesChange={(changedValues, allValues) => {
				if ('firstName' in changedValues || 'lastName' in changedValues) {
					const { firstName = '', lastName = '' } = allValues;
					const name = `${firstName as string}` + ` ` + `${lastName as string}`;
					props.form.setFieldsValue({ name });
				}
			}}
		>
			<Form.Item hidden name="customerId"></Form.Item>
			<Form.Item
				label="Customer"
				name="customerName"
				tooltip="The customer which the user belongs to."
				rules={[{ required: true, message: 'Customer is required.' }]}
			>
				<UserActiveOnlyAutoComplete form={props.form} />
			</Form.Item>
			<Form.Item
				label="First Name"
				name="firstName"
				rules={[
					{
						required: false,
					},
				]}
			>
				<Input />
			</Form.Item>
			<Form.Item
				label="Last Name"
				name="lastName"
				rules={[
					{
						required: false,
					},
				]}
			>
				<Input />
			</Form.Item>
			<Form.Item
				label="Display Name"
				name="name"
				tooltip="Display name is usually the full name of the workforce user (first and last name), is searchable and appears in the users list."
				rules={[
					{
						required: false,
					},
				]}
			>
				<Input />
			</Form.Item>
			<Form.Item
				label="Email"
				name="email"
				rules={[
					{
						required: false,
					},
				]}
			>
				<Input />
			</Form.Item>
			<Form.Item
				label="Phone"
				name="phone"
				rules={[
					{
						required: false,
					},
				]}
			>
				<Input />
			</Form.Item>
		</Form>
	);
};

export default React.memo(UserCreateFrom);
