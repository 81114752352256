import produce from 'immer';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type User } from '../tools/openapi';

const initialState = {
	user: {},
};

const userDetailSlice = createSlice({
	name: 'userDetail',
	initialState,
	reducers: {
		setCurrentUserDetail: (state, action: PayloadAction<User>) => {
			return produce(state, (draftState) => {
				draftState.user = action.payload;
			});
		},
		resetCurrentUserDetail: (state) => {
			return produce(state, (draftState) => {
				draftState.user = {};
			});
		},
	},
});

export const { setCurrentUserDetail, resetCurrentUserDetail } =
	userDetailSlice.actions;
export default userDetailSlice.reducer;
