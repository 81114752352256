/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccessFactor } from './AccessFactor';
import {
    AccessFactorFromJSON,
    AccessFactorFromJSONTyped,
    AccessFactorToJSON,
} from './AccessFactor';

/**
 * 
 * @export
 * @interface PinAccessResponseNoMatchInstruction
 */
export interface PinAccessResponseNoMatchInstruction {
    /**
     * 
     * @type {boolean}
     * @memberof PinAccessResponseNoMatchInstruction
     */
    concluded: boolean;
    /**
     * 
     * @type {number}
     * @memberof PinAccessResponseNoMatchInstruction
     */
    attemptsLeft: number;
    /**
     * 
     * @type {Array<AccessFactor>}
     * @memberof PinAccessResponseNoMatchInstruction
     */
    fallbackSteps: Array<AccessFactor>;
}

/**
 * Check if a given object implements the PinAccessResponseNoMatchInstruction interface.
 */
export function instanceOfPinAccessResponseNoMatchInstruction(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "concluded" in value;
    isInstance = isInstance && "attemptsLeft" in value;
    isInstance = isInstance && "fallbackSteps" in value;

    return isInstance;
}

export function PinAccessResponseNoMatchInstructionFromJSON(json: any): PinAccessResponseNoMatchInstruction {
    return PinAccessResponseNoMatchInstructionFromJSONTyped(json, false);
}

export function PinAccessResponseNoMatchInstructionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PinAccessResponseNoMatchInstruction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'concluded': json['concluded'],
        'attemptsLeft': json['attemptsLeft'],
        'fallbackSteps': ((json['fallbackSteps'] as Array<any>).map(AccessFactorFromJSON)),
    };
}

export function PinAccessResponseNoMatchInstructionToJSON(value?: PinAccessResponseNoMatchInstruction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'concluded': value.concluded,
        'attemptsLeft': value.attemptsLeft,
        'fallbackSteps': ((value.fallbackSteps as Array<any>).map(AccessFactorToJSON)),
    };
}

