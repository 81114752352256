import { Button } from 'antd';
import React, { useState } from 'react';
import CustomerCreateAndUpdateModal from './CustomerCreateAndUpdateModal';

const CustomerCreateButton: React.FC = () => {
	const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

	return (
		<>
			<Button
				type="primary"
				onClick={() => {
					setIsCreateModalVisible(true);
				}}
			>
				Create Customer
			</Button>
			<CustomerCreateAndUpdateModal
				isCreateModalVisible={isCreateModalVisible}
				setIsCreateModalVisible={setIsCreateModalVisible}
			/>
		</>
	);
};

export default React.memo(CustomerCreateButton);
