/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteCustomersRequest
 */
export interface DeleteCustomersRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteCustomersRequest
     */
    environmentId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteCustomersRequest
     */
    customerIds: Array<string>;
}

/**
 * Check if a given object implements the DeleteCustomersRequest interface.
 */
export function instanceOfDeleteCustomersRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "environmentId" in value;
    isInstance = isInstance && "customerIds" in value;

    return isInstance;
}

export function DeleteCustomersRequestFromJSON(json: any): DeleteCustomersRequest {
    return DeleteCustomersRequestFromJSONTyped(json, false);
}

export function DeleteCustomersRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteCustomersRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'environmentId': json['environmentId'],
        'customerIds': json['customerIds'],
    };
}

export function DeleteCustomersRequestToJSON(value?: DeleteCustomersRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'environmentId': value.environmentId,
        'customerIds': value.customerIds,
    };
}

