import React from 'react';
import { Form, Input, type FormInstance } from 'antd';
import '../AccountMembers.scss';

interface Props {
	form: FormInstance;
}
const AccountMemberCreateModalForm: React.FC<Props> = (props: Props) => {
	return (
		<>
			<Form form={props.form}>
				<Form.Item name="name" label="Name" rules={[{ required: true }]}>
					<Input placeholder="Enter name" />
				</Form.Item>
				<Form.Item name="email" label="Email" rules={[{ required: true }]}>
					<Input placeholder="Enter email" />
				</Form.Item>
			</Form>
		</>
	);
};

export default React.memo(AccountMemberCreateModalForm);
