import { Space, Avatar } from 'antd';
import randomColor from 'randomcolor';
import React from 'react';
import { type UsersResponseUser } from '../../../../tools/openapi';

interface Props {
	record: UsersResponseUser;
}

const UserNameAvatar: React.FC<Props> = (props: Props) => {
	return (
		<Space className={props.record.active ? '' : 'inactive-user'}>
			<Avatar
				style={{
					backgroundColor: randomColor({
						luminosity: 'dark',
						seed: props.record.initials,
					}),
				}}
			>
				{props.record.initials}
			</Avatar>
			{props.record.name}
		</Space>
	);
};

export default React.memo(UserNameAvatar);
