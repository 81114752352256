import { configureStore } from '@reduxjs/toolkit';
import startReducer from './startReducer';
import environmentReducer from './environmentReducer';
import usersReducer from './usersReducer';
import customerReducer from './customerReducer';
import accountMemberReducer from './accountMemberReducer';
import accessSessionReducer from './accessSessionReducer';
import accessKeyReducer from './accessKeyReducer';
import customerDetailReducer from './customerDetailReducer';
import userDetailReducer from './userDetailReducer';
import accessSessionDetailReducer from './accessSessionDetailReducer';
import environmentDetailReducer from './environmentDetailReducer';

const store = configureStore({
	reducer: {
		environment: environmentReducer,
		environmentDetail: environmentDetailReducer,
		startState: startReducer,
		user: usersReducer,
		userDetail: userDetailReducer,
		customer: customerReducer,
		customerDetail: customerDetailReducer,
		accountMember: accountMemberReducer,
		accessSession: accessSessionReducer,
		accessKey: accessKeyReducer,
		accessSessionDetail: accessSessionDetailReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false, // Disable the serializable check
		}),
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
