/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AccessFactor = {
    Face: 'FACE',
    Qr: 'QR',
    Nfc: 'NFC',
    Pin: 'PIN',
    Manual: 'MANUAL'
} as const;
export type AccessFactor = typeof AccessFactor[keyof typeof AccessFactor];


export function AccessFactorFromJSON(json: any): AccessFactor {
    return AccessFactorFromJSONTyped(json, false);
}

export function AccessFactorFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessFactor {
    return json as AccessFactor;
}

export function AccessFactorToJSON(value?: AccessFactor | null): any {
    return value as any;
}

