import { Space, Tooltip } from 'antd';
import React from 'react';
import Icon, {
	SmileOutlined,
	MehOutlined,
	QrcodeOutlined,
} from '@ant-design/icons';
import { ReactComponent as NFC } from '../../../../shared/svg/NFC.svg';
import '../Users.scss';
import { type UsersResponseUser } from '../../../../tools/openapi';
import { ReactComponent as PIN } from '../../../../shared/svg/dialpad.svg';

interface Props {
	record: UsersResponseUser;
}

const UserRegistrationStatus: React.FC<Props> = (props) => {
	return (
		<Space>
			{props.record.active && props.record.accessFactorStatus.FACE ? (
				<Tooltip title={`FACE ACTIVE`}>
					<SmileOutlined style={{ color: `black` }} />
				</Tooltip>
			) : (
				<Tooltip title={`FACE INACTIVE`}>
					<MehOutlined style={{ color: `lightGray` }} />
				</Tooltip>
			)}
			{props.record.active && props.record.accessFactorStatus.QR ? (
				<Tooltip title={`QR ACTIVE`}>
					<QrcodeOutlined style={{ color: `black` }} />
				</Tooltip>
			) : (
				<Tooltip title={`QR INACTIVE`}>
					<QrcodeOutlined style={{ color: `lightGray` }} />
				</Tooltip>
			)}
			{props.record.active && props.record.accessFactorStatus.NFC ? (
				<Tooltip title={`NFC ACTIVE`}>
					<Icon component={NFC} style={{ color: `black` }} />
				</Tooltip>
			) : (
				<Tooltip title={`NFC INACTIVE`}>
					<Icon component={NFC} style={{ color: `lightGray` }} />
				</Tooltip>
			)}
			{props.record.active && props.record.accessFactorStatus.PIN ? (
				<Tooltip title={`PIN ACTIVE`}>
					<Icon component={PIN} style={{ color: `black` }} />
				</Tooltip>
			) : (
				<Tooltip title={`PIN INACTIVE`}>
					<Icon component={PIN} style={{ color: `lightGray` }} />
				</Tooltip>
			)}
		</Space>
	);
};

export default React.memo(UserRegistrationStatus);
