import React, { Suspense } from 'react';
import { type RouteObject } from 'react-router-dom';

import { Space, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import UserDetail from '../mainContent/users/UserDetails/UserDetail';
import Users from '../mainContent/users/UserList/Users';
import SettingsPage from '../mainContent/settings/SettingsPage';
import EnvironmentSettings from '../mainContent/environment/Environments/Environments';
import Customers from '../mainContent/customers/CustomerList/Customers';
import CustomerDetail from '../mainContent/customers/CustomerDetails/CustomerDetail';
import EnvironmentDetail from '../mainContent/environment/EnvironmentDetails/EnvironmentDetail';
import HomePage from '../login/home/HomePage';
import ActivityPage from '../mainContent/activities/ActivityPage';
import AccessSessions from '../mainContent/accessSessions/AccessSessionsList/AccessSessions';
import AccessSessionDetail from '../mainContent/accessSessions/AccessSessionDetail/AccessSessionDetail';
import AccountMembers from '../mainContent/accountMembers/AccountMembers';
import WebSDK from '../webSDK/WebSDK';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const router: RouteObject[] = [
	{
		path: '/',
		element: (
			<Suspense
				fallback={
					<Space size="large" className="loading flex-all-center">
						<Spin indicator={antIcon} size="large" tip="Loading..." />
					</Space>
				}
			>
				<HomePage />
			</Suspense>
		),
		children: [
			{
				path: '/activity',
				element: <ActivityPage />,
			},
			{
				path: '/user',
				element: <Users />,
			},
			{
				path: '/user/detail/:id',
				element: <UserDetail />,
			},
			{
				path: '/settings',
				element: <SettingsPage />,
			},
			{
				path: '/environment',
				element: <EnvironmentSettings />,
			},
			{
				path: '/environment/detail/:id',
				element: <EnvironmentDetail />,
			},
			{
				path: '/customer',
				element: <Customers />,
			},
			{
				path: '/customer/detail/:id',
				element: <CustomerDetail />,
			},
			{
				path: '/access_session',
				element: <AccessSessions />,
			},
			{
				path: '/access_session/detail/:sessionId',
				element: <AccessSessionDetail />,
			},
			{
				path: '/account_member',
				element: <AccountMembers />,
			},
			/* Single Customer */
			{
				path: '/s/activity',
				element: <ActivityPage />,
			},
			{
				path: '/s/user',
				element: <Users />,
			},
			{
				path: '/s/access_session',
				element: <AccessSessions />,
			},
			{
				path: '/s/settings',
				element: <SettingsPage />,
			},
		],
	},
	{
		path: '/websdk/:customerId/:environmentId',
		element: (
			<Suspense
				fallback={
					<Space size="large" className="loading flex-all-center">
						<Spin indicator={antIcon} size="large" tip="Loading..." />
					</Space>
				}
			>
				<WebSDK />
			</Suspense>
		),
	},
];
