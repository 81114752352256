/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateOnboardingAccountRequest
 */
export interface CreateOnboardingAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOnboardingAccountRequest
     */
    accountName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOnboardingAccountRequest
     */
    ownerName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOnboardingAccountRequest
     */
    idToken: string;
}

/**
 * Check if a given object implements the CreateOnboardingAccountRequest interface.
 */
export function instanceOfCreateOnboardingAccountRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accountName" in value;
    isInstance = isInstance && "ownerName" in value;
    isInstance = isInstance && "idToken" in value;

    return isInstance;
}

export function CreateOnboardingAccountRequestFromJSON(json: any): CreateOnboardingAccountRequest {
    return CreateOnboardingAccountRequestFromJSONTyped(json, false);
}

export function CreateOnboardingAccountRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOnboardingAccountRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accountName': json['accountName'],
        'ownerName': json['ownerName'],
        'idToken': json['idToken'],
    };
}

export function CreateOnboardingAccountRequestToJSON(value?: CreateOnboardingAccountRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accountName': value.accountName,
        'ownerName': value.ownerName,
        'idToken': value.idToken,
    };
}

