import { Modal, Button, Input } from 'antd';
import React, { useEffect, useState } from 'react';

interface DeleteConfirmationProps {
	title: string;
	message: string;
	messageAdd?: string;
	placeholder?: string;
	visible: boolean;
	centered?: boolean;
	inputDisplay?: 'inline' | 'none';
	deleDisabled?: boolean;
	customKey?: string;
	onCancel: () => void;
	onConfirm: () => void;
	onInputChange?: (value: string) => void;
}

const DeleteConfirmation: React.FC<DeleteConfirmationProps> = (
	props: DeleteConfirmationProps
) => {
	const [inputValue, setInputValue] = useState('');
	useEffect(() => {
		if (props.visible) {
			setInputValue('');
		}
	}, [props.visible]);
	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		setInputValue(event.target.value);
		if (props.onInputChange !== undefined) {
			props.onInputChange(event.target.value);
		}
	};
	return (
		<Modal
			title={props.title}
			open={props.visible}
			centered={props.centered}
			onCancel={props.onCancel}
			footer={[
				<Button key="cancel" onClick={props.onCancel}>
					Cancel
				</Button>,
				<Button
					key={props.customKey != null ? props.customKey : 'Delete'}
					disabled={props.deleDisabled}
					danger
					onClick={props.onConfirm}
					type={'primary'}
				>
					{props.customKey != null ? props.customKey : 'Delete'}
				</Button>,
			]}
		>
			{' '}
			<p style={{ lineHeight: '30px' }}>
				{props.message} <br />
				{props.messageAdd}
			</p>
			<Input
				style={{ display: props.inputDisplay }}
				placeholder={props.placeholder}
				value={inputValue}
				onChange={handleInputChange}
			/>
		</Modal>
	);
};

export default DeleteConfirmation;
