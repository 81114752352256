import React from 'react';
import { Space, Typography } from 'antd';
import { type CreateAccountMemberResponse } from '../../../tools/openapi/models/CreateAccountMemberResponse';

const { Text, Paragraph } = Typography;

interface Props {
	accountMember: CreateAccountMemberResponse;
}

const AccountMemberCreateModalSuccess: React.FC<Props> = (props) => {
	return (
		<div className='account-create-success'>
			<Text>
				<Space className="custom-detail-space" align="start" direction="vertical">
					<span>
						<Text strong>{props.accountMember.name}</Text> has been successfully
						added to the team.
					</span>
					<span>
						An email containing a verification link has been sent to{' '}
						<Text strong>{props.accountMember.email}</Text>.
					</span>
					<span>
						Please provide the following temporary password to the new team
						member:
					</span>
				</Space>
			</Text>
			<div className="password-container">
				<Paragraph className="password-text" copyable>
					{props.accountMember.password}
				</Paragraph>
			</div>
		</div>
	);
};

export default AccountMemberCreateModalSuccess;
