import React from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { type RootState } from '../../../../store/store';
import { Descriptions } from 'antd';
import { useNavigate } from 'react-router-dom';
import { type SessionDetailsWithStepsResponse } from '../../../../tools/openapi';

const AccessSessionDescription: React.FC = () => {
	const navigate = useNavigate();
	const {session} = useAppSelector(
		(state: RootState) =>
			state.accessSessionDetail
				.sessionDetailWithSteps as SessionDetailsWithStepsResponse
	);

	const openUserDetail = (id: string): void => {
		navigate(`/user/detail/${id}`);
	};

	const openCustomerDetail = (id: string): void => {
		navigate(`/customer/detail/${id}`);
	};

	const title = <span>Access Information</span>;

	const descriptionItems = [
		{
			label: 'User Name',
			content: (
				<a
					onClick={() => {
						if (session?.userId != null) {
							openUserDetail(session?.userId);
						}
					}}
					style={{ cursor: 'pointer', width: '100%' }}
				>
					{session?.userName}
				</a>
			),
		},
		{
			label: 'Customer Name',
			content: (
				<a
					onClick={() => {
						if (session?.customerId != null) {
							openCustomerDetail(session?.customerId);
						}
					}}
					style={{ cursor: 'pointer', width: '100%' }}
				>
					{session?.customerName}
				</a>
			),
		},
		{
			label: 'Session ID',
			content: session?.id,
		},
		{
			label: 'Concluded Factor',
			content: session?.concludedFactor,
		},
		{
			label: 'Duration',
			content:
            session?.duration != null
					? `${(session.duration / 1000).toFixed(2)} s`
					: '',
		},
		{
			label: 'Security Score Rating',
			content: session?.securityScoreRating,
		},
	];

	return (
		<section className="section">
			<Descriptions title={title} layout="horizontal" column={1} bordered>
				{descriptionItems.map((item) => (
					<Descriptions.Item
						key={item.label}
						label={item.label}
						labelStyle={{ width: '14em', margin: '0.5em 0' }}
						contentStyle={{ margin: '0.5em 0 0 15em' }}
					>
						{item.content}
					</Descriptions.Item>
				))}
			</Descriptions>
		</section>
	);
};

export default React.memo(AccessSessionDescription);
