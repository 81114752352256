import Title from 'antd/es/typography/Title';
import React from 'react';
import EnvironmentDeletion from './EnvironmentDeletion';

const EnvironmentDangerZone: React.FC = () => {

	return (
		<section className="section">
			<Title className='section-header' level={5}>Danger Zone</Title>
			<EnvironmentDeletion />
		</section>
	);
};

export default React.memo(EnvironmentDangerZone);
