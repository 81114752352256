import React, { useEffect, useState } from 'react';
import './AccountMembers.scss';
import AccountMembersTable from './table/AccountMembersTable';
import { useAppDispatch } from '../../store/hooks';
import { setAccountMemberList } from '../../store/accountMemberReducer';
import AccountMemberCreateButton from './header/AccountMemberCreateButton';
import AccountMemberSearchBar from './header/AccountMemberSearchBar';
import { Spin } from 'antd';
import useMgmtApiWrapper from '../../hooks/openapi/useMgmtApiWrapper';

const AccountMembers: React.FC = () => {
	const dispatch = useAppDispatch();
	const mgmtApiWrapper = useMgmtApiWrapper();

	const [isAccountMembersLoading, setIsAccountMembersLoading] = useState(true);

	const fetchData = (): void => {
		setIsAccountMembersLoading(true);
		void mgmtApiWrapper
			.getAccountMembers()
			.then((r) => {
				if (r !== undefined) {
					dispatch(setAccountMemberList(r.accountMembers));
				}
				setIsAccountMembersLoading(false);
			});
	};

	useEffect(() => {
		fetchData();
	}, []);

	if (isAccountMembersLoading) {
		return (
			<>
				<div className="account-header">
					<AccountMemberSearchBar />
					<AccountMemberCreateButton fetchData={fetchData} />
				</div>
				<Spin className="settings-loading" size="large" />
			</>
		);
	} else {
		return (
			<>
				<div className="account-header">
					<AccountMemberSearchBar />
					<AccountMemberCreateButton fetchData={fetchData} />
				</div>
				<AccountMembersTable />
			</>
		);
	}
};

export default React.memo(AccountMembers);
