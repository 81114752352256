import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { type MenuProps } from 'antd';
import React from 'react';
import UserFaceActiveDeletion from './UserFaceActiveDeletion';
import { type ActiveRegistrationResponse } from '../../../../../../tools/openapi';
import DropdownButton from '../../../../../../shared/DropdownButton';
import UserFaceUploadButton from '../UserFaceUpload/UserFaceUploadButton';

interface Props {
	id: string;
	dataActive: ActiveRegistrationResponse | undefined;
	fetchFaceRegistrations: () => void;
}

const UserFaceActiveDropdown: React.FC<Props> = ({
	id,
	dataActive,
	fetchFaceRegistrations,
}) => {
	const faceRegisDropdown: MenuProps['items'] = [
		{
			label:
				id !== undefined ? (
					<UserFaceUploadButton
						userId={id}
						fetchFaceRegistrations={fetchFaceRegistrations}
					/>
				) : null,
			key: '0',
			icon: <PlusCircleOutlined />,
		},
		{
			label: (
				<UserFaceActiveDeletion
					dataActive={dataActive}
					fetchFaceRegistrations={fetchFaceRegistrations}
				/>
			),
			key: '1',
			icon: <DeleteOutlined />,
			danger: true,
			disabled: dataActive?.registration == null,
		},
	];
	return <DropdownButton menu={faceRegisDropdown} />;
};
export default React.memo(UserFaceActiveDropdown);
