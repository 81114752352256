/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccessStepAttemptCountResponse
 */
export interface AccessStepAttemptCountResponse {
    /**
     * 
     * @type {Date}
     * @memberof AccessStepAttemptCountResponse
     */
    date?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof AccessStepAttemptCountResponse
     */
    count?: number | null;
}

/**
 * Check if a given object implements the AccessStepAttemptCountResponse interface.
 */
export function instanceOfAccessStepAttemptCountResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AccessStepAttemptCountResponseFromJSON(json: any): AccessStepAttemptCountResponse {
    return AccessStepAttemptCountResponseFromJSONTyped(json, false);
}

export function AccessStepAttemptCountResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessStepAttemptCountResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function AccessStepAttemptCountResponseToJSON(value?: AccessStepAttemptCountResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'count': value.count,
    };
}

