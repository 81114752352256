/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConfirmFaceAccessRequest
 */
export interface ConfirmFaceAccessRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfirmFaceAccessRequest
     */
    stepId: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmFaceAccessRequest
     */
    environmentId: string;
}

/**
 * Check if a given object implements the ConfirmFaceAccessRequest interface.
 */
export function instanceOfConfirmFaceAccessRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "stepId" in value;
    isInstance = isInstance && "environmentId" in value;

    return isInstance;
}

export function ConfirmFaceAccessRequestFromJSON(json: any): ConfirmFaceAccessRequest {
    return ConfirmFaceAccessRequestFromJSONTyped(json, false);
}

export function ConfirmFaceAccessRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfirmFaceAccessRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stepId': json['stepId'],
        'environmentId': json['environmentId'],
    };
}

export function ConfirmFaceAccessRequestToJSON(value?: ConfirmFaceAccessRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stepId': value.stepId,
        'environmentId': value.environmentId,
    };
}

