/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FaceRegistrationSource = {
    IosSdk: 'IOS_SDK',
    AndroidSdk: 'ANDROID_SDK',
    WebSdk: 'WEB_SDK',
    Api: 'API',
    Console: 'CONSOLE'
} as const;
export type FaceRegistrationSource = typeof FaceRegistrationSource[keyof typeof FaceRegistrationSource];


export function FaceRegistrationSourceFromJSON(json: any): FaceRegistrationSource {
    return FaceRegistrationSourceFromJSONTyped(json, false);
}

export function FaceRegistrationSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): FaceRegistrationSource {
    return json as FaceRegistrationSource;
}

export function FaceRegistrationSourceToJSON(value?: FaceRegistrationSource | null): any {
    return value as any;
}

