import produce from 'immer';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type AccessSessionHistoriesResponseAccessSession } from '../tools/openapi';

const initialState = {
	accessSessions: [] as AccessSessionHistoriesResponseAccessSession[],
	accessSession: {},
	pageNumber: 1,
	PageSize: 10,
	loading: true,
	includeUnsuccessful: false,
};

const accessSessionSlice = createSlice({
	name: 'accessSession',
	initialState,
	reducers: {
		setCurrentAccessSession: (
			state,
			action: PayloadAction<AccessSessionHistoriesResponseAccessSession>
		) => {
			return produce(state, (draftState) => {
				draftState.accessSession = action.payload;
			});
		},
		setAccessSessionList: (
			state,
			action: PayloadAction<AccessSessionHistoriesResponseAccessSession[]>
		) => {
			return produce(state, (draftState) => {
				draftState.accessSessions = action.payload;
			});
		},
		setPageSize: (state, action: PayloadAction<number>) => {
			return produce(state, (draftState) => {
				draftState.PageSize = action.payload;
			});
		},
		setPageNumber: (state, action: PayloadAction<number>) => {
			return produce(state, (draftState) => {
				draftState.pageNumber = action.payload;
			});
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			return produce(state, (draftState) => {
				draftState.loading = action.payload;
			});
		},
		setIncludeUnsuccessful: (state, action: PayloadAction<boolean>) => {
			return produce(state, (draftState) => {
				draftState.includeUnsuccessful = action.payload;
			});
		},
	},
});

export const {
	setCurrentAccessSession,
	setAccessSessionList,
	setPageSize,
	setPageNumber,
	setLoading,
	setIncludeUnsuccessful,
} = accessSessionSlice.actions;
export default accessSessionSlice.reducer;
