import { type User } from '@auth0/auth0-react';
import React, { useState } from 'react';
import DangerZone from '../../../../../shared/DangerZone';
import DeleteConfirmation from '../../../../../shared/DeleteConfirmation';
import { useAppSelector } from '../../../../../store/hooks';
import { type RootState } from '../../../../../store/store';
import useMgmtApiWrapper from '../../../../../hooks/openapi/useMgmtApiWrapper';

interface Props {
	refetchUser: () => void;
}

const UserDeactivation: React.FC<Props> = (props: Props) => {
	const mgmtApiWrapper = useMgmtApiWrapper();

	const userDetail = useAppSelector(
		(state: RootState) => state.userDetail.user as User
	);

	const [isModalVisible, setIsModalVisible] = useState(false);

	const onClickDeactivate = (): void => {
		void mgmtApiWrapper
			.deactivateUser({ userId: userDetail.id })
			.then(() => {
				props.refetchUser();
				setIsModalVisible(false);
			})
	};

	return (
		<>
			<DangerZone
				title="Deactivate User"
				description="Deactivate a use will still keep all the records in the system, you can re-activate this user later."
				customKey={'Deactivate'}
				onConfirm={() => {
					setIsModalVisible(true);
				}}
			/>
			<DeleteConfirmation
				title="Are you sure? "
				message={`This action will deactivate the user. You can re-activate this user later.`}
				visible={isModalVisible}
				centered={true}
				customKey={'Deactivate'}
				inputDisplay={'none'}
				onCancel={() => {
					setIsModalVisible(false);
				}}
				onConfirm={onClickDeactivate}
			/>
		</>
	);
};

export default React.memo(UserDeactivation);
