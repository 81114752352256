import { Form, Modal } from 'antd';
import React from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { type RootState } from '../../../../store/store';
import {
	type StartResponse,
	type CreateUserRequest,
} from '../../../../tools/openapi';
import '../Users.scss';
import UserCreateForm from './UserCreateForm';
import useMgmtApiWrapper from '../../../../hooks/openapi/useMgmtApiWrapper';

interface Props {
	visible: boolean;
	setCreateModalVisible: (e: boolean) => void;
}

const UserCreateModal: React.FC<Props> = (props: Props) => {
	const mgmtApiWrapper = useMgmtApiWrapper();

	const [form] = Form.useForm();

	const startState: StartResponse = useAppSelector(
		(state: RootState) => state.startState.startState
	);

	const environment = useAppSelector(
		(state: RootState) => state.environment.environment
	);

	const handleSubmit = (values: CreateUserRequest): void => {
		const environmentId =
			environment?.id.length > 0
				? environment?.id
				: startState.account?.activeEnvironment?.id;
		const requestBody = {
			environmentId: environmentId as string,
			customerId: values.customerId,
			name: values.name,
			firstName: values.firstName,
			lastName: values.lastName,
			email: values.email,
			phone: values.phone,
		};
		void mgmtApiWrapper
			.createUser({ createUserRequest: requestBody })
			.then(() => {
				props.setCreateModalVisible(false);
			})
	};

	return (
		<Modal
			className="create-modal"
			width={600}
			centered={true}
			title={'New User'}
			open={props.visible}
			okText={'Create'}
			cancelText="Cancel"
			onCancel={() => {
				props.setCreateModalVisible(false);
			}}
			onOk={() => {
				form
					.validateFields()
					.then((values) => {
						handleSubmit(values);
						form.resetFields();
					})
					.catch((info) => {
						console.log('Validate Failed:', info);
					});
			}}
		>
			<UserCreateForm form={form} />
		</Modal>
	);
};

export default React.memo(UserCreateModal);
