/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccessSessionHistoriesResponseAccessSession } from './AccessSessionHistoriesResponseAccessSession';
import {
    AccessSessionHistoriesResponseAccessSessionFromJSON,
    AccessSessionHistoriesResponseAccessSessionFromJSONTyped,
    AccessSessionHistoriesResponseAccessSessionToJSON,
} from './AccessSessionHistoriesResponseAccessSession';

/**
 * 
 * @export
 * @interface AccessSessionHistoriesResponse
 */
export interface AccessSessionHistoriesResponse {
    /**
     * 
     * @type {Array<AccessSessionHistoriesResponseAccessSession>}
     * @memberof AccessSessionHistoriesResponse
     */
    accessSessions: Array<AccessSessionHistoriesResponseAccessSession>;
}

/**
 * Check if a given object implements the AccessSessionHistoriesResponse interface.
 */
export function instanceOfAccessSessionHistoriesResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accessSessions" in value;

    return isInstance;
}

export function AccessSessionHistoriesResponseFromJSON(json: any): AccessSessionHistoriesResponse {
    return AccessSessionHistoriesResponseFromJSONTyped(json, false);
}

export function AccessSessionHistoriesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessSessionHistoriesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessSessions': ((json['accessSessions'] as Array<any>).map(AccessSessionHistoriesResponseAccessSessionFromJSON)),
    };
}

export function AccessSessionHistoriesResponseToJSON(value?: AccessSessionHistoriesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessSessions': ((value.accessSessions as Array<any>).map(AccessSessionHistoriesResponseAccessSessionToJSON)),
    };
}

