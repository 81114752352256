import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useAppDispatch } from '../../../store/hooks';
import { setEnvironmentList } from '../../../store/environmentReducer';

import './Environments.scss';
import EnvironmentsCreateButton from './EnvironmentsHeader/EnvironmentsCreateButton';
import EnvironmentsList from './EnvironmentListElememts/EnvironmentsList';
import useMgmtApiWrapper from '../../../hooks/openapi/useMgmtApiWrapper';

const EnvironmentSettings: React.FC = () => {
	const dispatch = useAppDispatch();
	const mgmtApiWrapper = useMgmtApiWrapper();

	const [isEnvirLoading, setIsEnvirLoading] = useState(true);

	const fetchEnvironments = (): void => {
		setIsEnvirLoading(true);
		void mgmtApiWrapper
			.getEnvironments()
			.then((r) => {
				if (r !== undefined) {
					dispatch(setEnvironmentList(r.environments));
				}
				setIsEnvirLoading(false);
			})
	};

	useEffect(() => {
		fetchEnvironments();
	}, []);

	if (isEnvirLoading) {
		return (
			<>
				<EnvironmentsCreateButton fetchEnvironments={fetchEnvironments} />
				<Spin className="settings-loading" size="large" />
			</>
		);
	}

	return (
		<>
			<EnvironmentsCreateButton fetchEnvironments={fetchEnvironments} />
			<EnvironmentsList fetchEnvironments={fetchEnvironments} />
		</>
	);
};

export default React.memo(EnvironmentSettings);
