import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import {
	setCustomerList,
	setCustomerCount,
	setUseCustomPagination,
	setLoading,
	setPageNumber,
} from '../../../../store/customerReducer';
import '../Customers.scss';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { type RootState } from '../../../../store/store';
import { type StartResponse } from '../../../../tools/openapi';
import useMgmtApiWrapper from '../../../../hooks/openapi/useMgmtApiWrapper';

const { Search } = Input;

const CustomerSearchBar: React.FC = () => {
	const mgmtApiWrapper = useMgmtApiWrapper();
	const dispatch = useAppDispatch();

	const [inputSearchValue, setInputSearchValue] = useState('');
	const [debouncedSearchValue, setDebouncedSearchValue] = useState('');

	const startState: StartResponse = useAppSelector(
		(state: RootState) => state.startState.startState
	);

	const environment = useAppSelector(
		(state: RootState) => state.environment.environment
	);

	const page = useAppSelector((state: RootState) => state.customer.pageNumber);

	const size = useAppSelector((state: RootState) => state.customer.PageSize);

	const fetchData = (): void => {
		const environmentId =
			environment?.id.length > 0
				? environment?.id
				: startState.account?.activeEnvironment?.id;
		const onSearch = inputSearchValue != null && inputSearchValue.length > 0;
		setLoading(true);
		const requestBody = {
			environmentId: environmentId as string,
			pageNumber: page - 1,
			pageSize: size,
			...(onSearch ? { query: inputSearchValue } : {}),
		};
		void mgmtApiWrapper
			.getCustomers(requestBody)
			.then((r) => {
				if (r !== undefined) {
					onSearch
						? dispatch(setUseCustomPagination(true))
						: dispatch(setCustomerCount(r.totalCount));
					dispatch(setCustomerList(r.customers));
				}
				dispatch(setLoading(false));
			})
	};

	useEffect(() => {
		const debouncedValueUpdate = setTimeout(() => {
			page !== 1
				? dispatch(setPageNumber(1))
				: setDebouncedSearchValue(inputSearchValue);
		}, 500);

		return () => {
			clearTimeout(debouncedValueUpdate);
		};
	}, [inputSearchValue]);

	useEffect(() => {
		fetchData();
	}, [environment, page, size, debouncedSearchValue]);

	return (
		<Search
			value={inputSearchValue}
			placeholder="Search by name"
			allowClear
			enterButton
			style={{ width: '30%' }}
			onChange={(e) => {
				setInputSearchValue(e.target.value);
			}}
		/>
	);
};

export default React.memo(CustomerSearchBar);
