/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccountMemberRole } from './AccountMemberRole';
import {
    AccountMemberRoleFromJSON,
    AccountMemberRoleFromJSONTyped,
    AccountMemberRoleToJSON,
} from './AccountMemberRole';

/**
 * 
 * @export
 * @interface CreateAccountMemberRequest
 */
export interface CreateAccountMemberRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAccountMemberRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountMemberRequest
     */
    name: string;
    /**
     * 
     * @type {AccountMemberRole}
     * @memberof CreateAccountMemberRequest
     */
    role?: AccountMemberRole | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountMemberRequest
     */
    avatar?: string | null;
}

/**
 * Check if a given object implements the CreateAccountMemberRequest interface.
 */
export function instanceOfCreateAccountMemberRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function CreateAccountMemberRequestFromJSON(json: any): CreateAccountMemberRequest {
    return CreateAccountMemberRequestFromJSONTyped(json, false);
}

export function CreateAccountMemberRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAccountMemberRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'name': json['name'],
        'role': !exists(json, 'role') ? undefined : AccountMemberRoleFromJSON(json['role']),
        'avatar': !exists(json, 'avatar') ? undefined : json['avatar'],
    };
}

export function CreateAccountMemberRequestToJSON(value?: CreateAccountMemberRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'name': value.name,
        'role': AccountMemberRoleToJSON(value.role),
        'avatar': value.avatar,
    };
}

