import { List } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../store/hooks';
import { type RootState } from '../../../../store/store';
import { type EnvironmentsResponseEnvironment } from '../../../../tools/openapi';
import EnvironmentsDropdownButton from './EnvironmentsDropdownButton';

interface Props {
	fetchEnvironments: () => void;
}

const EnvironmentsList: React.FC<Props> = (props: Props) => {
	const navigate = useNavigate();

	const environments = useAppSelector(
		(state: RootState) => state.environment.environments
	);

	return (
		<List
			dataSource={environments}
			renderItem={(item: EnvironmentsResponseEnvironment) => (
				<List.Item className="list-item">
					<div
						style={{ width: '80%', cursor: 'pointer' }}
						onClick={() => {
							navigate(`/environment/detail/${item.id}`);
						}}
					>
						<List.Item.Meta
							title={item.name}
							description={`Customers: ${item.size}`}
						/>
					</div>
					<EnvironmentsDropdownButton
						row={item}
						fetchEnvironments={props.fetchEnvironments}
					/>
				</List.Item>
			)}
		/>
	);
};

export default React.memo(EnvironmentsList);
