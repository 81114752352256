import React from 'react';
import UserNameAvatar from '../mainContent/users/UserList/usersTable/UserNameAvatar';
import { type UsersResponseUser } from '../tools/openapi';
import { type ColumnsType } from 'antd/es/table';
import { useParams, type Params } from 'react-router-dom';
import { Button, Space, message } from 'antd';
import Title from 'antd/es/typography/Title';
import UserSearchBar from '../mainContent/users/UserList/usersHeader/UserSearchBar';
import UsersTable from '../mainContent/users/UserList/usersTable/UsersTable';
import useMgmtApiWrapper from '../hooks/openapi/useMgmtApiWrapper';

interface RouteParams extends Params {
	customerId: string;
	environmentId: string;
}

const ManualAccess: React.FC = () => {
	const mgmtApi = useMgmtApiWrapper();
	const { customerId, environmentId } = useParams<RouteParams>();

	const clickSignin = (id: string): void => {
		if (customerId !== undefined && environmentId !== undefined) {
			void mgmtApi
				.manualAccess({
					manualAccessRequest: { customerId, environmentId, userId: id },
				})
				.then((r) => {
					console.log(r);
					void message.success('User has successfully clocked in.');
				});
		}
	};

	const columns: ColumnsType<UsersResponseUser> = [
		{
			title: 'Name',
			render: (_, record) => <UserNameAvatar record={record} />,
		},
		{
			title: 'Actions',
			render: (_, record) => (
				<Button
					onClick={() => {
						clickSignin(record.id);
					}}
				>
					Clock In
				</Button>
			),
		},
	];

	return (
		<>
			<div className="users-header">
				<Title level={3}>Users</Title>
			</div>
			<div className="users-content">
				<Space className="custom-table-space" direction="vertical" size="large">
					<UserSearchBar customerId={customerId} searchMode="ManualAccess" />
					<UsersTable customColunmns={columns} tableMode="ManualAccess" />
				</Space>
			</div>
		</>
	);
};

export default React.memo(ManualAccess);
