import { Layout } from 'antd';
import React, { useEffect } from 'react';
import '../AppLayout.scss';
import AvatarButton from '../userProfile/AvatarButton';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { type StartResponse } from '../../tools/openapi';
import { type RootState } from '../../store/store';
import { setEnvironment } from '../../store/environmentReducer';

const { Header } = Layout;

const AppHeader: React.FC = () => {
	const dispatch = useAppDispatch();

	const startState: StartResponse = useAppSelector(
		(state: RootState) => state.startState.startState
	);

	const environment = useAppSelector(
		(state: RootState) => state.environment.environment
	);

	useEffect(() => {
		if (environment.id.length === 0 && startState.account != null) {
			dispatch(setEnvironment(startState.account.activeEnvironment));
		}
	}, []);

	return (
		<Header className="header">
			<div className="customerName">{startState.account?.account.name}</div>
			<AvatarButton />
		</Header>
	);
};

export default React.memo(AppHeader);
