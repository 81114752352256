import { Button, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { type ApiKey } from '../../../tools/openapi';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { type RootState } from '../../../store/store';
import '../settings.scss';
import {
	setModalOnCreate,
	setModalVisible,
} from '../../../store/accessKeyReducer';
import AccessKeysTable from './AccessKeysTable';
import AccessKeyCreateAndDeleteModal from './AccessKeyCreateAndDeleteModal';
import useMgmtApiWrapper from '../../../hooks/openapi/useMgmtApiWrapper';

const AccessKey: React.FC = () => {
	const dispatch = useAppDispatch();
	const mgmtApiWrapper = useMgmtApiWrapper();

	const environment = useAppSelector(
		(state: RootState) => state.environment.environment
	);

	const [accessKeys, setAccessKeys] = React.useState<ApiKey[]>([]);
	const [isAccessKeysLoading, setIsAccessKeyLoading] = useState(true);

	const fetchData = (): void => {
		setIsAccessKeyLoading(true);
		void mgmtApiWrapper
			.getApiKeys({ environmentId: environment.id })
			.then((r) => {
				if (r !== undefined) {
					setAccessKeys(r.keys);
				}
				setIsAccessKeyLoading(false);
			});
	};

	useEffect(() => {
		fetchData();
	}, []);

	if (isAccessKeysLoading) {
		return <Spin className="settings-loading" size="large" />;
	} else {
		return (
			<section className="section">
				<div className={'access-create-key'}>
					<>
						<Button
							onClick={() => {
								dispatch(setModalOnCreate(true));
								dispatch(setModalVisible(true));
							}}
						>
							Create Key
						</Button>
						<AccessKeyCreateAndDeleteModal fetchData={fetchData} />
					</>
				</div>
				<div style={{ display: 'block' }}>
					<AccessKeysTable accessKeys={accessKeys} fetchData={fetchData} />
				</div>
			</section>
		);
	}
};

export default React.memo(AccessKey);
