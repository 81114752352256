import { Alert, Button } from 'antd';
import React from 'react';

interface DangerZoneProps {
	title: string;
	description?: string;
	onConfirm: () => void;
	customKey?: string;
}

const DangerZone: React.FC<DangerZoneProps> = ({
	title,
	description,
	onConfirm,
	customKey,
}: DangerZoneProps) => {
	return (
		<>
			<Alert
				message={
					<span style={{ color: 'darkred', fontWeight: 'bold' }}>{title}</span>
				}
				description={description}
				type="warning"
				style={{
					backgroundColor: '#ffe8e6',
					borderColor: '#ffe8e6',
					color: 'darkred',
				}}
				action={
					<Button
						type="primary"
						danger
						onClick={onConfirm}
						style={{ marginLeft: '30px', marginTop: '15px' }}
					>
						{customKey != null ? customKey : 'Delete'}
					</Button>
				}
			/>

			<div style={{ marginTop: 16 }}></div>
		</>
	);
};

export default DangerZone;
