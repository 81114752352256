import { Image } from 'antd';
import Table, { type ColumnsType } from 'antd/es/table';
import React from 'react';
import Title from 'antd/es/typography/Title';
import { useAppSelector } from '../../../../store/hooks';
import { type RootState } from '../../../../store/store';
import {
	type SessionDetailsWithStepsResponseStep,
	type SessionDetailsWithStepsResponse,
} from '../../../../tools/openapi';

const AccessSessionSteps: React.FC = () => {
	const { steps } = useAppSelector(
		(state: RootState) =>
			state.accessSessionDetail
				.sessionDetailWithSteps as SessionDetailsWithStepsResponse
	);

	const columns: ColumnsType<SessionDetailsWithStepsResponseStep> = [
		{
			title: 'Time',
			dataIndex: 'time',
			render: (_, record) => <span>{record.time.toLocaleString()}</span>,
		},
		{
			title: 'Factor',
			dataIndex: 'factor',
			render: (_, record) => <span>{record.factor}</span>,
		},

		{
			title: 'Identified',
			dataIndex: 'identified',
			render: (_, record) => <span>{record.identified.toString()}</span>,
		},
		{
			title: 'Photo',
			dataIndex: 'photo',
			render: (_, record) => (
				<span>
					{record.photoUrl != null ? (
						<Image key={record.photoUrl} width={100} src={record.photoUrl} />
					) : (
						''
					)}
				</span>
			),
		},
	];
	return (
		<section className="section">
			<Title className="h5" level={5}>
				Access Steps
			</Title>
			<Table
				dataSource={steps}
				columns={columns}
				rowKey={'time'}
				pagination={false}
			/>
		</section>
	);
};

export default React.memo(AccessSessionSteps);
