import React from 'react';
import { Button, Descriptions } from 'antd';
import UserFaceActiveDropdown from './UserFaceActiveDropdown';
import {
	type ActiveRegistrationResponse,
	type FaceRegistration,
} from '../../../../../../tools/openapi';
import '../../../UserDetail.scss';
import Title from 'antd/es/typography/Title';
import UserFaceUploadButton from '../UserFaceUpload/UserFaceUploadButton';

interface DescriptionItem {
	label: string;
	content: React.ReactNode;
}

interface Props {
	id: string;
	dataActive: ActiveRegistrationResponse | undefined;
	fetchFaceRegistrations: () => void;
	createDescriptionItems: (registration: FaceRegistration) => DescriptionItem[];
}

const UserFaceActive: React.FC<Props> = ({
	id,
	dataActive,
	fetchFaceRegistrations,
	createDescriptionItems,
}) => {
	const faceRigisterTitle = (
		<div className="user-section-header">
			<span>Face Registration</span>
			<UserFaceActiveDropdown
				id={id}
				dataActive={dataActive}
				fetchFaceRegistrations={fetchFaceRegistrations}
			/>
		</div>
	);

	if (dataActive?.registration != null) {
		return (
			<section className="section">
				<Descriptions
					title={faceRigisterTitle}
					layout="horizontal"
					column={1}
					bordered
				>
					{createDescriptionItems(dataActive.registration).map((item) => (
						<Descriptions.Item
							key={item.label}
							label={item.label}
							labelStyle={{ width: '12em', margin: '0.5em 0' }}
							contentStyle={{ margin: '0.5em 0 0 15em' }}
						>
							{item.content}
						</Descriptions.Item>
					))}
				</Descriptions>
			</section>
		);
	} else {
		return (
			<section className="section">
				<div className="user-section-header">
					<Title level={5}>Face Registration</Title>
					<Button>
					<UserFaceUploadButton
						userId={id}
						fetchFaceRegistrations={fetchFaceRegistrations}
					/>
					</Button>
				</div>
			</section>
		);
	}
};
export default React.memo(UserFaceActive);
