import { Form, type FormInstance, Input } from 'antd';
import React from 'react';
import { type EnvironmentsResponseEnvironment } from '../../../../tools/openapi';

interface Props {
	form: FormInstance;
	onFinish: (values: EnvironmentsResponseEnvironment) => void;
}

const EnvironmentsCreateAndUpdateForm: React.FC<Props> = (props: Props) => {
	return (
		<Form
			form={props.form}
			onFinish={props.onFinish}
			labelCol={{ span: 6 }}
			wrapperCol={{ span: 16 }}
		>
			<Form.Item
				name="id"
				label="Id"
				rules={[{ required: false }]}
				style={{ display: 'none' }}
			>
				<Input placeholder="Enter id" />
			</Form.Item>
			<Form.Item name="name" label="Name" rules={[{ required: true }]}>
				<Input placeholder="Enter name" />
			</Form.Item>
		</Form>
	);
};

export default React.memo(EnvironmentsCreateAndUpdateForm);
