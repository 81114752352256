import { Row, Col, Radio } from 'antd';
import React from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import { type RootState } from '../../../../../../store/store';
import { type UpdateCustomerSettingsRequest } from '../../../../../../tools/openapi';
import { QuestionCircleTwoTone } from '@ant-design/icons';

interface Props {
	updateSettings: <T extends keyof UpdateCustomerSettingsRequest>(
		target: T,
		value: UpdateCustomerSettingsRequest[T]
	) => void;
}

const CustomerPinLimit: React.FC<Props> = (props) => {
	const requireApprovalOnFaceRegistration = useAppSelector(
		(state: RootState) => state.customerDetail.requireApprovalOnFaceRegistration
	);

	const allowFaceRegistrationOnClient = useAppSelector(
		(state: RootState) => state.customerDetail.allowFaceRegistrationOnClient
	);

	return (
		<Row>
			<Col span={16}>
				Require Approval On Registration:{' '}
				<QuestionCircleTwoTone title="Enable this setting to necessitate supervisor confirmation for all new face registrations." />
			</Col>
			<Col span={4} push={2}>
				<Radio.Group
					value={requireApprovalOnFaceRegistration}
					onChange={(e) => {
						props.updateSettings(
							'requireApprovalOnFaceRegistration',
							e.target.value
						);
					}}
					style={{ display: 'flex', alignItems: 'center' }}
					disabled={!allowFaceRegistrationOnClient}
				>
					<Radio value={false} style={{ marginRight: '1rem' }}>
						<span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
							Instant Approve
						</span>
					</Radio>
					<Radio value={true}>
						<span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
							Require Approval
						</span>
					</Radio>
				</Radio.Group>
			</Col>
		</Row>
	);
};
export default React.memo(CustomerPinLimit);
