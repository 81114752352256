import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Spin, message } from 'antd';
import {
	type UpdateEnvironmentSettingsOperationRequest,
	type UpdateEnvironmentSettingsRequest,
} from '../../tools/openapi';
import { useAppSelector } from '../../store/hooks';
import { type RootState } from '../../store/store';
import useMgmtApiWrapper from '../../hooks/openapi/useMgmtApiWrapper';

interface url {
	url: string;
}

const Webhook: React.FC = () => {
	const mgmtApiWrapper = useMgmtApiWrapper();
	const environment = useAppSelector(
		(state: RootState) => state.environment.environment
	);

	const [form] = Form.useForm();
	const [oriWebhookUrl, setOriWebhookUrl] = useState('');
	const [inputValue, setInputValue] = useState('');
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);
	const [isWebhookLoading, setIsWebhookLoading] = useState(true);

	const onFinish: (e: url) => void = (e) => {
		const updateSettingRequest: UpdateEnvironmentSettingsRequest = {
			webhook: e.url,
		};
		const requestBody: UpdateEnvironmentSettingsOperationRequest = {
			environmentId: environment.id,
			updateEnvironmentSettingsRequest: updateSettingRequest,
		};
		void mgmtApiWrapper
			.updateEnvironmentSettings(requestBody)
			.then(() => {
				void message.success('Save success!');
			})
	};

	const onFinishFailed: () => void = () => {
		void message.error('Save failed!');
	};

	useEffect(() => {
		setIsWebhookLoading(true);
		void mgmtApiWrapper
			.getWebhookByEnvironmentId({ environmentId: environment.id })
			.then((r) => {
				if (r !== undefined) {
					form.setFieldValue('url', r.webhook);
					setOriWebhookUrl(r.webhook as string);
				}
				setIsWebhookLoading(false);
			})
	}, []);

	useEffect(() => {
		setIsButtonDisabled(form.getFieldValue('url') === oriWebhookUrl);
	}, [form.getFieldValue('url')]);

	if (isWebhookLoading) {
		return <Spin className="settings-loading" size="large" />;
	} else {
		return (
			<Form
				form={form}
				layout="vertical"
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
			>
				<Form.Item
					name="url"
					label="Webhook URL"
					rules={[
						{ type: 'url', warningOnly: true },
						{ type: 'string', min: 6 },
					]}
				>
					<Input
						value={inputValue}
						onChange={(e) => {
							setInputValue(e.target.value);
						}}
					/>
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit" disabled={isButtonDisabled}>
						Save
					</Button>
				</Form.Item>
			</Form>
		);
	}
};

export default React.memo(Webhook);
