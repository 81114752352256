/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ManualAccessResponse
 */
export interface ManualAccessResponse {
    /**
     * 
     * @type {string}
     * @memberof ManualAccessResponse
     */
    sessionId: string;
    /**
     * 
     * @type {string}
     * @memberof ManualAccessResponse
     */
    stepId: string;
    /**
     * 
     * @type {string}
     * @memberof ManualAccessResponse
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof ManualAccessResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ManualAccessResponse
     */
    avatar?: string | null;
}

/**
 * Check if a given object implements the ManualAccessResponse interface.
 */
export function instanceOfManualAccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sessionId" in value;
    isInstance = isInstance && "stepId" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function ManualAccessResponseFromJSON(json: any): ManualAccessResponse {
    return ManualAccessResponseFromJSONTyped(json, false);
}

export function ManualAccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManualAccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessionId': json['sessionId'],
        'stepId': json['stepId'],
        'userId': json['userId'],
        'name': json['name'],
        'avatar': !exists(json, 'avatar') ? undefined : json['avatar'],
    };
}

export function ManualAccessResponseToJSON(value?: ManualAccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sessionId': value.sessionId,
        'stepId': value.stepId,
        'userId': value.userId,
        'name': value.name,
        'avatar': value.avatar,
    };
}

