import { useRoutes } from 'react-router-dom';
import './App.scss';
import { router } from './router';
import type React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect } from 'react';
import { useAppDispatch } from './store/hooks';
import { type StartOperationRequest } from './tools/openapi';
import {
	setStartAccessToken,
	setStartIdToken,
	setStartState,
} from './store/startReducer';
import useMgmtApiWrapper from './hooks/openapi/useMgmtApiWrapper';

const App: React.FC = () => {
	const [idToken, setIdToken] = useState<string | undefined>(undefined);
	const [isIdTokenFetched, setIsIdTokenFetched] = useState<boolean>(false);
	const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
	const dispatch = useAppDispatch();
	const mgmtApiWrapper = useMgmtApiWrapper();

	useEffect(() => {
		if (isIdTokenFetched && idToken != null) {
			dispatch(setStartIdToken(idToken));
			const requestBody: StartOperationRequest = { startRequest: { idToken } };
			void mgmtApiWrapper.start(requestBody).then((r) => {
				if (r !== undefined) {
					void dispatch(setStartState(r));
				}
			});
		}
	}, [isIdTokenFetched, idToken]);

	useEffect(() => {
		const getAccessToken = async (): Promise<void> => {
			try {
				const accessToken = await getAccessTokenSilently({
					authorizationParams: {
						audience: process.env.REACT_APP_AUTH0_AUDIENCE,
						scope: process.env.REACT_APP_AUTH0_SCOPE,
					},
				});
				const idTokenClaims = await getIdTokenClaims();
				const idToken = idTokenClaims != null ? idTokenClaims.__raw : undefined;
				dispatch(setStartAccessToken(accessToken));
				setIdToken(idToken);
				setIsIdTokenFetched(true);
			} catch (e) {
				console.error(e);
			}
		};
		void getAccessToken();
	}, [getAccessTokenSilently, getIdTokenClaims, dispatch]);

	return useRoutes(router);
};

export default App;
