import produce from 'immer';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type StartResponse } from '../tools/openapi';

const initialState = {
	startState: {},
	idToken: '',
	accessToken: '',
};

const startSlice = createSlice({
	name: 'accessToken',
	initialState,
	reducers: {
		setStartState: (state, action: PayloadAction<StartResponse>) => {
			return produce(state, (draftState) => {
				draftState.startState = action.payload;
			});
		},
		setStartIdToken: (state, action: PayloadAction<string>) => {
			return produce(state, (draftState) => {
				draftState.idToken = action.payload;
			});
		},
		setStartAccessToken: (state, action: PayloadAction<string>) => {
			return produce(state, (draftState) => {
				draftState.accessToken = action.payload;
			});
		},
	},
});

export const { setStartState, setStartIdToken, setStartAccessToken } =
	startSlice.actions;
export default startSlice.reducer;
