/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { type CSSProperties, type ReactElement, type ComponentProps} from 'react';
import { Table, Pagination } from 'antd';
import type { ColumnsType, } from 'antd/es/table';
import CustomPagination from './CustomPagination';


interface Props<T> {
	rowClassName?: any;
	onRow?: ComponentProps<any>;
	tableStyle?: CSSProperties;
	data: T[];
	columns: ColumnsType<any>;
	rowKey: string;
	getPageContent?: ((page: number, pageSize: number) => void) | undefined;
	pagination: boolean;
	paginationOption?: {
		page: number;
		customPagination: boolean;
		pageChange: (page: number) => void;
		pageSize: number;
		sizeChange: (cur: number, size: number) => void;
		totalCount: number;
		showSizeChanger?: boolean;
		pageSizeOptions?: number[];
	};
}

const DataTable = <T extends object>(props: Props<T>): ReactElement => {
	let pagination = null;

	if (props.paginationOption != null) {
		pagination = props.paginationOption.customPagination ? (
			<CustomPagination
				currentPage={props.paginationOption.page}
				dataSize={props.data.length}
				onPageChange={props.paginationOption.pageChange}
				onPageSizeChange={props.paginationOption.sizeChange}
				showSizeChanger={props.paginationOption.showSizeChanger}
				pageSize={props.paginationOption.pageSize}
				pageSizeOptions={
					props.paginationOption.pageSizeOptions != null
						? props.paginationOption.pageSizeOptions
						: [10, 20, 50]
				}
			/>
		) : (
			<Pagination
				style={{marginTop: '16px', marginBottom: '16px'}}
				current={props.paginationOption.page}
				pageSize={props.paginationOption.pageSize}
				showSizeChanger={props.paginationOption.showSizeChanger}
				pageSizeOptions={
					props.paginationOption.pageSizeOptions != null
						? props.paginationOption.pageSizeOptions
						: [10, 20, 50]
				}
				onShowSizeChange={props.paginationOption.sizeChange}
				total={props.paginationOption.totalCount}
				showQuickJumper={true}
				onChange={props.paginationOption.pageChange}
			/>
		);
	}

	return (
		<div>
			<Table
				rowClassName={props.rowClassName}
				onRow={props.onRow}
				style={props.tableStyle}
				dataSource={props.data}
				columns={props.columns}
				rowKey={props.rowKey}
				pagination={props.pagination ? false : undefined}
			/>
			{props.pagination ? pagination : null}
		</div>
	);
};

export default React.memo(DataTable);
