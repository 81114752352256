import { List } from 'antd';
import React from 'react';
import { type CustomerResponseCustomer } from '../../../../tools/openapi';

interface Props {
	record: CustomerResponseCustomer;
}

const CustomerNameIDInRow: React.FC<Props> = (props: Props) => {
	return (
		<List.Item.Meta
			className="customer-list"
			title={props.record.name}
			description={'ID: ' + props.record.id}
		/>
	);
};

export default React.memo(CustomerNameIDInRow);
