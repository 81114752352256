import React from 'react';
import DropdownButton from '../../../../../shared/DropdownButton';
import { UserOutlined, EyeOutlined } from '@ant-design/icons';
import { type Params, useNavigate, useParams } from 'react-router-dom';
import { type MenuProps } from 'antd';

interface RouteParams extends Params {
	id: string;
}

const CustomerDescriptionDropdown: React.FC = () => {
	const navigate = useNavigate();
	const { id } = useParams<RouteParams>();

	const openUsers = (): void => {
		const searchQuery = id;
		navigate(`/user?search=${searchQuery as string}`);
	};

	const openAccessSessions = (): void => {
		const searchQuery = id;
		navigate(`/access_session?search=${searchQuery as string}&type=customerId`);
	};

	const dropdown: MenuProps['items'] = [
		{
			label: (
				<a type="primary" onClick={openUsers}>
					Users
				</a>
			),
			key: '0',
			icon: <UserOutlined />,
		},
		{
			label: (
				<a type="primary" onClick={openAccessSessions}>
					View Access Sessions
				</a>
			),
			key: '1',
			icon: <EyeOutlined />,
		},
	];

	return <DropdownButton menu={dropdown} />;
};
export default React.memo(CustomerDescriptionDropdown);
