import { type MenuProps } from 'antd';
import React from 'react';
import { DeleteOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import CustomerInRowDelete from './CustomerInRowDelete';
import DropdownButton from '../../../../shared/DropdownButton';
import OpenCustomerDetail from './OpenCustomerDetail';
import { type CustomerResponseCustomer } from '../../../../tools/openapi';
import CustomerInRowEdit from './CustomerInRowEdit';

interface Props {
	record: CustomerResponseCustomer;
}

const CustomerDropDownButton: React.FC<Props> = (props: Props) => {
	const items: MenuProps['items'] = [
		{
			label: <OpenCustomerDetail id={props.record.id} />,
			key: '0',
			icon: <EyeOutlined />,
		},
		{
			label: <CustomerInRowEdit record={props.record} />,
			key: '1',
			icon: <EditOutlined />,
		},
		{
			type: 'divider',
		},
		{
			label: <CustomerInRowDelete record={props.record} />,
			key: '2',
			icon: <DeleteOutlined />,
			danger: true,
		},
	];

	return (
		<>
			<DropdownButton menu={items} />
		</>
	);
};

export default React.memo(CustomerDropDownButton);
