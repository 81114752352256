import { Modal, type FormInstance } from 'antd';
import React from 'react';
import { type UpdateUserRequest } from '../../../../../tools/openapi';
import { type User } from '../../../../../tools/openapi/models/User';
import { useAppSelector } from '../../../../../store/hooks';
import { type RootState } from '../../../../../store/store';
import UserInformationEditForm from './UserInformationEditForm';
import useMgmtApiWrapper from '../../../../../hooks/openapi/useMgmtApiWrapper';
interface Props {
	fetchUser: () => void;
	form: FormInstance;
	openEditModal: boolean;
	setOpenEditModal: (e: boolean) => void;
}

const UserInformationEditModal: React.FC<Props> = (props: Props) => {
	const mgmtApiWrapper = useMgmtApiWrapper();

	const userDetail = useAppSelector(
		(state: RootState) => state.userDetail.user as User
	);

	const handleSubmit = (values: UpdateUserRequest): void => {
		const updatedValues = Object.fromEntries(
			Object.entries(values).map(([key, value]) => [key, value === '' ? null : value])
		  );
		if (updatedValues.name === null) {
			updatedValues.name = `${updatedValues.firstName as string}` + ` ` + `${updatedValues.lastName as string}`
		}
		void mgmtApiWrapper
			.updateUserById({ userId: userDetail.id, updateUserRequest: updatedValues })
			.then(() => {
				props.setOpenEditModal(false);
				props.fetchUser();
			})
	};

	return (
		<>
			<Modal
				className="create-modal"
				width={600}
				title={'Update User'}
				open={props.openEditModal}
				okText={'Update'}
				cancelText="Cancel"
				onCancel={() => {
					props.setOpenEditModal(false);
				}}
				centered={true}
				onOk={() => {
					props.form
						.validateFields()
						.then((values) => {
							handleSubmit(values);
						})
						.catch((info) => {
							console.log('Validate Failed:', info);
						});
				}}
			>
				<UserInformationEditForm form={props.form} />
			</Modal>
		</>
	);
};

export default React.memo(UserInformationEditModal);
