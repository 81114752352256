/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateCustomerResponseCustomer
 */
export interface CreateCustomerResponseCustomer {
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerResponseCustomer
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerResponseCustomer
     */
    environmentId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerResponseCustomer
     */
    name: string;
}

/**
 * Check if a given object implements the CreateCustomerResponseCustomer interface.
 */
export function instanceOfCreateCustomerResponseCustomer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "environmentId" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function CreateCustomerResponseCustomerFromJSON(json: any): CreateCustomerResponseCustomer {
    return CreateCustomerResponseCustomerFromJSONTyped(json, false);
}

export function CreateCustomerResponseCustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCustomerResponseCustomer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'environmentId': json['environmentId'],
        'name': json['name'],
    };
}

export function CreateCustomerResponseCustomerToJSON(value?: CreateCustomerResponseCustomer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'environmentId': value.environmentId,
        'name': value.name,
    };
}

