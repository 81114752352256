import { Space, Image } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { type RootState } from '../../../../../store/store';
import {
	type User,
	type FaceRegistration,
	type GetRegistrationsRequest,
} from '../../../../../tools/openapi';
import { type ActiveRegistrationResponse } from '../../../../../tools/openapi/models/ActiveRegistrationResponse';
import { type SubmittedRegistrationResponse } from '../../../../../tools/openapi/models/SubmittedRegistrationResponse';
import UserFaceActive from './UserFaceActive/UserFaceActive';
import UserFaceSubmitted from './UserFaceSubmitted/UserFaceSubmitted';
import useMgmtApiWrapper from '../../../../../hooks/openapi/useMgmtApiWrapper';

interface DescriptionItem {
	label: string;
	content: React.ReactNode;
}

const UserFaceRegistration: React.FC = () => {
	const userDetail = useAppSelector(
		(state: RootState) => state.userDetail.user as User
	);

	const [dataActive, setDataActive] = useState<ActiveRegistrationResponse>();
	const [dataSubmitted, setDataSubmitted] =
		useState<SubmittedRegistrationResponse>();
	const mgmtApiWrapper = useMgmtApiWrapper();

	const fetchFaceRegistrations = (): void => {
		const requestBody: GetRegistrationsRequest = { userId: userDetail.id };
		void mgmtApiWrapper
			.getActiveRegistration(requestBody)
			.then((r) => {
				setDataActive(r);
			})

		void mgmtApiWrapper
			.getSubmittedRegistration(requestBody)
			.then((r) => {
				setDataSubmitted(r);
			})
	};

	useEffect(() => {
		fetchFaceRegistrations();
	}, []);

	const createDescriptionItems = (
		registration: FaceRegistration
	): DescriptionItem[] => [
		{
			label: 'Created At',
			content: registration?.createdAt.toLocaleString(),
		},
		{
			label: 'Images',
			content: (
				<Space>
					{registration?.images.map((image) => (
						<Image key={image.url} width={100} src={image.url} />
					))}
				</Space>
			),
		},
		{
			label: 'Status',
			content: registration?.status,
		},
	];

	return (
		<>
			<UserFaceSubmitted
				dataSubmitted={dataSubmitted}
				fetchFaceRegistrations={fetchFaceRegistrations}
				createDescriptionItems={createDescriptionItems}
			/>
			<UserFaceActive
				id={userDetail.id}
				dataActive={dataActive}
				fetchFaceRegistrations={fetchFaceRegistrations}
				createDescriptionItems={createDescriptionItems}
			/>
		</>
	);
};
export default React.memo(UserFaceRegistration);
