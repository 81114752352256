import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import '../../../../UserList/Users.scss';

import {
	type NewFaceRegistrationOperationRequest,
	type SubmitFaceRegistrationRequest,
	type DeleteFaceRegistrationRequest,
} from '../../../../../../tools/openapi';
import UserFaceUpload from './UserFaceUpload';
import useMgmtApiWrapper from '../../../../../../hooks/openapi/useMgmtApiWrapper';
interface Props {
	userId: string;
	fetchFaceRegistrations: () => void;
}

const UserFaceUploadButton: React.FC<Props> = ({
	userId,
	fetchFaceRegistrations,
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const mgmtApiWrapper = useMgmtApiWrapper();
	const [registrationId, setRegistrationId] = useState('');
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [succeededUpload, setSucceededUpload] = useState(0);

	const showModal = (): void => {
		const requestBody: NewFaceRegistrationOperationRequest = {
			newFaceRegistrationRequest: { userId },
		};
		void mgmtApiWrapper.newFaceRegistration(requestBody).then((r) => {
			if (r !== undefined) {
				setRegistrationId(r.registrationId);
			}
			setIsModalOpen(true);
		});
	};

	const handleOk = (): void => {
		setConfirmLoading(true);
		const requestBody: SubmitFaceRegistrationRequest = { registrationId };
		void mgmtApiWrapper
			.submitFaceRegistration(requestBody)
			.then(() => {
				if (fetchFaceRegistrations != null) {
					fetchFaceRegistrations();
					setIsModalOpen(false);
					setConfirmLoading(false);
					setFileList([]);
				}
			})
	};

	const handleCancel = (): void => {
		const requestBody: DeleteFaceRegistrationRequest = {
			faceRegistrationId: registrationId,
		};
		void mgmtApiWrapper.deleteFaceRegistration(requestBody).then((r) => {
			setIsModalOpen(false);
			setFileList([]);
			setSucceededUpload(0);
		});
	};

	useEffect(() => {
		console.log(fileList);
	}, [fileList]);

	return (
		<>
			<a onClick={showModal}>Add New Registration</a>
			<Modal
				width={700}
				className="register-modal"
				title="Upload Photos"
				open={isModalOpen}
				onOk={handleOk}
				okText={'Submit'}
				okButtonProps={{ disabled: succeededUpload === 0 }}
				onCancel={handleCancel}
				centered={true}
				confirmLoading={confirmLoading}
				maskClosable={false}
			>
				<p>
					Please upload photos of the user with different angles (recommended 5
					angles in total), each under 5MB in size. The photos must contain only
					one face and the face must be clearly visible, without any sunglasses
					or other obstructions.
				</p>
				<br />
				<UserFaceUpload
					registrationId={registrationId}
					setSucceededUpload={setSucceededUpload}
					fileList={fileList}
					setFileList={setFileList}
				/>
			</Modal>
		</>
	);
};

export default React.memo(UserFaceUploadButton);
