import React, { useState } from 'react';
import {
	setCustomerCount,
	setCustomerList,
	setLoading,
} from '../../../../store/customerReducer';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { type RootState } from '../../../../store/store';
import {
	type StartResponse,
	type CustomerResponseCustomer,
} from '../../../../tools/openapi';
import CustomerDeleteConfirmationModal from './CustomerDeleteConfirmationModal';
import useMgmtApiWrapper from '../../../../hooks/openapi/useMgmtApiWrapper';
interface Props {
	record: CustomerResponseCustomer;
}

const CustomerInRowDelete: React.FC<Props> = (props: Props) => {
	const mgmtApiWrapper = useMgmtApiWrapper();
	const dispatch = useAppDispatch();
	const [isDeletionVisible, setIsDeletionVisible] = useState(false);

	const startState: StartResponse = useAppSelector(
		(state: RootState) => state.startState.startState
	);

	const environment = useAppSelector(
		(state: RootState) => state.environment.environment
	);

	const size = useAppSelector((state: RootState) => state.customer.PageSize);

	const page = useAppSelector((state: RootState) => state.customer.pageNumber);

	const fetchData = (): void => {
		const environmentId =
			environment?.id.length > 0
				? environment?.id
				: startState.account?.activeEnvironment?.id;
		dispatch(setLoading(true));
		const requestBody = {
			environmentId: environmentId as string,
			pageNumber: page - 1,
			pageSize: size,
		};
		void mgmtApiWrapper
			.getCustomers(requestBody)
			.then((r) => {
				if (r !== undefined) {
					dispatch(setCustomerCount(r.totalCount));
					dispatch(setCustomerList(r.customers));
				}
				dispatch(setLoading(false));
			})
	};

	const handleDelete = (id: string): void => {
		if (id != null) {
			void mgmtApiWrapper
				.deleteCustomer({ customerId: id })
				.then(() => {
					setIsDeletionVisible(false);
					fetchData();
				})
		}
	};

	return (
		<>
			<a
				onClick={() => {
					setIsDeletionVisible(true);
				}}
			>
				Delete
			</a>
			<CustomerDeleteConfirmationModal
				id={props.record.id}
				name={props.record.name}
				visible={isDeletionVisible}
				setIsDeletionVisible={setIsDeletionVisible}
				handleDelete={handleDelete}
			/>
		</>
	);
};

export default React.memo(CustomerInRowDelete);
