import React from 'react';
import { type User } from '../../../../../../tools/openapi';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../../../store/hooks';
import { type RootState } from '../../../../../../store/store';

const UserInformationOpenAccessSessions: React.FC = () => {
	const navigate = useNavigate();

	const userDetail = useAppSelector(
		(state: RootState) => state.userDetail.user as User
	);

	const openAccessSessions = (): void => {
		navigate(`/access_session?search=${userDetail.id}&type=userId`);
	};

	return (
		<a type="primary" onClick={openAccessSessions}>
			View Access Sessions
		</a>
	);
};
export default React.memo(UserInformationOpenAccessSessions);
