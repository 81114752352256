/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccessFactor } from './AccessFactor';
import {
	AccessFactorFromJSON,
	AccessFactorFromJSONTyped,
	AccessFactorToJSON,
} from './AccessFactor';
import type { SecurityScoreRating } from './SecurityScoreRating';
import {
	SecurityScoreRatingFromJSON,
	SecurityScoreRatingFromJSONTyped,
	SecurityScoreRatingToJSON,
} from './SecurityScoreRating';

/**
 *
 * @export
 * @interface SessionDetailsWithStepsResponseSession
 */
export interface SessionDetailsWithStepsResponseSession {
	/**
	 *
	 * @type {string}
	 * @memberof SessionDetailsWithStepsResponseSession
	 */
	id: string;
	/**
	 *
	 * @type {string}
	 * @memberof SessionDetailsWithStepsResponseSession
	 */
	customerId: string;
	/**
	 *
	 * @type {string}
	 * @memberof SessionDetailsWithStepsResponseSession
	 */
	customerName: string;
	/**
	 *
	 * @type {string}
	 * @memberof SessionDetailsWithStepsResponseSession
	 */
	userId?: string | null;
	/**
	 *
	 * @type {string}
	 * @memberof SessionDetailsWithStepsResponseSession
	 */
	userName?: string | null;
	/**
	 *
	 * @type {AccessFactor}
	 * @memberof SessionDetailsWithStepsResponseSession
	 */
	concludedFactor: AccessFactor;
	/**
	 *
	 * @type {number}
	 * @memberof SessionDetailsWithStepsResponseSession
	 */
	duration: number;
	/**
	 *
	 * @type {SecurityScoreRating}
	 * @memberof SessionDetailsWithStepsResponseSession
	 */
	securityScoreRating?: SecurityScoreRating | null;
	/**
	 *
	 * @type {Array<object>}
	 * @memberof SessionDetailsWithStepsResponseSession
	 */
	customData?: Array<object> | null;
}

/**
 * Check if a given object implements the SessionDetailsWithStepsResponseSession interface.
 */
export function instanceOfSessionDetailsWithStepsResponseSession(
	value: object
): boolean {
	let isInstance = true;
	isInstance = isInstance && 'id' in value;
	isInstance = isInstance && 'customerId' in value;
	isInstance = isInstance && 'customerName' in value;
	isInstance = isInstance && 'concludedFactor' in value;
	isInstance = isInstance && 'duration' in value;

	return isInstance;
}

export function SessionDetailsWithStepsResponseSessionFromJSON(
	json: any
): SessionDetailsWithStepsResponseSession {
	return SessionDetailsWithStepsResponseSessionFromJSONTyped(json, false);
}

export function SessionDetailsWithStepsResponseSessionFromJSONTyped(
	json: any,
	ignoreDiscriminator: boolean
): SessionDetailsWithStepsResponseSession {
	if (json === undefined || json === null) {
		return json;
	}
	return {
		id: json['id'],
		customerId: json['customerId'],
		customerName: json['customerName'],
		userId: !exists(json, 'userId') ? undefined : json['userId'],
		userName: !exists(json, 'userName') ? undefined : json['userName'],
		concludedFactor: AccessFactorFromJSON(json['concludedFactor']),
		duration: json['duration'],
		securityScoreRating: !exists(json, 'securityScoreRating')
			? undefined
			: SecurityScoreRatingFromJSON(json['securityScoreRating']),
		customData: !exists(json, 'customData') ? undefined : json['customData'],
	};
}

export function SessionDetailsWithStepsResponseSessionToJSON(
	value?: SessionDetailsWithStepsResponseSession | null
): any {
	if (value === undefined) {
		return undefined;
	}
	if (value === null) {
		return null;
	}
	return {
		id: value.id,
		customerId: value.customerId,
		customerName: value.customerName,
		userId: value.userId,
		userName: value.userName,
		concludedFactor: AccessFactorToJSON(value.concludedFactor),
		duration: value.duration,
		securityScoreRating: SecurityScoreRatingToJSON(value.securityScoreRating),
		customData: value.customData,
	};
}
