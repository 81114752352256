import { Button, Image, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import * as blazeface from '@tensorflow-models/blazeface';
import * as tf from '@tensorflow/tfjs';
import { type Params, useParams } from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import useMgmtApiWrapper from '../hooks/openapi/useMgmtApiWrapper';
import {
	type ConfirmFaceAccessRequest,
	type FaceAccessResponseIdentified,
} from '../tools/openapi';
import PinAccess from './PinAccess';
import ManualAccess from './ManualAccess';

interface RouteParams extends Params {
	customerId: string;
	environmentId: string;
}

const WebSDK: React.FC = () => {
	const mgmtApi = useMgmtApiWrapper();
	const { customerId, environmentId } = useParams<RouteParams>();

	const [model, setModel] = useState<blazeface.BlazeFaceModel | null>(null);
	const [currentStep, setCurrentStep] = useState<string>('Face');
	const [identified, setIdentified] = useState<boolean>(false);
	const [matchFound, setMatchFound] = useState<boolean>(false);
	const [identifiedDetail, setIdentifiedDetail] =
		useState<FaceAccessResponseIdentified>();
	const [identifying, setIdentifying] = useState<boolean>(false);
	const [capturedPhoto, setCaturedPhoto] = useState<string>();
	const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();

	const videoRef = useRef<HTMLVideoElement>(null);
	const canvasRef = useRef<HTMLCanvasElement>(null);

	const loadModel = async (): Promise<void> => {
		const m = await blazeface.load();
		setModel(m);
	};

	useEffect(() => {
		void tf.setBackend('webgl');
		void loadModel();
	}, []);

	const accessCamera = (): void => {
		void navigator.mediaDevices
			.getUserMedia({
				video: { width: 500, height: 400 },
				audio: false,
			})
			.then((stream) => {
				if (videoRef.current != null) {
					videoRef.current.srcObject = stream;
				}
			});
	};

	const faceAccess = async (): Promise<void> => {
		clearInterval(intervalId);
		setIdentifying(true);
		const blob = await new Promise<Blob | null>((resolve) =>
			canvasRef.current?.toBlob((blob) => {
				resolve(blob);
			}, 'image/jpeg')
		);
		if (blob != null && environmentId != null && customerId != null) {
			void mgmtApi
				.faceAccess({ customerId, environmentId, file: blob })
				.then((r) => {
					setIdentifying(false);
					if (r !== undefined) {
						if (r.identified != null) {
							setIdentified(true);
							setMatchFound(true);
							setIdentifiedDetail(r.identified);
							setCaturedPhoto(r.photoUrl);
						} else if (r.noMatch != null) {
							setIdentified(true);
							setMatchFound(false);
							setIdentifiedDetail(undefined);
							setCaturedPhoto(r.photoUrl);
						}
					}
				});
		}
	};

	const confirmAccess = (): void => {
		if (identifiedDetail !== undefined && environmentId != null) {
			const requestBody: ConfirmFaceAccessRequest = {
				environmentId,
				stepId: identifiedDetail.stepId,
			};
			void mgmtApi
				.confirmFaceAccess({ confirmFaceAccessRequest: requestBody })
				.then((r) => {
					console.log(r);
				});
		}
	};

	const detectFaces = async (): Promise<void> => {
		if (identified || identifying) return;
		if (model != null && videoRef.current != null) {
			const predictions = await model.estimateFaces(videoRef.current, false);
			if (canvasRef.current != null) {
				const ctx = canvasRef.current.getContext('2d');
				if (ctx != null) {
					ctx.drawImage(videoRef.current, 0, 0, 500, 400);
					predictions.forEach((prediction: any) => {
						ctx.beginPath();
						ctx.lineWidth = 4;
						ctx.strokeStyle = 'yellow';
						ctx.rect(
							prediction.topLeft[0],
							prediction.topLeft[1],
							prediction.bottomRight[0] - prediction.topLeft[0],
							prediction.bottomRight[1] - prediction.topLeft[1]
						);
						ctx.stroke();
					});
				}
			}
		}
	};

	const openCamera = (): void => {
		setIdentified(false);
		setIdentifying(false);
		setIdentifiedDetail(undefined);
		setCaturedPhoto('');
		setMatchFound(false);
		accessCamera();
		if (videoRef.current != null) {
			videoRef.current.addEventListener('loadeddata', () => {
				setIntervalId(
					setInterval(() => {
						void detectFaces();
					}, 40)
				);
			});
		}
	};

	useEffect(() => {
		if (
			model !== null &&
			canvasRef.current !== null &&
			videoRef.current !== null
		) {
			const debouncedFaceAccess = setTimeout(() => {
				void faceAccess();
			}, 1500);

			return () => {
				clearTimeout(debouncedFaceAccess);
			};
		}
	}, [intervalId]);

	if (currentStep === 'Face') {
		return (
			<div>
				<h1>
					Facial Detection <Button onClick={openCamera}>Open Camera</Button>
				</h1>
				{identifying ? (
					<Spin className="loading flex-all-center" size="large" />
				) : identified ? (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Image src={capturedPhoto} style={{ marginRight: '15px' }} />
						<Title level={3} style={{ marginRight: '15px' }}>
							{identifiedDetail !== undefined
								? identifiedDetail.name
								: 'No Match'}
						</Title>
						{matchFound ? (
							<Button onClick={confirmAccess}>Confirm</Button>
						) : (
							<>
								<Button onClick={openCamera}>Try Again</Button>
								<Button
									onClick={() => {
										setCurrentStep('Pin');
									}}
								>
									Use Pin
								</Button>
							</>
						)}
					</div>
				) : (
					<>
						<canvas
							ref={canvasRef}
							id="canvas"
							width="500"
							height="400"
						></canvas>
					</>
				)}
				<video
					ref={videoRef}
					id="video"
					autoPlay
					style={{ display: 'none', transform: 'scaleY(-1)' }}
				/>
			</div>
		);
	} else if (currentStep === 'Pin') {
		return <PinAccess setCurrentStep={setCurrentStep} />;
	} else {
		return <ManualAccess />;
	}
};

export default WebSDK;
