/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StartResponseAccount } from './StartResponseAccount';
import {
    StartResponseAccountFromJSON,
    StartResponseAccountFromJSONTyped,
    StartResponseAccountToJSON,
} from './StartResponseAccount';
import type { StartResponseEnvironment } from './StartResponseEnvironment';
import {
    StartResponseEnvironmentFromJSON,
    StartResponseEnvironmentFromJSONTyped,
    StartResponseEnvironmentToJSON,
} from './StartResponseEnvironment';
import type { StartResponseUser } from './StartResponseUser';
import {
    StartResponseUserFromJSON,
    StartResponseUserFromJSONTyped,
    StartResponseUserToJSON,
} from './StartResponseUser';

/**
 * 
 * @export
 * @interface StartResponseBootstrapAccount
 */
export interface StartResponseBootstrapAccount {
    /**
     * 
     * @type {StartResponseAccount}
     * @memberof StartResponseBootstrapAccount
     */
    account: StartResponseAccount;
    /**
     * 
     * @type {StartResponseEnvironment}
     * @memberof StartResponseBootstrapAccount
     */
    activeEnvironment: StartResponseEnvironment;
    /**
     * 
     * @type {StartResponseUser}
     * @memberof StartResponseBootstrapAccount
     */
    accountMember: StartResponseUser;
}

/**
 * Check if a given object implements the StartResponseBootstrapAccount interface.
 */
export function instanceOfStartResponseBootstrapAccount(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "account" in value;
    isInstance = isInstance && "activeEnvironment" in value;
    isInstance = isInstance && "accountMember" in value;

    return isInstance;
}

export function StartResponseBootstrapAccountFromJSON(json: any): StartResponseBootstrapAccount {
    return StartResponseBootstrapAccountFromJSONTyped(json, false);
}

export function StartResponseBootstrapAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): StartResponseBootstrapAccount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account': StartResponseAccountFromJSON(json['account']),
        'activeEnvironment': StartResponseEnvironmentFromJSON(json['activeEnvironment']),
        'accountMember': StartResponseUserFromJSON(json['accountMember']),
    };
}

export function StartResponseBootstrapAccountToJSON(value?: StartResponseBootstrapAccount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account': StartResponseAccountToJSON(value.account),
        'activeEnvironment': StartResponseEnvironmentToJSON(value.activeEnvironment),
        'accountMember': StartResponseUserToJSON(value.accountMember),
    };
}

