/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PinAccessResponseIdentified } from './PinAccessResponseIdentified';
import {
    PinAccessResponseIdentifiedFromJSON,
    PinAccessResponseIdentifiedFromJSONTyped,
    PinAccessResponseIdentifiedToJSON,
} from './PinAccessResponseIdentified';
import type { PinAccessResponseNoMatch } from './PinAccessResponseNoMatch';
import {
    PinAccessResponseNoMatchFromJSON,
    PinAccessResponseNoMatchFromJSONTyped,
    PinAccessResponseNoMatchToJSON,
} from './PinAccessResponseNoMatch';

/**
 * 
 * @export
 * @interface PinAccessResponse
 */
export interface PinAccessResponse {
    /**
     * 
     * @type {string}
     * @memberof PinAccessResponse
     */
    sessionId: string;
    /**
     * 
     * @type {string}
     * @memberof PinAccessResponse
     */
    stepId: string;
    /**
     * 
     * @type {PinAccessResponseIdentified}
     * @memberof PinAccessResponse
     */
    identified?: PinAccessResponseIdentified | null;
    /**
     * 
     * @type {PinAccessResponseNoMatch}
     * @memberof PinAccessResponse
     */
    noMatch?: PinAccessResponseNoMatch | null;
}

/**
 * Check if a given object implements the PinAccessResponse interface.
 */
export function instanceOfPinAccessResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sessionId" in value;
    isInstance = isInstance && "stepId" in value;

    return isInstance;
}

export function PinAccessResponseFromJSON(json: any): PinAccessResponse {
    return PinAccessResponseFromJSONTyped(json, false);
}

export function PinAccessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PinAccessResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessionId': json['sessionId'],
        'stepId': json['stepId'],
        'identified': !exists(json, 'identified') ? undefined : PinAccessResponseIdentifiedFromJSON(json['identified']),
        'noMatch': !exists(json, 'noMatch') ? undefined : PinAccessResponseNoMatchFromJSON(json['noMatch']),
    };
}

export function PinAccessResponseToJSON(value?: PinAccessResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sessionId': value.sessionId,
        'stepId': value.stepId,
        'identified': PinAccessResponseIdentifiedToJSON(value.identified),
        'noMatch': PinAccessResponseNoMatchToJSON(value.noMatch),
    };
}

