/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FaceRegistrationCountResponse } from './FaceRegistrationCountResponse';
import {
    FaceRegistrationCountResponseFromJSON,
    FaceRegistrationCountResponseFromJSONTyped,
    FaceRegistrationCountResponseToJSON,
} from './FaceRegistrationCountResponse';

/**
 * 
 * @export
 * @interface FaceRegistrationsCountResponse
 */
export interface FaceRegistrationsCountResponse {
    /**
     * 
     * @type {Array<FaceRegistrationCountResponse>}
     * @memberof FaceRegistrationsCountResponse
     */
    registrationsCount: Array<FaceRegistrationCountResponse>;
}

/**
 * Check if a given object implements the FaceRegistrationsCountResponse interface.
 */
export function instanceOfFaceRegistrationsCountResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "registrationsCount" in value;

    return isInstance;
}

export function FaceRegistrationsCountResponseFromJSON(json: any): FaceRegistrationsCountResponse {
    return FaceRegistrationsCountResponseFromJSONTyped(json, false);
}

export function FaceRegistrationsCountResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FaceRegistrationsCountResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'registrationsCount': ((json['registrationsCount'] as Array<any>).map(FaceRegistrationCountResponseFromJSON)),
    };
}

export function FaceRegistrationsCountResponseToJSON(value?: FaceRegistrationsCountResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'registrationsCount': ((value.registrationsCount as Array<any>).map(FaceRegistrationCountResponseToJSON)),
    };
}

