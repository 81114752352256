import { Form } from 'antd';
import React, { useState } from 'react';
import { type User } from '../../../../../../tools/openapi/models/User';
import { useAppSelector } from '../../../../../../store/hooks';
import { type RootState } from '../../../../../../store/store';
import UserInformationEditModal from '../../UserInformationEditModal/UserInformationEditModal';
interface Props {
	fetchUser: () => void;
}

const UserEditButton: React.FC<Props> = (props: Props) => {
	const userDetail = useAppSelector(
		(state: RootState) => state.userDetail.user as User
	);

	const [form] = Form.useForm();
	const [openEditModal, setOpenEditModal] = useState(false);

	return (
		<>
			<a
				onClick={() => {
					form.resetFields();
					form.setFieldsValue({
						name: userDetail.name,
						firstName: userDetail.firstName,
						lastName: userDetail.lastName,
						email: userDetail.email,
						phone: userDetail.phone,
						pin: userDetail.pin
					});
					setOpenEditModal(true);
				}}
			>
				Edit User
			</a>
			<UserInformationEditModal
				openEditModal={openEditModal}
				setOpenEditModal={setOpenEditModal}
				fetchUser={props.fetchUser}
				form={form}
			/>
		</>
	);
};

export default UserEditButton;
