/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useRef } from 'react';
import { Spin } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import DataTable from '../../../../shared/DataTable';
import {
	type StartResponse,
	type UsersResponseUser,
} from '../../../../tools/openapi';
import { setPageNumber, setPageSize } from '../../../../store/usersReducer';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { type RootState } from '../../../../store/store';
import '../Users.scss';
import UserDropdownButton from './UserDropdownButton';
import UserNameAvatar from './UserNameAvatar';
import UserRegistrationStatus from './UserRegistrationStatus';

interface Props {
	tableMode?: string;
	customColunmns?: ColumnsType<UsersResponseUser>;
}

const UsersTable: React.FC<Props> = (props: Props) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const tableRef = useRef<HTMLDivElement | null>(null);
	const isFirstRender = useRef(true);

	const startState: StartResponse = useAppSelector(
		(state: RootState) => state.startState.startState
	);

	const users = useAppSelector((state: RootState) => state.user.users);

	const userCount = useAppSelector((state: RootState) => state.user.count);

	const page = useAppSelector((state: RootState) => state.user.pageNumber);

	const size = useAppSelector((state: RootState) => state.user.PageSize);

	const useCustomPagination = useAppSelector(
		(state: RootState) => state.user.useCustomPagination
	);

	const loading = useAppSelector((state: RootState) => state.user.loading);

	const openUserDetail = (id: string): void => {
		navigate(`/user/detail/${id}`);
	};

	const scrollToFirstRow = (): void => {
		if (!isFirstRender.current && tableRef.current != null) {
			tableRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	const pageChange = (page: number): void => {
		dispatch(setPageNumber(page));
	};

	const pageSizeChange = (cur: number, size: number): void => {
		dispatch(setPageSize(size));
	};

	useEffect(() => {
		dispatch(setPageNumber(1));
	}, [size]);

	useEffect(() => {
		scrollToFirstRow();
	}, [page, size]);

	useEffect(() => {
		isFirstRender.current = false;
	}, []);

	const columns: ColumnsType<UsersResponseUser> = [
		{
			title: 'Name',
			render: (_, record) => <UserNameAvatar record={record} />,
		},
		{
			title: 'Customer',
			render: (_, record) => (
				<span className={record.active ? '' : 'inactive-user'}>
					{record.customerName}
				</span>
			),
		},
		{
			title: 'Status',
			render: (_, record) => <UserRegistrationStatus record={record} />,
		},
		{
			title: 'Last Access',
			render: (_, record) => (
				<span className={record.active ? '' : 'inactive-user'}>
					{record.lastAccessed?.toLocaleString()}
				</span>
			),
		},
		{
			title: 'Access Count',
			render: (_, record) => (
				<span className={record.active ? '' : 'inactive-user'}>
					{record.totalAccessed}
				</span>
			),
		},
		{
			title: 'Actions',
			render: (_, record) => <UserDropdownButton id={record.id} />,
		},
	];

	if (loading) {
		return <Spin className="loading flex-all-center" size="large" />;
	} else {
		return (
			<div ref={tableRef}>
				<DataTable
					onRow={
						props.tableMode === 'ManualAccess'
							? undefined
							: (record: UsersResponseUser) => {
									return {
										onClick: (): void => {
											openUserDetail(record.id);
										},
										style: { cursor: 'pointer' },
									};
							  }
					}
					data={users}
					columns={
						props.customColunmns !== undefined
							? props.customColunmns
							: startState.account?.account.singleCustomer
							? columns
							: columns?.filter((column) => column.title !== 'Customer')
					}
					rowKey={'id'}
					tableStyle={undefined}
					pagination={true}
					paginationOption={{
						page,
						pageChange,
						customPagination: useCustomPagination,
						pageSize: size,
						sizeChange: pageSizeChange,
						totalCount: userCount,
						showSizeChanger: true,
						pageSizeOptions: [10, 20, 50],
					}}
				/>
			</div>
		);
	}
};

export default React.memo(UsersTable);
