/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const FaceRegistrationStatus = {
    Submitted: 'SUBMITTED',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Rejected: 'REJECTED',
    Withdrawn: 'WITHDRAWN'
} as const;
export type FaceRegistrationStatus = typeof FaceRegistrationStatus[keyof typeof FaceRegistrationStatus];


export function FaceRegistrationStatusFromJSON(json: any): FaceRegistrationStatus {
    return FaceRegistrationStatusFromJSONTyped(json, false);
}

export function FaceRegistrationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): FaceRegistrationStatus {
    return json as FaceRegistrationStatus;
}

export function FaceRegistrationStatusToJSON(value?: FaceRegistrationStatus | null): any {
    return value as any;
}

