import { Checkbox } from 'antd';
import React from 'react';

interface Props {
	includeUnsuccessful: boolean;
	setIncludeUnsuccessful: (e: boolean) => void;
}

const AccessSessionsIncludeUnsuccessfulCheckbox: React.FC<Props> = (
	props: Props
) => {
	return (
		<div style={{ marginLeft: '16px' }}>
			<Checkbox
				checked={props.includeUnsuccessful}
				onChange={(e) => {
					props.setIncludeUnsuccessful(e.target.checked);
				}}
			>
				<span style={{ whiteSpace: 'nowrap' }}>
					Include Unsuccessful Sessions
				</span>
			</Checkbox>
		</div>
	);
};

export default React.memo(AccessSessionsIncludeUnsuccessfulCheckbox);
