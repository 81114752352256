/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccessFactor } from './AccessFactor';
import {
    AccessFactorFromJSON,
    AccessFactorFromJSONTyped,
    AccessFactorToJSON,
} from './AccessFactor';

/**
 * 
 * @export
 * @interface SessionDetailsWithStepsResponseStep
 */
export interface SessionDetailsWithStepsResponseStep {
    /**
     * 
     * @type {Date}
     * @memberof SessionDetailsWithStepsResponseStep
     */
    time: Date;
    /**
     * 
     * @type {AccessFactor}
     * @memberof SessionDetailsWithStepsResponseStep
     */
    factor: AccessFactor;
    /**
     * 
     * @type {boolean}
     * @memberof SessionDetailsWithStepsResponseStep
     */
    identified: boolean;
    /**
     * 
     * @type {string}
     * @memberof SessionDetailsWithStepsResponseStep
     */
    photoUrl?: string | null;
}

/**
 * Check if a given object implements the SessionDetailsWithStepsResponseStep interface.
 */
export function instanceOfSessionDetailsWithStepsResponseStep(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "time" in value;
    isInstance = isInstance && "factor" in value;
    isInstance = isInstance && "identified" in value;

    return isInstance;
}

export function SessionDetailsWithStepsResponseStepFromJSON(json: any): SessionDetailsWithStepsResponseStep {
    return SessionDetailsWithStepsResponseStepFromJSONTyped(json, false);
}

export function SessionDetailsWithStepsResponseStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionDetailsWithStepsResponseStep {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'time': (new Date(json['time'])),
        'factor': AccessFactorFromJSON(json['factor']),
        'identified': json['identified'],
        'photoUrl': !exists(json, 'photoUrl') ? undefined : json['photoUrl'],
    };
}

export function SessionDetailsWithStepsResponseStepToJSON(value?: SessionDetailsWithStepsResponseStep | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'time': (value.time.toISOString()),
        'factor': AccessFactorToJSON(value.factor),
        'identified': value.identified,
        'photoUrl': value.photoUrl,
    };
}

