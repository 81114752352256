import InformationTab from '../../../shared/InformationTab';
import CustomerInformationTab from './CustomerInformationTab/CustomerInformationTab';
import { type TabsProps } from 'antd';
import React from 'react';
import CustomerSettings from './CustomerSettings/CustomerSettings';

const CustomerDetail: React.FC = () => {
	const items: TabsProps['items'] = [
		{
			key: '1',
			label: 'Information',
			children: <CustomerInformationTab />,
		},
		{
			key: '2',
			label: 'Settings',
			children: <CustomerSettings />,
		},
	];

	return (
		<div style={{ padding: '1em', width: '100%' }}>
			<InformationTab items={items} />
		</div>
	);
};

export default React.memo(CustomerDetail);
