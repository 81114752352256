import React, { useEffect, useRef } from 'react';
import { Spin, Avatar } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import DataTable from '../../../../shared/DataTable';
import {
	type StartResponse,
	type AccessSessionHistoriesResponseAccessSession,
} from '../../../../tools/openapi';
import {
	setPageNumber,
	setPageSize,
} from '../../../../store/accessSessionReducer';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { type RootState } from '../../../../store/store';
import '../AccessSession.scss';
import AccessSessionsDropdownButton from './AccessSessionsDropdownButton';
import AccessSessionsFactorWithIcon from './AccessSessionsFactorWithIcon';
import { useNavigate } from 'react-router-dom';

const AccessSessionsTable: React.FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const tableRef = useRef<HTMLDivElement | null>(null);
	const isFirstRender = useRef(true);

	const startState: StartResponse = useAppSelector(
		(state: RootState) => state.startState.startState
	);

	const accessSessions = useAppSelector(
		(state: RootState) => state.accessSession.accessSessions
	);

	const page = useAppSelector(
		(state: RootState) => state.accessSession.pageNumber
	);

	const size = useAppSelector(
		(state: RootState) => state.accessSession.PageSize
	);

	const loading = useAppSelector(
		(state: RootState) => state.accessSession.loading
	);

	const openAccessSessionDetail = (id: string): void => {
		navigate(`/access_session/detail/${id}`);
	};

	const rowClassName = (
		record: AccessSessionHistoriesResponseAccessSession
	): string => {
		if (record.succeeded === false) {
			return 'unsuccessful-session';
		}
		return '';
	};

	const scrollToFirstRow = (): void => {
		if (!isFirstRender.current && tableRef.current != null) {
			tableRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	const pageChange = (page: number): void => {
		dispatch(setPageNumber(page));
	};

	const pageSizeChange = (cur: number, size: number): void => {
		dispatch(setPageSize(size));
	};

	useEffect(() => {
		dispatch(setPageNumber(1));
	}, [size]);

	useEffect(() => {
		scrollToFirstRow();
	}, [page, size]);

	useEffect(() => {
		isFirstRender.current = false;
	}, []);

	const columns: ColumnsType<AccessSessionHistoriesResponseAccessSession> = [
		{
			title: 'Name',
			dataIndex: 'name',
			render: (_, record) => <span>{record.userName}</span>,
		},
		{
			title: 'Customer',
			dataIndex: 'customer',
			render: (_, record) => <span>{record.customerName}</span>,
		},
		{
			title: 'Time',
			dataIndex: 'startedAt',
			render: (_, record) => <span>{record.startedAt?.toLocaleString()}</span>,
		},
		{
			title: 'Factor',
			dataIndex: 'concludedFactor',
			render: (_, record) => <AccessSessionsFactorWithIcon record={record} />,
		},
		{
			title: 'Photo',
			render: (_, record) => (
				<>
					{record.photoUrl != null ? (
						<Avatar size={40} src={record.photoUrl} />
					) : (
						''
					)}
				</>
			),
		},
		{
			title: 'Actions',
			render: (_, record) => <AccessSessionsDropdownButton id={record.id} />,
		},
	];

	if (loading) {
		return <Spin className="loading flex-all-center" size="large" />;
	} else if (startState.account?.account.singleCustomer === true) {
		return (
			<div ref={tableRef}>
				<DataTable
					rowClassName={rowClassName}
					onRow={(record: AccessSessionHistoriesResponseAccessSession) => {
						return {
							onClick: (): void => {
								openAccessSessionDetail(record.id);
							},
							style: { cursor: 'pointer' },
						};
					}}
					data={accessSessions}
					columns={columns?.filter((column) => column.title !== 'Customer')}
					rowKey={'id'}
					tableStyle={undefined}
					pagination={true}
					paginationOption={{
						page,
						pageChange,
						pageSize: size,
						customPagination: true,
						sizeChange: pageSizeChange,
						totalCount: 0,
						showSizeChanger: true,
						pageSizeOptions: [10, 20, 50],
					}}
				/>
			</div>
		);
	} else {
		return (
			<div ref={tableRef}>
				<DataTable
					rowClassName={rowClassName}
					onRow={(record: AccessSessionHistoriesResponseAccessSession) => {
						return {
							onClick: (): void => {
								openAccessSessionDetail(record.id);
							},
							style: { cursor: 'pointer' },
						};
					}}
					data={accessSessions}
					columns={columns}
					rowKey={'id'}
					tableStyle={undefined}
					pagination={true}
					paginationOption={{
						page,
						pageChange,
						pageSize: size,
						customPagination: true,
						sizeChange: pageSizeChange,
						totalCount: 0,
						showSizeChanger: true,
						pageSizeOptions: [10, 20, 50],
					}}
				/>
			</div>
		);
	}
};

export default React.memo(AccessSessionsTable);
