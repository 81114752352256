import React, { useState } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { json, jsonParseLinter } from '@codemirror/lang-json';
import Title from 'antd/es/typography/Title';
import { Button } from 'antd';
import '../AccessSessionDetail.scss';
import { useAppSelector } from '../../../../store/hooks';
import { type RootState } from '../../../../store/store';
import {
	type SessionDetailsWithStepsResponse,
	type UpdateCustomDataByIdRequest,
} from '../../../../tools/openapi';
import useMgmtApiWrapper from '../../../../hooks/openapi/useMgmtApiWrapper';
import { linter } from '@codemirror/lint'

const AccessSessionCustomData: React.FC = () => {
	const { session } = useAppSelector(
		(state: RootState) =>
			state.accessSessionDetail
				.sessionDetailWithSteps as SessionDetailsWithStepsResponse
	);
	const mgmtApiWrapper = useMgmtApiWrapper();

	const [customData, setCustomData] = useState(
		JSON.stringify(session.customData, null, 2)
	);
    const [syntaxError, setSyntaxError] = useState('');

    const linterExtension = linter(jsonParseLinter())

	const onChange = React.useCallback((value: string) => {
		console.log('value:', value);
        try {
            JSON.parse(value);
            setSyntaxError('');
          } catch (error) {
            if(error instanceof Error){
            setSyntaxError(error.message);
            }
          }
		setCustomData(value);
	}, []);

	const handleSaveData = (): void => {
		const requestBody: UpdateCustomDataByIdRequest = {
			sessionId: session.id,
			updatedCustomDataRequest: {customData},
		};
		void mgmtApiWrapper.updateCustomDataById(requestBody);
	};

	return (
		<section className="section">
			<Title className="h5" level={5}>
				Custom Data
			</Title>
			<CodeMirror
				theme={'dark'}
				value={customData}
				extensions={[json(), linterExtension]}
				onChange={onChange}
                maxWidth='1000px'
			/>
            {(syntaxError.length > 0) && <div style={{color: 'red'}}>{syntaxError}</div>}
			<div className="custom-save-button">
				<Button onClick={handleSaveData} disabled={(syntaxError.length > 0)}>Save</Button>
			</div>
		</section>
	);
};

export default React.memo(AccessSessionCustomData);
