import { message } from 'antd';
import React, { useState } from 'react';
import UserDeleteConfirmationModal from './UserDeleteConfirmationModal';
import useMgmtApiWrapper from '../../../../hooks/openapi/useMgmtApiWrapper';

interface Props {
	id: string;
}

const UserInRowDelete: React.FC<Props> = (props: Props) => {
	const mgmtApiWrapper = useMgmtApiWrapper();
	const [isDeletionVisible, setIsDeletionVisible] = useState(false);

	const handleDelete = (id: string): void => {
		if (id != null) {
			void mgmtApiWrapper
				.deleteUser({ userId: id })
				.then(() => {
					void message.success('User deleted successfully');
					setIsDeletionVisible(false);
				})
		}
	};

	return (
		<>
			<a
				onClick={() => {
					setIsDeletionVisible(true);
				}}
			>
				Delete
			</a>
			<UserDeleteConfirmationModal
				id={props.id}
				visible={isDeletionVisible}
				setIsDeletionVisible={setIsDeletionVisible}
				handleDelete={handleDelete}
			/>
		</>
	);
};

export default React.memo(UserInRowDelete);
