import React from 'react';
import Title from 'antd/es/typography/Title';
import { type User } from '../../../../../tools/openapi';
import '../../UserDetail.scss';
import { useAppSelector } from '../../../../../store/hooks';
import { type RootState } from '../../../../../store/store';

const UserInformationTitle: React.FC = () => {
	const userDetail = useAppSelector(
		(state: RootState) => state.userDetail.user as User
	);

	return (
		<Title level={2} className={userDetail.active ? '' : 'inactive-user'}>
			{userDetail.name != null && userDetail.name.length > 0
				? userDetail.name
				: 'Not Given'}
			{userDetail.active ? '' : ' (Inactive)'}
		</Title>
	);
};
export default React.memo(UserInformationTitle);
