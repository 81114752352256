/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BatchCreateCustomersRequestCustomer
 */
export interface BatchCreateCustomersRequestCustomer {
    /**
     * 
     * @type {string}
     * @memberof BatchCreateCustomersRequestCustomer
     */
    name: string;
}

/**
 * Check if a given object implements the BatchCreateCustomersRequestCustomer interface.
 */
export function instanceOfBatchCreateCustomersRequestCustomer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function BatchCreateCustomersRequestCustomerFromJSON(json: any): BatchCreateCustomersRequestCustomer {
    return BatchCreateCustomersRequestCustomerFromJSONTyped(json, false);
}

export function BatchCreateCustomersRequestCustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchCreateCustomersRequestCustomer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
    };
}

export function BatchCreateCustomersRequestCustomerToJSON(value?: BatchCreateCustomersRequestCustomer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
    };
}

