import { AutoComplete, Input } from 'antd';
import { type FormInstance } from 'antd/es/form';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { type RootState } from '../../../../store/store';
import { type StartResponse } from '../../../../tools/openapi';
import '../Users.scss';
import useMgmtApiWrapper from '../../../../hooks/openapi/useMgmtApiWrapper';

interface CustomerInfo {
	label: string;
	value: string;
	key: string;
}

interface Props {
	form: FormInstance;
}

const UserCreateModalAutoComplete: React.FC<Props> = (props: Props) => {
	const mgmtApiWrapper = useMgmtApiWrapper();

	const startState: StartResponse = useAppSelector(
		(state: RootState) => state.startState.startState
	);

	const environment = useAppSelector(
		(state: RootState) => state.environment.environment
	);

	const [customerList, setCustomerList] = useState<CustomerInfo[]>([]);
	const [customerOptions, setCustomerOptions] = useState<CustomerInfo[]>([]);

	const handleSearch = (inputText: string): void => {
		if (inputText === '' || inputText === undefined) {
			setCustomerOptions([]);
		} else {
			const filteredCustomerList = customerList.filter((option) =>
				option.label.toLowerCase().includes(inputText.toLowerCase())
			);

			setCustomerOptions(filteredCustomerList);
		}
	};

	useEffect(() => {
		const environmentId =
			environment?.id.length > 0
				? environment?.id
				: startState.account?.activeEnvironment?.id;
		void mgmtApiWrapper
			.getCustomers({ environmentId: environmentId as string })
			.then((r) => {
				if (r !== undefined) {
					const customerInfoList = r.customers.map((customer) => ({
						value: `${customer.name} (${customer.id})`,
						label: `${customer.name} (${customer.id})`,
						key: customer.id,
					}));

					setCustomerList(customerInfoList);
				}
			})
	}, []);

	return (
		<AutoComplete
			options={customerOptions}
			onSelect={(value, option) => {
				props.form.setFieldsValue({ customerId: option.key, customerName: option.value });
			}}
			placeholder="Enter Customer"
			notFoundContent="No Matched Customer Found"
			defaultOpen={false}
			allowClear={true}
			onSearch={handleSearch}
		>
			<Input />
		</AutoComplete>
	);
};

export default React.memo(UserCreateModalAutoComplete);
