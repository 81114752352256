import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DangerZone from '../../../../../shared/DangerZone';
import DeleteConfirmation from '../../../../../shared/DeleteConfirmation';
import { useAppSelector } from '../../../../../store/hooks';
import { type RootState } from '../../../../../store/store';
import { type CustomerResponseCustomer } from '../../../../../tools/openapi';
import useMgmtApiWrapper from '../../../../../hooks/openapi/useMgmtApiWrapper';

const CustomerDeletion: React.FC = () => {
	const mgmtApiWrapper = useMgmtApiWrapper();
	const navigate = useNavigate();
	
	const customerDetail = useAppSelector(
		(state: RootState) =>
			state.customerDetail.customer as CustomerResponseCustomer
	);

	const [isModalVisible, setIsModalVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');


	const handleDelete = (): void => {
		void mgmtApiWrapper
			.deleteCustomer({ customerId: customerDetail.id })
			.then(() => {
				setIsModalVisible(false);
				navigate(`/customer`);
			})
	};

	return (
		<>
			<DangerZone
				title="Delete Customer"
				description="This customer will be removed and it will no longer have the application acess."
				onConfirm={() => {
					setIsModalVisible(true);
				}}
			/>
			<DeleteConfirmation
				title="Are you sure? "
				message={`This action will permanently delete the customer. It cannot be undone. `}
				messageAdd={`Please enter the customer name "${customerDetail.name}" to confirm.`}
				placeholder={'Type the customer name to confirm.'}
				visible={isModalVisible}
				deleDisabled={inputValue !== customerDetail.name}
				centered={true}
				inputDisplay={'inline'}
				onCancel={() => {
					setIsModalVisible(false);
				}}
				onConfirm={handleDelete}
				onInputChange={(value) => {
					setInputValue(value);
				}}
			/>
		</>
	);
};

export default React.memo(CustomerDeletion);
