/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FaceRegistration } from './FaceRegistration';
import {
    FaceRegistrationFromJSON,
    FaceRegistrationFromJSONTyped,
    FaceRegistrationToJSON,
} from './FaceRegistration';

/**
 * 
 * @export
 * @interface UserRegistrationResponse
 */
export interface UserRegistrationResponse {
    /**
     * 
     * @type {FaceRegistration}
     * @memberof UserRegistrationResponse
     */
    active?: FaceRegistration | null;
    /**
     * 
     * @type {FaceRegistration}
     * @memberof UserRegistrationResponse
     */
    submitted?: FaceRegistration | null;
}

/**
 * Check if a given object implements the UserRegistrationResponse interface.
 */
export function instanceOfUserRegistrationResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserRegistrationResponseFromJSON(json: any): UserRegistrationResponse {
    return UserRegistrationResponseFromJSONTyped(json, false);
}

export function UserRegistrationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRegistrationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : FaceRegistrationFromJSON(json['active']),
        'submitted': !exists(json, 'submitted') ? undefined : FaceRegistrationFromJSON(json['submitted']),
    };
}

export function UserRegistrationResponseToJSON(value?: UserRegistrationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': FaceRegistrationToJSON(value.active),
        'submitted': FaceRegistrationToJSON(value.submitted),
    };
}

