import { Typography, Alert, Button } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoutButton from '../LogoutButton';
import './EmailVerifyPage.scss';
import useMgmtApiWrapper from '../../hooks/openapi/useMgmtApiWrapper';

const { Title } = Typography;

const EmailVerifyPage: React.FC = () => {
	const mgmtApiWrapper = useMgmtApiWrapper();

	const navigate = useNavigate();
	const verified = (): void => {
		navigate(0);
	};

	const [isEmailOnCooldown, setIsEmailOnCooldown] = useState(false);

	const resendVerificationEmail = (): void => {
		setIsEmailOnCooldown(true);
		void mgmtApiWrapper
			.resendAuth0VerificationEmail()
			.then(() => {
				void 
				setTimeout(() => {
					setIsEmailOnCooldown(false);
				}, 60000);
			})
	};

	return (
		<div className="email-verify-container">
			<div className="email-verify-box">
				<Title level={2} className="email-verify-title">
					Please Verify Your Email
				</Title>
				<Alert
					className="email-verify-alert"
					message="We've sent a verification link to your email address. Please click the link to verify your account."
					type="info"
					showIcon
				/>
				<div className="email-verify-remind">
					<p>Already verified? Click here:</p>
					<Button className="email-verified-button" onClick={verified}>
						Verified
					</Button>
				</div>
				<div className="email-verify-remind">
					<p>Did not receive verification email? Click here:</p>
					{!isEmailOnCooldown ? (
						<Button
							className="email-verified-button"
							onClick={resendVerificationEmail}
						>
							Resend
						</Button>
					) : (
						<p style={{ fontWeight: 'bold' }}>Send again after 1 minute.</p>
					)}
				</div>
				<div className="email-verify-remind">
					<p>Create by mistake?</p>
					<LogoutButton />
				</div>
			</div>
		</div>
	);
};

export default EmailVerifyPage;
