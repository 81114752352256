import { Breadcrumb } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

interface Props {
	pathname: string;
}

export interface BreadcrumbItems {
	name: string;
	path: string;
}

const BreadcrumNav: React.FC<Props> = (props: Props) => {
	const [breadcrumb, setBreadcrumb] = useState<BreadcrumbItems[]>([]);

	function toTitleCase(str: string): string {
		return str
			.split('_')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
			.join(' ');
	}

	const generateBreadcrumb = (): BreadcrumbItems[] => {
		const breadcrumb: BreadcrumbItems[] = [];

		breadcrumb.push({ name: 'Home', path: '/' });

		let currentPath = '';
		props.pathname.split('/').forEach((path) => {
			if (path.length > 0) {
				const isUuid = /^[a-f0-9]{8}-([a-f0-9]{4}-){3}[a-f0-9]{12}$/i.test(
					path
				);
				if (!isUuid && path !== "s") {
					currentPath += `/${path}`;
					breadcrumb.push({
						name: toTitleCase(path),
						path: currentPath,
					});
				}
			}
		});

		return breadcrumb;
	};

	useEffect(() => {
		setBreadcrumb(generateBreadcrumb());
	}, [props]);

	return (
		<Breadcrumb className="routeNav">
			{breadcrumb.map(({ name, path }, index) => (
				<Breadcrumb.Item key={path}>
					{index === breadcrumb.length - 1 ? (
						<span>{name}</span>
					) : (
						<Link to={path}>{name}</Link>
					)}
				</Breadcrumb.Item>
			))}
		</Breadcrumb>
	);
};

export default React.memo(BreadcrumNav);
