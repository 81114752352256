import produce from 'immer';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type EnvironmentResponseEnvironment } from '../tools/openapi';

const initialState = {
	environment: {},
};

const environmentDetailSlice = createSlice({
	name: 'environmentDetail',
	initialState,
	reducers: {
		setCurrentEnvironmentDetail: (
			state,
			action: PayloadAction<EnvironmentResponseEnvironment>
		) => {
			return produce(state, (draftState) => {
				draftState.environment = action.payload;
			});
		},
		resetCurrentEnvironmentDetail: (state) => {
			return produce(state, (draftState) => {
				draftState.environment = {};
			});
		},
	},
});

export const { setCurrentEnvironmentDetail, resetCurrentEnvironmentDetail } =
	environmentDetailSlice.actions;
export default environmentDetailSlice.reducer;
