import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { type AccessStepAttemptCountResponse } from '../../tools/openapi/models/AccessStepAttemptCountResponse';
import { Line } from '@ant-design/plots';
import useMgmtApiWrapper from '../../hooks/openapi/useMgmtApiWrapper';

interface Props {
	id: string | undefined;
}

interface LineChartData {
	date: string | null | undefined;
	type: string;
	value?: number | undefined | null;
}

const { Title } = Typography;

const ActivityPageLineChart: React.FC<Props> = (props: Props) => {
	const getStartDateOfCurrentWeek = (): Date => {
		const currentDate = new Date();
		const currentDay = currentDate.getDay();
		const daysToSunday = currentDay; // For Sunday as the first day of the week
		const startDate = new Date(currentDate);
		startDate.setDate(currentDate.getDate() - daysToSunday);
		startDate.setHours(0, 0, 0, 0); // Reset time to 00:00:00.000
		return startDate;
	};

	const getEndDateOfCurrentWeek = (): Date => {
		const currentDate = new Date();
		const currentDay = currentDate.getDay();
		const daysToSaturday = 6 - currentDay; // Calculate the number of days remaining until Saturday
		const endDate = new Date(currentDate);
		endDate.setDate(currentDate.getDate() + daysToSaturday);
		endDate.setHours(23, 59, 59, 999); // Set time to 23:59:59.999
		return endDate;
	};
	const [
		totalAccessAttemptsByEnvironment,
		setTotalAccessAttemptsByEnvironment,
	] = useState<LineChartData[]>([]);
	const [totalAccessMatchesByEnvironment, setTotalAccessMatchesByEnvironment] =
		useState<LineChartData[]>([]);
	const [startDate] = useState<Date>(getStartDateOfCurrentWeek());
	const [endDate] = useState<Date>(getEndDateOfCurrentWeek());
	const mgmtApiWrapper = useMgmtApiWrapper();

	const lineChartConfig = {
		data: [
			...totalAccessAttemptsByEnvironment,
			...totalAccessMatchesByEnvironment,
		],
		xField: 'date',
		yField: 'value',
		seriesField: 'type',
		yAxis: { title: { text: 'Number' } },
		xAxis: { tickCount: 5 },
		tooltip: { shared: true },
		lineStyle: { lineWidth: 2 },
		point: {
			size: 5,
			shape: 'circle',
			style: { fillOpacity: 1, strokeOpacity: 1, lineWidth: 2 },
		},
	};

	const transformCountsToLineChartData = (
		counts: AccessStepAttemptCountResponse[],
		type: string
	): LineChartData[] => {
		const lineChartData: LineChartData[] = [];
		counts.forEach((count: AccessStepAttemptCountResponse) => {
			lineChartData.push({
				date: count.date?.toLocaleString(),
				type,
				value: count.count,
			});
		});

		return lineChartData;
	};

	useEffect(() => {
		void mgmtApiWrapper
			.getAccessStepAttemptCount({
				environmentId: props.id,
				startDate,
				endDate,
			})
			.then((r) => {
				if (r !== undefined) {
					setTotalAccessAttemptsByEnvironment(
						transformCountsToLineChartData(
							r.accessStepAttemptCounts,
							'attempts'
						)
					);
				}
			});

		void mgmtApiWrapper
			.getAccessStepAttemptCount({
				environmentId: props.id,
				startDate,
				endDate,
				succeeded: true,
			})
			.then((r) => {
				if (r !== undefined) {
					setTotalAccessMatchesByEnvironment(
						transformCountsToLineChartData(
							r.accessStepAttemptCounts,
							'accesses'
						)
					);
				}
			});
	}, []);

	return (
		<>
			<Title level={4}>Access and Attempts</Title>
			<Line {...lineChartConfig} />
		</>
	);
};

export default React.memo(ActivityPageLineChart);
