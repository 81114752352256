/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StartResponseOnboarding
 */
export interface StartResponseOnboarding {
    /**
     * 
     * @type {string}
     * @memberof StartResponseOnboarding
     */
    authId: string;
    /**
     * 
     * @type {string}
     * @memberof StartResponseOnboarding
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof StartResponseOnboarding
     */
    picture?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartResponseOnboarding
     */
    givenName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartResponseOnboarding
     */
    familyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartResponseOnboarding
     */
    name?: string | null;
}

/**
 * Check if a given object implements the StartResponseOnboarding interface.
 */
export function instanceOfStartResponseOnboarding(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "authId" in value;
    isInstance = isInstance && "email" in value;

    return isInstance;
}

export function StartResponseOnboardingFromJSON(json: any): StartResponseOnboarding {
    return StartResponseOnboardingFromJSONTyped(json, false);
}

export function StartResponseOnboardingFromJSONTyped(json: any, ignoreDiscriminator: boolean): StartResponseOnboarding {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authId': json['authId'],
        'email': json['email'],
        'picture': !exists(json, 'picture') ? undefined : json['picture'],
        'givenName': !exists(json, 'givenName') ? undefined : json['givenName'],
        'familyName': !exists(json, 'familyName') ? undefined : json['familyName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function StartResponseOnboardingToJSON(value?: StartResponseOnboarding | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authId': value.authId,
        'email': value.email,
        'picture': value.picture,
        'givenName': value.givenName,
        'familyName': value.familyName,
        'name': value.name,
    };
}

