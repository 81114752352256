/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StartResponseBootstrapAccount } from './StartResponseBootstrapAccount';
import {
    StartResponseBootstrapAccountFromJSON,
    StartResponseBootstrapAccountFromJSONTyped,
    StartResponseBootstrapAccountToJSON,
} from './StartResponseBootstrapAccount';
import type { StartResponseOnboarding } from './StartResponseOnboarding';
import {
    StartResponseOnboardingFromJSON,
    StartResponseOnboardingFromJSONTyped,
    StartResponseOnboardingToJSON,
} from './StartResponseOnboarding';
import type { StartResponseVerifyEmail } from './StartResponseVerifyEmail';
import {
    StartResponseVerifyEmailFromJSON,
    StartResponseVerifyEmailFromJSONTyped,
    StartResponseVerifyEmailToJSON,
} from './StartResponseVerifyEmail';

/**
 * 
 * @export
 * @interface StartResponse
 */
export interface StartResponse {
    /**
     * 
     * @type {StartResponseOnboarding}
     * @memberof StartResponse
     */
    onboarding?: StartResponseOnboarding | null;
    /**
     * 
     * @type {StartResponseBootstrapAccount}
     * @memberof StartResponse
     */
    account?: StartResponseBootstrapAccount | null;
    /**
     * 
     * @type {StartResponseVerifyEmail}
     * @memberof StartResponse
     */
    verifyEmail?: StartResponseVerifyEmail | null;
}

/**
 * Check if a given object implements the StartResponse interface.
 */
export function instanceOfStartResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StartResponseFromJSON(json: any): StartResponse {
    return StartResponseFromJSONTyped(json, false);
}

export function StartResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StartResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'onboarding': !exists(json, 'onboarding') ? undefined : StartResponseOnboardingFromJSON(json['onboarding']),
        'account': !exists(json, 'account') ? undefined : StartResponseBootstrapAccountFromJSON(json['account']),
        'verifyEmail': !exists(json, 'verifyEmail') ? undefined : StartResponseVerifyEmailFromJSON(json['verifyEmail']),
    };
}

export function StartResponseToJSON(value?: StartResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'onboarding': StartResponseOnboardingToJSON(value.onboarding),
        'account': StartResponseBootstrapAccountToJSON(value.account),
        'verifyEmail': StartResponseVerifyEmailToJSON(value.verifyEmail),
    };
}

