import { Layout } from 'antd';
import React from 'react';
import './AppLayout.scss';
import AppSider from './accountLayoutComponents/AppSider';
import AppMainContent from './accountLayoutComponents/AppMainContent';
import AppHeader from './accountLayoutComponents/AppHeader';
import { useAppSelector } from '../store/hooks';
import { type RootState } from '../store/store';
import { type StartResponse } from '../tools/openapi';
import SingleCustomerHeader from './singleCustomerLayoutComponents/SingleCustomerHeader';
import SingleCustomerSider from './singleCustomerLayoutComponents/SingleCustomerSider';
import SingleCustomerMainContent from './singleCustomerLayoutComponents/SingleCustomerMainContent';

const AppLayout: React.FC = () => {
	const startState: StartResponse = useAppSelector(
		(state: RootState) => state.startState.startState
	);

	if (startState.account?.account.singleCustomer === true) {
		return (
			<>
				<Layout className="windowLayout">
					<SingleCustomerHeader />
					<Layout>
						<SingleCustomerSider />
						<SingleCustomerMainContent />
					</Layout>
				</Layout>
			</>
		);
	} else {
		return (
			<>
				<Layout className="windowLayout">
					<AppHeader />
					<Layout hasSider>
						<AppSider />
						<AppMainContent />
					</Layout>
				</Layout>
			</>
		);
	}
};

export default React.memo(AppLayout);
