/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteAccessStepsRequest
 */
export interface DeleteAccessStepsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteAccessStepsRequest
     */
    accessStepIds: Array<string>;
}

/**
 * Check if a given object implements the DeleteAccessStepsRequest interface.
 */
export function instanceOfDeleteAccessStepsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accessStepIds" in value;

    return isInstance;
}

export function DeleteAccessStepsRequestFromJSON(json: any): DeleteAccessStepsRequest {
    return DeleteAccessStepsRequestFromJSONTyped(json, false);
}

export function DeleteAccessStepsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteAccessStepsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessStepIds': json['accessStepIds'],
    };
}

export function DeleteAccessStepsRequestToJSON(value?: DeleteAccessStepsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessStepIds': value.accessStepIds,
    };
}

