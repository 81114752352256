import React, { useEffect, useRef, useState } from 'react';
import DataTable from '../../shared/DataTable';
import { type ColumnsType } from 'antd/es/table';
import { type AccessSessionHistoriesResponseAccessSession } from '../../tools/openapi/models/AccessSessionHistoriesResponseAccessSession';
import { Avatar, Button, Card, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { type RootState } from '../../store/store';
import { type StartResponse } from '../../tools/openapi';
import '../accessSessions/AccessSessionsList/AccessSession.scss';
import AccessSessionsFactorWithIcon from '../accessSessions/AccessSessionsList/accessSessionsTable/AccessSessionsFactorWithIcon';
import useMgmtApiWrapper from '../../hooks/openapi/useMgmtApiWrapper';

interface Props {
	id: string | undefined;
}

const { Title } = Typography;

const ActivityPageAccessSessionTable: React.FC<Props> = (props: Props) => {
	const mgmtApiWrapper = useMgmtApiWrapper();
	const navigate = useNavigate();
	const tableRef = useRef<HTMLDivElement | null>(null);

	const startState: StartResponse = useAppSelector(
		(state: RootState) => state.startState.startState
	);

	const [data, setData] = useState<
		AccessSessionHistoriesResponseAccessSession[]
	>([]);

	const columns: ColumnsType<AccessSessionHistoriesResponseAccessSession> = [
		{
			title: 'User',
			dataIndex: 'userName',
			render: (_, record) => <span>{record.userName}</span>,
		},
		{
			title: 'Time',
			dataIndex: 'createdAt',
			render: (_, record) => <span>{record.startedAt?.toLocaleString()}</span>,
		},
		{
			title: 'Customer',
			dataIndex: 'customerName',
			render: (_, record) => <span>{record.customerName}</span>,
		},
		{
			title: 'Factor',
			dataIndex: 'concludedFactor',
			render: (_, record) => <AccessSessionsFactorWithIcon record={record} />,
		},
		{
			title: 'Photo',
			render: (_, record) => (
				<>
					{record.photoUrl != null ? (
						<Avatar size={40} src={record.photoUrl} />
					) : (
						''
					)}
				</>
			),
		},
	];

	const openAccessSessions = (): void => {
		navigate('/access_session');
	};

	const getStartDateOfCurrentDay = (): Date => {
		const currentDate = new Date();
		const startDate = new Date(currentDate);
		startDate.setDate(currentDate.getDate() - 5);
		startDate.setHours(0, 0, 0, 0); // Reset time to 00:00:00.000
		return startDate;
	};

	const getEndDateOfCurrentDay = (): Date => {
		const currentDate = new Date();
		const endDate = new Date(currentDate);
		endDate.setDate(currentDate.getDate() + 2);
		endDate.setHours(23, 59, 59, 999); // Set time to 23:59:59.999
		return endDate;
	};

	const getAccessSessionHistory = (): void => {
		const requestBody = {
			environmentId: props.id,
			pageNumber: 0,
			pageSize: 10,
			startDate: getStartDateOfCurrentDay(),
			endDate: getEndDateOfCurrentDay(),
		};
		void mgmtApiWrapper
			.getAccessSessionHistories(requestBody)
			.then((r) => {
				if (r !== undefined) {
					setData(r.accessSessions);
				}
			})
	};

	const rowClassName = (
		record: AccessSessionHistoriesResponseAccessSession
	): string => {
		if (record.succeeded === false) {
			return 'unsuccessful-session';
		}
		return '';
	};

	const accessTableTitle = (
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				width: '100%',
			}}
		>
			<span>Recent access events</span>
			<Button type="primary" onClick={openAccessSessions}>
				View More
			</Button>
		</div>
	);

	useEffect(() => {
		getAccessSessionHistory();
	}, []);

	if (startState.account?.account.singleCustomer === true) {
		return (
			<Card>
				<Title level={4}>{accessTableTitle}</Title>
				<div ref={tableRef}>
					<DataTable
						rowClassName={rowClassName}
						data={data}
						columns={columns?.filter((column) => column.title !== 'Customer')}
						rowKey={'id'}
						tableStyle={undefined}
						pagination={true}
					/>
				</div>
			</Card>
		);
	}

	return (
		<Card>
			<Title level={4}>{accessTableTitle}</Title>
			<div ref={tableRef}>
				<DataTable
					rowClassName={rowClassName}
					data={data}
					columns={columns}
					rowKey={'id'}
					tableStyle={undefined}
					pagination={true}
				/>
			</div>
		</Card>
	);
};

export default React.memo(ActivityPageAccessSessionTable);
