import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DangerZone from '../../../../../shared/DangerZone';
import DeleteConfirmation from '../../../../../shared/DeleteConfirmation';
import { useAppSelector } from '../../../../../store/hooks';
import { type RootState } from '../../../../../store/store';
import { type User } from '../../../../../tools/openapi/models/User';
import useMgmtApiWrapper from '../../../../../hooks/openapi/useMgmtApiWrapper';

const UserDeletion: React.FC = () => {
	const mgmtApiWrapper = useMgmtApiWrapper();

	const userDetail = useAppSelector(
		(state: RootState) => state.userDetail.user as User
	);

	const [isModalVisible, setIsModalVisible] = useState(false);
	const navigate = useNavigate();
	const handleDelete = (): void => {
		void mgmtApiWrapper
			.deleteUser({ userId: userDetail.id })
			.then(() => {
				setIsModalVisible(false);
				navigate(`/user`);
			})
	};

	return (
		<>
			<DangerZone
				title="Delete User"
				description="This user will be removed and it will no longer have the application acess."
				onConfirm={() => {
					setIsModalVisible(true);
				}}
			/>
			<DeleteConfirmation
				title="Are you sure? "
				message={`This action will permanently delete the user. It cannot be undone. `}
				messageAdd={`Are you absolutely sure?`}
				visible={isModalVisible}
				centered={true}
				inputDisplay={'none'}
				onCancel={() => {
					setIsModalVisible(false);
				}}
				onConfirm={handleDelete}
			/>
		</>
	);
};

export default React.memo(UserDeletion);
