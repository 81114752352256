import { Row, Col, Select } from 'antd';
import React from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import { type RootState } from '../../../../../../store/store';
import { type UpdateCustomerSettingsRequest } from '../../../../../../tools/openapi';
import { QuestionCircleTwoTone } from '@ant-design/icons';

interface Props {
	updateSettings: <T extends keyof UpdateCustomerSettingsRequest>(
		target: T,
		value: UpdateCustomerSettingsRequest[T]
	) => void;
}

const { Option } = Select;

const CustomerPinLimit: React.FC<Props> = (props) => {
	const pinAttemptLimit = useAppSelector(
		(state: RootState) => state.customerDetail.pinAttemptLimit
	);

	return (
		<Row>
			<Col span={20}>
				PIN Attempt Limit:{' '}
				<QuestionCircleTwoTone title="Set a maximum number of incorrect pin attempts allowed." />
			</Col>
			<Col span={4} push={12}>
				<Select
					style={{ alignItems: 'center' }}
					value={pinAttemptLimit}
					onChange={(value: number) => {
						props.updateSettings('pinAttemptLimit', value);
					}}
				>
					{[...Array(10)].map((_, index) => (
						<Option key={index + 1} value={index + 1}>
							{index + 1}
						</Option>
					))}
				</Select>
			</Col>
		</Row>
	);
};
export default React.memo(CustomerPinLimit);
