/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccessFactor } from './AccessFactor';
import {
    AccessFactorFromJSON,
    AccessFactorFromJSONTyped,
    AccessFactorToJSON,
} from './AccessFactor';

/**
 * 
 * @export
 * @interface FaceAccessResponseIdentifiedInstruction
 */
export interface FaceAccessResponseIdentifiedInstruction {
    /**
     * 
     * @type {boolean}
     * @memberof FaceAccessResponseIdentifiedInstruction
     */
    concluded: boolean;
    /**
     * 
     * @type {number}
     * @memberof FaceAccessResponseIdentifiedInstruction
     */
    confirmWaitMs: number;
    /**
     * 
     * @type {Array<AccessFactor>}
     * @memberof FaceAccessResponseIdentifiedInstruction
     */
    negateFallbackSteps: Array<AccessFactor>;
}

/**
 * Check if a given object implements the FaceAccessResponseIdentifiedInstruction interface.
 */
export function instanceOfFaceAccessResponseIdentifiedInstruction(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "concluded" in value;
    isInstance = isInstance && "confirmWaitMs" in value;
    isInstance = isInstance && "negateFallbackSteps" in value;

    return isInstance;
}

export function FaceAccessResponseIdentifiedInstructionFromJSON(json: any): FaceAccessResponseIdentifiedInstruction {
    return FaceAccessResponseIdentifiedInstructionFromJSONTyped(json, false);
}

export function FaceAccessResponseIdentifiedInstructionFromJSONTyped(json: any, ignoreDiscriminator: boolean): FaceAccessResponseIdentifiedInstruction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'concluded': json['concluded'],
        'confirmWaitMs': json['confirmWaitMs'],
        'negateFallbackSteps': ((json['negateFallbackSteps'] as Array<any>).map(AccessFactorFromJSON)),
    };
}

export function FaceAccessResponseIdentifiedInstructionToJSON(value?: FaceAccessResponseIdentifiedInstruction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'concluded': value.concluded,
        'confirmWaitMs': value.confirmWaitMs,
        'negateFallbackSteps': ((value.negateFallbackSteps as Array<any>).map(AccessFactorToJSON)),
    };
}

