import React from 'react';
import { type User } from '../../../../../tools/openapi';
import Title from 'antd/es/typography/Title';
import UserDeletion from './UserDeletion';
import UserDeactivation from './UserDeactivation';
import { useAppSelector } from '../../../../../store/hooks';
import { type RootState } from '../../../../../store/store';

interface Props {
	fetchUser: () => void;
}

const UserDangerZone: React.FC<Props> = (props: Props) => {
	const userDetail = useAppSelector(
		(state: RootState) => state.userDetail.user as User
	);

	if (userDetail.active) {
		return (
			<section className="section">
				<div>
					<Title className="section-header" level={5}>
						Danger Zone
					</Title>
					<UserDeletion />
					<UserDeactivation refetchUser={props.fetchUser} />
				</div>
			</section>
		);
	}

	return (
		<section className="section">
			<div>
				<Title className="section-header" level={5}>
					Danger Zone
				</Title>
				<UserDeletion />
			</div>
		</section>
	);
};

export default React.memo(UserDangerZone);
