import produce from 'immer';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import {
	type PinConfig,
	type CustomerResponseCustomer,
} from '../tools/openapi';

const initialState = {
	customer: {},
	allowManualEntry: false,
	allowFaceRegistrationOnClient: false,
	pinCodeDigit: 'FOUR_DIGIT' as PinConfig,
	previousPinCodeDigit: 'FOUR_DIGIT' as PinConfig,
	pinAttemptLimit: 3,
	requireApprovalOnFaceRegistration: false,
	isPinModalOpen: false,
	autoTakingPhotoOnIdentification: true,
};

const customerDetailReducer = createSlice({
	name: 'customerDetail',
	initialState,
	reducers: {
		setCurrentCustomerDetail: (
			state,
			action: PayloadAction<CustomerResponseCustomer>
		) => {
			return produce(state, (draftState) => {
				draftState.customer = action.payload;
			});
		},
		resetCurrentCustomerDetail: (state) => {
			return produce(state, (draftState) => {
				draftState.customer = {};
			});
		},
		setAllowManualEntry: (state, action: PayloadAction<boolean>) => {
			return produce(state, (draftState) => {
				draftState.allowManualEntry = action.payload;
			});
		},
		setAllowFaceRegistrationOnClient: (
			state,
			action: PayloadAction<boolean>
		) => {
			return produce(state, (draftState) => {
				draftState.allowFaceRegistrationOnClient = action.payload;
			});
		},
		setPinCodeDigit: (state, action: PayloadAction<PinConfig>) => {
			return produce(state, (draftState) => {
				draftState.pinCodeDigit = action.payload;
			});
		},
		setPreviousPinCodeDigit: (state, action: PayloadAction<PinConfig>) => {
			return produce(state, (draftState) => {
				draftState.previousPinCodeDigit = action.payload;
			});
		},
		setPinAttemptLimit: (state, action: PayloadAction<number>) => {
			return produce(state, (draftState) => {
				draftState.pinAttemptLimit = action.payload;
			});
		},
		setRequireApprovalOnFaceRegistration: (
			state,
			action: PayloadAction<boolean>
		) => {
			return produce(state, (draftState) => {
				draftState.requireApprovalOnFaceRegistration = action.payload;
			});
		},
		setIsPinModalOpen: (state, action: PayloadAction<boolean>) => {
			return produce(state, (draftState) => {
				draftState.isPinModalOpen = action.payload;
			});
		},
		setAutoTakingPhotoOnIdentification: (
			state,
			action: PayloadAction<boolean>
		) => {
			return produce(state, (draftState) => {
				draftState.autoTakingPhotoOnIdentification = action.payload;
			});
		},
	},
});

export const {
	setCurrentCustomerDetail,
	resetCurrentCustomerDetail,
	setAllowManualEntry,
	setAllowFaceRegistrationOnClient,
	setPinCodeDigit,
	setPreviousPinCodeDigit,
	setPinAttemptLimit,
	setRequireApprovalOnFaceRegistration,
	setIsPinModalOpen,
	setAutoTakingPhotoOnIdentification,
} = customerDetailReducer.actions;
export default customerDetailReducer.reducer;
