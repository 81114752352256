/* tslint:disable */
/* eslint-disable */
/**
 * Facade API
 * Facade API for external consumption with API token auth.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: dev-relationship@facade.cloud
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PinConfig = {
    FourDigit: 'FOUR_DIGIT',
    SixDigit: 'SIX_DIGIT'
} as const;
export type PinConfig = typeof PinConfig[keyof typeof PinConfig];


export function PinConfigFromJSON(json: any): PinConfig {
    return PinConfigFromJSONTyped(json, false);
}

export function PinConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): PinConfig {
    return json as PinConfig;
}

export function PinConfigToJSON(value?: PinConfig | null): any {
    return value as any;
}

