import { Button, Form } from 'antd';
import React, { useState } from 'react';
import EnvironmentsCreateAndUpdateModal from './EnvironmentsCreateAndUpdateModal';

interface Props {
	fetchEnvironments: () => void;
}

const EnvironmentsCreateButton: React.FC<Props> = (props: Props) => {
	const [form] = Form.useForm();

	const [modalVisible, setModalVisible] = useState(false);

	return (
		<div className="create-envir-button">
			<Button
				type="primary"
				onClick={() => {
					setModalVisible(true);
				}}
			>
				Create Environment
			</Button>
			<EnvironmentsCreateAndUpdateModal
				form={form}
				modalVisible={modalVisible}
				isCreate={true}
				setModalVisible={setModalVisible}
				fetchEnvironments={props.fetchEnvironments}
			/>
		</div>
	);
};

export default React.memo(EnvironmentsCreateButton);
