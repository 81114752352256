import React from 'react';
import { type AccountMemberResponse } from '../../../tools/openapi';
import { message } from 'antd';
import { setAccountMemberList } from '../../../store/accountMemberReducer';
import { useAppDispatch } from '../../../store/hooks';
import useMgmtApiWrapper from '../../../hooks/openapi/useMgmtApiWrapper';

interface Props {
	record: AccountMemberResponse;
}

const AccountMemberInRowDelete: React.FC<Props> = (props: Props) => {
	const dispatch = useAppDispatch();
	const mgmtApiWrapper = useMgmtApiWrapper();

	const fetchData = (): void => {
		void mgmtApiWrapper.getAccountMembers().then((r) => {
			if (r !== undefined) {
				dispatch(setAccountMemberList(r.accountMembers));
			}
		});
	};

	const handleDelete = (record: AccountMemberResponse): void => {
		if (record.role.toLowerCase() === 'owner') {
			void message.warning('Account Owner Cannot Be Deleted.');
		} else {
			void mgmtApiWrapper
				.deleteAccountMember({ accountMemberId: record.id })
				.then(() => {
					fetchData();
				});
		}
	};

	return (
		<>
			<a
				onClick={() => {
					handleDelete(props.record);
				}}
			>
				Delete
			</a>
		</>
	);
};

export default React.memo(AccountMemberInRowDelete);
