import { type UploadFile, type UploadProps, Upload, Modal } from "antd";
import { type RcFile } from "antd/es/upload";
import React, { type Dispatch, type SetStateAction, useState, type ComponentState } from "react";
import useMgmtApi from "../../../../../../hooks/openapi/useMgmtApi";
import {type UploadFaceRegistrationImageRequest } from "../../../../../../tools/openapi";
import { PlusOutlined } from '@ant-design/icons';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import useMgmtApiWrapper from "../../../../../../hooks/openapi/useMgmtApiWrapper";

interface Props {
    registrationId: string;
    setSucceededUpload: Dispatch<SetStateAction<ComponentState>>;
	fileList: UploadFile[];
	setFileList: Dispatch<SetStateAction<ComponentState>>;
}

const getBase64 = async (file: RcFile): Promise<string> =>
	await new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			resolve(reader.result as string);
		};
		reader.onerror = (error) => {
			reject(error);
		};
	});

const UserFaceUpload: React.FC<Props> = ({
	registrationId, setSucceededUpload,fileList,setFileList
}) => {
    const mgmtApi = useMgmtApi();
	const mgmtApiWrapper = useMgmtApiWrapper();
	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState('');
	const [previewTitle, setPreviewTitle] = useState('');
	const handlePreviewCancel = (): void => {
		setPreviewOpen(false);
	};

	const handlePreview = async (file: UploadFile): Promise<void> => {
		if (file.url == null && file.preview == null) {
			file.preview = await getBase64(file.originFileObj as RcFile);
		}
		setPreviewImage(file.url ?? (file.preview as string));
		setPreviewOpen(true);
		setPreviewTitle(
			file.name ?? file.url?.substring(file.url?.lastIndexOf('/') + 1)
		);
	};

	const handleChange: UploadProps['onChange'] = ({
		fileList: newFileList,
	}): void => {
		setFileList(newFileList);
	};

	const uploadButton = (
		<div>
			<PlusOutlined />
			<div style={{ marginTop: 8 }}>Upload</div>
		</div>
	);

	const handleUpload = (option: UploadRequestOption): void => {
		const file: RcFile = option.file as RcFile;
		const requestBody: UploadFaceRegistrationImageRequest = {
			registrationId,
			file: option.file as Blob,		};
		void mgmtApi
			.uploadFaceRegistrationImage(requestBody)
			.then((r) => {
				console.log(r);
				if (option.onSuccess !== undefined) {
					option.onSuccess(r);
					setSucceededUpload((prev: number) => prev  + 1);
				}
			})
			.catch(async (error) => {
				if (option.onError !== undefined) {
					option.onError(error.response);
				}
				void mgmtApiWrapper.handleError(error);
				setTimeout(() => {
					setFileList((prevFileList: UploadFile[]) => {
						const updatedFileList = prevFileList.filter(
							(f) => f.uid !== file.uid
						);
						return updatedFileList;
					});
				}, 3000);
			});
	};


	return (
		<>
			<Upload
				accept=".jpg , .png"
				customRequest={handleUpload}
				listType="picture-card"
				fileList={fileList}
				onPreview={(file) => {
					void handlePreview(file);
				}}
				onChange={handleChange}
				multiple={true}
				showUploadList={{ showRemoveIcon: false }}
			>
				{fileList.length >= 10 ? null : uploadButton}
			</Upload>
			<Modal
				open={previewOpen}
				title={previewTitle}
				footer={null}
				onCancel={handlePreviewCancel}
				centered={true}
			>
				<img alt="example" style={{ width: '100%' }} src={previewImage} />
			</Modal>
		</>
	);
};

export default React.memo(UserFaceUpload);
