import { Row, Col } from 'antd';
import React from 'react';
import { type UpdateCustomerSettingsRequest } from '../../../../../../../tools/openapi';
import CustomerPinRadioGroup from './CustomerPinRadioGroup';
import { QuestionCircleTwoTone } from '@ant-design/icons';

interface Props {
	updateSettings: <T extends keyof UpdateCustomerSettingsRequest>(
		target: T,
		value: UpdateCustomerSettingsRequest[T]
	) => void;
	updateOperation: <T extends keyof UpdateCustomerSettingsRequest>(
		target: T,
		value: UpdateCustomerSettingsRequest[T]
	) => void;
}

const CustomerPinDigitRow: React.FC<Props> = (props) => {
	return (
		<Row>
			<Col span={20}>
				PIN Code Length:{' '}
				<QuestionCircleTwoTone title="Set the number of digits required for user-specific pin codes." />
			</Col>
			<Col span={4} push={12}>
				<CustomerPinRadioGroup
					updateSettings={props.updateSettings}
					updateOperation={props.updateOperation}
				/>
			</Col>
		</Row>
	);
};
export default React.memo(CustomerPinDigitRow);
